import Background from '../../templates/background';
import Tab from '../../atoms/tab';
import Tabs from '../../molecules/tabs';
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import BadgeTarget from '../../../resources/images/badge-target.png';
import Megaphone from '../../../resources/images/megaphone.png';
import ChallengeModal from '../../molecules/challenge-modal';
import Card from '../../atoms/card';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../middleware/providers/user-context';
import WelcomeModal from '../../organisms/welcome-modal';
import PortalBridge from '../../atoms/portal-bridge';
import Button from '../../atoms/button';
import { API, ROLES } from '../../../middleware/utils/constants';
import axios from 'axios';
import CarouselModal from '../../molecules/carousel-modal';
import CarouselSlide from '../../atoms/carousel-slide';
import InfoBox from '../../molecules/info-box';
import Paragraph from '../../atoms/paragraph';
import MyProgress from '../../organisms/my-progress';
import Menu from '../../organisms/menu';
import MyValidatorsTab from '../../organisms/my-validators-tab';
import { TabsContext } from '../../../middleware/providers/tabs-context';
import MyPerformance from '../../organisms/my-performance';
import Modal from '../../atoms/modal';
import Recognition from "../../../resources/images/recognition-medal.svg";
import TimeManagement from "../../../resources/images/time-management-medal.svg";
import JustInTime from "../../../resources/images/just-in-time-medal.svg";
import ClapHand from "../../../resources/images/clap-candidate.png";
import { getSchedule } from "../../../middleware/services/common";
import { indexes } from "../../../middleware/utils/constants";
import { formatDateLong } from "../../../middleware/utils/formatDate";
const MissionsPage = () => {

    const { user, medalModal, setMedalModal, login, setCurrentChallenge } = useContext(UserContext);
    const { activeTab, setActiveTab } = useContext(TabsContext);
  //  const navigate = useNavigate();

    const [role, setRole] = useState('');
    const roleName = ROLES[role]?.roleName;
    const level = ROLES[role]?.level;

    const [refresh, setRefresh] = useState(false);

    const [day, setDay] = useState('');
    //const [days, setDays] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [activeChallenge, setActiveChallenge] = useState(null);
    const [selector, setSelector] = useState('first');

    const [challengeOpen, setChallengeOpen] = useState(false);
    const [welcomeModal, setWelcomeModal] = useState(false);
    const [challengeModalContent, setChallengeModalContent] = useState({});
    const [welcomeFirstDayModal, setWelcomeFirstDayModal] = useState(false);
    const [welcomeSecondDayModal, setWelcomeSecondDayModal] = useState(false);
    const [welcomeThirdDayModal, setWelcomeThirdDayModal] = useState(false);
    const [welcomeFilterDayModal, setWelcomeFilterDayModal] = useState(false);
    const [welcomeAligmentDayModal, setWelcomeAligmentDayModal] = useState(false);
    const [menuShow, setMenuShow] = useState(false);
    const [completedChallenge, setCompletedChallenge] = useState(false);
    const location = useLocation();

    const MEDALS = {
        recognition: {
            img: Recognition,
            text: 'Reconocimiento'
        },
        timeManagement: {
            img: TimeManagement,
            text: 'Manejo del tiempo'
        },
        justInTime: {
            img: JustInTime,
            text: 'Justo a tiempo'
        }
    };
    const [schedule, setSchedule] = useState([]);

    const fetchData = async (userId) => {
        const schedule = await getSchedule(userId);
        console.log(schedule)
        setSchedule(schedule);
    };
 
    useEffect(() => {
        if(user?._id){
            fetchData(user?._id);
        }
    }, [user]);
    const openChallengeModal = (challenge, index) => {

   
        const icon = ROLES[role]?.challenges?.[activeChallenge?.day]?.[index]?.icon;
        
        const itemsMedal = challenge?.description.filter((item) => item?.medal);
        const challengeModal = {
            icon: icon,
            name: challenge?.nameChallenge,
            items: challenge?.description.map((item, indexItem) => {
                return item?.activity && {
                    icon: item?.icon,
                    text: item?.activity
                }
            }),
            itemsMedal: itemsMedal.map((item, indexItem) => {
                return item?.medal && {
                    icon: item?.icon,
                    text: item?.medal
                };
            }),
        };

        setChallengeModalContent(challengeModal);
        setChallengeOpen(true);
    };

    const finishChallenge = () => {
        axios.put(`${API.CHALLENGE_COMPLETED.url}/${user?._id}/${activeChallenge?.idChallenges}`).then((response) => {
            if (response.status === 200) {
                setRefresh(!refresh);
                setCompletedChallenge(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        axios.get(`${API.GET_INFORMATION.url}`).then((response) => {
            if (response?.status === 200) {
                const newRole = response?.data?.data?.role?.indexOf('new') > -1 ? response?.data?.data?.role?.split(' ')?.[1] : response?.data?.data?.role;
                setRole(newRole);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.PROGRESS_POINT.url}`).then((response) => {
            const data = response.data.data;
            if (data?.message !== 'This user has no challenges yet') {
                setChallenges(data);
                setDay(data[0]?.nameDay);
                setChallenges(data);
                console.log(data)
                const active = data?.find((challenge, index) => {
                    if (data[index - 1]?.status && !challenge?.status) {
                        return challenge;
                    }
                }) || data[0];

                setActiveChallenge(active);
                setCurrentChallenge(active);
                getSelectorPosition(active);

                if (!data[0]?.status && active?.day === 'rules day') {
                    setWelcomeModal(true);
                }

                if (!data[0]?.status && active?.day === 'day one') {
                    setWelcomeFirstDayModal(true);
                }

                if (active?.day === 'day two' && !data[0]?.status) {
                    setWelcomeSecondDayModal(true);
                }

                if (active?.day === 'day three' && !data[0]?.status) {
                    setWelcomeThirdDayModal(true);
                }

                if (active?.day === 'filter day' && !data[0]?.status) {
                    setWelcomeFilterDayModal(true);
                }

                if (active?.day === 'alignment day' && !data[0]?.status) {
                    setWelcomeAligmentDayModal(true);
                }

            }
        }).catch((error) => {
            console.log(error);
            setChallenges([]);
        });



        if (location.state?.refresh) {
            login();
        }
    }, [refresh, location.state, completedChallenge]);

    useEffect(() => {
        if (medalModal !== '' && medalModal?.typeMedals !== 'wildcard' && medalModal !== 'continue' || activeChallenge?.timeManagement > 0) {
            if ((activeChallenge?.day === 'day one' || activeChallenge?.day === 'day two' || activeChallenge?.day === 'day three') && activeChallenge?.nameChallenge === 'Desafío A') {
                setCompletedChallenge(true);
            }
        }
    }, [medalModal, activeChallenge]);

    const getSelectorPosition = (active) => {
        switch (active?.day) {
            case 'induction day':
                setSelector('first');
                break;
            case 'rules day':
                setSelector('second');
                break;
            case 'day one':
                setSelector('third');
                break;
            case 'day two':
                setSelector('fourth');
                break;
            case 'day three':
                setSelector('fifth');
                break;
            case 'filter day':
                setSelector('sixth');
                break;
            case 'alignment day':
                setSelector('seventh');
                break;
            case 'epa day': 
                setSelector('eighth');
                break;
            case 'end of epa':
                setSelector('ninth');
                break;
            default:
                setSelector('first');
                break;
        };
    };

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };

    return (
        <Background className={`missions-page ${activeTab === 2 ? 'auto' : ''}`}>
            <header>
                <div className={`logo logo--${role}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => { }}></span>
            </header>
            <h4 className="title">Misiones Diarias</h4>
            <Tabs >
                <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>Mi Progreso</Tab>
                {
                    (user?.role === 'candidate' || user?.role === 'new candidate') && ['filter day', 'alignment day', 'epa day', 'end of epa'].find((day) => activeChallenge?.day === day) &&
                    <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Mis Validadores</Tab>
                }
                {
                    user?.role === 'host' && ['induction day', 'rules day'].find((day) => activeChallenge?.day === day) &&
                    <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Mis Validadores</Tab>
                }
                {
                    (user?.role === 'host' || user?.role === 'new host') && !['induction day', 'rules day'].find((day) => activeChallenge?.day === day) &&
                    <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Mis Aspirantes</Tab>
                }
                {
                    (user?.role === 'coordinator' || user?.role === 'new coordinator') &&
                    <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Mis Anfitriones</Tab>
                }
                    {
                    (user?.role === 'director' || user?.role === 'new director') &&
                    <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Mis Anfitriones</Tab>
                }
                {
                    (user?.role === 'captain' || user?.role === 'new captain') &&
                    <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Mis Anfitriones</Tab>
                }
                <Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>Mi Desempeño</Tab>
            </Tabs>
            {activeTab === 0 && <MyProgress
                openChallengeModal={openChallengeModal}
                selector={selector}
                refresh={refresh}
                day={day} 
                role={role}
                />}
            {activeTab === 1 && <MyValidatorsTab idChallenge={activeChallenge?.idChallenges} activeChallenge={activeChallenge} />}
            {activeTab === 2 && <MyPerformance />}
            <ChallengeModal
                icon={challengeModalContent?.icon}
                name={challengeModalContent?.name}
                isOpen={challengeOpen}
                onClose={() => setChallengeOpen(false)}
            >
                <Card type="quinary">
                    <div className="card-list">
                        {challengeModalContent?.items?.map((item, index) => <div className="item" key={item.icon+index}>
                            <span className={`icon ${item?.icon}`}></span>
                            <div className="item--text">{item?.text}</div>
                        </div>)}
                    </div>
                </Card>

                {challengeModalContent?.itemsMedal?.length > 0 && <Card type="quinary">
                    <div className="card-list medals">
                        {challengeModalContent?.itemsMedal?.map((item, index) => item?.icon && <div className="item" key={item.icon+index}>
                            <span className={`icon ${item?.icon}`}></span>
                            <div className="item--text">{item?.text}</div>
                        </div>)}
                    </div>
                </Card>}
            </ChallengeModal>
            <WelcomeModal  role={role} roleName={roleName} level={level} isOpen={welcomeModal} onClose={() => setWelcomeModal(false)} />
            <CarouselModal isOpen={welcomeFirstDayModal}>
                <CarouselSlide >
                    <InfoBox
                        role={role}
                        roleName={roleName}
                        level={level}
                        day={<>{day}🔐🔐🔒</>}
                        title="¡Bienvenido(a) a la Plataforma ACDC!"
                        paragraph={<>Hoy inicias tu {day} Aspirante, fortalecerás tu cualidad de HAMBRE para lograr tu meta con el mejor desempeño.
                            <br />
                            <br />
                            Recuerda Aspirante, hoy conocerás más acerca de tu Propósito y como ganarte la llave H1 🗝️ HAMBRE, no olvides que no hay respuestas incorrectas, tu Anfitrión(a) está dispuesto(a) a ayudarte en lo que necesites.
                            <br />
                            <br />
                            ¡Sigue así Aspirante!
                        </>}
                    ></InfoBox>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section-bridge">
                        <h3>{activeChallenge?.nameDay}</h3>
                        <PortalBridge id="day-rules" role={role} selector={"third"}>
                        {schedule?.map((day, index) => <span key={indexes[index]} className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                    </PortalBridge>
                
                        <p>Deslizando el selector hacia <b>{activeChallenge?.nameDay}</b> en el Cronograma que verás en la pantalla, encontrarás, paso a paso, las actividades que debes realizar.</p>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <Paragraph size="s" align="center">
                        <img src={Megaphone} alt="megaphone" className="megaphone" />
                        <b>😉 Recordatorio 👇🏼</b>
                        <br />
                        <br />
                        No hay respuestas correctas o incorrectas.
                        <br />
                        <br />
                        TWOBOT 🤖 y tu Anfitrión(a)  ⚪ estarán contigo hasta el final.
                    </Paragraph>
                    <Button size="small" onClick={() => setWelcomeFirstDayModal(false)}>¡Vale!</Button>
                </CarouselSlide>
            </CarouselModal>
            <CarouselModal isOpen={welcomeSecondDayModal}>
                <CarouselSlide >
                    <InfoBox
                        role={role}
                        roleName={roleName}
                        level={level}
                        day={<>{day}🔐🔐🔒</>}
                        title="¡Bienvenido(a) a la Plataforma ACDC!"
                        paragraph={<>Hoy inicias tu Día 2 Aspirante, fortalecerás tu cualidad de HUMILDAD para lograr tu meta con el mejor desempeño.
                            <br />
                            <br />
                            Recuerda Aspirante, hoy conocerás más acerca de tu Destino y como ganarte la llave H2 🗝️ HUMILDAD
                            <br />
                            <br />
                            ¡Sigue así Aspirante!
                        </>}
                    ></InfoBox>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section-bridge">
                        <h3>Día 2</h3>
                        <PortalBridge id="day-rules" role={role} selector={"third"}>
                        {schedule?.map((day, index) => <span key={indexes[index]} className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                    </PortalBridge>
                        <p>Deslizando el selector hacia Día 2 en el Cronograma que verás en la pantalla, encontrarás, paso a paso, las actividades que debes realizar.</p>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <Paragraph size="s" align="center">
                        <img src={Megaphone} alt="megaphone" className="megaphone" />
                        <b>😉 Recordatorio 👇🏼</b>
                        <br />
                        <br />
                        No hay respuestas correctas o incorrectas.
                        <br />
                        <br />
                        TWOBOT 🤖 y tu Anfitrión(a)  ⚪ estarán contigo hasta el final.
                    </Paragraph>
                    <Button size="small" onClick={() => setWelcomeSecondDayModal(false)}>¡Vale!</Button>
                </CarouselSlide>
            </CarouselModal>
            <CarouselModal isOpen={welcomeThirdDayModal}>
                <CarouselSlide >
                    <InfoBox
                        role={role}
                        roleName={roleName}
                        level={level}
                        day={<>{day}🔐🔐🔒</>}
                        title="¡Bienvenido(a) a la Plataforma ACDC!"
                        paragraph={<>Hoy inicias tu Día 3 Aspirante, fortalecerás tu cualidad de HONOR para lograr tu meta con el mejor desempeño.
                            <br />
                            <br />
                            Recuerda Aspirante, hoy conocerás más acerca de tu Destino y como ganarte la llave H2 🗝️ HONOR
                            <br />
                            <br />
                            ¡Sigue así Aspirante!
                        </>}
                    ></InfoBox>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section-bridge">
                        <h3>Día 3</h3>
                  
                        <PortalBridge id="day-rules" role={role} selector={"fourth"}>
                        {schedule?.map((day, index) => <span key={indexes[index]} className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                    </PortalBridge>
                
                        <p>Deslizando el selector hacia Día 3 en el Cronograma que verás en la pantalla, encontrarás, paso a paso, las actividades que debes realizar.</p>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <Paragraph size="s" align="center">
                        <img src={Megaphone} alt="megaphone" className="megaphone" />
                        <b>😉 Recordatorio 👇🏼</b>
                        <br />
                        <br />
                        No hay respuestas correctas o incorrectas.
                        <br />
                        <br />
                        TWOBOT 🤖 y tu Anfitrión(a)  ⚪ estarán contigo hasta el final.
                    </Paragraph>
                    <Button size="small" onClick={() => setWelcomeThirdDayModal(false)}>¡Vale!</Button>
                </CarouselSlide>
            </CarouselModal>
            <CarouselModal isOpen={welcomeFilterDayModal}>
                <CarouselSlide >
                    <InfoBox
                        role={role}
                        roleName={roleName}
                        level={level}
                        day={<>{day}🔐🔐🔒</>}
                        title="¡Bienvenido(a) a la Plataforma ACDC!"
                        paragraph={<>Recuerda que:
                            <br />
                            Tu prioridad hoy, es sincronizarte con tu Anfitrión(a). Tendrás que enviar los correos de validación a tus referencias personales.  Serán tus Validadores.
                            <br />
                            <br />
                            ¡Entre hoy y mañana podrás ser preseleccionado(a) por tu buen desempeño y ganarte un ticket de entrada al EPA!
                        </>}
                    ></InfoBox>
                </CarouselSlide>
                <CarouselSlide>
                    <img src={BadgeTarget} alt="badge-target" className="badge-target" />
                    <Paragraph size="s" align="center">
                        Te encuentras cumpliendo tu
                        <br />
                        Meta 2.
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Conseguir 6 validaciones y ser uno de los tres preseleccionados para asistir al EPA.
                        <br />
                        <br />
                        Tienes hasta el Día de Alineamiento para conseguirlo.
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        Recuerda que puedes revisar el estado de tus metas siempre desde <b>Mi Desempeño.</b>
                    </Paragraph>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section-bridge">
                        <h3>Día de Filtro</h3>
                        <PortalBridge id="day-rules" role={role} selector={"fifth"}>
                            {schedule?.map((day, index) => <span key={indexes[index]} className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                        </PortalBridge>
                        <p>Deslizando el selector hacia Día de Filtro en el Cronograma que verás en la pantalla, encontrarás, paso a paso, las actividades que debes realizar.</p>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <Paragraph size="s" align="center">
                        <img src={Megaphone} alt="megaphone" className="megaphone" />
                        <b>😉 Recordatorio 👇🏼</b>
                        <br />
                        <br />
                        No hay respuestas correctas o incorrectas.
                        <br />
                        <br />
                        TWOBOT 🤖 y tu Anfitrión(a)  ⚪ estarán contigo hasta el final.
                    </Paragraph>
                    <Button size="small" onClick={() => setWelcomeFilterDayModal(false)}>¡Vale!</Button>
                </CarouselSlide>
            </CarouselModal>
            <CarouselModal isOpen={welcomeAligmentDayModal}>
                <CarouselSlide >
                    <InfoBox
                        role={role}
                        roleName={roleName}
                        level={level}
                        day={<>{day}🔐🔐🔒</>}
                        title="¡Bienvenido(a) a la Plataforma ACDC!"
                        paragraph={<>Has logrado llegar a un súper día 👌🏼
                            El <b>Día de Alineamiento 🏁</b>
                            <br />
                            <br />
                            Hoy:
                            <br />
                            👉🏼 Enviarás los correos a los nuevos validadores necesarios para completar la meta del Desafío B.
                            <br />
                            👉🏼 Completarás las 6 validaciones personales.
                            <br />
                            👉🏼 Y comenzarás tu preparación para el siguiente Nivel de Influencia, Desempeño.
                        </>}
                    ></InfoBox>
                </CarouselSlide>
                <CarouselSlide>
                    <img src={BadgeTarget} alt="badge-target" className="badge-target" />
                    <Paragraph size="s" align="center">
                        Te encuentras cumpliendo tu
                        <br />
                        Meta 2.
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Conseguir 6 validaciones y ser uno de los tres preseleccionados para asistir al EPA.
                        <br />
                        <br />
                        Tienes hasta el Día de Alineamiento para conseguirlo.
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        Recuerda que puedes revisar el estado de tus metas siempre desde <b>Mi Desempeño.</b>
                    </Paragraph>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section-bridge">
                        <h3>Día de Alineamiento</h3>
                        <PortalBridge id="day-rules" role={role} selector={"fifth"}>
                            {schedule?.map((day, index) => <span key={indexes[index]} className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                        </PortalBridge>
                        <p>Deslizando el selector hacia Día de Filtro en el Cronograma que verás en la pantalla, encontrarás, paso a paso, las actividades que debes realizar.</p>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <Paragraph size="s" align="center">
                        <img src={Megaphone} alt="megaphone" className="megaphone" />
                        <b>😉 Recordatorio 👇🏼</b>
                        <br />
                        <br />
                        No hay respuestas correctas o incorrectas.
                        <br />
                        <br />
                        TWOBOT 🤖 y tu Anfitrión(a)  ⚪ estarán contigo hasta el final.
                    </Paragraph>
                    <Button size="small" onClick={() => setWelcomeAligmentDayModal(false)}>¡Vale!</Button>
                </CarouselSlide>
            </CarouselModal>
            <Menu role={role} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
            <Modal isOpen={completedChallenge && !activeChallenge?.status} className="confirmation-register-modal">
                <div className="confirmation-register-container">
                    <div className="light">
                        <img src={ClapHand} alt="Clap Candidate" />
                    </div>
                    <Paragraph align="center" size="s">
                        ¡Genial!
                        <br />
                        Has completado el <b>Desafío A.</b>
                        <br />
                        <br />
                        Ya puedes continuar con el <b>Desafío B</b> del {activeChallenge?.nameDay}.
                        <br />
                        <br />
                        <b>👋🏻  Ya nos vemos.</b>
                    </Paragraph>
                    <Button size="small" onClick={() => {
                        finishChallenge();
                    }}>¡Vale!</Button>
                </div>
            </Modal>
            <Modal isOpen={medalModal !== '' && medalModal?.typeMedals !== 'wildcard' && medalModal !== 'continue'} className="confirmation-register-modal medal-modal">
                <div className="confirmation-register-container">
                    <img src={MEDALS[medalModal?.typeMedals]?.img} alt="Time Management" style={{ opacity: medalModal.medal ? '1' : '0.5' }} />
                    {medalModal.medal ? <><Paragraph align="center" size="s">
                        ¡Has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                    </Paragraph>
                        <Paragraph align="center" size="s">
                            Estas te servirán para medir tu desempeño y poder ser uno de los 3 preseleccionados para asistir al EPA.
                        </Paragraph></> :
                        <Paragraph align="center" size="s">
                            ¡No has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                    }
                    <Button size="small" onClick={() => {
                        setMedalModal('');
                    }}>¡Vale!</Button>
                </div>
            </Modal>
        </Background >
    );
};

export default MissionsPage;