import React from 'react';

const GroupRadio = ({ name, children, value, onChange, disabled }) => {
    return (
        <div className="group-radio" name={name}>
            {
                !value ?
                React.Children.map(children, (child) => React.cloneElement(child, { name, onChange, disabled}))
                : React.Children.map(children, (child) => React.cloneElement(child, { name, onChange, disabled, checked: child.props.value === value}))
            }
        </div>
    );
};

export default GroupRadio;