import React from 'react'

import Paragraph from '../../atoms/paragraph'
import MoneyHand from "../../../resources/images/money-hand.png";
import './index.scss';
import Button from '../../atoms/button';

export const ChallengeHonorFourth = ({ setStep, step }) => {


  return (
    <>
      <img src={MoneyHand} alt="Money Hand" className="money-hand" />
      <Paragraph size="s" align="center" className="text-honor honor-width">
        En total, tu equipo ACDC tiene el potencial de generar USD 400 mil al mes. Por lo tanto, el aporte de tu equipo, correspondiente al 10% para el Ecosistema de Innovación y emprendimiento ACDC sería potencialmente de USD 40 mil mensuales, de los cuáles USD 4 mil mensuales irán a las Fundaciones que el equipo elija.
      </Paragraph>
      <br />
      <br />
      <br />
      <br />
      <Button onClick={() => setStep(step + 1)}>Continuar</Button>
    </>
  )
}
