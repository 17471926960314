const Step = ({children, order, visibility, className}) => {

    const style = {
        visibility,
        width: 0,
        height: 0,
        margin: 0,
        padding: 0,
    };

    return (
        <div className={`step ${className}`} id={`step-${order}`} style={visibility === 'hidden' ? style : {}}>
            {children}
        </div>
    );
};

Step.displayName = 'Step';

export default Step;