import { useState } from "react";
import CreditCardsLogo from "../../../resources/images/credit-cards.png";
import PSEIcon from "../../../resources/images/pse-icon.png";
import NequiIcon from "../../../resources/images/nequi-icon.png";
import PaymentMethod from "../../atoms/payment-method";

const PaymentMethodSelector = ({ callback }) => {

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const handlePaymentMethodClick = (e) => {
        const paymentMethod = e.target.getAttribute('name');
        setSelectedPaymentMethod(paymentMethod);
        callback?.(paymentMethod);
    };

    return (
        <div className="payment-method-selector">
            <PaymentMethod name="credit-cards" label="Paga con tus tarjetas" logo={CreditCardsLogo} onClick={handlePaymentMethodClick} selected={selectedPaymentMethod === 'credit-cards'} />
            <PaymentMethod name="pse" label="Paga con tu cuenta PSE" logo={PSEIcon} onClick={handlePaymentMethodClick} selected={selectedPaymentMethod === 'pse'} disabled />
            <PaymentMethod name="nequi" label="Paga por medio de NEQUI" logo={NequiIcon} onClick={handlePaymentMethodClick} selected={selectedPaymentMethod === 'nequi'} disabled />
        </div>
    );
};

export default PaymentMethodSelector;