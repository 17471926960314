import ProgressBar from "../../atoms/progress-bar";
import { ReactComponent as MedalsCore } from "../../../resources/images/medals-core.svg";
import { UserContext } from "../../../middleware/providers/user-context";
import { useContext } from "react";
import { ReactComponent as TimeManagement } from "../../../resources/images/time-management-medal-new.svg";
import { ReactComponent as Recognition } from "../../../resources/images/recognition-medal-new.svg";
import { ReactComponent as JustInTime } from '../../../resources/images/just-in-time-medal-new.svg';
import { ReactComponent as ComodinMedal } from '../../../resources/images/comodin-medal.svg';

const Medals = ({ role, recognition, timeManagement, justInTime }) => {

    const { user } = useContext(UserContext);
    const recognitionDefault = recognition || user?.recognition;
    const timeManagementDefault = timeManagement || user?.timeManagement;
    const justInTimeDefault = justInTime || user?.justInTime;

    const medalPercentage = (medal) => {
        const percentage = (medal?.has / medal?.of) * 100;
        console.log(percentage, medal?.has, medal?.of);
        if (percentage > 100) {
            return 100;
        }
        return percentage;
    };



    return (
        <div className={"medals-container"}>
            <div className={`core core--${role}`}>
                <MedalsCore />
            </div>
            <div className={`medal-recognition`}>
                <div className="medal-recognition__container">
                    <span className="medal-recognition__qty">{recognitionDefault?.has}/{recognitionDefault?.of}</span>
                    <ProgressBar role={role} range={medalPercentage(recognitionDefault)} size="small" {...role} />
                </div>
                <div className={`medal-time-recognition__icon ${role}`}>
                    <Recognition className="medal-control__medal" width={90} height={81} />
                </div>
                <span className="medal-recognition__title">Reconocimiento</span>
            </div>
            <div className="medal-time-management">
                <div className="medal-time-management__container">
                    <span className="medal-time-management__qty">{timeManagementDefault?.has}/{timeManagementDefault?.of}</span>
                    <ProgressBar role={role} range={medalPercentage(timeManagementDefault)} size="small" />
                </div>
                <div className={`medal-time-management__icon ${role}`}>
                    <TimeManagement className="medal-control__medal" width={90} height={81} />
                </div>
                <span className="medal-time-management__title">Manejo del Tiempo</span>
            </div>
            <div className="medal-just-in-time">
                <div className="medal-just-in-time__container">
                    <span className="medal-just-in-time__qty">{justInTimeDefault?.has}/{justInTimeDefault?.of}</span>
                    <ProgressBar role={role} range={medalPercentage(justInTimeDefault)} size="small" />
                </div>
                <div className={`medal-just-in-time__icon ${role}`}>
                    <JustInTime className="medal-control__medal" width={90} height={81} />
                </div>
                <span className="medal-just-in-time__title">Justo a Tiempo</span>
            </div>
        </div>
    );
};

export default Medals;