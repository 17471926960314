export const BadgeDays = ({ days, role }) => {
    return (
        <ul className="badge-days">
            {days?.map((day) => {
                const isActive = day.challenges?.filter((challenge) => challenge.status).length > 0 ? 'active' : '';
                return <li className="badge-days-day">
                    <span className={`badge-days-day-alias ${role} ${isActive}`}>{day.alias}</span>
                    <ul className="badge-days-day-points">
                        {day.challenges?.map((challenge) => <li className={`badge-days-day-points-point ${role} ${challenge.status ? 'active' : ''}`}>.</li>)}
                    </ul>
                </li>
            })}
        </ul>
    );
};