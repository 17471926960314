import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Step from "../../molecules/step";
import Checkbox from "../../atoms/checkbox";
import ClapHand from "../../../resources/images/clap-candidate.png";
import { useContext, useEffect, useState } from "react";
import Card from "../../atoms/card";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";

const InductionDayChallengeC = ({ role, step, setStep, finishChallenge }) => {

    const { user } = useContext(UserContext);

    const [finishButton, setFinishButton] = useState(false);

    const [whatDays, setWhatDays] = useState(false);
    const [whatDaysNo, setWhatDaysNo] = useState(false);
    const [twelve, setTwelve] = useState(false);
    const [six, setSix] = useState(false);
    const [nine, setNine] = useState(false);

    const [idChallenge, setIdChallenge] = useState();

    const sendAnswer = () => {
        if(whatDays || whatDaysNo) {

            let scheduleChallenges;
            if(twelve) {
                scheduleChallenges = "doce";
            }
            if(six) {
                scheduleChallenges = "seis";
            }
            if(nine) {
                scheduleChallenges = "nueve";
            }
            if(whatDaysNo) {
                scheduleChallenges = 'undefined';
            }

            const body = {
                scheduleChallenges
            };

            axios.put(`${API.APPLICANT_CHALLENGES_SCHEDULE.url}/${user?._id}/${idChallenge}`, body).then((response) => {
                if(response.status === 200) {
                    setStep(step + 1)
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    useEffect(() => {
        axios.get(`${API.PROGRESS_POINT.url}`).then((response) => {
            const data = response.data.data;
            setIdChallenge(data[2].idChallenges);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <div className="induction-day-challenge-b">
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="justify">
                    ¡Aspirante! Para completar los Desafíos vas a requerir apróximadamente 15 minutos.
                    En ACDC, la sincronización facilita la coordinación de actividades. Es importante que tu Anfitrión(a) conozca cómo vas a organizar tu tiempo.
                </Paragraph>
                <Paragraph size="s" align="justify">
                    A continuación, deberás elegir los horarios en los que vas a completar los Desafíos de las misiones diarias.
                </Paragraph>
                <Checkbox
                    name="whatdays"
                    label="En lo posible, realizaré mis desafíos todos los días a las:"
                    checked={whatDays}
                    onChange={(e) => {
                        setWhatDays(e.target.checked);
                        setWhatDaysNo(false);
                        setTwelve(false);
                        setSix(false);
                        setNine(false);
                    }} />
                <div className="whatdays-options">
                    <Checkbox
                        name="twelve"
                        label="12:00 pm"
                        disabled={!whatDays}
                        checked={twelve}
                        onChange={(e) => {
                            setTwelve(e.target.checked);
                            setSix(false);
                            setNine(false);
                        }}
                    />
                    <Checkbox
                        name="six"
                        label="06:00 pm"
                        disabled={!whatDays}
                        checked={six}
                        onChange={(e) => {
                            setSix(e.target.checked);
                            setTwelve(false);
                            setNine(false);
                        }}
                    />
                    <Checkbox
                        name="nine"
                        label="09:00 pm"
                        disabled={!whatDays}
                        checked={nine}
                        onChange={(e) => {
                            setNine(e.target.checked);
                            setTwelve(false);
                            setSix(false);
                        }}
                    />
                </div>
                <Checkbox
                    name="whatdaysno"
                    label="No me es posible definir un horario. Sin embargo,  me aseguraré de completar los desafíos diarios durante el transcurso de cada día, antes de las 9:30 pm."
                    checked={whatDaysNo}
                    onChange={(e) => {
                        setWhatDaysNo(e.target.checked);
                        setWhatDays(false);
                        setTwelve(false);
                        setSix(false);
                        setNine(false);
                    }}
                />
                <Button onClick={() => sendAnswer()}>Continuar</Button>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    <b>¡Vas super!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                    Te queda poco para completar el <b>Desafío C.</b>
                </Paragraph>
                <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                <Card type="secondary" outline>
                    <div className="epa-schedule-card" >
                        <Paragraph align="center" size="s">
                            Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                        </Paragraph>
                        <div className="epa-schedule-card__key">
                        Cierre Inducción
                        </div>
                        <CopyField value="Cierre Inducción"  setStep={() => setFinishButton(true)}/>
                        <Paragraph align="center" size="xs" padding={16}>
                            Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                            <b>Mi Progreso</b>
                        </Paragraph>
                    </div>
                </Card>
                {finishButton && <Button onClick={() => finishChallenge(null)}>Finalizar</Button>}
            </Step>
        </div>
    );
};

export default InductionDayChallengeC;