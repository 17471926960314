const LevelInfluence = ({ level, description }) => {
    return (
        <div className="level-influence">
            <div>
                <span className="level-influence__icon">
                    📶
                </span>
                <span className="level-influence__text">{description}</span>
            </div>
            <span className="level-influence__level">{level}</span>
        </div >
    );
};

export default LevelInfluence;