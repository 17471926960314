import { useContext, useState } from "react";
import Paragraph from "../../atoms/paragraph";
import TextField from "../../atoms/text-field";
import Form from "../../molecules/form";
import Button from "../../atoms/button";
import Step from "../../molecules/step";
import OtpField from "../../atoms/otp-field";
import axios from "axios";
import { API, MESSAGES } from "../../../middleware/utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { LOGIN } from "../../../middleware/utils/postRequests";
import { UserContext } from "../../../middleware/providers/user-context";

const RegisterFormCandidate = () => {

    const navigate = useNavigate();
    const { name } = useParams();

    const { login } = useContext(UserContext);

    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [retypedPasswordValue, setRetypedPasswordValue] = useState(null);
    const [retypedPassword, setRetypedPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRetypedPassword, setShowRetypedPassword] = useState(false);
    const [otp, setOtp] = useState(null);
    const [otpLength, setOtpLength] = useState(6);

    const nextStep = (e) => {
        e.preventDefault();
        setStep(step + 1);
    };

    const validateField = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'email':
                if (e.target.validity.valid) {
                    setEmail(true);
                    setEmailValue(value);
                } else
                    setEmail(false);
                break;
            case 'password':
                setPasswordValue(value);
                if (value === retypedPasswordValue && e.target.validity.valid) {
                    setPassword(true);
                    setRetypedPassword(true);
                }
                else {
                    setPassword(false);
                    setRetypedPassword(false);
                }
                break;
            case 'retypedpassword':
                setRetypedPasswordValue(value);
                if (value === passwordValue && e.target.validity.valid) {
                    setRetypedPassword(true);
                    setPassword(true);
                } else {
                    setRetypedPassword(false);
                    setPassword(false);
                }
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            email: e.target.email.value,
            password: e.target.password.value,
            action: 'REGISTER_CANDIDATE'
        };
        axios.post(API.REGISTER_CANDIDATE.url, body).then((response) => {
            if (response.status === 201) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const sendOtp = (e) => {
        e.preventDefault();
        const body = {
            email: emailValue,
            code: otp,
            action: 'REGISTRATION_CONFIRMATION'
        };
        axios.post(API.REGISTRATION_CONFIRMATION.url, body).then((response) => {
            if (response.status === 201) {
                const body = {
                    email: emailValue,
                    password: passwordValue,
                    always: false,
                    remember: false,
                    action: 'LOGIN'
                }
                axios.post(API.LOGIN.url, body).then((response) => {
                    if (response.status === 201) {
                        login(response.data.data);
                        axios.get(`${API.PROGRESS_POINT.url}`).then((response) => {
                            navigate(`/induction-day/b/${response.data.data?.[1]?.idChallenges}`);
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }).catch((error) => {
            alert(MESSAGES.ERROR[error?.response?.data?.message]);
            console.log(error);
        });
    };

    return (
        <div className="register-form-candidate">
            <Form onSubmit={handleSubmit}>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <h2 >
                        Sé parte de este gran proyecto
                    </h2>
                    <Paragraph align="center" size="s">
                        Por favor ingresa el mismo correo electrónico con el que confirmaste tu asistencia al <b>EPA.</b>
                    </Paragraph>
                    <TextField
                        name="email"
                        placeholder="Correo electrónico"
                        leftIcon={{ icon: 'icon-mail' }}
                        pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                        onChange={validateField}
                        required />
                    <Button onClick={nextStep} disabled={!email}>Aceptar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <span className="title">¡Hola!</span>
                    <span className="subtitle">{name}</span>
                    <Paragraph align="center" size="xs">
                        Crea una contraseña
                    </Paragraph>
                    <TextField
                        name="password"
                        type={`${!showPassword ? `password` : `text`}`}
                        placeholder="Contraseña"
                        rightIcon={{ icon: !showPassword ? 'icon-eye-off' : 'icon-eye', size: showPassword ? 11 : 16, onClick: () => setShowPassword(!showPassword) }}
                        leftIcon={{ icon: 'icon-lock' }}
                        max={10}
                        onChange={validateField}
                        required />
                    <TextField
                        name="retypedpassword"
                        type={`${!showRetypedPassword ? `password` : `text`}`}
                        placeholder="Contraseña"
                        rightIcon={{ icon: !showRetypedPassword ? 'icon-eye-off' : 'icon-eye', size: showRetypedPassword ? 11 : 16, onClick: () => setShowRetypedPassword(!showRetypedPassword) }}
                        leftIcon={{ icon: 'icon-lock' }}
                        max={10}
                        onChange={validateField}
                        required />
                    <h3 className="final">
                        Sé parte de este gran proyecto
                    </h3>
                    <Button disabled={!password}>Aceptar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                    <span className="title">¡Hola!</span>
                    <span className="subtitle">{name}</span>
                    <Paragraph align="center" size="xs">
                        Ingresa token de 6 dígitos
                    </Paragraph>
                    <OtpField maxLength={otpLength} onChange={setOtp} />
                    <Button onClick={sendOtp} disabled={otp?.length !== otpLength}>Aceptar</Button>
                </Step>
            </Form>
        </div>
    );
};

export default RegisterFormCandidate;