import axios from "axios";
import { decryptData, encryptData } from "./encrypt";
import { postLoginResponse } from "./postResponses";
import * as ACTIONS from "./postRequests";

const { REACT_APP_TOKEN } = process.env;

const getRequestInterceptor = axios.interceptors.request.use(
    function (config) {
        if (config.method === 'get') {
            const url = config.url?.split('?')[0];
            const action = config.url?.split('?')[1]?.split('=')[1];
            config.url = url;
            if (!action) {
                const token = document.cookie?.split(';').find(cookie => cookie.includes('session-id')).split('=')[1].split('|')[0];
                const id = document.cookie?.split(';').find(cookie => cookie.includes('session-id')).split('=')[1].split('|')[1];
                config.url = `${config.url}/${id}`;
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                };
            } 

            if(action === 'GET_INFORMATION' || url.includes('acdc-rules') || url.includes('acdc-onboarding') || config.url.includes("acdc-payments")) {
                const token = document.cookie?.split(';').find(cookie => cookie.includes('session-id'))?.split('=')[1]?.split('|')[0] || REACT_APP_TOKEN;
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                };
            }

            if(action === 'GET_CHALLENGES_BY_DAY_ROLE') {
                config.url = `${config.url}`;
            }

            if(action === 'CHALLENGES_WITH_MEDALS_BE_ASSIGNED') {
                config.url = `${config.url}`;
            }


            if(action === 'GET_PROGRESS_POINT') {
                config.url = `${config.url}`;
            }

            /*config.params = {
                encryptedParams: encryptData(JSON.stringify(config.params))
            };*/
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const postRequestInterceptor = axios.interceptors.request.use(
    function (config) {
        if (config.method === 'post' && config.data) {
            ACTIONS[config.data.action](config);
        }

        if(config.url.includes("acdc-rules") || config.url.includes("acdc-payments")) {
            const token = document.cookie?.split(';').find(cookie => cookie.includes('session-id'))?.split('=')[1]?.split('|')[0] || REACT_APP_TOKEN;
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`
            };
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const getResponseInterceptor = axios.interceptors.response.use(
    function (response) {
        if (response.config.method === 'get') {
            //response.data = JSresponse.data);
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const postResponseInterceptor = axios.interceptors.response.use(
    function (response) {
        if (response.config.method === 'post') {
            postLoginResponse(response);
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const putRequestInterceptor = axios.interceptors.request.use(
    function (config) {
        if (config.method === 'put') {
            if(config.url.includes("acdc-onboarding") || config.url.includes("acdc-payments")) {
                const token = document.cookie?.split(';').find(cookie => cookie.includes('session-id'))?.split('=')[1]?.split('|')[0] || REACT_APP_TOKEN;
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                };
            }
        }
        
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);


export { getRequestInterceptor, postRequestInterceptor, getResponseInterceptor, postResponseInterceptor, putRequestInterceptor };