import Avatar from '../../../resources/images/avatar.png';

export const ReferenceBox = ({ index, name }) => {
    return (
        <div className="reference-box">
            <span className="reference-box-index">{index}</span>
            <img className="reference-box-image" src={Avatar} alt="good-hand-coordinator" />
            <span className="reference-box-name">{name}</span>
        </div>
    );
}