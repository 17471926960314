import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import { CardImage } from "../../molecules/card-image";

import Calendar from "../../../resources/images/calendar-host.png";
import CopyField from "../../atoms/copy-field";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { getSchedule } from "../../../middleware/services/common";

export const EpaLinkContent = () => {
    const { user } = useContext(UserContext);
    const [epaDate, setEpaDate] = useState();

    const fetchData = async () => {
        const response = await getSchedule(user?._id);;
        setEpaDate(response.slice(-1)[0]); 
    };

    useEffect(() => {
        if(user)
            fetchData();
    },[user]);

    return <div className="epa-link-content">
        <span className="epa-link-content-title">Disponible hasta Día de Reglas</span>
        <CardImage type="secondary" image={Calendar}>
            <Paragraph size="s" align="center">
                {epaDate?.date}
                <br />
                <b>Hora: {epaDate?.hour}</b>
            </Paragraph>
            <Paragraph size="s" align="center" className="container-link">
                Link de Convocatoria al EPA
                <br />
                <br />
                <span>https://app.universoacdc.com/schedule</span>
            </Paragraph>
            <CopyField value={`https://app.universoacdc.com/schedule/${user?._id}`} text="Copiar link"/>
        </CardImage>
        <Card className="epa-link-content-card-info" type="quinary">
            <span className="icon-info"></span>
            <Paragraph size="s" align="center">
                Recuerda que debes agregar este link al mensaje de la Clave C del ABC Convocatoria, el cual debes  copiar, editar y pegar a tus Validadores para que se aenden al EPA.
            </Paragraph>
        </Card>
    </div>
};