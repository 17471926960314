import WompiLogo from '../../../resources/images/wompi-logo.png';
import BancolombiaLgo from '../../../resources/images/bancolombia-logo.png';

export const WaterMark = () => {
    return (
        <div className="water-mark">
            <img src={WompiLogo} alt="Visa" className="visa" />
            <img src={BancolombiaLgo} alt="MasterCard" className="master-card" />
        </div>
    );
};