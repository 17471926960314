const OtpField = ({ value, maxLength, name, required, onChange }) => {
    const handleChange = (e) => {
        let { value } = e.target;
        const inputs = document.querySelectorAll('.otp-field input');
        const currentIndex = Array.from(inputs).findIndex(input => input === e.target);

        if (value.length === 1 && currentIndex < inputs.length - 1) {
            inputs[currentIndex + 1].focus();
        } else if (value.length === 0 && currentIndex > 0) {
            inputs[currentIndex - 1].focus();
        }

        const otpValue = Array.from(inputs).map(input => input.value).join('');
        onChange?.(otpValue);
    };

    return (
        <div className="otp-field">
            {Array.from({ length: maxLength }, (_, index) => (
                <input
                    style={{ width: `calc(100% / ${maxLength})` }}
                    key={index}
                    type="password"
                    onKeyUp={handleChange}
                    max={9}
                    maxLength={1}
                    name={name}
                    pattern="\d*"
                    inputMode="numeric"
                    required={required}
                    autoFocus={index === 0}
                />
            ))}
            <span className="icon icon-state-selected"></span>
        </div>
    );

};

export default OtpField;