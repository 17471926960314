import classNames from 'classnames';
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import { useEffect } from 'react';

const Loader = ({ isShow }) => {

    const classes = classNames('loader', {
        'loader--show': isShow,
    });

    useEffect(() => {
        if (isShow) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isShow]);

    return (
        <div className={classes}>
            <div className="loader__viewport">
                <MainLogo className="logo-gif" />
            </div>
        </div>
    );
};

export default Loader;