import { useEffect, useState } from "react";

const SwitchDisableTrue = ({ value }) => {
    const [checked, setChecked] = useState(false);
    const [active, setActive] = useState('');

    useEffect(() => {
        if (value === 'Si') {
            setChecked(true);
            return;
        } else if (value === 'No') {
            setChecked(false);
            setActive('active');
            return;
        }

        if (!checked) {
            setActive('');
        }
    }, [value]);

    return (
        <div className="switch">
            <input type="checkbox" id="switch" checked={checked} disabled={true}/>
            <label htmlFor="switch" className={`label-switch success ${checked ? 'active' : ''}`} 
            >Sí</label>
            <label htmlFor="switch" className={`label-switch error ${!checked ? active : ''}`}
            >No</label>
        </div>
    );
};




export { SwitchDisableTrue};