import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "../../../middleware/providers/user-context";
import {
  getCaptainAndHostWithAspirants,
  getListOfHostWithTheirSelectApplicants,
} from "../../../middleware/services/epaCoordinatorServices";
import { API, ROLES } from "../../../middleware/utils/constants";
import Paragraph from "../../atoms/paragraph";
import Checkbox from "../../atoms/checkbox";
import Button from "../../atoms/button";

const ActivitySeletedModal = ({
  numPager,
  setNumPager,
  setStep,
  step,
  idCoordinator,
}) => {
  const { user,eventEpa } = useContext(UserContext);
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useState({
    selected: [{ id: "", status: false }],
  });
  const [listHostsByCandidate, setListHostsByCandidate] = useState([]);
  useEffect(() => {
    fetchData();
    setNumPager(0);
  }, [eventEpa]);

  const fetchData = async () => {
    const list = await getListOfHostWithTheirSelectApplicants(idCoordinator);
    setListHostsByCandidate(list);
    return;
  };
  const onChangeCandidate = (e, idCandidate,checked) => {
    setIsChecked(!e)
    console.log(e);
    console.log(isChecked);
    setState((prevState) => {
      let newSelected = [...prevState.selected];
      if (idCandidate === "" || idCandidate === null) {
        newSelected = newSelected.filter((item) => item.id !== idCandidate);
      } else {
        const existingIndex = newSelected.findIndex(
          (item) => item.id === idCandidate && item.id !== ""
        );
        if (existingIndex !== -1) {
          newSelected[existingIndex] = {
            id: idCandidate,
            pdlConnection: e,
            status: true,
          };
        } else {
          newSelected.push({ id: idCandidate, status: e });
        }
      }
      return { ...prevState, selected: newSelected };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      selected: state.selected.filter((candidate) => candidate.id !== ""),
      pdlConnection: true,
      action: "SELECT_FINAL_EPA_CANDIDATES",
    };
    axios
      .post(`${API.SELECT_FINAL_EPA_CANDIDATES.url}/${user?._id}`, data)
      .then((response) => {
        if (response.status === 201) {
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <span className="title">¡Ahora es tu turno de seleccionar!</span>
      <br />
      <Paragraph size="s" align="center" className="p-room-p-0">
        ¡Coordinador(a)!, De los PDLs de tus Aspirantes, ¿cuál está más
        conectado con tu PDL?
      </Paragraph>
        <>
        {
          listHostsByCandidate &&(
            <>
         <br />
          <div className="candidate-text">
            <span className="role"> {ROLES[listHostsByCandidate[numPager]?.role]?.circle} </span>
            <span className="name">
              <b>{listHostsByCandidate[numPager]?.name}:</b>
            </span>
          </div> 
           {listHostsByCandidate[numPager]?.candidates?.map((candidate) => (
            <>
              <div className="candidadte-with-host">
                <span className="checbok-candidate">
                  <Checkbox
                    className="medal-switch__info__name"
                    name="hold"
                    label={<></>}
                    onChange={(e) =>
                      onChangeCandidate(e.target.checked, candidate?._id,candidate?.pdlConnectionDirector)
                    }
                   checked={isChecked?false:candidate?.pdlConnectionDirector}
                  />
                </span>
                <div className="label-candidate">
                  {candidate.name}
                  <br />
                  {ROLES[candidate.role || "candidate"].circle}{" "}
                  {ROLES[candidate.role || "candidate"].roleName}
                </div>
              </div>
            </>
          ))} 
            </>
          )
        }
        </>
      
      <Button className="performance-button">Ver Desempeño</Button>
      <span className="title-small">
        Este botón te muestra el
        <br /> desempeño de tus aspirantes
      </span>
      {numPager < 1 ? (
        <>
          <Button
            onClick={() => setNumPager(numPager + 1)}
            className="margin-top"
          
          >
            Continuar
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={onSubmitHandler}
            className="margin-top"
          
          >
            Continuar
          </Button>
        </>
      )}{" "}
    </>
  );
};
export default ActivitySeletedModal;
