import { useParams } from "react-router-dom";
import { PAGES } from "../../../middleware/utils/constants";
import { useContext } from "react";
import { UserContext } from "../../../middleware/providers/user-context";


const ConditionRender = ({condition, component}) => {
    const context = useContext(condition.context);
    const Component = component;
    return context[condition.key] === condition.value && <Component />;
};

const Builder = () => {

    const { page } = useParams();
    const { user } = useContext(UserContext);

    const build = (bricks) => {
        return bricks?.map((brick) => {
            const Component = brick.type;
            if (brick.condition) {
                return <ConditionRender condition={brick.condition} component={Component} />
            } else {
                return <Component {...brick.props} >
                    {brick.bricks ? build(brick.bricks) : brick?.props?.children}
                </Component>
            }
        });
    };

    return build(PAGES[page].bricks);
};

export default Builder;