import axios from "axios";
import { API } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
const keys = {
    ListReidualDatesById: ["getListReidualDatesById"],
  
};




const getListReidualDatesById =async  (idUser) => {

    const {data}=await  axios.get(`${API.LIST_RESIDUAL_DATES.url}/${idUser}?action=list-reidual-dates`);
    return  data.data;

  }
 
 export const QueriesListReidualDatesById=(idUser) => {
    return useQuery({
        queryKey: keys.ListReidualDatesById,
        queryFn: async () => await getListReidualDatesById(idUser),
    })
 }

