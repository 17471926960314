const roles = {
    ADMIN: 'admin',
    USER: 'user',
};

const permissions = {
    ADMIN_PAGES: ['adminPage1', 'adminPage2'],
    USER_PAGES: ['profile', 'missions', 'challenge']
};

const hasPermission = (page, isLogged) => {
    const { role } = { role: 'user' };
    if (isLogged) {
        switch (role) {
            case roles.ADMIN:
                return permissions.ADMIN_PAGES.includes(page);
            case roles.USER:
                return permissions.USER_PAGES.includes(page);
            default:
                return false;
        }
    }
};

export default hasPermission;
