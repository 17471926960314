import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import EpaBase from "../../../resources/images/epa-base.png";
import SimulatorModuleTutorial from "../../../resources/images/simulator-module-tutorial.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import CheckHand from '../../../resources/images/check-hand.png';
import TouchScreen from "../../../resources/images/screen-touch-hand.png"
import Calendar from "../../../resources/images/calendar-coordinator.png";
import EpaBaseCoordinator from "../../../resources/images/epa-base-coordinator.png";
import AvatarCalendar from "../../../resources/images/avatar-calendar.png";
import YoutubeImage from "../../../resources/images/youtube-image.png";
import ScreenTools from "../../../resources/images/screen-tools.png";
import GoodHand from "../../../resources/images/good-hand.png";
import SyncIcon from '../../../resources/images/sync-icon-large.png';
import WarningIcon from '../../../resources/images/warning.png';
import Button from "../../atoms/button";
import MedalSwitch from "../../molecules/medal-switch";
import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import Checkbox from "../../atoms/checkbox";
import Modal from "../../atoms/modal";
import { API, ROLES } from "../../../middleware/utils/constants";
import GiftSwitch from "../../molecules/gift-switch";
import CompletedProcess from "../../molecules/completed-process";
import Card from "../../atoms/card";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import AvatarChallengesMarked from "../../molecules/avatar-challenges-marked";

const HostAlignmentDay = ({ finishChallenge, step, setStep, setChangeRole, setChangeDay, challenge }) => {
    const { challengeName, idChallenge } = useParams();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const [challengeState, setChallengeState] = useState(challenge);

    const [medals, setMedals] = useState([]);
    const [firstSchedule, setFirstSchedule] = useState(false);
    const [secondSchedule, setSecondSchedule] = useState(false);
    const [thirdSchedule, setThirdSchedule] = useState(false);
    const [fourthSchedule, setFourthSchedule] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [watchedVideo, setWatchedVideo] = useState(false);

    const [confirmEpa, setConfirmEpa] = useState(false);

    const [gifts, setGifts] = useState([]);

    const [firstEpaConfirmed, setFirstEpaConfirmed] = useState(false);
    const [secondEpaConfirmed, setSecondEpaConfirmed] = useState(false);

    const [showValidatorsModal, setShowValidatorsModal] = useState(false);

    const [calculatePurcheseJeunesse, setCalculatePurcheseJeunesse] = useState(false);

    const [aspirantsWithTraining, setAspirantsWithTraining] = useState([]);

    const [canFinish, setCanFinish] = useState(false);

    const messagePayment = {
        'PSE': '¡Asegúrate que tu cuenta esté habilitada para transacciones virtuales!',
        'Tarjeta débito.': 'Asegúrate que tu tarjeta de débito esté habilitada para transacciones virtuales.',
        'Tarjeta de crédito.': ' ¡Asegúrate de que tu tarjeta esté vigente y cuentes con el cupo disponible! A todos nos puede pasar.',
        'Efectivo (Consignación bancaria).': 'Asegúrate de contar con el tiempo suficiente para consignar y enviar el soporte antes del EPA !'
    };

    const selectGift = (name, e) => {
        if (e.target.checked) {
            setGifts([...gifts, {
                name,
                selected: e.target.checked
            }]);
        } else {
            setGifts(gifts.filter(item => item.name !== name));
        }
    };

    const formatNumber = (value) => {
        const n = value?.toString();
        return n?.replace?.(/\D/g, "").replace?.(/\B(?=(\d{3})+(?!\d))/g, ",")
    };

    const hasPaymetSelected = () => {
        return challengeState?.questions[0]?.options?.find(option => option?.status);
    };

    useEffect(() => {
        console.log(challengeState);
        axios.get(`${API.GET_CALCULATE_PURCHASE.url}?action=GET_CALCULATE_PURCHASE`).then((response) => {
            if (response.status === 200) {
                setCalculatePurcheseJeunesse(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${API.LIST_CANDIDATES_WITH_TRAINING.url}/${user?._id}?action=GET_ASPIRANTS_WITH_TRAINING`).then((response) => {
            if (response.status === 200) {
                console.log(response.data.data);
                setAspirantsWithTraining(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <img src={EpaBase} alt="EPA Base" />
                    <Paragraph size="s" align="center">
                        <b>¡Prepárate para el EPA!</b>
                        <br />
                        <br />
                        Ahora vas a tener la oportunidad de:
                        <br />
                        <br />
                        👉🏼 Practicar y asegurarte de que tu desempeño en el EPA sea excelente.
                        <br />
                        <br />
                        👉🏼 Familiarizarte con la entrega de medallas a tus Aspirantes durante el EPA. A continuación, te enseñamos cómo hacerlo.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Cuando estés en el Módulo Simulador de Medallas, debes presionar el botón Sí o el botón No, según el caso.
                        <br />
                        <br />
                        Solo hasta que hayas marcado la casilla de medalla en todos los(as) Aspirantes, podrás avanzar.
                    </Paragraph>
                    <img src={SimulatorModuleTutorial} alt="Simulator Module Tutorial" />
                    <Paragraph size="s" align="center">
                        Ten en cuenta que habrán Aspirantes inhabilitados, estos serán responsabilidad del otro Anfitrión.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Módulo simluador de medallas.
                        <br />
                        <br />
                        Aquí podrás simular cómo otorgar las Medallas de Manejo del Tiempo a tus Aspirantes cuando estés dentro del EPA.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <TimeManagementMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                Presiona SÍ, en el participante que haya ganado medalla de Manejo del Tiempo
                                y No, en el caso contrario
                            </Paragraph>
                        </div>
                        <MedalSwitch roleName="Aspirante" name="Karime Castañeda" onClick={(value) => {
                            setMedals([...medals, { value, name: 'Karime Castañeda' }]);
                        }} />
                        <MedalSwitch roleName="Aspirante" name="Karime Castañeda" onClick={(value) => {
                            setMedals([...medals, { value, name: 'Karime Castañeda' }]);
                        }} />
                        <MedalSwitch roleName="Aspirante" name="Karime Castañeda" onClick={(value) => {
                            setMedals([...medals, { value, name: 'Karime Castañeda' }]);
                        }} />
                        <MedalSwitch roleName="Aspirante" name="Karime Castañeda" value="No" disabled />
                        <MedalSwitch roleName="Aspirante" name="Karime Castañeda" value="Si" disabled />
                        <MedalSwitch roleName="Aspirante" name="Karime Castañeda" value="No" disabled />
                    </div>
                    <Paragraph size="s" align="center">
                        Cuando estés en el EPA, solo podrás continuar una vez tu Director(a) confirme que se dieron o no, todas las medallas.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío A.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ahora que ya realizaste las actividades, continúa con el siguiente ▶️ VIDEO, para seguir con tu <b>Día de Alineamiento.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        Podrás ver el video en cualquier momento desde la información <span className="icon-info"></span> del <b>Desafío A.</b>
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Ver video</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">Es momento de definir la fecha y hora del próximo EPA.</span>
                    <Paragraph size="s" align="center">
                        Tu Coordinador(a) futuro Nuevo(a) Director(a), propone las siguientes 4 opciones para el EPA:
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        Selecciona al menos 3 opciones que se ajusten a tu  disponibilidad.
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        <div className="checkbox-table__item">
                            <Checkbox
                                checked={firstSchedule}
                                onChange={() => {
                                    setFirstSchedule(!firstSchedule);
                                    if (!firstSchedule)
                                        setSelectedItems([...selectedItems, '4 de Nov. del  2023;7:00 pm']);
                                    else
                                        setSelectedItems(selectedItems.filter(item => item !== '4 de Nov. del  2023;7:00 pm'));
                                }}
                            />
                            <span className="checkbox-table__item__start-time">4 de Nov. del  2023</span>
                            <span className="checkbox-table__item__end-time">7:00 pm</span>
                        </div>
                        <div className="checkbox-table__item">
                            <Checkbox
                                checked={secondSchedule}
                                onChange={() => {
                                    setSecondSchedule(!secondSchedule);
                                    if (!secondSchedule)
                                        setSelectedItems([...selectedItems, '10 de Nov. del 2023;8:00 pm']);
                                    else
                                        setSelectedItems(selectedItems.filter(item => item !== '10 de Nov. del 2023;8:00 pm'));
                                }}
                            />
                            <span className="checkbox-table__item__start-time">10 de Nov. del 2023</span>
                            <span className="checkbox-table__item__end-time">8:00 pm</span>
                        </div>
                        <div className="checkbox-table__item">
                            <Checkbox
                                checked={thirdSchedule}
                                onChange={() => {
                                    setThirdSchedule(!thirdSchedule);
                                    if (!thirdSchedule)
                                        setSelectedItems([...selectedItems, '15 de Nov. del 2023;7:00 pm']);
                                    else
                                        setSelectedItems(selectedItems.filter(item => item !== '15 de Nov. del 2023;7:00 pm'));
                                }}
                            />
                            <span className="checkbox-table__item__start-time">15 de Nov. del 2023</span>
                            <span className="checkbox-table__item__end-time">7:00 pm</span>
                        </div>
                        <div className="checkbox-table__item">
                            <Checkbox
                                checked={fourthSchedule}
                                onChange={() => {
                                    setFourthSchedule(!fourthSchedule);
                                    if (!fourthSchedule)
                                        setSelectedItems([...selectedItems, '20 de Nov. del 2023;8:00 pm']);
                                    else
                                        setSelectedItems(selectedItems.filter(item => item !== '20 de Nov. del 2023;8:00 pm'));
                                }}
                            />
                            <span className="checkbox-table__item__start-time">20 de Nov. del 2023</span>
                            <span className="checkbox-table__item__end-time">8:00 pm</span>
                        </div>
                    </div>
                    <Button onClick={() => setStep(step + 1)} disabled={selectedItems.length !== 3}>Continuar</Button>
                </Step>
                <Modal isOpen={step === 2} className="host-alignment-day-container epa-confirmation-modal">
                    <Paragraph size="s" align="center">
                        ¿Estás seguro que puedes asistir al EPA en las fechas seleccionadas?
                    </Paragraph>
                    <div className="epa-confirmation-modal__actions">
                        <Button onClick={() => setStep(step + 1)}>Sí</Button>
                        <Button onClick={() => setStep(step - 1)}>No</Button>
                    </div>
                </Modal>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">¡Excelente!</span>
                    <img src={CheckHand} alt="epa-schedule-hand" width={156} height={156} />
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        {selectedItems.map((item) => <div className="checkbox-table__item">
                            <span className="checkbox-table__item__start-time">{item.split(';')[0]}</span>
                            <span className="checkbox-table__item__end-time">{item.split(';')[1]}</span>
                        </div>)}
                    </div>
                    <Button onClick={() => setWatchedVideo(true)} >Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" disabled={!watchedVideo}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Zona de Aprendizaje del EPA</b>
                    </Paragraph>
                    <img src={TouchScreen} alt="Touch Screen" />
                    <Paragraph size="s" align="center">
                        Ahora experimentarás una simulación que replica los desafíos que desbloquearás para tu nuevo rol  al final del EPA.
                        <br />
                        <span className="featured">Presta atención para que puedas prepararte de manera óptima para cuando llegue el momento.</span>
                    </Paragraph>
                    <span className="subtitle">¡Tú serás el(la) Nuevo(a) Coordinador(a)!</span>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Modal isOpen={step === 5} className="host-alignment-day-container epa-confirmation-modal">
                    <span className="title">¡Anfitrión(a)!</span>
                    <Paragraph size="s" align="center">
                        Primero vas a entrenarte en las actividades correspondientes al Desafío Honor que vivirás al final del EPA.
                    </Paragraph>
                    <Button onClick={() => {
                        setStep(step + 1);
                        setChangeRole('coordinator');
                        setChangeDay('Simulación Nuevo(a) Coordinador(a)');
                    }} size="small">¡Vale!</Button>
                </Modal>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <img src={Calendar} alt="Calendar" className="calendar-image" />
                    <span className="title">Agendamiento EPA.</span>
                    <Paragraph size="s" align="center">
                        Es momento de definir la fecha y hora de tu EPA.
                        <br />
                        <br />
                        A continuación podrás visualizar las fechas que eligieron tus dos Nuevos Anfitriones.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        {user?.name}
                        <br />
                        ⚪ Nuevo(a) {ROLES[user?.role].roleName}(a)
                        <br />
                        Eligió como posibles fechas para el EPA:
                    </Paragraph>
                    <div className="schedule-table">
                        <div className="schedule-table__header">
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__start-time">
                                <span className="icon-calendar"></span> 10/11/23
                            </span>
                            <span className="schedule-table__item__end-time">8:00 pm</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__start-time">
                                <span className="icon-calendar"></span> 15/11/23
                            </span>
                            <span className="schedule-table__item__end-time">7:30 pm</span>
                        </div>
                    </div>
                    <Paragraph size="s" align="center">
                        {user?.name}
                        <br />
                        ⚪ Nuevo(a) {ROLES[user?.role].roleName}(a)
                        <br />
                        Eligió como posibles fechas para el EPA:
                    </Paragraph>
                    <div className="schedule-table">
                        <div className="schedule-table__header">
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__start-time">
                                <span className="icon-calendar"></span> 10/11/23
                            </span>
                            <span className="schedule-table__item__end-time">8:00 pm</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__start-time">
                                <span className="icon-calendar"></span> 15/11/23
                            </span>
                            <span className="schedule-table__item__end-time">7:30 pm</span>
                        </div>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Por lo tanto,
                        <br />
                        Nuevo(a) Coordinador(a) la mejor fecha
                        <br />
                        para el EPA es:
                    </Paragraph>
                    <div className="schedule-table">
                        <div className="schedule-table__header">
                        </div>
                        <div className="schedule-table__item active">
                            <span className="schedule-table__item__start-time">
                                <span className="icon-calendar"></span> 10/11/23
                            </span>
                            <span className="schedule-table__item__end-time">8:00 pm</span>
                        </div>
                    </div>
                    <img src={EpaBaseCoordinator} alt="EPA Base Coordinator" className="epa-base-coordinator" />
                    <Button onClick={() => { }} size="small" disabled>Elegir nueva fecha</Button>
                    <Paragraph size="xs" align="center">
                        Presiona el botón “Elegir nueva fecha’’ en caso de que no funcione para ti la fecha definida. La nueva fecha deberá ser acordada con tu Director(a).
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="coordinator">Aceptar Fecha</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">Confirmación de fecha para el EPA.</span>
                    <Paragraph size="s" align="center">
                        Tu Director(a) está revisando la fecha definida para cada EPA...
                    </Paragraph>
                    <div className="schedule-table">
                        <div className="schedule-table__header">
                        </div>
                        <div className="schedule-table__item active">
                            <span className="schedule-table__item__start-time">
                                15 de Nov. del 2023
                            </span>
                            <span className="schedule-table__item__end-time">7:30 pm</span>
                        </div>
                    </div>
                    <img src={AvatarCalendar} alt="Avatar Calendar" className="avatar-calendar" />
                    <Paragraph size="s" align="center">
                        Una vez tu Director(a) confirme la fecha se habilitará el botón Continuar EPA.
                    </Paragraph>
                    <Checkbox
                        label="Confirmo que estoy disponible y que participaré del EPA en la fecha y hora definidas."
                        checked={confirmEpa}
                        onChange={() => setConfirmEpa(!confirmEpa)}
                    />
                    <Button onClick={() => setStep(step + 1)} disabled={!confirmEpa} role="coordinator">Continuar EPA</Button>
                </Step>
                <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">¡Nuevo(a) Coordinador(a)!</span>
                    <Paragraph size="s" align="center">
                        Es momento de definir los días del cronograma. El día de Inducción será mañana y el Día de Alineamiento será un día antes del EPA. El(La) nuevo(a) Director(a) podrá modificar el Día de Reglas que moverá las demás fechas hasta el Día de Filtro.
                    </Paragraph>
                    <div className="schedule-table">
                        <div className="schedule-table__header">
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día de Inducción
                            </span>
                            <span className="schedule-table__item__date active">Martes 7 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día de Reglas
                            </span>
                            <span className="schedule-table__item__date active">Miércoles 8 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día 1
                            </span>
                            <span className="schedule-table__item__date active">Jueves 9 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día 2
                            </span>
                            <span className="schedule-table__item__date active">Viernes 10 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día 3
                            </span>
                            <span className="schedule-table__item__date active">Sábado 11 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día de Filtro
                            </span>
                            <span className="schedule-table__item__date active">Lunes 13 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                Día de Alineamiento
                            </span>
                            <span className="schedule-table__item__date active">Martes 14 de Nov.</span>
                        </div>
                        <div className="schedule-table__item">
                            <span className="schedule-table__item__day_name">
                                EPA
                            </span>
                            <span className="schedule-table__item__date active">Miércoles 15 de Nov.</span>
                        </div>
                    </div>
                    <Paragraph size="s" align="center">
                        Podrás avanzar cuando el(la) Nuevo(a) Director(a) confirme las fechas de cronograma.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="coordinator">Aceptar</Button>
                </Step>
                <Modal isOpen={step === 11} className="host-alignment-day-container epa-confirmation-modal">
                    <span className="title">¡Anfitrión(a)!</span>
                    <Paragraph size="s" align="center">
                        Ahora vas a entrenarte en las actividades correspondientes al <b>Desafío Humildad</b> que vivirás al final del EPA.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Vale!</Button>
                </Modal>
                <Step order={12} visibility={step === 12 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Confirmación Canal de Youtube
                    </Paragraph>
                    <img src={YoutubeImage} alt="Youtube Image" className="youtube-image" />
                    <Paragraph size="s" align="center">
                        ¡Nuevo(a) Coordinador(a)!
                        <br />
                        <br />
                        Es momento que tus dos Nuevos Anfitriones se unan al canal de YouTube de ACDC con el regalo 🎁  que te corresponde darles, para que así puedan acceder al contenido que requieren para aplicar la estrategia. Mañana iniciarán con el día de Inducción junto contigo y convocarán a los Validadores.
                    </Paragraph>
                    <Button style="ghost" size="small" onClick={() => { }}>Manual de compra</Button>
                    <Paragraph size="s" align="center">
                        Una vez hayas hecho el obsequio, envía el comprobante al grupo de WhatsApp.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={13} visibility={step === 13 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Activa el swtich del(la) Nuevo(a) Anfitrión(a) que ya se encuentre unido al canal de YouTube.
                    </Paragraph>
                    <div className="gift-switch-container">
                        <GiftSwitch role={'host'} nameRole={'Nuevo(a) Anfitrión(a)'} name={user?.name} range={7} onClick={(e) => selectGift(user?.name, e)} />
                        <GiftSwitch role={'host'} nameRole={'Nuevo(a) Anfitrión(a)'} name={'Karime Casatañeda'} range={7} onClick={(e) => selectGift('Karime Castañeda', e)} />
                    </div>
                    <span className="subtitle">Este switch se activa manualmente.</span>
                    <Paragraph size="xs" align="center">
                        Podrás continuar cuando tus Nuevos Anfitriones estén unidos al canal de Youtube y el(la) Nuevo(a) Director(a) confirme que hiciste el obsequio.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={gifts.length === 0}>Continuar</Button>
                </Step>
                <Modal isOpen={step === 14} className="host-alignment-day-container epa-confirmation-modal fit-content">
                    <img src={YoutubeImage} alt="Youtube Image" className="youtube-image" />
                    <span className="title">¡Súper!</span>
                    <Paragraph size="s" align="center">
                        Tus Nuevos Anfitriones ya están unidos al canal de YouTube de ACDC con el regalo 🎁 que les hiciste, ahora ellos pueden acceder al contenido que requieren para aplicar la estrategia. Mañana iniciarás con el día de Inducción junto con tu equipo.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Vale!</Button>
                </Modal>
                <Step order={15} visibility={step === 15 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">¡Momento de Agradecer!</span>
                    <img src={ScreenTools} alt="Screen Tools" className="screen-tools" />
                    <Paragraph size="s" align="center">
                        Tus nuevos Anfitriones van a realizar el entrenamiento contigo de la clave A (Agradecimiento) del ABC de la Convocatoria consultando las claves al Nuevo TWOBOT  (Nuevo(a) Capitán(a)).
                        <br />
                        <br />
                        En la siguiente pantalla, marca la casilla del(la) Anfitrión(a) con quien hayas realizado ya el Entrenamiento.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small" disabled>Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} >Continuar</Button>
                </Step>
                <Step order={16} visibility={step === 16 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <div className="epa-info">
                        <span>EPA:</span>
                        <div className="epa-info__date">
                            <span>15 de Nov. del 2023</span>
                            <span>8:00 AM</span>
                        </div>
                    </div>
                    <CompletedProcess
                        role={'host'}
                        nameRole={'Nuevo(a) Anfitrión(a)'}
                        name={'Karime Castañeda'}
                        range={7}
                        checked={firstEpaConfirmed}
                        onChange={() => setFirstEpaConfirmed(!firstEpaConfirmed)}
                        showModal={setShowValidatorsModal}
                    />
                    <CompletedProcess
                        role={'host'}
                        nameRole={'Nuevo(a) Anfitrión(a)'}
                        name={'Karime Castañeda'}
                        range={7}
                        checked={secondEpaConfirmed}
                        onChange={() => setSecondEpaConfirmed(!secondEpaConfirmed)}
                        showModal={setShowValidatorsModal}
                    />
                    <Button onClick={() => setStep(step + 1)}>Siguiente</Button>
                </Step>
                <Step order={17} visibility={step === 17 ? 'visible' : 'hidden'} className="filter-day-container">
                    <span className="title">
                        Fin de la Zona de Aprendizaje del EPA.
                    </span>
                    <img src={CheckHand} alt="epa-schedule-hand" />
                    <Paragraph size="s" align="center">
                        Excelente!
                        <br />
                        <br />
                        Estás listo(a) para desempeñar tu rol como Nuevo(a) Coordinador(a). Recuerda lo aprendido y aplícalo en tus desafíos <b>Honor, Humildad y Hambre</b> del EPA.
                    </Paragraph>
                    <Button onClick={() => {
                        setStep(step + 1);
                        setChangeRole(user?.role);
                        setChangeDay(challengeName);
                    }}>Continuar</Button>
                </Step>
                <Step order={18} visibility={step === 18 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Sync Alineamiento Anfitrión
                            </div>
                            <CopyField value="Sync Alineamiento Anfitrión" setStep={() => {
                                setCanFinish(true);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                    <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                </Step>
                <Modal isOpen={showValidatorsModal} className="completed-modal epa-confirmation-modal">

                </Modal>
            </>}
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">¡Anfitrión(a)!</span>
                    <Paragraph size="s" align="center">
                        Mañana, cuando selecciones a tus 2 mejores Aspirantes, al finalizar el EPA, y te conviertas en el(la) nuevo(a) Coordinador(a), deberás realizar tu primera compra durante este camino de desarrollo como líder dentro de ACDC.
                    </Paragraph>
                    <div className="question-purchase">
                        <span className="question-purchase__text">¿Qué vas a comprar?</span>
                        <span className="question-purchase__featured">Una Oficina Virtual</span>
                    </div>
                    <Paragraph size="s" align="center">
                        Esta oficina virtual te permitirá comercializar los productos que tiene disponible Jeunesse en más de 100 países.
                    </Paragraph>
                    <div className="question-purchase">
                        <span className="question-purchase__text">¿Cuál es el valor?</span>
                        <span className="question-purchase__featured">$35 USD</span>
                    </div>
                    <Paragraph size="s" align="center">
                        Tasa de Cambio (J) a $ {formatNumber(calculatePurcheseJeunesse?.dollarPrice)} COP =
                        <h3>$ {formatNumber(calculatePurcheseJeunesse?.priceInJeunessePeso)} COP</h3>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">¡Anfitrión(a)!</span>
                    <Paragraph size="s" align="center">
                        Recuerda, debes tener listo El Obsequio para que los 2 Nuevos Anfitriones que quedarán seleccionados en el EPA, se unan al canal de YouTube y tengan acceso a los videos de la estrategia ACDC.
                    </Paragraph>
                    <div className="question-purchase">
                        <span className="question-purchase__text">¿Cuánto requieres disponible?</span>
                        <span className="question-purchase__featured">${calculatePurcheseJeunesse?.hostGifts} USD</span>
                    </div>
                    <Paragraph size="s" align="center">
                        Tasa de Cambio (J) a $ {formatNumber(calculatePurcheseJeunesse?.dollarPrice)} COP =
                        <h3>${formatNumber(calculatePurcheseJeunesse?.hostGiftsInWeight)} COP</h3>
                    </Paragraph>
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card total-card" >
                            <Paragraph align="center" size="s">
                                Total
                            </Paragraph>
                            <div className="featured">
                                ${formatNumber(calculatePurcheseJeunesse?.priceInJeunessePeso)} + ${formatNumber(calculatePurcheseJeunesse?.hostGiftsInWeight)}
                            </div>
                            <Paragraph align="center" size="s">
                                <h3>${formatNumber(calculatePurcheseJeunesse?.totalToHaveAvailable)} COP</h3>
                            </Paragraph>
                            <Paragraph align="center" size="xs" >
                                En total debes tener diponible para el día del EPA
                                ${formatNumber(calculatePurcheseJeunesse?.totalToHaveAvailable)}
                            </Paragraph>
                        </div>
                    </Card>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Modal isOpen={step === 3} className="host-alignment-day-container epa-confirmation-modal warning-modal">
                    <img src={WarningIcon} alt="Warning" />
                    <span className="title">¡Recuerda!</span>
                    <Paragraph size="s" align="center">
                        La Oficina Virtual se paga directamente en la Web de Jeunesse al final del EPA y tiene un valor de $140.000 COP. Los $100.000 correspondientes al El Obsequio debes consignarlos directamente a los dos Anfitriones seleccionados al Final del EPA ($50.000 COP a cada uno).
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Entendido!</Button>
                </Modal>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Para apoyarte con la preparación de tu compra de la Oficina Virtual, selecciona cuál de las opciones de pago vas a utilizar:
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Valor de tu Oficina Virtual:
                        <br />
                        <span className="bold">USD 35 - ({calculatePurcheseJeunesse?.priceInJeunessePeso}).</span>
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        {challengeState?.questions[0]?.options.map((option) => <div className="checkbox-table__item">
                            <Checkbox
                                checked={option.status}
                                onChange={(e) => {
                                    const newChallengeState = { ...challengeState };
                                    newChallengeState.questions[0].options = newChallengeState.questions[0].options.map((o) => {
                                        if (o.option === option.option) {
                                            o.status = e.target.checked;
                                        } else {
                                            o.status = false;
                                        }
                                        return o;
                                    });
                                    console.log(newChallengeState);
                                    setChallengeState(newChallengeState);
                                }}
                            />
                            <span className="checkbox-table__item__payment">{option.option}</span>
                        </div>)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} disabled={!hasPaymetSelected()}>Continuar</Button>
                </Step>
                <Modal isOpen={step === 5} className="host-alignment-day-container epa-confirmation-modal payment-modal">
                    <img src={WarningIcon} alt="Warning" />
                    <Paragraph size="s" align="center">
                        {messagePayment[hasPaymetSelected()?.option]}
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Vale!</Button>
                </Modal>
                <Modal isOpen={step === 6} className="host-alignment-day-container epa-confirmation-modal payment-modal">
                    <img src={WarningIcon} alt="Warning" />
                    <Paragraph size="s" align="center">
                        Marca las casillas indicando que cuentas con los requisitos para la compra de tu oficina virtual y poder continuar con el desafío.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Vale!</Button>
                </Modal>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        Marca la casilla para indicar que estás listo(a) para realizar una de las mejores compras de tu Vida:
                    </Paragraph>
                    {challengeState?.questions[1]?.options.map((option, index) => <div className="checkbox-table-optional-item">
                        <Paragraph size="s" align="left">
                            {option.option}
                        </Paragraph>
                        <div className="checkbox-table__options">
                            <Checkbox
                                label="Sí"
                                checked={option.status}
                                onChange={(e) => {
                                    const newChallengeState = { ...challengeState };
                                    newChallengeState.questions[1].options[index].status = true;
                                    setChallengeState(newChallengeState);
                                }}
                            />
                            <Checkbox
                                label="No"
                                checked={!option.status}
                                onChange={(e) => {
                                    const newChallengeState = { ...challengeState };
                                    newChallengeState.questions[1].options[index].status = false;
                                    setChallengeState(newChallengeState);
                                }}
                            />
                        </div>
                    </div>)}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center" >
                        A continuación, encontrarás la hora y el link de la meet a la que deberás conectarte para realizar una sincronización de 10 minutos con el resto del equipo.
                    </Paragraph>
                    <div className="sync-meet-card">
                        <img src={SyncIcon} alt="sync" className="sync" />
                        <span className="title">Hora: 9:30 pm</span>
                        <Paragraph size="s" align="center" >
                            Link de la meet:
                        </Paragraph>
                        <div className="meet-link">
                            <span>meet//:a923nr42098nf3</span>
                        </div>
                        <CopyField value="meet//:a923nr42098nf3" text="Copiar Link" />
                    </div>
                    <Paragraph size="xs" align="center" className="bottom-advice" >
                        Este botón se habilitará en medio de la reunión para que puedas realizar el resto del desafío.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role={user?.role}>Continuar</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">Requisitos de participación en un EPA:</span>
                    <Paragraph size="s" align="center" >
                        Estos son los primeros 3 requisitos que se encuentran confirmando los Aspirantes de tus Anfitriones en este momento:
                    </Paragraph>
                    <Paragraph size="s" align="left" className="with-background" >
                        1. Disposición de 2 Horas para participar enfocado(a) en el EPA y sin interrupciones.
                    </Paragraph>
                    <Paragraph size="s" align="left" className="with-background" >
                        2. Tener computador y celular cargados, audífonos y buena conexión a internet.
                    </Paragraph>
                    <Paragraph size="s" align="left" className="with-background" >
                        3. Aceptar el Acuerdo de Honor.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center" >
                        Visualiza cómo van los(as) Aspirantes confirmando los primeros 3 requisitos que te mostramos en la pantalla anterior.
                    </Paragraph>
                    {aspirantsWithTraining.map((aspirant) => {
                        const keys = ["provisionTwoHours", "connectionTools", "confidentialityAgreement", "virtualSessions", "minutesByWhatsApp"];
                        const checks = keys.map(key => aspirant.epaParticipationRequirements?.[key]) || [false, false, false, false, false];
                        return <AvatarChallengesMarked role={'candidate'} name={aspirant.name} nameRole={`⚫ ${ROLES['candidate'].roleName}`} range={7} checks={checks} />
                    })}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">Requisitos de participación en un EPA:</span>
                    <Paragraph size="s" align="center" >
                        Estos son los dos requisitos finales que se encuentran confirmando los(as) Aspirantes en este momento:
                    </Paragraph>
                    <Paragraph size="s" align="left" className="with-background" >
                        4. 4 sesiones virtuales de trabajo en equipo de 2 horas cada una.
                    </Paragraph>
                    <Paragraph size="s" align="left" className="with-background" >
                        5. 15 minutos diarios por WhatsApp.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={12} visibility={step === 12 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center" >
                        Visualiza cómo van los(as) Aspirantes confirmando los primeros 3 requisitos que te mostramos en la pantalla anterior.
                    </Paragraph>
                    {aspirantsWithTraining.map((aspirant, index) => {
                        const checks = Object.values(aspirant.epaParticipationRequirements || [false, false, false, false, false]);
                        const outline = checks.filter(check => check === false).length === 0;
                        return <AvatarChallengesMarked role={'candidate'} name={aspirant.name} nameRole={`⚫ ${ROLES['candidate'].roleName}`} range={7} checks={checks} outline={outline} />
                    })}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={13} visibility={step === 13 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <span className="title">¡Excelente!</span>
                    <Paragraph size="s" align="center" >
                        Estos son los preseleccionados:
                    </Paragraph>
                    {aspirantsWithTraining.map((aspirant, index) => {
                        const checks = Object.values(aspirant.epaParticipationRequirements || [false, false, false, false, false]);
                        const outline = checks.filter(check => check === false).length === 0;
                        return outline && <AvatarChallengesMarked role={'candidate'} name={aspirant.name} nameRole={`⚫ ${ROLES['candidate'].roleName}`} range={7} />
                    })}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={14} visibility={step === 14 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Cierre Alineamiento
                            </div>
                            <CopyField value="Cierre Alineamiento" setStep={() => {
                                setCanFinish(true);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                    <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                </Step>
            </>}
        </>
    );
};

export default HostAlignmentDay;