import GoodHandCoordinator from "../../resources/images/good-hand-coordinator.png";

export const getBricksChallengeC = () => {
    
    return [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Actividad Recomendación Plan B<br/><br/>Para completar tu Meta 1, que consiste en asegurar que tus 2 Anfitriones logren agendar a 6 Aspirantes cada uno, hemos habilitado el Plan B que aplicamos cuando los Anfitriones no han logrado completar sus 6 agendados después de convocar a todos sus validadores. 

                El Plan B consiste en la posibilidad de volver a aplicar el ABC Filtro, lo que permitirá a tus Anfitriones tener nuevos validadores en la Plataforma hoy mismo. 

                Estos nuevos validadores podrán ser convocados y agendados hasta el Día de Reglas del Juego. Para esto, tú como Coordinador(a), le recomendarás a cada Anfitrión una persona por cada cupo que les falte para completar los 6 agendados que requieren la Meta 1.`,
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    'getGridGiftSwitchChallengeInductionC',
    {
        type: 'step',
        props: {
            order: 6,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío C.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Desafío C Coordinador',
                    nameChallenge: 'Desafío C',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
]};