import React from 'react'
import Modal from '../../atoms/modal';
import Paragraph from '../../atoms/paragraph';
import Button from '../../atoms/button';
import WarningIcon from '../../../resources/images/warning.png';

export const ModalAlertAutocompleEpaDay = ({isValid,setIsValid,setStep,step,className}) => {
  return (
    <>
         <Modal isOpen={isValid} className={`${className}`}>
                    <img src={WarningIcon} alt="Warning" className="warning" />
                    <Paragraph size="s" align="center">
                    Para cambiar los datos de la factura de tu compra, desmarca la opción de autocompletar y asegúrate de ingresar manualmente toda la información requerida. Los datos deben ser diferentes a los que ingresaste en la pantalla datos de tarjeta para que puedas continuar.
                        </Paragraph>
                    <Button onClick={() => {
                        setStep(step + 1)
                        setIsValid(false);
                    }} size="small">¡Vale!</Button>
                </Modal>
    </>
  )
}
