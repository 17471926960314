import GoodHandCoordinator from '../../resources/images/good-hand-coordinator.png';

export const getBricksChallengeC = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'readingSection',
                props: {
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `“Recuerda, la ambición florece al estar a disgusto con el statu qu o. El descontento y el estar cómodo no pueden coexistir. Si un líder está demasiado cómodo la ambición se morirá y corre el riesgo de que la cómoda y suave grava lo jale hacia la cuneta de la autocomplacencia.

La mayoría de las personas en esta vida están buscando el camino fácil. Desean comodidad y para obtenerla, pagan el precio de la mediocridad, así que se apresuran para llegar hacia ese camino como vacas en un establo a la hora de la comida.”`,
                    }
                ]
            },
            {
                type: 'quote',
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0'
                            }
                        },
                        children: 'Tomado del libro:'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                fontWeight: '700'
                            }
                        },
                        children: 'Lanzando una Revolución sobre el Liderazgo.'
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Vas súper!<br/><br/>Te queda poco para completar el Desafío C.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Cierre Día 1 Coordinador',
                    nameChallenge: 'Desafío C',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    },
];