//import {ReactComponent as AvatarMenu} from '../../../resources/images/circular-progress.svg';


const MenuAspiranteInfo = ({ name, role, nameRole, range, orientation = 'column', children, extraContent, icon, className = ''}) => {

    return (
        <div className={`menu-user-info menu-user-info--${role} ${orientation} ${className}`}>
            <div className={`menu-user-info-avatar progress-${range}-8`}>
               
            </div>
            <div className="menu-user-info-title">
                <h4>{name}</h4>
                <span>{icon}  {nameRole}</span>
                
                {children}
            </div>
            {extraContent}
        </div>
    );
};

export default MenuAspiranteInfo;