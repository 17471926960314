import React, { useContext, useEffect, useState } from "react";
import { API, ROLES } from "../../../middleware/utils/constants";
import Paragraph from "../../atoms/paragraph";
import Select from "../../atoms/select";
import Button from "../../atoms/button";
import axios from "axios";
import { UserContext } from "../../../middleware/providers/user-context";
import { getCaptainAndHostWithAspirants } from "../../../middleware/services/epaCoordinatorServices";

const NowItIsyourTurnToSelect = ({
  setStep,
  step,
  applicantsAndHosts,
  className,
  icon,
  idCoordinator,
}) => {
  const { user ,eventEpa} = useContext(UserContext);
  const [countSelect, setCountSelect] = useState(0);
  
  const [state, setState] = useState({ selected: [] });

  const [captainAndHosts, setCaptainAndHosts] = useState([]);
  useEffect(() => {
    fetchData();
  }, [idCoordinator,eventEpa]);
  const fetchData = async () => {
    const listCandiadates = await getCaptainAndHostWithAspirants(idCoordinator);
    setCaptainAndHosts(listCandiadates);
    return;
  };

  const sendSelection = (e) => {
    e.preventDefault();

    const data = {
      id: "",
      selected: state.selected,
      action: "SELECT_FINAL_EPA_CANDIDATES",
    };
    axios
      .post(`${API.SELECT_FINAL_EPA_CANDIDATES.url}/${user?._id}`, data)
      .then((response) => {
        if (response.status === 201) {
          setStep(step + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSelect = (candidateId, hostIndex) => {
    setState((prevState) => {
      let selected = [...prevState.selected];
      if (candidateId) {
        const candidateExists = selected.some(
          (item) => item.id === candidateId
        );
        if (!candidateExists) {
          selected.push({ id: candidateId, status: true });
        }
      } else {
        selected = selected.filter((item) => item.id !== candidateId);
      }
      return { ...prevState, selected };
    });
    if (candidateId) {
      setCountSelect(countSelect + 1);
    } else {
      setCountSelect(countSelect - 1);
    }
  };

  
  useEffect(() => {
    applicantsAndHosts.map((item, index) => {
      axios
        .get(
          `${API.SHORTLISTED_APPLICANTS.url}/${item?._id}?action=SHORTLISTED_APPLICANTS`
        )
        .then((response) => {
          
        });
    });
  }, [applicantsAndHosts]);

  return (
    <>
      <form onSubmit={sendSelection}>
        {captainAndHosts
          ?.filter((item) => item._id === user?._id)
          ?.map((generation) =>
            generation.children.map(
              (child) =>
                child.role === "coordinator" && (
                  <div className="generation-map" key={child._id}>
                    <br />
                    <Paragraph
                      size="s"
                      align="center"
                      className={`${className} box-coordinator`}
                    >
                      <div className={`info ${child.role}`}>
                        <span className="name">
                          <b>{child.name}</b>
                        </span>
                        <br />
                        <span className="role">
                          {ROLES[child.role]?.circle} {child.role}
                        </span>
                      </div>
                    </Paragraph>
                    {child.children
                      ?.filter((host) => host.role === "host")
                      ?.map((host, hostIndex) => (
                        <div key={host._id}>
                          <div className="box-host">
                            <span className="role">
                              {ROLES[host.role]?.circle}
                            </span>
                            <span>{host.name}:</span>
                          </div>
                          <div className="epa-requirements__status">
                            {[...Array(2)].map((_, idx) => (
                              <span
                                key={`${host._id}-${idx}`}
                                className="medal-switch box-candidate candidate-select"
                              >
                                <Select
                                  className="medal-switch__info__name"
                                  onChange={(e) =>
                                    handleSelect(e.target.value, hostIndex)
                                  }
                                >
                                  <option value="">
                                    Selecciona tu Aspirante
                                  </option>
                                  {host.children
                                    ?.filter(
                                      (candidate) =>
                                        candidate.role === "candidate"
                                    )
                                    ?.map((candidate) => (
                                      <option
                                        key={candidate._id}
                                        value={candidate._id}
                                      >
                                        {candidate.name}
                                      </option>
                                    ))}
                                </Select>
                              </span>
                            ))}
                          </div>
                          <br />
                        </div>
                      ))}
                  </div>
                )
            )
          )}
        <Button disabled={countSelect < 4}>Continuar</Button>
      </form>
    </>
  );
};
export default NowItIsyourTurnToSelect;
