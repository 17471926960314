import React from "react";
import ScaleItem from "../../atoms/scale-item";

const Scale = ({ children }) => {
    return (
        <div className="scale">
            <div className="scale__flow">
                {React.Children.map(children, (child, index) => {
                    return (
                        <ScaleItem
                            role={child.props.role}
                            roleName={child.props.roleName}
                            level={child.props.level}
                            index={index + 1}
                            active={child.props.active}
                        />
                    );
                })}
            </div>
            <div className="scale__line">
                <div className="line left"></div>
                Niveles de Influencia
                <div className="line right"></div>
            </div>
        </div>
    );
};

export default Scale;