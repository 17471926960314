import classNames from "classnames"

const RadioButton = ({ name, value, checked, onClick, required, auxiliarText, disabled, readOnly }) => {
    const classes = classNames("radio-button", {
        "radio-button--disabled": disabled,
    });

    return (
        <label className={classes}>
            <input type="radio" name={name} value={value} checked={checked} onClick={onClick} required={required} readOnly={readOnly}/>
            {auxiliarText && <span className="auxiliar-text">{auxiliarText}</span>}
            <span className="radio-button__checkmark">{value}</span>
        </label>
    );
};

export default RadioButton;