import axios from "axios";
import { API, ROLES } from "../utils/constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import CalendarIcon from "../../resources/images/calendar-icon.png";
const keys = {
  listOfNewHosts: ["listOfNewHosts"],
  getGridGiftSwitchCalendar: true,
};

const getListOfNewHosts = async (idUser) => {
  const { data } = await axios.get(
    `${API.LIST_OF_NEW_HOSTS.url}/${idUser}?action=LIST_OF_NEW_HOSTS`
  );
  return data.data;
};
  
const getListOfCoordinatorsWithTherNewHosts = async (idUser) => {
  try{
  const { data } = await axios.get(`${API.LIST_OF_NEW_COORDINATORS_WITH_THEIR_NEW_HOSTS.url}/${idUser}?action=LIST_OF_NEW_COORDINATORS_WITH_THEIR_NEW_HOSTS`);
  return data.data;
  } catch (error) {
    console.log(error);
  }

}

export const getGridGiftSwitch = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const response = data?.map((item) => {
    return {
      type: "giftSwitch",
      props: {
        role: item.role,
        nameRole: ROLES[item.role].roleName,
        name: item.name,
        range: 7,
        id: item?._id,
        onClick: () =>putAddToWhatSapp(item)
      },
    
      action: "putAddToWhatSapp",
    };
  });
  return response;
};

export const getGridGiftSwitchCalendar = async (idUser) => {
  const data = await getListOfNewHosts(idUser);

  const rowInfo = data?.map((item) => {
    const isRegistered = item.personalReferences?.processStatus === 'registered';

    return {
      type: 'paragraph',
      props: {
        size: 's',
        align: 'center',
        style: {
          width: '129px',
          top: '-10px',
          opacity: '0.7',
        },
      },
      children: isRegistered
        ? 'Ya te agendaste al Epa.'
        : 'Aún no te has agendado al Epa.',
    };
  });
  const rowCard = data?.map((item, index) => {
    const isRegistered = item.personalReferences?.processStatus === 'registered';

    return {
      type: 'giftSwitch',
        props: {
        role: item.role === 'host' ? 'host' : item.role,
          nameRole: item.role === 'host' ? 'Anfitrión(a)' : ROLES[item.role]?.roleName,
            name: item.name,
              range: 7,
                items: [
                  {
                    image: CalendarIcon,
                    title: 'Prueba', // Aquí puedes cambiar el título según sea necesario
                    wait: !isRegistered, // Estado de espera si no está registrado
                    width: '24px',
                  },
                ],
        },
    };
  });

  const response = [...rowCard, ...rowInfo];
  return response;
};


const textFieldValues = {};

const setTextFieldValue = (hostId, fieldIndex, value) => {
  if (!textFieldValues[hostId]) {
    textFieldValues[hostId] = [];
  }
  textFieldValues[hostId][fieldIndex] = value;
};

const handleSubmitPersonalReferences = (hostId, idChallenge) => {
  const values = textFieldValues[hostId] || [];
  const data = {
    references: Object.values(values).map((value) => ({ name: value })),
  };

  axios
    .put(`${API.SAVE_PERSONAL_REFERENCES.url}/${hostId}/${idChallenge}`, data)
    .then((response) => { })
    .catch((error) => {
      console.log(error);
    });
  delete textFieldValues[hostId];
};

export const getGridGiftSwitchChallengeInductionC = async (
  idUser,
  idChallenge
) => {
  const data = await getListOfNewHosts(idUser);
  const result = [];

  data.forEach((host, index) => {
    const candidatesScheduled = host.candidates?.length;
    const suggestedCandidates = 6 - host.candidates?.length;
console.log(candidatesScheduled)
console.log(suggestedCandidates)
    const step = (index + 1) * 2;

    // Paso principal para mostrar información del anfitrión
    result.push({
      type: "step",
      props: { order: step },
      bricks: [
        {
          type: "menuUserInfo",
          props: {
            name: `${host.name}`,
            role: host.role,
            nameRole: "Anfitrión",
            range: candidatesScheduled,
            style: { marginTop: "24px" },
          },
        },
        {
          type: "paragraph",
          props: { size: "s", align: "center", style: { marginTop: "34px" } },
          children: `Avance Meta 1: ${candidatesScheduled}/6 Agendados`,
        },
        {
          type: "counterBadges",
          props: {
            items: Array(6)
              .fill(0)
              .map((_, index) => ({ isActive: index < suggestedCandidates })),
          },
        },
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            withBackground: true,
            style: { marginTop: "24px" },
          },
          children:
            "Recomiéndales personas que tanto tú como tu Anfitrión(a) conozcan y que creas tienen buen perfil para ser parte de nuestra comunidad épica.",
        },
        {
          type: "button",
          props: {
            styles: { position: "relative", marginTop: "96px" },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    });

    // Paso con los campos de texto y el botón de envío
    result.push({
      type: "step",
      props: { order: step + 1 },
      bricks: [
        {
          type: "paragraph",
          props: { size: "xs", align: "center" },
          children: `Se habilitaron ${suggestedCandidates}  Validadores recomendados para ${suggestedCandidates} cupos restantes del(la) Anfitrión(a):`,
        },
        {
          type: "textFieldList",
          bricks: Array(suggestedCandidates)
            .fill(6)
            .map((_, fieldIndex) => ({
              id: "textField" + fieldIndex,
              type: "textField",
               action: {
                action: "suggestedTextField",
                id: "continueSuggestedTextField" + step,
                targetCounter: suggestedCandidates,
              },
            })),
        },
        {
          type: "button",
          id: "continueSuggestedTextField" + step,
          props: {
            styles: { position: "relative", marginTop: "96px" },
            disabled: true,
            onClick: () =>
              handleSubmitPersonalReferences(host._id, idChallenge), // Usa la función de envío
          
          },
          
          children: "Continuar",
          action: "nextAndReset",
        },
      ],
    });
  });

  return result;
};

export const QueriesListOfNewHosts = (idUser) => {
  return useQuery({
    queryKey: keys.listOfNewHosts,
    queryFn: async () => await getListOfNewHosts(idUser),
  });
};

export const putAddToWhatSapp = async (data) => {
  console.log("getGridGiftSwitch")
  console.log(data);
  let dataUpdate = {
    status: data.status,
    property: "addedToWhatsappGroup",
  };

  const resp = await axios.put(
    `${API.ADD_TO_WHATSAPP_GROUP.url}/${data.id}?action=update-property-host`,
    dataUpdate
  );
  return resp;
};

export const QueriesputAddToWhatSapp = () => {
  const mutation = useMutation({
    mutationFn: (data) => putAddToWhatSapp(data),
    onSuccess: (response) => {
      console.log(response);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  return mutation;
};

export const getGridGiftSwitchChallengeRulesB = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const sortData = data?.sort((a, b) => a?.name - b?.name);

  let dataHost = sortData?.flatMap((host, index) => {
    const step = index + 3;
    const orderIndex = index === 0 ? index : index + 1;

    return [
      {
        type: "step",
        props: { order: step },
        bricks: [
          {
            type: "card",
            props: {
              type: "quinary",
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "343px",
                paddingLeft: "13px",
                marginBottom: "16px",
              },
            },
            bricks: [
              {
                type: "menuUserInfo",
                props: {
                  name: `${host?.name}`,
                  role: host?.role,
                  nameRole: "Anfitrión",
                  range: 7,
                  orientation: "row",
                  size: "small",
                  headingAlign: "left",
                },
              },
              {
                type: "paragraph",
                props: {
                  size: "s",
                  align: "center",
                },
                children: `Requiere de <strong style="color: #4DFFDD">${data.length}</strong> personas de Lista de Espera.`,
              },
            ],
          },
          {
            type: "paragraph",
            props: {
              size: "s",
              align: "justify",
              style: {
                maxWidth: "320px",
              },
            },
            children: `A continuación, elige las personas que usarás de tu Lista de Espera para ayudar a tu Anfitrión(a):`,
          },
          {
            type: "textFieldList",
            bricks: [
              {
                type: "textField",
                id: "textFieldCandidate" + orderIndex,
                props: {
                  disabled: true,
                  placeholder: "Selecciona un Aspirante",
                  rightIcon: {
                    icon: "icon-add",
                    size: "24",
                    color: "#0063DD",
                  },
                  value: "",
                },
                action: {
                  action: "openModalSelect",
                  id: "candidateList" + step,
                },
              },
              {
                type: "textField",
                id: "textFieldCandidate" + (orderIndex + 1),
                props: {
                  disabled: true,
                  placeholder: "Selecciona un Aspirante",
                  rightIcon: {
                    icon: "icon-add",
                    size: "24",
                    color: "#0063DD",
                  },
                  value: "",
                },
                action: {
                  action: "openModalSelect",
                  id: "candidateList" + step,
                },
              },
            ],
          },
          {
            type: "paragraph",
            props: {
              size: "xs",
              align: "center",
              style: {
                maxWidth: "320px",
              },
            },
            children: `Si no cuentas con personas disponibles en tu Lista de Espera, no dudes en acudir a la lista de tu Director(a) ¡La ayuda está a la mano!`,
          },
          {
            type: "button",
            props: {
              styles: {
                position: "relative",
                marginTop: "66px",
              },
            },
            children: "Continuar",
            action: "next",
          },
        ],
      },
      {
        type: "listModal",
        id: "candidateList" + step,
        props: {
          isOpen: false,
          title: [
            "Lista de Espera Coordinador(a) 🔴 (Tú)",
            "Lista de Espera Director(a) 🟡",
          ],
          items: host?.candidates?.map((candidate, fieldIndex) => ({
            id: candidate._id,
            name: candidate.name + " " + fieldIndex,
            action: {
              action: "selectCandidate",
              id: "textFieldCandidate",
              index: fieldIndex,
            },
            disabled: false,
          })),
        },
        children: "Prueba",
      },
    ];
  });
  const response = dataHost;
  return response;
};

export const getGridGiftSwitchChallengeRulesC = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const response = data?.map((item) => {
    return {
      type: "giftSwitch",
      props: {
        role: item.role,
        nameRole: ROLES[item.role].roleName,
        name: item.name,
        range: 7,
        id: item?._id,
        onClick: () => putAddToWhatSapp(item?._id),
      },
      //action:"putAddToWhatSapp"
    };
  });
  return response;
};
const getCandidatAplicatProgressPoint = async (idUser, day) => {
  const params = day ? { day } : undefined;

  try {
    const response = await axios.get(
      `${API.PROGRESS_POINT.url}/${idUser}/?action=PROGRESS_POINT`,
      {
        params,
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
window.numTitleModal = 0;
export const CoordinatorFirstDayChallengeCardB = async (idUser) => {
  let card = [];

  card = [
    {
      type: "step",
      props: {
        order: 3,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children: `A continuación vas a poder revisar las respuestas que puso cada Anfitrión en su <b>Desafío B</b> del <b>Día 1</b> en las actividades de:`,
        },

        {
          type: "card",
          props: {
            type: "tertiary",
            style: {
              display: "flex",
              width: "284px",
              padding: "10px 27px 10px 12px",
              alignItems: "center",
              marginBottom: "0",
              height: "50px",
            },
          },
          bricks: [
            {
              type: "paragraph",
              props: {
                size: "s",
                align: "center",
                style: {
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                },
              },
              children:
                '<span class="icon-state-selected" style="display: inline-flex; align-items: center; border: 2px solid white; border-radius: 4px; width: 22px; height: 22px; font-size: 12px;"></span> Selección Multiple de Hambre',
            },
          ],
        },
        {
          type: "button",
          props: {
            size: "small",
            style: "ghost",
            styles: {
              position: "relative",
              marginTop: "66px",
              marginBottom: "-29px",
            },
            onClick: () => {
              openModalCheckboxSelect(idUser);
            },
          },
          children: "Ver respuesta",

          action: {
            action: "openModalHostCheckbox",
            id: "candidateList0",
          },
        },

        {
          type: "card",
          props: {
            type: "tertiary",
            style: {
              display: "flex",
              width: "284px",
              padding: "10px 27px 10px 12px",
              alignItems: "center",
              marginTop: "16px",
              marginBottom: "0",
              height: "50px",
            },
          },
          bricks: [
            {
              type: "paragraph",
              props: {
                size: "s",
                align: "left",
                style: {
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                },
              },
              children:
                '<span class="icon-state-selected" style="display: inline-flex; flex: 1; align-items: center; border: 2px solid white; border-radius: 4px; width: 22px; height: 22px; font-size: 12px; padding: 0 4.62px 0 0.62px;"></span> Selección múltiple del avance de la Meta que esperan cumplir.',
            },
          ],
        },
        {
          type: "button",
          props: {
            size: "small",
            style: "ghost",
            styles: {
              position: "relative",
              marginTop: "66px",
              marginBottom: "-29px",
            },
            onClick: () => {
              openModalCheckboxSelectOne(idUser);
            },
          },
          children: "Ver respuesta",

          action: {
            action: "openModalHostCheckbox",
            id: "candidateListOne",
          },
        },
        //end
        {
          type: "card",
          props: {
            type: "tertiary",
            style: {
              display: "flex",
              width: "284px",
              padding: "10px 27px 10px 12px",
              alignItems: "center",
              marginTop: "16px",
              marginBottom: "0",
              height: "50px",
            },
          },
          bricks: [
            {
              type: "paragraph",
              props: {
                size: "s",
                align: "left",
                style: {
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                },
              },
              children:
                '<span class="icon-pencil" style="display: inline-flex; flex: 1; align-items: center; width: 29px; height: 29px; font-size: 24px;"></span> Actividad escrita de cómo va a lograr un alto desempeño.',
            },
          ],
        },
        {
          type: "button",
          props: {
            size: "small",
            style: "ghost",
            styles: {
              position: "relative",
              marginTop: "66px",
              marginBottom: "-29px",
            },
            onClick: () => {
              window.numTitleModal = 3;
              openModalCheckboxSelectThree(idUser);
            },
          },
          children: "Ver respuesta",

          action: {
            action: "openModalHostCheckbox",
            id: "candidateListThree",
          },
        },
        {
          type: "button",
          id: "candidateList",
          props: {
            styles: {
              position: "relative",
              marginTop: "96px",
            },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    },
  ];

  return [...card];
};

export const openModalCheckboxSelect = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const dataHost = await getCandidatAplicatProgressPoint(
    data[0]?._id,
    "day one"
  );
  let modalCard = [];

  const question = dataHost.data?.data[1]?.questions[0];
  const options = question?.options || [];
  const formattedQuestions = options
    .filter((item) => item?.status === true)
    .map((item, index) => ({
      type: "paragraph",
      props: {
        size: "s",
        align: "left",
        style: {
          margin: "0",
          display: "flex",
          alignItems: "center",
          gap: "7px",
        },
      },
      children: `
      <span class="icon-state-selected" 
            style="display: inline-flex; flex: 1; align-items: center; 
            border: 2px solid #0063DD; border-radius: 4px; 
            width: 22px; height: 22px; font-size: 12px; 
            padding: 0 4.62px 0 0.62px; color: #0063DD"></span>
      ${item?.question} 
    `,
    }));

  const elements = [
    {
      type: "paragraph",
      props: {
        size: "s",
        align: "center",
      },
      children: `(Pregunta)
            <br/>
  Situaciones en tu Vida y que te gustaría cambiar:
            <br/>
            <br/>
            (Respuesta)
            `,
    },

    ...formattedQuestions,

    {
      type: "button",
      props: {
        size: "small",
        styles: {
          alignSelf: "center",
          margin: "40px 65.5px 0",
        },
      },
      children: "Continuar",
      action: "closeModal",
    },
  ];
  modalCard.push(...elements.map((element) => element));
  return modalCard;
};

export const openModalCheckboxSelectOne = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const dataHost = await getCandidatAplicatProgressPoint(
    data[1]?._id,
    "day one"
  );
  let modalCard = [];
  const question = dataHost.data?.data[1]?.questions[1];
  const options = question?.options || [];

  const formattedQuestions = options
    .filter((item) => item?.status === true)
    .map((item, index) => ({
      type: "paragraph",
      props: {
        size: "s",
        align: "left",
        style: {
          margin: "0",
          display: "flex",
          alignItems: "center",
          gap: "7px",
        },
      },
      children: `
      <span class="icon-state-selected" 
            style="display: inline-flex; flex: 1; align-items: center; 
            border: 2px solid #0063DD; border-radius: 4px; 
            width: 22px; height: 22px; font-size: 12px; 
            padding: 0 4.62px 0 0.62px; color: #0063DD"></span>
      ${item?.question} 
    `,
    }));

  const elements = [
    {
      type: "paragraph",
      props: {
        size: "s",
        align: "center",
      },
      children: `(Pregunta)
            <br/>
Con cuántos de tus seis (6) Aspirantes que iniciaron contigo esta experiencia, esperas completar el día de hoy:
            <br/>
            <br/>
            (Respuesta)
            `,
    },

    ...formattedQuestions,

    {
      type: "button",
      props: {
        size: "small",
        styles: {
          alignSelf: "center",
          margin: "40px 65.5px 0",
        },
      },
      children: "Continuar",
      action: "closeModal",
    },
  ];
  modalCard.push(...elements.map((element) => element));
  return modalCard;
};

export const openModalCheckboxSelectThree = async (idUser) => {
  const data = await getListOfNewHosts(idUser);
  const dataHost = await getCandidatAplicatProgressPoint(
    data[0]?._id,
    "day one"
  );
  console.log(dataHost);
  let modalCard = [];

  const question = dataHost.data?.data[1]?.questions[3];
  const options = question?.options || [];
  const formattedQuestions = options
    .filter((item) => item?.status === true)
    .map((item, index) => ({
      type: "paragraph",
      props: {
        size: "s",
        align: "left",
        style: {
          margin: "0",
          display: "flex",
          alignItems: "center",
          gap: "7px",
        },
      },
      children: `
      <span class="icon-state-selected" 
            style="display: inline-flex; flex: 1; align-items: center; 
            border: 2px solid #0063DD; border-radius: 4px; 
            width: 22px; height: 22px; font-size: 12px; 
            padding: 0 4.62px 0 0.62px; color: #0063DD"></span>
      ${item?.text} 
    `,
    }));

  const elements = [
    {
      type: "paragraph",
      props: {
        size: "s",
        align: "center",
      },
      children: `(Pregunta)
            <br/>
    ¿Qué estás dispuesto a hacer para superar tus desafíos?
            <br/>
            <br/>
            (Respuesta)
            `,
    },

    ...formattedQuestions,

    {
      type: "button",
      props: {
        size: "small",
        styles: {
          alignSelf: "center",
          margin: "40px 65.5px 0",
        },
      },
      children: "Continuar",
      action: "closeModal",
    },
  ];
  modalCard.push(...elements.map((element) => element));
  return modalCard;
};
const saveSituationsToChange = (idUser, idChange) => {
  console.log(idUser, idChange);
};

export const getBricksChallengeB = async (idUser, idChallenge) => {
  const dataHost = await getCandidatAplicatProgressPoint(idUser, "day two");

  const question = dataHost.data?.data[1]?.questions[0];
  const options = question?.options || [];

  const formattedQuestions = options.map((item) => ({
    type: "checkbox",
    props: {
      label: item.option,
      name: "binary-bonus",
      style: {
        width: "288px",
        padding: "0px 0px 0px 35px",
        marginTop: "49px",
      },
    },
    onClick: () => saveSituationsToChange(idUser, idChallenge),
  }));

  return [
    {
      type: "step",
      props: {
        order: 4,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            style: {
              marginTop: "30px",
              width: "200px",
            },
          },
          children: "¿Para cobrar Bono Binario necesito al menos?",
        },
        ...formattedQuestions,

        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "226px",
            },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    },
  ];
};

export const getBricksChallengeBStepSix = async (idUser) => {
  const dataHost = await getCandidatAplicatProgressPoint(idUser, "day two");
  const question = dataHost.data?.data[1]?.questions[2];
  const options = question?.options || [];

  const formattedQuestions = options.map((item) => ({
    type: "checkbox",
    props: {
      label: item.option,
      name: "binary-bonus",
      style: {
        width: "288px",
        padding: "0px 0px 0px 35px",
        marginTop: "49px",
      },
    },
  }));
  return [
    {
      type: "step",
      props: {
        order: 6,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            style: {
              maxWidth: "323px",
            },
          },
          children:
            "¿Cuánto Ganas en total en el Bono de Inicio Rápido (Bono Gen5)  si tú y tús primeras 5 Generaciones compran un paquete ESP 1 que cuesta aproximadamente $200 dólares ?",
        },
        {
          type: "generationTable",
          props: {
            headings: ["Generación", "Comisión x ESP1", "Paquetes x ESP1"],
            items: [
              ["G1", "$25 USD", "2"],
              ["G2", "$5 USD", "4"],
              ["G3", "$5 USD", "8"],
              ["G4", "$5 USD", "16"],
              ["G5", "$10 USD", "32"],
            ],
          },
        },
        ...formattedQuestions,
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "96px",
            },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    },
  ];
};

export const getBricksChallengeBStepEight = async (idUser) => {
  const dataHost = await getCandidatAplicatProgressPoint(idUser, "day two");
  const question = dataHost.data?.data[1]?.questions[3];
  const options = question?.options || [];

  const formattedQuestions = options.map((item) => ({
    type: "checkbox",
    props: {
      label: item.option,
      name: "binary-bonus",
      style: {
        width: "288px",
        padding: "0px 0px 0px 35px",
        marginTop: "49px",
      },
    },
  }));
  return [
    {
      type: "step",
      props: {
        order: 8,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            style: {
              maxWidth: "323px",
            },
          },
          children:
            "¿Cuánto Ganas en total en el Bono de Inicio Rápido (Bono Gen5)  si tú y tús primeras 5 Generaciones compran un paquete ESP 3 que cuesta aproximadamente $1000 dólares ?",
        },
        {
          type: "generationTable",
          props: {
            headings: ["Generación", "Comisión x ESP3", "Paquetes x ESP3"],
            items: [
              ["G1", "$150 USD", "2"],
              ["G2", "$20 USD", "4"],
              ["G3", "$20 USD", "8"],
              ["G4", "$20 USD", "16"],
              ["G5", "$40 USD", "32"],
            ],
          },
        },
        ...formattedQuestions,
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "96px",
            },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    },
  ];
};

export const getBricksChallengeBStepEleven = async (idUser) => {
  const dataHost = await getCandidatAplicatProgressPoint(idUser, "day two");
  const question = dataHost.data?.data[1]?.questions[4];
  const options = question?.options || [];

  const formattedQuestions = options.map((item) => ({
    type: "checkbox",
    props: {
      label: item.option,
      name: "binary-bonus",
      style: {
        width: "288px",
        padding: "0px 0px 0px 35px",
        marginTop: "49px",
      },
    },
  }));
  return [
    {
      type: "step",
      props: {
        order: 11,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children:
            "En caso de que no puedas hacer tu compra en el rol de Coordinador(a) ¿Cuánto podrías ganar con la estrategia ACDC?",
        },
        ...formattedQuestions,
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "226px",
            },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    },
  ];
};

export const getBricksChallengeBThreeStepThree = async (idUser) => {
  const dataHost = await getCandidatAplicatProgressPoint(idUser, "day three");
  const question = dataHost.data?.data[1]?.questions[0];
  const options = question?.options || [];

  const formattedQuestions = options.map((item) => {
    let copPrice = item.price.split(" ");
    let cop =
      copPrice.length === 3 ? copPrice[1] + " " + copPrice[2] : copPrice[1];
    return {
      type: "checkbox",
      props: {
        label: [
          item.packageType,
          item.points,
          "<span style='text-align: right;'>" +
            copPrice[0] +
            " <span style='font-size: 14px; opacity: 0.7;'> " +
            cop +
            " </span></span>",
        ],
        name: "binary-bonus",
        labelBackground: true,
        dynamic: true,
        style: {
          marginTop: "8px",
        },
        isRadio: true,
        ariaLabel: item.packageType,
      },
      action: {
        action: "enabledButton",
        id: "continueButton",
      },
    };
  });
  return formattedQuestions;
};

export const getCartTotalsCommissions = async (idUser) => {
  try {
    const response = await axios.get(
      `${API.GET_CART_TOTALS_COMMISSIONS.url}/${idUser}/?action=GET_CART_TOTALS_COMMISSIONS`
    );

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBricksChallengeBThreeStepFor = async (idUser) => {
  return [
    {
      type: "step",
      props: {
        order: 4,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            style: {
              marginTop: "10px",
            },
          },
          children: "Has elegido <b>Crear un paquete.</b>",
        },
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            style: {
              maxWidth: "327px",
            },
          },
          children: `Primero da clic en el botón del carrito y calcula los puntos y el valor exacto a pagar.`,
        },
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
            style: {
              maxWidth: "338px",
            },
          },
          children: `Luego ingresa el número de puntos y el valor que vas a pagar según lo calculado en le carrito.`,
        },
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "96px",
            },
            size: "small",
          },
          children: "ir al  carrito",
          action: "next",
          disabled: true,
        },
        {
          type: "textFieldPoints",
        },
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "246px",
            },
            onclick: () => getCartTotalsCommissions(idUser),
          },
          onclick: () => getCartTotalsCommissions(idUser),
          disabled: true,
          children: "Aceptar",
          //action: "next",
        },
      ],
    },
  ];
};

export const coordinatorFilterDayChallengeB = async (idUser) => {
  const dataHost = await getListOfNewHosts(idUser);
  const dataUserHosts = dataHost.map((item) => {
    return {
      type: "userCardInfo",
      props: {
        user: {
          name: item.name,
          role: "host",
          nameRole: "Anfitrión(a)",
          range: "7",
          trainingList: [
            {
              active: true,
            },
            {
              active: false,
            },
            {
              active: false,
            },
          ],
        },
      },
    };
  });
  return [
    {
      type: "step",
      props: {
        order: 2,
      },
      bricks: [
        ...dataUserHosts,
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children: `Entre más Aspirantes entrenados  en el <strong>ABC Filtro</strong>, más Aspirantes con buen Desempeño tendrán tus Anfitriones para lograr los 3 preseleccionados de su Meta 2. 🎯`,
        },
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children: `Recuerda que puedes chequear qué Aspirantes ya realizaron el Entrenamiento, con este ícono <span style="color: #36FFDB; border: 1px solid #36FFDB; border-radius: 5px; font-size: 12px; padding: 1px 2px;">Ent. <span class="icon-grade" style="color: #36FFDB;font-size: 16px;"></span></span> desde <strong>Mis Anfitriones.</strong>`,
        },
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "120px",
            },
            icon: {
              icon: "icon-exit-side-btn",
              position: "right",
            },
          },
          children: "Ir a Mis Anfitriones",
          action: "finish",
        },
      ],
    },
  ];
};

//coordinator alignment day
export const getCaptainAndHostWithAspirantsByDirector = async (userId) => {
  try {
    const list = await axios.get(
      `${API.GET_CAPTAIN_AND_HOST_WITH_APPLICANTS.url}/${userId}?action=GET_CAPTAIN_AND_HOST_WITH_APPLICANTS`
    );
    console.log(list.data);
    return list?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
const getCaptainAndHostWithAspirants = async (userId) => {
  try {
    const list = await axios.get(
      `${API.GET_CAPTAIN_AND_HOST_WITH_APPLICANTS.url}/${userId}?action=GET_CAPTAIN_AND_HOST_WITH_APPLICANTS`
    );
    console.log(list.data);
    return list?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
export const coordinatorAlignmentDayA = async (idUser) => {
  const data = await getCaptainAndHostWithAspirants(idUser);

  const user = data
    .filter((item) => item.role === "director")
    ?.map((item) => {
      return {
        name: item?.name,
        role: item.role,
        disabled: false,
      };
    });

  return [
    {
      type: "step",
      props: {
        order: 3,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children: `<b>Módulo simluador de medallas.</b><br /><br />Aquí podrás simular cómo se visualizan las medallas de los Aspirantes y a su vez cómo se  otorgan las <b>Medallas de Manejo del Tiempo</b> 🥇 a tus Anfitriones cuando estés en el EPA.`,
        },
        {
          type: "assignationMedalList",
          props: {
            users: user,
            medal: "time-management-medal",
          },
        },
        {
          type: "button",
          props: {
            role: "coordinator",
            styles: {
              position: "relative",
              marginTop: "80px",
            },
          },
          children: "Continuar",
          action: "next",
        },
      ],
    },
  ];
};

export const coordinatorWithDirectorAlignmentDayB = async (idUser) => {
  return [
    {
      type: "step",
      props: { order: 1 },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children:
            "<b>¡Prepárate para el EPA!</b><br /><br />Ahora es el momento de definir tu disponibilidad de fecha y hora para los próximos 2 EPAs, en los que asumirás el rol de Director(a). Define 4 opciones para que los Anfitriones y sus Aspirantes puedan seleccionar la que mejor se ajuste a sus horarios.",
        },
        {
          type: "paragraph",
          props: {
            size: "xs",
            align: "center",
          },
          children: "Verifica tu disponibilidad en la agenda para cada opción.",
        },
        {
          type: "gridDatePicker",
          props: {},
        },
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "120px",
            },
          },
          children: "Aceptar",
          action: "next",
        },
      ],
    },
  ];
};
const getEpaDateCoordinatingSchedulesById = async (idUser) => {
  try {
    const { data } = await axios.get(
      `${API.EPA_DATE_COORDINATING_SCHEDULES_BY_ID.url}/${idUser}?action=coordinating-schedules`
    );
    return data.data;
  } catch (e) {
    console.log(e);
  }
};
export const coodinatorWithDirectorAndCoordinatorAlignmentDayB = async (
  idUser
) => {
  const epaDates = await getEpaDateCoordinatingSchedulesById("667f444fc6e8693222cb04a5");
  
  const user = epaDates?.map((item) => {

    return        {
      type: "userInfoDate",
      props: {
        user: {
          name: item.nameCoordinator,
          role: "coordinator",
          nameRole: "Nuevo(a) Coordinador(a)",
          range: 7,
        },
        date:item?.schedule[6].date,
        time:item?.schedule[6].hour,
      },
    }
  });


  //http://localhost:3008/api/v1/acdc-rules/epa-date/coordinating-schedules/66a2dd78195f842a8e774d1c
  return [
    {
      type: "step",
      props: {
        order: 3,
      },
      bricks: [
        {
          type: "paragraph",
          props: {
            size: "s",
            align: "center",
          },
          children: `<b>Confirmación de fechas para el EPA.</b>
              <br /><br />Es momento de definir la fecha y hora del EPA de los Coordinadores:`,
        },
        ...user,
        
        {
          type: "button",
          props: {
            styles: {
              position: "relative",
              marginTop: "80px",
            },
            role: "director",
          },
          children: "Continuar EPA",
          action: "next",
        },
      ],
    },
  ];
};

export const coordinatorAlignmentDayBWithHost=async(idUser)=>{}



// alignment day B
export const coordinatorgiftSwitchAlignmentDayB = async (idUser) => {
  const data=await getListOfNewHosts(idUser)

  const user = data?.filter(host=>host.role==='host')?.map((item) => {

    return     {
      type: 'giftSwitch',
      props: {
          name: item.name,
          role: item.role,
          nameRole: 'Nuevo(a) Anfitrión',
          range: 7,
          orientation: 'row',
          size: 'x-small',
      }
  }
  });

  return [
    {
      type: 'step',
      props: {
          order: 6,
      },
      bricks: [
          {
              type: 'paragraph',
              props: {
                  size: 's',
                  align: 'center',
              },
              children: `A continuación deberás activar el switch de cada Nuevo(a) Anfitrión(a) para confirmar que ya se encuentran agregados al grupo de WhatsApp correspondiente.`,
          },
             ...user,
          {
              type: 'button',
              props: {
                  styles: {
                      position: 'relative',
                      marginTop: '80px',
                  },
              },
              children: 'Continuar',
              action: 'next',
          }
      ]
  }
  ]
}
