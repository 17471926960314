import { useEffect, useState } from "react";
import TextField from "../../atoms/text-field";
import Form from "../../molecules/form";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";

const EpaScheduleForm = ({ callback }) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [valid, setValid] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        callback(e, `${name} ${lastName}`, email);
    };

    useEffect(() => {
        if (email !== '' && name !== '' && lastName !== '') {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [email, name, lastName]);

    return (
        <div className="epa-schedule-form">
            <Form onSubmit={handleSubmit}>
                <TextField
                    name="email"
                    placeholder="Correo electrónico"
                    leftIcon={{ icon: 'icon-mail' }}
                    pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                    onChange={(e) => setEmail(e.target.value)}
                    required />
                <Paragraph align="center" size="xs">
                    Por favor ingresa el mismo correo al que llegó la validación de referencia personal.
                </Paragraph>
                <TextField
                    name="name"
                    placeholder="Nombre"
                    onChange={(e) => setName(e.target.value)}
                    required />
                <TextField
                    name="lastName"
                    placeholder="Apellido"
                    onChange={(e) => setLastName(e.target.value)}
                    required />
                <span className="epa-schedule-form--confirmation-message">¿Deseas confirmar tu agendamiento?</span>
                <Button disabled={!valid}>Confirmar</Button>
            </Form>
        </div>
    );
};

export default EpaScheduleForm;