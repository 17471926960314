import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Book from "../../../resources/images/book.png";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import CheckHand from '../../../resources/images/check-hand.png';
import KeyH1 from "../../../resources/images/key-h1-host.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Checkbox from "../../atoms/checkbox";
import TextArea from "../../atoms/text-area";
import { useContext, useState } from "react";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";

const HostFirstDay = ({ finishChallenge, step, setStep, challenge }) => {
    const { challengeName, idChallenge } = useParams();
    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const [challengeState, setChallengeState] = useState(challenge);

    const [option3, setOption3] = useState(false);
    const [option4, setOption4] = useState(false);
    const [option5, setOption5] = useState(false);
    const [option6, setOption6] = useState(false);

    const [connected, setConnected] = useState(false);
    const [freetime, setFreetime] = useState(false);
    const [money, setMoney] = useState(false);
    const [discovery, setDiscovery] = useState(false);
    const [future, setFuture] = useState(false);
    const [contribute, setContribute] = useState(false);
    const [contributetodo, setContributetodo] = useState(false);
    const [other, setOther] = useState(false);

    const [noTime, setNoTime] = useState(false);
    const [aspirantsNotInit, setAspirantsNotInit] = useState(false);
    const [aspirantsNotAdvance, setAspirantsNotaAdvance] = useState(false);
    const [aspirantsNotFinishChallenge, setAspiranstNotFinishChallenge] = useState(false);
    const [problems, setProblems] = useState(false);
    const [problemsTwoBot, setProblemsTwoBot] = useState(false);
    const [secondOther, setSecondOther] = useState(false);

    const [otherText, setOtherText] = useState('');

    const saveResponse = () => {
        const data = {
            questions: challengeState.questions,
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        };

        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`, data).then((response) => {
            if (response.status === 201) {
                finishChallenge(null);
            }
        }).catch((error) => {
            console.log(error);
        });
    };


    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hey!</span>
                        <img src={WarningIcon} alt="warning-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío inicia por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />
                                Cuando tu Coordinador(a) reciba y confirme que le enviaste la nota de voz correspondiente al <b>Desafío A</b> podrás continuar con tus desafíos diarios.
                            </Paragraph>
                        </Card>
                        <Button onClick={() => finishChallenge(step + 1)}>¡Vale!</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío A.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ya puedes continuar con el <b>Desafío B</b> del <b>Día 1.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => navigate('/missions')}>¡Vale!</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Card type="secondary" outline className="host-first-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                “Un líder se siente motivado para cambiar el Statu Quo.”
                                <br />
                                <br />
                                “Este descontento es el que produce la ambición o motivación para avanzar y alcanzar el cambio.”
                                <br />
                                <br />
                                El autor John MacArthur escribió: “Parece que todos los líderes con talento tienen un impulso innato para triunfar. Quienes no tienen este instinto no son líderes muy eficaces”.
                                <br />
                                <br />
                                ”El hambre crece como una semilla en la tierra del descontento y crece para arriba hacia una mejor visión del mañana igual que una planta crece hacia el sol”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-first-day-container-button">Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Paragraph size="s" align="center">
                        {challengeState?.questions?.[0]?.question}
                    </Paragraph>
                    {challengeState?.questions?.[0]?.options?.map((option) => {
                        return (
                            <>
                                <Checkbox
                                    name={option.question}
                                    label={option.question}
                                    checked={option.status}
                                    onChange={(e) => {
                                        const newChallengeState = { ...challengeState };
                                        newChallengeState.questions[0].options.find((o) => o.question === option.question).status = e.target.checked;
                                        console.log(newChallengeState);
                                        setChallengeState(newChallengeState);
                                    }}
                                />
                                {typeof option.text === 'string' && <TextArea
                                    placeholder="¿Cómo?"
                                    disabled={!option.status}
                                    onChange={(e) => option.text = e.target.value}
                                />}
                            </>
                        );
                    })}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Paragraph size="s" align="left" className="wishes-paragraph">
                        <ul className="wishes-list">
                            {challengeState?.questions?.[0]?.options?.map((option) => {
                                const text = option.question.includes('Otras') ? option.text : option.thirdPerson;
                                return option.status && <li>{text}</li>
                            })}
                            {/*<li>Quieres conectarte más con lo que haces.</li>
                            <li>Quieres dejar de preocuparte por el dinero.</li>
                            <li>Otra(s). Siento que no me he podido desarrollar como quisiera profesionalmente.</li>*/}
                        </ul>
                    </Paragraph>
                    <div className="feelings-container">
                        <span className="feelings-title">¡Excelente!</span>
                        <img src={CheckHand} alt="epa-schedule-hand" width={140} />
                        <Paragraph size="s" align="center">
                            Has sentido o estás sintiendo
                            <br />
                            <h3>HAMBRE</h3>
                            una de las 3 cualidades fundamentales e indispensables con las que debe contar un líder en su camino de desarrollo.
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Paragraph size="s" align="center">
                        {challengeState?.questions?.[1]?.question}
                    </Paragraph>
                    <div className="aspirants-container">
                        {challengeState?.questions?.[1]?.options?.map((option, index) => {
                            return (
                                <div className="aspirants-number">
                                    <Checkbox
                                        name={`option${(index + 2) + 1}`}
                                        label={(index + 2) + 1}
                                        checked={option.status}
                                        onChange={(e) => {
                                            const newChallengeState = { ...challengeState };
                                            const newOptions = newChallengeState.questions[1].options.map((o) => {
                                                o.status = false;
                                                return o;
                                            });
                                            newOptions[index].status = e.target.checked;
                                            newChallengeState.questions[1].options = newOptions;
                                            setChallengeState(newChallengeState);
                                        }}
                                    />
                                    <span className={`aspirants-label ${!option.status ? 'hidden' : ''}`}>{option.option}</span>
                                </div>
                            );
                        })}
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Paragraph size="s" align="center">
                        {challengeState?.questions?.[2]?.question}
                    </Paragraph>
                    {challengeState?.questions?.[2]?.options?.map((option) => {
                        return (
                            <>
                                <Checkbox
                                    name={option.option}
                                    label={option.option}
                                    checked={option.status}
                                    onChange={(e) => {
                                        const newChallengeState = { ...challengeState };
                                        newChallengeState.questions[2].options.find((o) => o.option === option.option).status = e.target.checked;
                                        setChallengeState(newChallengeState);
                                    }}
                                />
                                {typeof option.text === 'string' && <TextArea
                                    placeholder="¿Cómo?"
                                    disabled={!option.status}
                                    onChange={(e) => option.text = e.target.value}
                                />}
                            </>
                        );
                    })}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Paragraph size="s" align="center">
                        {challengeState?.questions?.[3]?.question}
                    </Paragraph>
                    <TextArea
                        placeholder="Escribe aquí."
                        maxlength={280}
                        onChange={(e) => {
                            const newChallengeState = { ...challengeState };
                            newChallengeState.questions[3].text = e.target.value;
                            setChallengeState(newChallengeState);
                        }}
                    />
                    <Button onClick={() => setStep(step + 1)} disabled={challengeState?.questions[3].text.length < 50}>Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-first-day-container">
                    <Card type="secondary" outline className="host-first-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                <h3>Hambre</h3>
                                “El hambre provee la energía para comenzar, la resistencia para persistir y la voluntad para terminar una tarea”.
                                <br />
                                <br />
                                ”El Esfuerzo desempeña un papel fundamental. El Esfuerzo es el resultado directo del hambre involucrada y, cuanto mayor sea el Esfuerzo, los Resultados o la Influencia también serán mayores Si un individuo está solamente involucrado marginalmente para alcanzar una meta, es muy probable que esa meta nunca se vaya a lograr. El Esfuerzo debe ser significativo, y los esfuerzos significativos solamente pueden provenir de aquellos que estén significativamente “hambrientos”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-first-day-container-button">Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        <b>¡Genial!</b>
                        <br />
                        Has completado el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={KeyH1} alt="Key H1" />
                    <Card type="secondary" >
                        <Paragraph align="center" size="s">
                            <b>¡Conseguiste la Llave de hoy!</b>
                            <h3>Llave H1 (Hambre)</h3>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Esta te da el paso al <b>Día 2.</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Ahora solo falta que completes el Desafío C, ¡ya puedes iniciarlo!
                        </Paragraph>
                    </Card>
                    <Button onClick={saveResponse}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Cierre Día 1 Anfitrión
                            </div>
                            <CopyField value="Cierre Día 1 Anfitrión" setStep={() => {
                                console.log('setStep');
                                finishChallenge(null);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                </Step>
            </>}
        </>
    )
};

export default HostFirstDay;