import { useContext} from "react";
import Paragraph from "../../atoms/paragraph";

import { UserContext } from "../../../middleware/providers/user-context";
import { ROLES } from "../../../middleware/utils/constants";

const ActivitySelectedCoordinator = ({

  title,
  descriptionOne,
  descriptionTwo,
  className,
  icon,
  hostCandidates,
}) => {
  const { user } = useContext(UserContext);
 
  return (
    <>
      <span className="title">
        <b>{title}</b>
      </span>
      <Paragraph size="s" align="center" className="p-room-p-1">
        {descriptionOne}
      </Paragraph>
      <Paragraph size="s" align="center" className="p-room-p-1">
        {descriptionTwo}
      </Paragraph>

      {hostCandidates
        ?.filter((item) => item._id === user?._id)
        ?.map((generation) =>
          generation.children.map(
            (child) =>
              child.role === "coordinator" && (
                <div className="generation-map">
                  <br />
                  <Paragraph
                    size="s"
                    align="center"
                    className={`${className} box-coordinator `}
                  >
                    <div className={`info ${child.role}`}>
                      <span className="name">
                        <b>{child.name}</b>
                      </span>
                      <br />
                      <span className="role">
                        {" "}
                        {
                          ROLES[
                            child.role
                          ]?.circle
                        }{" "}
                        {child.role==='coordinator' ?'Coordinador':''}
                      </span>
                    </div>
                    <br />
                  </Paragraph>
                  {child.children?.map(
                    (host) =>
                      host.role === "host" && (
                        <>
                        <div className="box-host">
                          <span className="role">
                            {" "}
                            {ROLES[host.role]?.circle}
                          </span> 
                          <span className="">{host.name}:</span>
                          </div>
                          
                          {
                            <div className="epa-requirements__status">
                              {host.children?.map(
                                (candidate) =>
                                  candidate.role == "candidate" && (
                                    <>
                                      <span className={`medal-switch box-candidate `}>
                                        <span className="medal-switch__info__name">
                                          {candidate.name}
                                        </span>
                                      </span>
                                     
                                    </>
                                  )
                              )}
                               <br/>
                            </div>
                          }
                        </>
                      )
                  )}
                </div>
              )
          )
        )}
    </>
  );
};

export default ActivitySelectedCoordinator;
