import { useEffect, useState } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { MONTH_MAP } from '../../../middleware/utils/constants';

const CustomDatePicker = ({time, isOpen, onSelect, onCancel, min, max, role = 'candidate', isTimePicker}) => {

    const dateConfig = {
        year: {
            format: 'YYYY',
            caption: 'Año',
            step: 1,
        },
        month: {
            format: value => MONTH_MAP[parseInt(value.getMonth()) + 1],
            caption: 'Mes',
            step: 1,
        },
        date: {
            format: 'DD',
            caption: 'Día',
            step: 1,
        }
    };

    const timeConfig = {
        hour: {
            format: 'hh',
            caption: 'Hora',
            step: 1,
            min: 0,
            max: 23
        },
        minute: {
            format: 'mm',
            caption: 'Minutos',
            step: 1,
            min: 0,
            max: 59
        },  
    };

    useEffect(() => {
        const datepickerElement = document.querySelectorAll('.datepicker');
        datepickerElement?.forEach((item) => {
            item.classList.add(role);
        });
    });

    return (
        <DatePicker
            value={time}
            isOpen={isOpen}
            min={min}
            max={max}
            theme="dark"
            confirmText="Confirmar"
            cancelText="Cancelar"
            onSelect={onSelect}
            onCancel={onCancel}
            dateConfig={isTimePicker ? timeConfig : dateConfig}
            showCaption
        />
    );
};

export default CustomDatePicker;