import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Book from "../../../resources/images/book.png";
import WarningIcon from '../../../resources/images/warning.png';
import PauseIcon from '../../../resources/images/pause.png';
import GoodHand from '../../../resources/images/good-hand.png';
import ClapHand from "../../../resources/images/clap-candidate.png";
import ThumbGame from "../../../resources/images/thumb-game.png";
import ListBox from "../../../resources/images/list-box-136-148.svg";
import CandidateToHost from "../../../resources/images/candidate-to-host.png";
import GreetCandidate from "../../../resources/images/greet.png";
import NewHost from "../../../resources/images/new-host.png";
import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import { ReactComponent as RecognitionMedal } from "../../../resources/images/recognition-medal.svg";
import { ReactComponent as JustInTimeMedal } from "../../../resources/images/just-in-time-medal.svg";
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import MedalSwitch from "../../molecules/medal-switch";
import Card from "../../atoms/card";
import TextField from "../../atoms/text-field";
import GroupRadio from "../../molecules/group-radio";
import RadioButton from "../../atoms/radio-button";
import RadioQuestion from "../radio-question";
import Checkbox from "../../atoms/checkbox";
import axios from "axios";
import { API, ROLES } from "../../../middleware/utils/constants";
import Recognition from "../../../resources/images/recognition-medal.svg";
import { number } from "prop-types";

const EpaDay = ({ finishChallenge, step, setStep, ...props }) => {
    const { user, eventEpa, setEventEpa, changeRoleContext } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();

    const [medals, setMedals] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [winnerCandidates, setWinnerCandidates] = useState(null);
    const [hostWinners, setHostWinners] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const [thumbScore, setThumbScore] = useState([]);
    const [thumbScore2, setThumbScore2] = useState([]);

    // Questions states
    const [prospect, setProspect] = useState(null);
    const [associate, setAssociate] = useState(null);
    const [train, setTrain] = useState(null);
    const [duplicate, setDuplicate] = useState(null);
    const [hold, setHold] = useState(false);
    const [dedicate, setDedicate] = useState(false);
    const [participate, setParticipate] = useState(false);
    const [potentiate, setPotentiate] = useState(false);
    const [confidentiality, setConfidentiality] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [waitingListModal, setWaitingListModal] = useState(false);
    const [gameOverModal, setGameOverModal] = useState(false);

    const [openRecognitionModal, setOpenRecognitionModal] = useState({
        isOpen: false,
        wonMedal: false,
        targetScore: [0, 0],
        role: [null, null],
    });

    const saveResponse = () => {
        const data = {
            questions: [{
                question: '',
                options: [
                    {
                        option: 'Prospectar',
                        number: prospect
                    },
                    {
                        option: 'Asociar',
                        number: associate
                    },
                    {
                        option: 'Capacitar',
                        number: train
                    },
                    {
                        option: 'Duplicar',
                        number: duplicate
                    },
                ]
            }],
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        }


        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`, data).then((response) => {
            if (response.status === 201) {
                const newData = {
                    id: 'all',
                    message: 'refresh',
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveResponseChallengeA = () => {
        const data = {
            maintainPerformance: hold,
            confidentialityAgreement: confidentiality,
            minutesByWhatsApp: dedicate,
            provisionTwoHours: participate,
            enhanceTheThreeHours: potentiate,
        };


        axios.put(`${API.EPA_PARTICIPATION_REQUIREMENTS.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                const newData = {
                    id: 'all',
                    message: 'refresh',
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const onChange = (e) => { };

    const fetchData = async () => {
        console.log('Fetching data...');
        try {
            const [candidateWinnersResponse, hostWinnersResponse] = await Promise.all([
                axios.get(`${API.GET_TIME_MANAGEMENT_CANDIDATE_WINNERS.url}/${user?.createByHost}?action=GET_TIME_MANAGEMENT_CANDIDATE_WINNERS`),
                axios.get(`${API.GET_TIME_MANAGEMENT_HOST_WINNERS.url}/${user?.createByHost}/${user?.role}?action=GET_TIME_MANAGEMENT_HOST_WINNERS`)
            ]);

            if (candidateWinnersResponse.status === 200) {
                const winnerCandidates = candidateWinnersResponse.data.data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                });
                setWinnerCandidates(winnerCandidates);
                candidateWinnersResponse.data.data.map((candidate, index) => !candidate.timeManagement && setMedals([...medals, { name: candidate?.name }]));
            }

            if (hostWinnersResponse.status === 200) {
                setHostWinners(hostWinnersResponse.data.data);
                hostWinnersResponse.data.data.map((host, index) => !host.timeManagement && setRooms([...rooms, { name: host?.name }]));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const assignMedal = (medalName, value, idUser, round) => {

        const data = {
            typeMedals: medalName,
            status: value
        };

        if (round) {
            data.round = round;
        }

        axios.put(`${API.REGISTER_MEDAL.url}/${idUser}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                const newData = {
                    ...data,
                    medal: true,
                    action: 'EVENT_MEDAL_ALLOCATION'
                };
                axios.post(`${API.EVENT_MEDAL_ALLOCATION.url}/${idUser}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const changeRole = (role) => {
        const data = {
            role: `new host`
        };

        axios.put(`${API.CHANGE_OF_ROLE.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const waitingList = (epaFinalStatus) => {
        const data = {
            epaFinalStatus,
        };

        axios.put(`${API.UPDATE_WAITING_LIST.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                setStep(3);
            }
        }).catch((error) => {
            console.log(error);
        });

    }

    useEffect(() => {
        fetchData();
    }, [eventEpa, refresh]);

    return (
        <>
      
            {challengeName === 'Desafío Sala E' && <>
                
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center" className="color-paragraph">
                        <b>Actividad PDL</b>
                    </Paragraph>
                    <Paragraph size="s" align="center" className="color-paragraph">
                        ¡Aspirante! Te encuentras en el Desafío de la Sala Épica, aquí compartirás tu PDL (Propósito, Destino y Legado) a tu equipo ACDC en una Nota de voz de 0:50 a 1:10 , sigue las instrucciones dadas por el(la) Director(a) del EPA para realizar esta actividad.
                    </Paragraph>
                    <div className="warning-card">
                        <img src={WarningIcon} alt="warning" className="warning" width={98} height={93} />
                        <Paragraph size="s" align="center" className="color-paragraph">
                            Recuerda que para ganar la medalla, la Nota de voz debe ser de 0:50 a 1:10.
                        </Paragraph>
                    </div>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Modal className="confirmation-modal step epa-day-modal" isOpen={step === 2 || step === 5}>
                    <img src={PauseIcon} alt="pause-icon" width={106} height={106} />
                    <span className="title">¡Aspirante!</span>
                    <Paragraph align="center" size="s">
                        Te encuentras en una pantalla de Pausa Global, ningún miembro del EPA podrá avanzar hasta que el(la) Director(a) lo permita.
                        <br />
                        <br />
                        Procura estar atento(a) a las indicaciones para completar las actividades de forma sincronizada.
                    </Paragraph>
                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </Modal>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad PDLs.</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Manejo del Tiempo 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <TimeManagementMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Manejo del Tiempo.
                            </Paragraph>
                        </div>
                        {winnerCandidates?.filter((candidate) => candidate.role === 'candidate')?.map((candidate, index) => <MedalSwitch roleName={ROLES[candidate.role].roleName} name={candidate.name} value={candidate.challenges[0].timeManagement ? 'Si' : 'No'} disabled />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">¡Felicitaciones!<br />Tu participación fue perfecta.</span>
                    <img src={GoodHand} alt="good-hand" />
                    <Paragraph size="s" align="center">
                        ¡Has ganado una medalla de <b>Manejo del  Tiempo!</b>
                    </Paragraph>
                    <TimeManagementMedal />
                    <Button onClick={() => setStep(step + 1)} role={user?.role}>Ver vídeo</Button>
                    <Button onClick={() => setStep(step + 1)} style="ghost" size="small">Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Visualiza si tu equipo ganó la medalla en equipo de Justo a Tiempo de la Sala Épica</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Manejo del Tiempo 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <TimeManagementMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                Para ganar esta medalla de equipo, tu Director(a) debió haber completado la pausa #4 de la Sala Épica máximo 30 minutos después de la hora de inicio del EPA.
                            </Paragraph>
                        </div>
                        {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled onClick={(value) => {
                            setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        }}
                            value={host.timeManagement ? 'Sí' : 'No'}
                        />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={rooms.length === 0}>Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Card type="secondary" outline className="epa-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “Necesitamos líderes con un idealismo inspirador, líderes que cuenten con grandes visiones, que sueñen en grande y que luchen por esos sueños para que se vuelvan realidad, personas que logren encender a los demás con la chispa de sus propias almas apasionadas”
                                <br />
                                <br />
                                <br />
                                <br />
                                -Theodore Roosevelt.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el Desafío de la <b>Sala Épica.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ahora ya sabes por qué somos una Comunidad <b>Épica.</b>
                        <br />
                        <br />
                        Ya puedes iniciar el desafío de la <b>Sala Plataforma.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Sala P' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad Juego de Pulgares</span>
                    <img src={ThumbGame} alt="thumb-game" />
                    <Paragraph size="s" align="justify" >
                        En el video verás a una Aspirante mujer a la izquierda y a un Aspirante hombre a la derecha. Cada vez que escuches un sonido significa que uno de los dos ha ganado un punto en el juego. Tu desafío será contabilizar cuántos puntos gana cada uno y al final de los 15 segundos, registra los resultados en la siguiente pantalla.
                    </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad Juego de Pulgares</span>
                    <img src={ThumbGame} alt="thumb-game" />
                    <div className="score-board">
                        <span className="title">¡Registra los resultados!</span>
                        <div className="score-board__player">
                            <span className="score-board__player__name">Puntos de la Aspirante mujer</span>
                            <TextField
                                placeholder="0"
                                value={thumbScore[0]}
                                onChange={(e) => setThumbScore([e.target.value, thumbScore[1]])}
                            />
                        </div>
                        <div className="score-board__player">
                            <span className="score-board__player__name">Puntos de la Aspirante hombre</span>
                            <TextField
                                type="number"
                                inputMode="numeric"
                                placeholder="0"
                                value={thumbScore[1]}
                                onChange={(e) => setThumbScore([thumbScore[0], e.target.value])}
                                pattern={/[0-9]/g}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            setOpenRecognitionModal({
                                isOpen: true,
                                wonMedal: thumbScore[0] === '1' && thumbScore[1] === '2',
                                targetScore: [1, 2],
                                role: ['el Aspirante mujer', 'el Aspirante hombre'],
                            });
                            if (thumbScore[0] === '1' && thumbScore[1] === '2') {
                                assignMedal('recognition', true, user?._id, 1);
                            }
                            setStep(step + 1);
                        }}
                    >
                        Continuar
                    </Button>
                </Step>
                <Modal isOpen={openRecognitionModal.isOpen} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        {openRecognitionModal.wonMedal ? <><Paragraph align="center" size="s">
                            <b>¡Felicitaciones!</b>
                            ¡El puntaje que ingresaste es correcto!
                        </Paragraph>
                            <img src={Recognition} alt="Recognition" style={{ opacity: openRecognitionModal.wonMedal ? '1' : '0.5' }} />
                            <Paragraph align="center" size="s">
                                ¡Has ganado una medalla de <b>Reconocimiento!</b>
                            </Paragraph></> :
                            <Paragraph align="center" size="s">
                                ¡Ups!
                                <br />
                                <br />
                                Lamentablemente el puntaje correcto era
                                {` ${openRecognitionModal.targetScore?.[0]} para ${openRecognitionModal.role?.[0]} y ${openRecognitionModal.targetScore?.[1]} para ${openRecognitionModal.role?.[1]}`}
                                <br />
                                En esta ocasión, no ganaste medalla de Reconocimiento ¡Podrás mejorar tu desempeño en la siguiente Sala!
                            </Paragraph>
                        }
                        <Button size="small" onClick={() => setOpenRecognitionModal(false)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad compartir Juego de Pulgares</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Reconocimiento 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <RecognitionMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Reconocimiento.
                            </Paragraph>
                        </div>
                        {winnerCandidates?.filter((candidate) => candidate.role === 'candidate')?.map((candidate, index) => <MedalSwitch roleName={ROLES[candidate.role].roleName} name={candidate.name} value={candidate.challenges[0]?.roundOneRecognitionMedal > 0 ? 'Si' : 'No'} disabled />)}
                    </div>
                    <Paragraph size="s" align="center">
                        Podrás continuar una vez tu Director(a) confirme que se dieron o no, todas las medallas.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad Juego de Pulgares 2</span>
                    <img src={ThumbGame} alt="thumb-game" />
                    <Paragraph size="s" align="justify" >
                        En el video verás a una Anfitriona a la izquierda y a un Anfitrión a la derecha. Cada vez que escuches un sonido significa que uno de los dos ha ganado un punto en el juego. Tu desafío será contabilizar cuántos puntos gana cada uno y al final de los 10 segundos, registrar los resultados en la siguiente pantalla.
                    </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad Juego de Pulgares 2</span>
                    <img src={ThumbGame} alt="thumb-game" />
                    <div className="score-board">
                        <span className="title">¡Registra los resultados!</span>
                        <div className="score-board__player">
                            <span className="score-board__player__name">Puntos de la Anfitriona</span>
                            <TextField
                                placeholder="0"
                                value={thumbScore2[0]}
                                onChange={(e) => setThumbScore2([e.target.value, thumbScore2[1]])}
                            />
                        </div>
                        <div className="score-board__player">
                            <span className="score-board__player__name">Puntos del Anfitrión</span>
                            <TextField
                                type="number"
                                inputMode="numeric"
                                placeholder="0"
                                pattern={/[0-9]/g}
                                value={thumbScore2[1]}
                                onChange={(e) => setThumbScore2([thumbScore2[0], e.target.value])}
                            />
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            setOpenRecognitionModal({
                                isOpen: true,
                                wonMedal: thumbScore2[0] === '9' && thumbScore2[1] === '9',
                                targetScore: [9, 9],
                                role: ['la Anfitriona', 'el Anfitrión'],
                            });
                            if (thumbScore2[0] === '9' && thumbScore2[1] === '9') {
                                assignMedal('recognition', true, user?._id, 2);
                            }
                            setStep(step + 1);
                        }}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Tabla de resultados</span>
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Aspirante/Anfitriona</th>
                                <th>Aspirante/Anfitrión</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Ronda 15s</th>
                                <td>1.000.000</td>
                                <td>2.000.000</td>
                                <td className="border">3.000.000</td>
                            </tr>
                            <tr>
                                <th>Ronda 10s</th>
                                <td>9.000.000</td>
                                <td>9.000.000</td>
                                <td className="border">18.000.000</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="conclutions">
                        <span className="title">Conclusiones:</span>
                        <ol>
                            <li>Con la estrategia de COOPERAR se gana más dinero en menos tiempo.</li>
                            <li>¿A cuántos hay que enseñarles para que apliquen la estrategia? A solo DOS.</li>
                            <li>Juego (NO ganas dinero en la vida real) vs Gamificación (SI ganas dinero en la vida real)</li>
                        </ol>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad compartir Juego de Pulgares 2</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Reconocimiento 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <RecognitionMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Reconocimiento.
                            </Paragraph>
                        </div>
                        {winnerCandidates?.filter((candidate) => candidate.role === 'candidate')?.map((candidate, index) => <MedalSwitch roleName={ROLES[candidate.role].roleName} name={candidate.name} value={candidate.challenges[0]?.roundTwoRecognitionMedal > 0 ? 'Si' : 'No'} disabled />)}
                    </div>
                    <Paragraph size="s" align="center">
                        Podrás continuar una vez tu Director(a) confirme que se dieron o no, todas las medallas.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        Espera a que el resto del equipo lleguen a la pausa #9 para poder continuar con tus actividades.
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Podrás continuar una vez tu Director(a) confirme que el equipo está sincronizado.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role={user?.role}>Continuar</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad Network Marketing</b>
                        <br />
                        <br />
                        En la siguientes tablas se muestra la comparación entre el modelo tradicional y el modelo de Innovación ACDC para los 4 procesos básicos de las redes de mercadeo:
                        Prospectar, Asociar, Capacitar y Duplicar.
                        <br />
                        <br />
                        Puntúa las mejoras de estos procesos del 1 al 5 teniendo en cuenta los siguientes parámetros:
                    </Paragraph>
                    <GroupRadio name="traditional">
                        <RadioButton value="1" onClick={onChange} auxiliarText="No me gusta  la mejora" disabled />
                        <RadioButton value="2" onClick={onChange} auxiliarText="No mejora tanto" disabled />
                        <RadioButton value="3" onClick={onChange} auxiliarText="Indiferente" disabled />
                        <RadioButton value="4" onClick={onChange} auxiliarText="Me gusta la mejora" disabled />
                        <RadioButton value="5" onClick={onChange} auxiliarText="Me gusta mucho la mejora" disabled />
                    </GroupRadio>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'} className="epa-day-container">
                    <RadioQuestion
                        name="Prospectar"
                        questionText="Prospectar"
                        auxText="Puntúa:"
                        onChange={(e) => setProspect(e.target.value)}
                        content={<>
                            <div className="traditional">
                                <Paragraph size="s" align="center" className="old">
                                    <b>Tradicional</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Hacer listas + Conocer personas.
                                </Paragraph>
                            </div>
                            <div className="acdc">
                                <Paragraph size="s" align="center" className="new">
                                    <b>Innovación ACDC</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Solo puedes convocar a tus 6 Validadores del PDL
                                </Paragraph>
                            </div>
                        </>}
                    />
                    <RadioQuestion
                        name="Asociar"
                        questionText="Asociar"
                        auxText="Puntúa:"
                        onChange={(e) => setAssociate(e.target.value)}
                        content={<>
                            <div className="traditional">
                                <Paragraph size="s" align="center" className="old">
                                    <b>Tradicional</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Llevar personas a eventos durante años
                                </Paragraph>
                            </div>
                            <div className="acdc">
                                <Paragraph size="s" align="center" className="new">
                                    <b>Innovación ACDC</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Solo tienes un EPA para seleccionar a 2 de tus 3 preseleccionados
                                </Paragraph>
                            </div>
                        </>}
                    />
                    <Button onClick={() => setStep(step + 1)} disabled={!prospect || !associate}>Continuar</Button>
                </Step>
                <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="epa-day-container">
                    <RadioQuestion
                        name="Capacitar"
                        questionText="Capacitar"
                        auxText="Puntúa:"
                        onChange={(e) => setTrain(e.target.value)}
                        content={<>
                            <div className="traditional">
                                <Paragraph size="s" align="center" className="old">
                                    <b>Tradicional</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Audios, libros y eventos durante años
                                </Paragraph>
                            </div>
                            <div className="acdc">
                                <Paragraph size="s" align="center" className="new">
                                    <b>Innovación ACDC</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    De la mano de TWOBOT en WhatsApp con videos en YouTube y la Plataforma ACDC terminando los 5 niveles en uno o dos meses.
                                </Paragraph>
                            </div>
                        </>}
                    />
                    <RadioQuestion
                        name="Duplicar"
                        questionText="Duplicar"
                        auxText="Puntúa:"
                        onChange={(e) => setDuplicate(e.target.value)}
                        content={<>
                            <div className="traditional">
                                <Paragraph size="s" align="center" className="old">
                                    <b>Tradicional</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Llevar personas a eventos durante años
                                </Paragraph>
                            </div>
                            <div className="acdc">
                                <Paragraph size="s" align="center" className="new">
                                    <b>Innovación ACDC</b>
                                </Paragraph>
                                <Paragraph size="s" align="center" className="custom">
                                    Adquieres a TWOBOT cuando llegas al nivel 5 y ya no tienes que estar en más EPAs.
                                </Paragraph>
                            </div>
                        </>}
                    />
                    <Button onClick={() => setStep(step + 1)} disabled={!train || !duplicate}>Continuar</Button>
                </Step>
                <Step order={12} visibility={step === 12 ? 'visible' : 'hidden'} className="epa-day-container">
                    <RadioQuestion
                        name="ProspectarResponse"
                        questionText="Prospectar"
                        auxText="Puntúa:"
                        value={prospect}
                        onChange={() => { }}
                        disabled
                    />
                    <RadioQuestion
                        name="AsociarResponse"
                        questionText="Asociar"
                        auxText="Puntúa:"
                        value={associate}
                        onChange={() => { }}
                        disabled
                    />
                    <RadioQuestion
                        name="CapacitarResponse"
                        questionText="Capacitar"
                        auxText="Puntúa:"
                        value={train}
                        onChange={() => { }}
                        disabled
                    />
                    <RadioQuestion
                        name="DuplicarResponse"
                        questionText="Duplicar"
                        auxText="Puntúa:"
                        value={duplicate}
                        onChange={() => { }}
                        disabled
                    />
                    <Button onClick={saveResponse}>Continuar</Button>
                </Step>
                <Step order={13} visibility={step === 13 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Visualiza si tu equipo ganó la medalla en equipo de Justo a Tiempo de la Sala Plataforma</b>
                        <br />
                        <br />
                        Para ganar esta medalla de equipo, tu Director(a) debió haber completado la actividad de la pausa #9 de la Sala Plataforma máximo 1 hora y 10 minutos después del inicio del EPA.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <JustInTimeMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Justo a Tiempo.
                            </Paragraph>
                        </div>
                        {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled onClick={(value) => {
                            setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        }}
                            value={host.justInTime ? 'Sí' : 'No'}
                        />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={14} visibility={step === 14 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el Desafío de la <b>Sala Plataforma.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ahora ya conoces que la <b>Plataforma</b> ACDC nos asegura con 2 conectados.
                        <br />
                        <br />
                        Ya puedes iniciar el desafío de la <b>Sala Admisiones.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Sala A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad confirmación de requisitos</span>
                    <Paragraph size="s" align="center">
                        ¡Aspirante! Estás a un paso de ser seleccionado(a). Para continuar, confirma que cumples con los siguientes requisitos:
                    </Paragraph>
                    <Checkbox
                        name="hold"
                        label="1. Sostener el Desempeño que tuviste como Aspirante y mejorarlo hasta convertirte en Capitán."
                        checked={hold}
                        onChange={(e) => setHold(e.target.checked)}
                    />
                    <Checkbox
                        name="dedicate"
                        label="2. Dedicar 15 minutos diarios en promedio de trabajo individual a través de WhatsApp y la Plataforma ACDC."
                        checked={dedicate}
                        onChange={(e) => setDedicate(e.target.checked)}
                    />
                    <Checkbox
                        name="participate"
                        label="3. Participar 4 noches de trabajo en equipo virtual en los Espacios Privados Agendados (EPAs) en sesiones de 2 horas en Google Meet."
                        checked={participate}
                        onChange={(e) => setParticipate(e.target.checked)}
                    />
                    <Checkbox
                        name="potentiate"
                        label="4. Potenciar las 3 Hs: Hambre para tomar acción, Humildad para aprender y Honorabilidad para lograr lo que te propones y ser tu palabra."
                        checked={potentiate}
                        onChange={(e) => setPotentiate(e.target.checked)}
                    />
                    <Button onClick={() => setStep(step + 1)} disabled={!hold || !dedicate || !participate || !potentiate}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center" className="custom-border-color">
                        Sin embargo, para seguir avanzando necesitas confirmar el <b>quinto requisito</b> que es esencial para ser parte de nuestra comunidad épica:
                    </Paragraph>
                    <Checkbox
                        name="confidentiality"
                        label="5. Confidencialidad absoluta de lo que has vivido durante el proceso incluido el EPA hasta que seas Capitán."
                        checked={confidentiality}
                        onChange={(e) => setConfidentiality(e.target.checked)}
                    />
                    <Button id="read-button" size="small" onClick={() => setIsOpen(true)}>Leer Acuerdo</Button>
                    <img src={ListBox} alt="List" />
                    <Paragraph size="s" align="center">
                        Marca el la casilla del Quinto Requisito para poder continuar
                    </Paragraph>
                    <Button onClick={saveResponseChallengeA} disabled={!confidentiality}>Continuar</Button>
                </Step>
                <Modal className="challenge-modal" isOpen={isOpen}>
                    <span className="close icon-close" onClick={() => setIsOpen(false)} onKeyDown={() => { }}></span>
                    <span className="title" >Acuerdo de confidencialidad</span>
                    <Paragraph size="s" align="center">
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </Paragraph>
                </Modal>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Visualiza si tu equipo ganó la medalla en equipo de Justo a Tiempo de la Sala de Admisiones</b>
                        <br />
                        <br />
                        Para ganar esta medalla de equipo, los dos Anfitriones deben completar el entrenamiento máximo 1 hora con 45 minutos después de la hora de inicio del EPA.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <JustInTimeMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Justo a Tiempo.
                            </Paragraph>
                        </div>
                        {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled onClick={(value) => {
                            setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        }}
                            value={host.justInTime ? 'Sí' : 'No'}
                        />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)}  >Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el Desafío de la <b>Sala Plataforma.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ahora ya conoces que la <b>Plataforma</b> ACDC nos asegura con 2 conectados.
                        <br />
                        <br />
                        Ya puedes iniciar el desafío de la <b>Sala Admisiones.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Cambio de Rol' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <img src={CandidateToHost} alt="Candidate To Host" />
                    <Paragraph size="s" align="center">
                        Presiona el botón “Convertirme en Nuevo(a) Anfitrión(a)” si lo ves habilitado, de lo contrario espera las indicaciones del Director.
                    </Paragraph>
                    <Button role="host"
                        onClick={() => {
                            changeRole();
                        }}
                        disabled={!user?.selectedApplicant}
                    >
                        Convertirme en Nuevo(a) Anfitrión(a)
                    </Button>
                    <Button id="wait-list" onClick={() => {
                        setWaitingListModal(true);
                    }} >
                        Lista de espera
                    </Button>
                    <Button id="game-over" onClick={() => setGameOverModal(true)} style="abort" size="small" >Game Over</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        ¡Felicitaciones!
                        <br />
                        <br />
                        Has sido seleccionado(a) como uno de los 2 Aspirantes de tu Anfitrión
                        <br />
                        <br />
                        ¡Bienvenido(a) a nuestra comunidad épica!
                    </Paragraph>
                    <img src={NewHost} alt="New Host" width={194} height={194} />
                    <Paragraph size="s" align="center">
                        Ahora que eres el nuevo(a) Anfitrión(a),
                        continúa con el EPA en tu nuevo rol.
                    </Paragraph>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button id="finish" onClick={() => finishChallenge(null)} style="ghost" size="small">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        ¡Tu proceso ha culminado!
                    </Paragraph>
                    <img src={GreetCandidate} alt="Clap Candidate" width={194} height={194} />
                    <Paragraph size="s" align="center">
                        ¡Gracias por haber participado en nuestro proyecto ACDC!
                        <br />
                        <br />
                        ¡Éxitos!
                    </Paragraph>
                    <Button id="game-over" onClick={() => finishChallenge(null)} style="abort" size="small">Salir</Button>
                </Step>
                <Modal isOpen={gameOverModal} className="game-over-confirm">
                    <span className="title">¿Estás seguro que quieres ejecutar un <b>Game Over</b>?</span>
                    <Paragraph size="s" align="center">Esta opción culmina inmediantamente tu proceso y te excluye de cualquier oportunidad de ser parte de nuestra comunidad épica ACDC.</Paragraph>
                    <div className="buttons">
                        <Button size="small" onClick={() => {
                            waitingList("game over");
                            setGameOverModal(false);
                        }}>Sí</Button>
                        <Button size="small" onClick={() => setGameOverModal(false)}>No</Button>
                    </div>
                </Modal>
                <Modal isOpen={waitingListModal} className="game-over-confirm">
                    <Paragraph size="s" align="center">¡Ups!
                        <br />
                        Lamentablemente, no cumpliste con el desempeño. ¡Pero no te desanimes! Puedes quedar seleccionado(a) para participar en los próximos EPAs.
                        <br />
                        <br />
                        Haz clic en el botón <b>Lista de Espera</b> para quedar seleccionado en un próximo EPA..</Paragraph>
                    <Button size="small" onClick={() => {
                        waitingList("waiting list");
                        setWaitingListModal(false);
                    }}>¡Vale!</Button>
                </Modal>
            </>}
        </>
    );
};

export default EpaDay;