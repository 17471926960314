import axios from "axios";
import Button from "../../atoms/button";
import TextField from "../../atoms/text-field";
import Form from "../../molecules/form";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import Paragraph from "../../atoms/paragraph";
import WarningIcon from "../../../resources/images/warning.png";
import CheckHand from "../../../resources/images/check-hand.png";
import { useState } from "react";
import { useParams } from "react-router-dom";

const ForgotForm = () => {

    const { token } = useParams();

    const [showModal, setShowModal] = useState(false);
    const [showChangedModal, setShowChangedModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (e.target.email.checkValidity()) {

            const body = {
                email: e.target.email.value,
                action: 'RECOVERY'
            };

            axios.post(API['RECOVERY'].url, body).then((response) => {
                if (response.status === 201) {
                    setShowModal(true);
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    };


    const handleSubmitUpdate = (e) => {
        e.preventDefault();

        if (e.target.password.checkValidity() && e.target.confirmPassword.checkValidity()) {
            const body = {
                password: e.target.password.value,
                action: 'UPDATE_PASSWORD'
            };

            if(e.target.password.value !== e.target.confirmPassword.value) {
                alert('Las contraseñas no coinciden');
                return;
            }

            axios.post(`${API['UPDATE_PASSWORD'].url}?token=${token}`, body).then((response) => {
                if (response.status === 201) {
                    setShowChangedModal(true);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };


    const renderForm = () => {
        if (token) {
            return (
                <Form onSubmit={handleSubmitUpdate}>
                    <TextField
                        name="password"
                        type={`${!showPassword ? `password` : `text`}`}
                        placeholder="Nueva contraseña"
                        rightIcon={{ icon: !showPassword ? 'icon-eye-off' : 'icon-eye', size: showPassword ? 11 : 16, onClick: () => setShowPassword(!showPassword) }}
                        leftIcon={{ icon: 'icon-lock' }}
                        max={10}
                        required />
                    <TextField
                        name="confirmPassword"
                        type={`${!showPassword ? `password` : `text`}`}
                        placeholder="Confirmar contraseña"
                        rightIcon={{ icon: !showPassword ? 'icon-eye-off' : 'icon-eye', size: showPassword ? 11 : 16, onClick: () => setShowPassword(!showPassword) }}
                        leftIcon={{ icon: 'icon-lock' }}
                        max={10}
                        required />
                    <p className="forgot-form--description">Crea una nueva contraseña.  Recuerda usar al menos una mayúscula y un número.</p>
                    <Button>Aceptar</Button>
                </Form>
            )
        }

        return (
            <Form onSubmit={handleSubmit}>
                <TextField
                    name="email"
                    placeholder="Correo electrónico"
                    leftIcon={{ icon: 'icon-mail' }}
                    pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                    required />
                <p className="forgot-form--description">Enviaremos un link de recuperación de contraseña a tu correo electrónico</p>
                <Button>Enviar</Button>
            </Form>
        );
    }


    return (
        <>
            <div className="forgot-form">
                <h3 className="forgot-form--title">Recuperar contraseña</h3>
                {renderForm()}
            </div>
            <Modal className="forgot-confirmation-modal" isOpen={showModal}>
                <img src={WarningIcon} alt="warning" />
                <Paragraph align="center" size="s">
                    ¡Listo!
                    <br />
                    <br />
                    Hemos enviado un link donde podrás recuperar tu contraseña a tu correo electrónico.
                </Paragraph>
                <Button disabled={false} onClick={() => setShowModal(!showModal)} size="small">¡Vale!</Button>
            </Modal>
            <Modal className="forgot-confirmation-modal" isOpen={showChangedModal}>
                <img src={CheckHand} alt="warning" width={122} height={122}/>
                <Paragraph align="center" size="s">
                    ¡Listo!
                    <br />
                    <br />
                    Has cambiado tu contraseña, inicia sesión nuevamente para continuar tu experiencia en la Plataforma ACDC
                </Paragraph>
                <Button disabled={false} onClick={() => {
                    window.location.href = '/'
                }} size="small">¡Vale!</Button>
            </Modal>
        </>
    );
};

export default ForgotForm;