import { useEffect, useState } from "react";
import Paragraph from "../../atoms/paragraph";
import Step from "../../molecules/step";
import YouTubePlayer from "../../molecules/youtube-player";
import Button from "../../atoms/button";
import Checkbox from "../../atoms/checkbox";
import TextField from "../../atoms/text-field";
import RadioQuestion from "../radio-question";
import TextArea from "../../atoms/text-area";
import Modal from "../../atoms/modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";

const ValidatorForm = () => {

    const { token } = useParams();

    const [videoSeen, setVideoSeen] = useState(false);
    const [step, setStep] = useState(1);

    const [name, setUserName] = useState('');
    const [candidateName, setCandidateName] = useState('');
    const [role, setRole] = useState('');

    const [nameField, setName] = useState(false);
    const [lastName, setLastName] = useState(false);
    const [featuredSkills, setFeaturedSkills] = useState(false);
    const [perceptionValidator, setPerceptionValidator] = useState(false);

    const [services, setServices] = useState(false);
    const [technicalServices, setTechnicalServices] = useState(false);
    const [entrepreneurship, setEntrepreneurship] = useState(false);
    const [projects, setProjects] = useState(false);
    const [education, setEducation] = useState(false);
    const [other, setOther] = useState(false);


    const [leadership, setLeaderShip] = useState(false);
    const [communication, setCommunication] = useState(false);
    const [knowledge, setKnowledge] = useState(false);
    const [teamwork, setTeamwork] = useState(false);
    const [creativity, setCreativity] = useState(false);
    const [empathy, setEmpathy] = useState(false);

    const [confirmationModal, setConfirmationModal] = useState(false);

    const validCheck = services || technicalServices || entrepreneurship || projects || education || other;

    const [sendButton, setSendButton] = useState(false);


    const nextStep = (e) => {
        e.preventDefault();
        setStep(step + 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (e.target.checkValidity()) {
            const body = {
                name: e.target.name.value,
                lastName: e.target.lastName.value,
                experience: {
                    professionalServices: services,
                    technicalServices,
                    entrepreneurship,
                    projects,
                    education,
                    other
                },
                skills: {
                    leadershipAndSelfManagement: e.target?.leadership.value,
                    effectiveCommunication: e.target?.communication.value,
                    criticalThinking: e.target.knowledge?.value,
                    teamwork: e.target.teamwork?.value,
                    creativity: e.target.creativity?.value,
                    empathy: e.target.empathy?.value
                },
                featuredSkills: e.target.featuredSkills?.value,
                perceptionValidator: e.target.perceptionValidator?.value,
                role,
            }
            axios.put(`${API.SAVE_VALIDATION.url}/${token}`, body).then((response) => {
                setConfirmationModal(true);
            }).catch((error) => {
                console.log(error);
            });
        }
    };


    const validSkills = () => {
        return leadership && communication && knowledge && teamwork && creativity && empathy;
    }


    const validFields = (e) => {
        if (e.target.checkValidity()) {
            if (e.target.name === 'name') {
                setName(true);
            } else if (e.target.name === 'lastName') {
                setLastName(true);
            } else if (e.target.name === 'featuredSkills') {
                setFeaturedSkills(true);
            } else if (e.target.name === 'perceptionValidator') {
                setPerceptionValidator(true);
            }
            
            setSendButton(nameField && lastName && featuredSkills && perceptionValidator);

        } else {
            setSendButton(false);
        }
    };

    useEffect(() => {
        axios.get(`${API.VALIDATOR_CANDIDATE.url}/${token}?action=VALIDATOR_CANDIDATE`).then((response) => {
            if (response.status === 200) {
                const data = response.data.data;
                setUserName(data?.personalReferences[0].name);
                setRole(data?.personalReferences[0].role);
                setCandidateName(data?.name);
            }
        });
    });

    return (
        <>
            <form className="validate-form" onSubmit={handleSubmit}>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        ¡Hola {name}!
                        <br />
                        <br />
                        {candidateName} está aplicando en una convocatoria del Laboratorio de Innovación ACDC, y es un prerrequisito del proceso de preselección tener experiencia en al menos una de la siguientes áreas:
                        <br />
                        <br />
                        Servicios Profesionales<br />
                        Servicios Técnicos<br />
                        Emprendimiento<br />
                        Liderazgo<br />
                        <br />
                        Para continuar mira este corto video, ahí te damos un contexto del Laboratorio ACDC:
                    </Paragraph>
                    <YouTubePlayer videoId="bL8w5OVsszQ" videoSeen={videoSeen} setVideoSeen={setVideoSeen} />
                    <Button disabled={false} onClick={nextStep}>Ya ví el video</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        1. Para que {candidateName?.split(' ')[0]} pueda avanzar en el proceso de preselección, necesita que confirmes su experiencia en al menos una de las siguientes áreas:
                    </Paragraph>
                    <Paragraph align="center" size="xs">
                        Selecciona una o más opciones con base en tu experiencia compartida con el Aspirante:
                    </Paragraph>
                    <div className="validate-form__checkboxes">
                        <Checkbox label="Servicios Profesionales" name="services" checked={services} onChange={(e) => setServices(e.target.checked)} />
                        <Checkbox label="Servicios Técnicos" name="technical-services" checked={technicalServices} onChange={(e) => setTechnicalServices(e.target.checked)} />
                        <Checkbox label="Emprendimiento" name="entrepreneurship" checked={entrepreneurship} onChange={(e) => setEntrepreneurship(e.target.checked)} />
                        <Checkbox label="Proyectos" name="projects" checked={projects} onChange={(e) => setProjects(e.target.checked)} />
                        <Checkbox label="Educación" name="eductation" checked={education} onChange={(e) => setEducation(e.target.checked)} />
                        <Checkbox label="Otra" name="Other" checked={other} onChange={(e) => setOther(e.target.checked)} />
                        <TextField placeholder="Other" name="other-text-field" disabled={!other} />
                    </div>
                    <Button disabled={!validCheck} onClick={nextStep}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        2. De 1 a 5, siendo 1 un puntaje  bajo y 5 un puntaje  alto ¿Cuantó puntaje le darías a {candidateName?.split(' ')[0]} en las siguientes habilidades?
                    </Paragraph>
                    <RadioQuestion
                        name="leadership"
                        questionText="Liderazgo y autogestión"
                        auxText="Puntúa:"
                        onChange={() => setLeaderShip(true)}
                    />
                    <RadioQuestion
                        name="communication"
                        questionText="Comunicación efectiva"
                        auxText="Puntúa:"
                        onChange={() => setCommunication(true)}
                    />
                    <RadioQuestion
                        name="knowledge"
                        questionText="Pensamiento crítico"
                        auxText="Puntúa:"
                        onChange={() => setKnowledge(true)}
                    />
                    <RadioQuestion
                        name="teamwork"
                        questionText="Trabajo en equipo"
                        auxText="Puntúa:"
                        onChange={() => setTeamwork(true)}
                    />
                    <RadioQuestion
                        name="creativity"
                        questionText="Creatividad"
                        auxText="Puntúa:"
                        onChange={() => setCreativity(true)}
                    />
                    <RadioQuestion
                        name="empathy"
                        questionText="Empatía"
                        auxText="Puntúa:"
                        onChange={() => setEmpathy(true)}
                    />
                    <Button disabled={!validSkills()} onClick={nextStep}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        3. Por favor escriba una o dos habilidades en las que {candidateName?.split(' ')[0]} se destaque:
                    </Paragraph>
                    <TextArea
                        name="featuredSkills"
                        id="question-three"
                        placeholder="Ejemplo: Liderazgo, comunicación, planeación, innovación, etc."
                        minlength={1}
                        maxlength={250}
                        onChange={validFields}
                        onBlur={validFields}
                        required
                    />
                    <Paragraph align="center" size="s">
                        4. De acuerdo con su percepción... ¿Por qué {candidateName?.split(' ')[0]}, debería ser parte de este Proyecto?
                    </Paragraph>
                    <TextArea
                        name="perceptionValidator"
                        id="question-four"
                        placeholder="Escriba un máximo de 250 caracteres."
                        minlength={50}
                        maxlength={250}
                        onChange={validFields}
                        onBlur={validFields}
                        required
                    />
                    <Paragraph align="center" size="s">
                        5.Diligencie su nombre completo:
                    </Paragraph>
                    <TextField placeholder="Nombres" name="name" onChange={validFields} onBlur={validFields} required/>
                    <TextField placeholder="Apellidos" name="lastName" onChange={validFields} onBlur={validFields} required/>
                    <Button disabled={!sendButton}>Finalizar</Button>
                </Step>
            </form>
            <Modal className="confirmation-modal" isOpen={confirmationModal}>
                <Paragraph align="center" size="s">
                    Hemos recibido exitosamente tu confirmación de la experiencia de {candidateName?.split(' ')[0]}
                    <br />
                    <br />
                    ¡Gracias por aportar!
                </Paragraph>
                <Button disabled={!sendButton} onClick={() => {
                    setConfirmationModal(false);
                    window.location.href = '/';
                }} size="small">¡Vale!</Button>
            </Modal>
        </>
    );
};

export default ValidatorForm;