import axios from "axios";
import { API } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
const keys = {
  UsersBelowBydId: ["UsersBelowBydId"],
  getListOfHostWithTheirSelectApplicants:["getListOfHostWithTheirSelectApplicants"],
  FoundationsSelectedByNewCandidatesById: ["FoundationsSelectedByNewCandidatesById"],
};




const getUsersBelowBydId =async  (idUser) => {

    const {data}=await  axios.get(`${API.USERS_BELOW.url}/${idUser}?action=USERS_BELOW`);
    return  data.data;

  }
 
 export const QueriesUsersBelowBydId=(idUser) => {
  
    return useQuery({
        queryKey: keys.UsersBelowBydId,
        queryFn: async () => await getUsersBelowBydId(idUser),
    })
 }
const getListOfHostWithTheirSelectApplicants = async (userId) => {
  try {
      const {data}=await axios.get(`${API.LIST_OF_HOSTS_WITH_THEIR_SELECTED_APPLICANTES.url}/${userId}?action=LIST_OF_HOSTS_WITH_THEIR_SELECTED_APPLICANTES`);
      return  data.data;
  } catch (error) {
      console.log(error);
  }
};

export const useQuerieListOfHostWithTheirSelectApplicantsBydId=(idUser) => {
  
  return useQuery({
      queryKey: keys.getListOfHostWithTheirSelectApplicants,
      queryFn: async () => await getListOfHostWithTheirSelectApplicants(idUser),
  })
}
 const getFoundationsSelectedByNewCandidatesById =async  (idCoordinator) => {

  const {data}=await  axios.get(`${API.FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES.url}/${idCoordinator}?action=foundations-selected-by-new-candidates`);
  return  data.data;

}

export const QueriesFoundationsSelectedByNewCandidatesById=(idCoordinator) => {
  console.log(idCoordinator)
  return useQuery({
      queryKey: keys.FoundationsSelectedByNewCandidatesById,
      queryFn: async () => await getFoundationsSelectedByNewCandidatesById(idCoordinator),
  })
}

 //FOUNDATIONS_SELECTED_BY_NEW_CANDIDATES