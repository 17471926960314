import React, { useState } from 'react';

const DragSelect = ({ children, callback }) => {
    const [items, setItems] = useState(
        React.Children.toArray(children).map((child, index) => ({ id: index + 1, content: child }))
    );

    const handleDragStart = (id) => {
        setItems((prevItems) =>
            prevItems.map((item) => {
                if (item.id === id) {
                    return { ...item, isDragging: true };
                }
                return item;
            })
        );
    };

    const handleDragEnd = () => {
        setItems((prevItems) => prevItems.map((item) => ({ ...item, isDragging: false })));
    };

    const handleDrop = (draggedId, newIndex, item) => {
        const draggedIndex = items.findIndex((item) => item.isDragging) + 1;
        const updatedItems = [...items];
        const draggedItem = updatedItems.find((item) => item.id === draggedIndex);
        const droppedItem = updatedItems.find((item) => item.id === draggedId);
        draggedItem.id = draggedId;
        droppedItem.id = draggedIndex;
        updatedItems[draggedIndex - 1] = droppedItem
        updatedItems[draggedId - 1] = draggedItem;
        setItems(updatedItems);
        callback(updatedItems);
    };




    return (
        <div className="drag-select">
            {items.map((item, index) => {
                const element = document.getElementById(item.content.props.children.props.children[0].props.id);
                if(element) {
                    element.innerText = `${item.id}.`;
                }
                return item?.content ? (
                    React.cloneElement(item.content, {
                        key: index,
                        id: index,
                        onDragStart: () => handleDragStart(item.id),
                        onDragEnd: handleDragEnd,
                        onDrop: (newIndex) => handleDrop(item.id, newIndex, item)
                    })
                ) : null;
            }
            )}
        </div>
    );
};

export default DragSelect;
