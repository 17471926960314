import { useState, useRef } from 'react';

const DragItem = ({ id, children, onDragStart, onDragEnd, onDrop }) => {
    const [isDragging, setIsDragging] = useState(false);
    const touchStartPos = useRef({ x: 0, y: 0 });
    const touchMoveRef = useRef(null);

    const handleDragStart = () => {
        setIsDragging(true);
        onDragStart(id);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        onDragEnd();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        onDrop();
    };

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        touchStartPos.current = { x: touch.clientX, y: touch.clientY };
        handleDragStart();
        touchMoveRef.current = e.target;

        document.body.style.overflow = 'hidden';
        onDragStart(id);
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const moveX = touch.clientX - touchStartPos.current.x;
        const moveY = touch.clientY - touchStartPos.current.y;

        if (touchMoveRef.current) {
            touchMoveRef.current.style.transform = `translate(${moveX}px, ${moveY}px)`;
        }
    };

    const handleTouchEnd = (e) => {
        if (touchMoveRef.current) {
            touchMoveRef.current.style.transform = 'none';
            touchMoveRef.current = null;
        }

        document.body.style.overflow = '';

        handleDragEnd();
        handleDrop(e);
    };

    return (
        <div
            className={`drag-item ${isDragging ? 'dragging' : ''}`}
            draggable="true"
            ref={touchMoveRef}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()} // Permite soltar elementos dentro de este elemento
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </div>
    );
};

export default DragItem;
