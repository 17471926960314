import React, { useState } from "react";
import Button from "../../atoms/button";
import Paragraph from "../../atoms/paragraph";
import classNames from "classnames";

const MedalControl = ({ idUser, challengeName, medal, idChallenge, medalName, callBack, disabled }) => {

    const MEDALS = {
        recognition: 'Medalla de Reconocimiento',
        justInTime: 'Medalla de Justo a tiempo',
        timeManagement: 'Medalla de Manejo del tiempo',
        wildcard: 'Medalla Comodín'
    };

    const className = classNames('medal-control', {
        'medal-control--disabled': disabled,
    });

    const Medal = ({ medal }) => {
        return React.cloneElement(medal, { className: 'medal-control__medal' });
    };

    return (
        <>
            <div className={className}>
                <div className="medal-control__info">
                    {<Medal medal={medal}/>}
                    {MEDALS[medalName]}
                </div>
                <div className="medal-control__buttons">
                    <span className="title">{challengeName}</span>
                    <Paragraph size="s" align="left" >
                        ¿Gana la medalla?
                    </Paragraph>
                    <Button size="small" onClick={() => callBack(true, medalName, idChallenge)}>Sí</Button>
                    <Button size="small" onClick={() => callBack(false, medalName, idChallenge)}>No</Button>
                </div>
            </div>
        </>
    );
};

export default MedalControl;