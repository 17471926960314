import { encryptData } from "./encrypt";

const postLoginResponse = (response) => {
    const data = response.data.data;

    if (data?.accessToken || data?.accessToken!==undefined &&  !response.config.url.includes("acdc-rules") || !response.config.url.includes("acdc-payments") ){
        if(data.accessToken){
            localStorage.setItem('session-id',`${data.accessToken}|${data.id}`);
            document.cookie = `session-id=${localStorage.getItem('session-id')}`;
        }
        if (data.remember) {
            const sessionValues = JSON.parse(localStorage.getItem('session-values'));
            localStorage.setItem('session-values', JSON.stringify({
                ...sessionValues,
                remember: data.remember,
                always: data.always,
            }));
        }

        if (data.always) {
            sessionStorage.setItem('session-id', document.cookie?.split(';').find(cookie => cookie.includes('session-id')).split('=')[1]);
            setTimeout(() => {
                sessionStorage.removeItem('session-id');
            }, 12 * 60 * 60);
        }
        document.cookie = `session-id=${localStorage.getItem('session-id')}`;
    }else{
        document.cookie = `session-id=${localStorage.getItem('session-id')}`;
    }
};

export { postLoginResponse };