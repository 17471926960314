import React from 'react'

import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'
import './index.scss';


export const ChallengeHonorSix = ({ setOpenModal, setStep, step }) => {

  return (
    <>
    <br/>
      <div className="card-host card-host-border card-host-border-style" type="secondary" outline>
        <br/>
        <span className="title-honor">Acuerdo de Honor<br /> </span>
        <span className="title-honor"> como nuevo(a) Coordinador(a)</span>
        <br />
        <br />
        <Paragraph size="s" align="center">
          Reconozco que cada acción que tomo no solo afecta mis metas, sino que también contribuye al logro de las metas de mi equipo ACDC. Así, me comprometo a:
        </Paragraph>
        <br />
        <ul>
          <li>Cumplir con las misiones diarias del <br/>cronograma.</li>
          <li>Mantener comunicación transparente y <br/>colaborativa.</li>
          <li>Partocopar en las fechas y horarios <br/> establecidos de los EPAs.</li>
          <li>Mantener y mejorar mi Desempeño.</li>
          <li>Realizar los aportes mensuales.(10%)</li>
          <li>Realizar las comprars requeridas para el <br/> desarrollo de mi rol.</li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <Button onClick={() => {
        setStep(step + 1)
        setOpenModal(true)
      }} className="director margin-top">Aceptar Acuerdo de Honor</Button>
    </>
  )
}
