import axios from "axios";
import { API } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
const keys = {
  EpaDateCoordinatingSchedulesById: ["EpaDateCoordinatingSchedulesById"],
  FoundationsSelectedByNewCandidatesById: ["FoundationsSelectedByNewCandidatesById"],
};



///api/v1/acdc-rules/epa-date/list-reidual-dates/{idUser}
const getEpaDateCoordinatingSchedulesById =async  (idUser) => {

    const {data}=await  axios.get(`${API.EPA_DATE_COORDINATING_SCHEDULES_BY_ID.url}/${idUser}?action=coordinating-schedules`);
    return  data.data;

  }
 
 export const QueriesEpaDateCoordinatingSchedulesById=(idUser) => {
  
    return useQuery({
        queryKey: keys.EpaDateCoordinatingSchedulesById,
        queryFn: async () => await getEpaDateCoordinatingSchedulesById(idUser),
    })
 }

