import React from 'react'
import ClapHand from "../../../resources/images/clap-hand-director.png";
import Paragraph from '../../atoms/paragraph';
import Button from '../../atoms/button';
export const FinishHambre = ({finishChallenge}) => {
    return (
        <>
            <span className='title'>
                <b>¡Felicitaciones!</b>
            </span>
      
            
            <Paragraph size="s" align="justify" className="p-size">
            ¡Felicitaciones has completado los desafíos del EPA! Mañana es el día de Inducción del siguiente nivel de influencia, Formación de Líderes!.
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />
            <Button onClick={()=>finishChallenge(null)}>Finalizar</Button>

        </>
    )
}
