const ScaleItem = ({ role, roleName, level, index, active, ...props }) => {
    const width = 89 + 30 * index;

    return (
        <div className={`scale-item scale-item__${role} ${active ? `active` : ``}`}>
            <div className="scale-item--bottom">
                <span className="scale-item--bottom--description">Nivel {index}</span>
            </div>
            {active && <div className="selector" style={{
                left: `${width+120}px`,
            }}></div>}
            <div className="scale-item--top" style={{
                width: `${width}px`,
            }}>
                <div>
                    <span className="scale-item--top__icon">
                        💪🏼
                    </span>
                    <span className="scale-item--top--role">{roleName}</span>
                </div>
                <div>
                    <span className="scale-item--top__icon">
                        📶
                    </span>
                    <span className="scale-item--top--level">{level}</span>
                </div>
            </div>
        </div>
    );
};

export default ScaleItem;
