import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import { useParams } from "react-router-dom";
import NewHost from "../../../resources/images/new-host.png";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Card from "../../atoms/card";
import Book from "../../../resources/images/book.png";
import GoodHand from '../../../resources/images/good-hand.png';
import ClapHand from "../../../resources/images/clap-candidate.png";
import WarningIcon from '../../../resources/images/warning.png';
import CheckHand from '../../../resources/images/check-hand.png';
import GiftImage from "../../../resources/images/gift-image.png";
import HostGift from "../../../resources/images/host-gift.png";
import GraphIcon from "../../../resources/images/graph-icon.png";
import MediaIcon from "../../../resources/images/media-icon.png";
import Calendar from "../../../resources/images/calendar-host.png";
import CalendarCheckHost from "../../../resources/images/calendar-check-host.png";
import GreenCheck from '../../../resources/images/green-check.png';
import MoneyHand from "../../../resources/images/money-hand.png";
import DirectorCalendar from "../../../resources/images/director-calendar.png";
import ToolsScreenCoordinator from "../../../resources/images/tools-screen-coordinator.png";
import Checkbox from "../../atoms/checkbox";
import Modal from "../../atoms/modal";
import GiftSwitch from "../../molecules/gift-switch";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import MenuUserInfo from "../../molecules/menu-user-info";
import { formatDate, formatDateLong } from "../../../middleware/utils/formatDate";
import PaymentMethodSelector from "../../molecules/payment-method-selector";
import TextField from "../../atoms/text-field";
import Select from "../../atoms/select";
import Form from "../../molecules/form";
import Loader from "../../molecules/loader";
import html2canvas from "html2canvas";
import forge from 'node-forge';
import ChallengePage from "../../pages/challenge";
import { PaymentEpaDay } from "../../pages/payment/payment-epa-day";
import { ModalAlertAutocompleEpaDay } from "../../pages/payment/modal-alert-autocomple-epa-day";
import { WaterMark } from "../../molecules/water-mark";
const EpaEndDay = ({ finishChallenge, step, setStep, challenge, ...props }) => {
    const { user, eventEpa, transationParam, setTransationParam } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();

    const [foundations, setFoundations] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [newRole, setNewRole] = useState(null);

    const circle = {
        host: '⚪',
        'new host': '⚪',
        coordinator: '🔴',
        'new coordinator': '🔴',
        director: '🟡',
        captain: '🔵',
    };
    const publicDecrypt = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0BY6F1qMy+bbcPzRrxY/
    cqo2sPUO8MkBJTRcf+YSeopD6kpo+Wefsbpnf5pLz4nfmgerPS7ma3dVaqnSqfEd
    bR0a3MlB2AVUy/0KK09btpMBi0fkUVHzg8jNXS8l4M8uRTnF3Nw1Em82MFAj4g9w
    YX/2wNuFzfHX7+I0n4eok2z8YVEnQrCgwDSNN9iZ6odBYaEWJMx68CNY4E79QAms
    IPkzi9L+qHk7qbH97Da5zQeYb19aa6dTKYRaxbzPEu+NsvwHsBzOAoOHELCSwVmu
    /9ftvBbbLKc67PPWruof7ehDGe/IHtHMtkrQdI5RdDQ1qcfcHT1fiGm9YK+PAm3h
    WwIDAQAB
    -----END PUBLIC KEY-----`;
    const [listNewRoles, setListNewRoles] = useState([]);
    const [dateEpaAvailable, setDateEpaAvailable] = useState([]);
    const [readyDates, setReadyDates] = useState([]);
    const [suggestedDate, setSuggestedDate] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentBody, setPaymentBody] = useState({});
    const [billBody, setBillBody] = useState({});
    const [email, setEmail] = useState('');
    const [terms, setTerms] = useState(false);

    const [loader, setLoader] = useState(false);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [isValid, setIsValid] = useState(false);

    const [autoComplete, setAutoComplete] = useState(false);

    const [transactionInfo, setTransactionInfo] = useState(null);
    const [alegraProducts, setAlegraProducts] = useState([]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [newSelectedDates, setNewSelectedDates] = useState([]);

    const [imAvailable, setImAvailable] = useState(false);

    const handleSelectFoundation = (foundation) => {
        if (foundations.includes(foundation)) {
            setFoundations(foundations.filter(f => f !== foundation));
        } else {
            if (foundations.length >= 2) {
                const newFoundations = [...foundations];
                newFoundations.splice(foundations.length - 2, 2, foundation);
                setFoundations(newFoundations);
            } else {
                setFoundations([...foundations, foundation]);
            }
        }
    };

    const saveFoundations = () => {
        const options = foundations?.map(foundation => ({ option: foundation }));
        const data = {
            questions: [{
                question: '',
                options: options
            }],
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        }


        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 201) {
                const newData = {
                    id: 'all',
                    message: 'refresh',
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveSelectedDates = () => {
        const data = {
            data: readyDates?.epaDatesAvailable
        };

        axios.put(`${API.SAVE_SELECTED_DATES_FOR_EPA.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                setNewSelectedDates(readyDates?.epaDatesAvailable);
                axios.get(`${API.SUGGESTED_DATE_FOR_EPA.url}/${user?.createByHost}?action=SUGGESTED_DATE_FOR_EPA`).then((response) => {
                    if (response.status === 200) {
                        setSuggestedDate(response.data.data);
                        const newData = {
                            id: 'all',
                            message: 'refresh',
                            action: 'GENERIC_EVENT'
                        };
                        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                            if (response.status === 201) {
                                setRefresh(!refresh);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveSuggestedDate = () => {
        const data = {
            data: readyDates?.selectedDatesForEpa.map((date) => {
                return {
                    ...date,
                    status: date.date === suggestedDate?.[0]?.date,
                };
            }),
        };

        axios.put(`${API.SAVE_SELECTED_DATES_FOR_EPA.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                const newData = {
                    id: 'all',
                    message: 'refresh',
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const changeRole = (role) => {
        const data = {
            role: `host`
        };

        axios.put(`${API.CHANGE_OF_ROLE.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                finishChallenge(null);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
        setPaymentBody({
            ...paymentBody,
            email: e.target.value,
        });
    };

    const saveInfoCard = (e) => {
        e.preventDefault();
        const body = {
            number: e.target.card_number.value,
            action: 'VALIDATE'
        };
        setLoader(true);
        axios.post(`${API.VALIDATE.url}`, body).then((response) => {
            if (response.status === 201) {
                setIsValid(true);
                setPaymentBody({
                    id: user?._id,
                    token: "",
                    acceptance_token: "",
                    number: e.target.card_number.value,
                    cvc: e.target.cvc.value,
                    exp_month: e.target.exp_month.value,
                    exp_year: e.target.exp_year.value,
                    firstName: e.target.first_name.value,
                    lastName: e.target.last_name.value,
                    email: e.target.email.value,
                    documentType: e.target.identification_type.value,
                    identificationNumber: e.target.identification.value,
                    phone: e.target.phone.value,
                    action: 'ADD_CARD',
                });
                setLoader(false);
            }
        }).catch((error) => {
            console.log(error);
            setLoader(false);
        });

    };

    const handlePayment = async (e) => {
        e.preventDefault();
        setTransationParam('')
        try {
            const getAcceptanceKey = await axios.get(`${API.GET_ACCEPTANCE_KEY.url}?action=GET_ACCEPTANCE_KEY`);

            setLoader(true);
            if (getAcceptanceKey.status === 200 && paymentBody && e.target.checkValidity()) {
                const priceSplit = (alegraProducts?.[0]?.price?.[0]?.price + (alegraProducts?.[1]?.price?.[0]?.price * 19 / 100)).toString().split('.');
                //  //nuevo coordinador
                //  const priceSplit = ((alegraProducts?.[1]?.price?.[0]?.price * 2) + ((alegraProducts?.[1]?.price?.[0]?.price * 2) * 19 / 100)).toString().split('.');

                //  //new host
                //  const priceSplit = (alegraProducts?.[0]?.price?.[0]?.price + (alegraProducts?.[1]?.price?.[0]?.price * 19 / 100)).toString().split('.');

                const price = parseInt(priceSplit[0] + '00');
                const publicKey = forge.pki.publicKeyFromPem(publicDecrypt);
                const dataCard = {
                    number: paymentBody.number,
                    cvc: paymentBody.cvc,
                    exp_month: paymentBody.exp_month,
                    exp_year: paymentBody.exp_year,
                }
                const encrypted = publicKey.encrypt(`${JSON.stringify(dataCard)}`, 'RSA-OAEP');
                const encryptedBase64 = forge.util.encode64(encrypted);

                const body = {
                    id: user?._id,
                    action: 'ADD_CARD',
                    token: "",
                    acceptance_token: getAcceptanceKey.data.data.acceptance_token,
                    encryptedCardData: encryptedBase64,
                    firstName: autoComplete ? paymentBody?.firstName : billBody?.firstName,
                    lastName: autoComplete ? paymentBody?.lastName : billBody?.lastName,
                    email: autoComplete ? email : billBody?.email,
                    documentType: autoComplete ? paymentBody?.documentType : billBody?.documentType,
                    documentTypeBill: autoComplete ? paymentBody?.documentType : billBody?.documentTypeBill,

                    identificationNumber: autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber,
                    phone: autoComplete ? paymentBody?.phone : billBody?.phone,
                    price: price,
                    idProduct: alegraProducts?.[1]?.id.toString(),
                    firstNameBill: autoComplete ? paymentBody?.firstName : billBody?.firstName,
                    lastNameBill: autoComplete ? paymentBody?.lastName : billBody?.lastName,
                    identificationNumberBill: autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber,
                    emailBill: autoComplete ? email : billBody?.email,
                    phoneBill: autoComplete ? paymentBody?.phone : billBody?.phone,
                }
                axios.post(`${API.ADD_CARD.url}`, body).then((response) => {

                    if (response.status === 201) {
                        setStep(step + 1);
                        setLoader(false);
                        setTransactionInfo(response.data.data);
                        setTransationParam(response.data.data?.status)
                        const newData = {
                            id: 'all',
                            message: 'refresh',
                            action: 'GENERIC_EVENT'
                        };
                        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                            if (response.status === 201) {
                                //setRefresh(!refresh);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                    setLoader(false);
                });
            } else {
                setLoader(false);
            }
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    };

    useEffect(() => {
        axios.get(`${API.LIST_DATE_EPA_AVAILABLE.url}/${user?.createByHost}?action=LIST_DATE_EPA_AVAILABLE`).then((response) => {
            if (response.status === 200) {
                const data = response.data.data;
                const epaDatesAvailable = data?.epaDatesAvailable?.map((date) => ({
                    ...date,
                    status: false,
                }));
                data.epaDatesAvailable = epaDatesAvailable;
                setDateEpaAvailable(data);
            }
        }).catch((error) => {
            console.log(error);
        });


        axios.get(`${API.READY_EPA_AVAILABLE_DATES_COORDINATOR.url}/${user?.createByHost}?action=READY_EPA_AVAILABLE_DATES_COORDINATOR`).then((response) => {
            if (response.status === 200) {
                const data = response.data.data;
                const epaDatesAvailable = data?.selectedDatesForEpa;
                data.epaDatesAvailable = epaDatesAvailable?.map((date) => ({
                    ...date,
                    status: false,
                }));
                setReadyDates(data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.SINGLE_COORDINATOR_SCHEDULE.url}/${user?.createByHost}?action=SINGLE_COORDINATOR_SCHEDULE`).then((response) => {
            if (response.status === 200) {
                const schedule = response.data?.data?.schedule?.map((date) => {
                    return {
                        ...date,
                        date: formatDate(date.date),
                    }
                });
                setSchedule(schedule);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.GET_PRODUCTS_ALEGRA.url}?action=GET_PRODUCTS_ALEGRA`).then((response) => {
            if (response.status === 200) {
                setAlegraProducts(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.GET_INFORMATION.url}`).then((response) => {
            if (response?.status === 200) {
                setNewRole(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        getCountries().then((response) => {
            setCountries(response);
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.LIST_NEW_ROLES.url}/${user?.createByHost}?action=LIST_NEW_ROLES`).then((response) => {
            if (response.status === 200) {
                setListNewRoles(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

    }, [eventEpa]);

    useEffect(() => {
        const allFieldsFilled = Object.values(paymentBody).every(field => field.trim() !== '');
        setIsButtonDisabled(!allFieldsFilled);
    }, [paymentBody]);

    const formatPrice = (price) => {
        return new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP" }).format(Math.floor(price, 0)).split(',')[0];
    };

    const getCountries = () => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_COUNTRIES.url}?action=GET_COUNTRIES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getStates = (country) => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_STATES.url}/${country}?action=GET_STATES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getCities = (country, state) => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_CITIES.url}/${country}/${state}?action=GET_CITIES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };






    return (
        <>

            {challengeName === 'Desafío Honor' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={NewHost} alt="new-host" width={209} height={209} />
                    <Paragraph size="s" align="center">
                        ¿Listos para hacer historia? Hoy vas a sellar el compromiso con tu equipo porque aquí, en ACDC, todos compartimos un Legado en común, estamos construyendo el ecosistema de innovación y emprendimiento 4.0 de nuestra Comunidad Épica.
                    </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “Las palabras de un líder y los pasos que da, deben coincidir. Como dice el dicho: “Tu palabra es tu valor y tu valor está en tu palabra.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Es fundamental que cumplas con tu palabra no solo para lograr tus metas personales y tu PDL sino también para permitir que todo el equipo ACDC pueda realizar con éxito su PDL. De este compromiso de honor depende que:
                    </Paragraph>
                    <Paragraph size="s" align="left">
                        <>
                            Tú {circle[user?.role]} <b>{user?.name} </b>
                            logres generar hasta USD 80.000 al mes.
                            <br />
                            <br />
                        </>
                        {listNewRoles?.filter((newRole) => user?.name !== newRole.name).map((newRole, index) => <>{circle[newRole?.role]} <b>{newRole?.name}</b>
                            <br />
                            logre generar hasta USD 80.000 al mes.
                            {index < listNewRoles?.length && <br />}
                            {index < listNewRoles?.length && <br />}
                        </>)}
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={MoneyHand} alt="Money Hand" className="money-hand" />
                    <Paragraph size="s" align="center">
                        En total, tu equipo ACDC tiene el potencial de generar USD 400 mil al mes. Por lo tanto, el aporte de tu equipo, correspondiente al 10% para el Ecosistema de Innovación y emprendimiento ACDC sería potencialmente de USD 40 mil mensuales, de los cuáles USD 4 mil mensuales irán a las Fundaciones que el equipo elija.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">Selecciona las Fundaciones</span>
                    <Paragraph size="s" align="center">
                        De tu aporte mensual al Ecosistema, el 10% se destinará a fundaciones. Podrás elegir el tipo de fundación al que quieres aportar:
                    </Paragraph>
                    <Checkbox
                        label="Fundación de animales abandonados"
                        checked={foundations.includes('Fundación de animales abandonados')}
                        onChange={() => handleSelectFoundation('Fundación de animales abandonados')}
                    />
                    <Checkbox
                        label="Fundación Cáncer"
                        checked={foundations.includes('Fundación Cáncer')}
                        onChange={() => handleSelectFoundation('Fundación Cáncer')}
                    />
                    <Checkbox
                        label="Fundación Huérfanos"
                        checked={foundations.includes('Fundación Huérfanos')}
                        onChange={() => handleSelectFoundation('Fundación Huérfanos')}
                    />
                    <Checkbox
                        label="Fundación Ancianos"
                        checked={foundations.includes('Fundación Ancianos')}
                        onChange={() => handleSelectFoundation('Fundación Ancianos')}
                    />
                    <Checkbox
                        label="Fundación Discapacitados"
                        checked={foundations.includes('Fundación Discapacitados')}
                        onChange={() => handleSelectFoundation('Fundación Discapacitados')}
                    />
                    <Checkbox
                        label="Fundación a Migrantes"
                        checked={foundations.includes('Fundación a Migrantes')}
                        onChange={() => handleSelectFoundation('Fundación a Migrantes')}
                    />
                    <Checkbox
                        label="Fundación de Arte"
                        checked={foundations.includes('Fundación de Arte')}
                        onChange={() => handleSelectFoundation('Fundación de Arte')}
                    />
                    <Paragraph size="xs" align="center">
                        Selecciona dos categorías de las fundaciones a las que te gustaría aportar, podrás cambiarlas en el momento en que inicien tus aportes
                    </Paragraph>
                    <Button onClick={saveFoundations} disabled={foundations.length < 2}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline>
                        <span className="title">Acuerdo de Honor como nuevo(a) Anfitrión(a)</span>
                        <Paragraph size="s" align="center">
                            Reconozco que cada acción que tomo no solo afecta mis metas, sino que también contribuye al logro de las metas de mi equipo ACDC. Así, me comprometo a:
                        </Paragraph>
                        <ul>
                            {challenge?.questions?.[0]?.options?.map((option) => <li>{option.option}.</li>)}
                        </ul>
                    </Card>
                    <Button onClick={() => setStep(step + 1)} role="host">Acepto Acuerdo de Honor</Button>
                </Step>
                <Modal isOpen={step === 7} className="agreements-modal">
                    <img src={GoodHand} alt="Good Hand" width={142} height={142} />
                    <Paragraph size="s" align="center">
                        ¡Excelente!
                        <br />
                        <br />
                        Has aceptado el Acuerdo de Honor del equipo ACDC.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">
                        ¡Vale!
                    </Button>
                </Modal>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “El honor está conformado por las virtudes de la integridad, la honestidad, el sacrificio, la lealtad y la humildad, pero de un corazón justo y piadoso para aquellos menos poderosos”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={Calendar} alt="Calendar" className="calendar-image" />
                    <span className="title">Agendamiento EPA</span>
                    <Paragraph size="s" align="center">
                        Es momento de definir la fecha y hora de tu EPA
                        <br />
                        <br />
                        Tu nuevo(a) Director(a) y tu nuevo(a) Coordinador(a) proponen las siguientes {readyDates?.epaDatesAvailable?.length} opciones para el EPA:
                        <br />
                        <br />
                        (Elige los dos que más se ajusten a ti)
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        {readyDates?.epaDatesAvailable?.map((date) => {
                            return <div className="checkbox-table__item">
                                <Checkbox
                                    checked={date.status && selectedItems?.find((item) => item.date === date.date)}
                                    onChange={() => {
                                        if (readyDates?.epaDatesAvailable?.filter((date) => date.status)?.length < 2) {
                                            date.status = true;
                                            setSelectedItems([...selectedItems, date]);
                                        } else {
                                            setSelectedItems(selectedItems.filter((item) => item.date !== date.date));
                                            date.status = false;
                                        }

                                    }}
                                />
                                <span className="checkbox-table__item__start-time">
                                    <span className="icon-calendar"></span>
                                    {date.date}
                                </span>
                                <span className="checkbox-table__item__end-time">{date.hour}</span>
                            </div>
                        })}
                    </div>
                    <Button onClick={saveSelectedDates} disabled={selectedItems.length < 2}>Aceptar</Button>
                </Step>
                <Step order={10} visibility={step === 10 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        {user?.name}
                        <br />
                        ⚪ Nuevo(a) Anfitrión(a)
                        <br />
                        Eligió:
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        {newSelectedDates?.filter((date) => date.status)?.map((date) => {
                            return <div className="checkbox-table__item">
                                <span className="checkbox-table__item__start-time">
                                    <span className="icon-calendar"></span>
                                    {date.date}
                                </span>
                                <span className="checkbox-table__item__end-time">{date.hour}</span>
                            </div>
                        })}
                    </div>
                    <Paragraph size="s" align="center">
                        <b>Por lo tanto, la mejor fecha para el EPA es:</b>
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        <div className="checkbox-table__item">
                            <span className={`checkbox-table__item__start-time border-${user?.role?.split(' ')?.[1] || user?.role}`}>
                                <span className="icon-calendar"></span>
                                {suggestedDate?.[0]?.date}
                            </span>
                            <span className={`checkbox-table__item__end-time border-${user?.role?.split(' ')?.[1] || user?.role}`}>{suggestedDate?.[0]?.hour}</span>
                        </div>
                    </div>
                    {!suggestedDate?.[0] && <Paragraph size="xs" align="center" >
                        Esperando a que el otro Nuevo Anfitrión defina sus fechas
                    </Paragraph>}
                    <Paragraph size="s" align="center" >
                        🖐🏼 Antes de confirmar, valida tu disponibilidad para esa fecha y hora en tu calendario.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="host">Continuar</Button>
                </Step>
                <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        <b>Confirmación de fecha para el EPA</b>
                        <br />
                        Tu director está revisando la fecha definida para cada EPA...
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        <div className="checkbox-table__item">
                            <span className={`checkbox-table__item__start-time border`}>
                                <span className="icon-calendar"></span>
                                {suggestedDate?.[0]?.date}
                            </span>
                            <span className={`checkbox-table__item__end-time border`}>{suggestedDate?.[0]?.hour}</span>
                        </div>
                    </div>
                    <img src={DirectorCalendar} alt="Director Calendar" className="director-calendar-image" />
                    <Paragraph size="s" align="center" >
                        Una vez tu director confirme la fecha se habilitará el Botón Continuar EPA
                    </Paragraph>
                    <Checkbox
                        label="Confirmo que estoy disponible y que participaré del EPA en la fecha y hora definidas."
                        checked={imAvailable}
                        onChange={() => setImAvailable(!imAvailable)}
                    />
                    <Button onClick={() => setStep(step + 1)} role="host" disabled={!imAvailable}>Continuar EPA</Button>
                </Step>
                <Modal isOpen={step === 12} className="calendar-modal">
                    <img src={Calendar} alt="Calendar" width={122} height={122} />
                    <Paragraph size="s" align="center">
                        ¡Súper!
                        <br />
                        <br />
                        ¡Genial! Tu EPA ha sido agendado con éxito para el
                        <b> {formatDateLong(suggestedDate?.[0]?.date)} {suggestedDate?.[0]?.hour}</b>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">
                        ¡Vale!
                    </Button>
                </Modal>
                <Step order={13} visibility={step === 13 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Nuevo(a) Anfitrión(a)
                        <br />
                        <br />
                        Es momento de definir los días del cronograma. El día de Inducción será mañana y el Día de Alineamiento será un día antes del EPA.
                        <br />
                        <br />
                        El nuevo Director podrá modificar el Día de Reglas que moverá las demás fechas hasta el Día de Filtro.
                    </Paragraph>
                    <div className="checkbox-table">
                        <div className="checkbox-table__header">
                        </div>
                        {schedule?.map((date, index) => <div className="checkbox-table__item">
                            <span className={`checkbox-table__item__start-time days`}>
                                {date.day}
                            </span>
                            <span className={`checkbox-table__item__end-time ${index > 0 ? 'border' : ''} days`}>{date.date}</span>
                        </div>)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role="host">Continuar</Button>
                </Step>
                <Step order={14} visibility={step === 14 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        ¡Genial!
                        <br />
                        <br />
                        Has completado el <b>Desafío Honor</b> del Final del EPA
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="background-p">
                        Ya puedes iniciar el <b>Desafío Humildad</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => { }} role="host">Ver video</Button>
                    <Button onClick={() => finishChallenge(null)} size="small" style="ghost">Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Humildad' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <img src={CalendarCheckHost} alt="Calendar Check Host" className="calendar-check-host" />
                    <Paragraph size="s" align="center">
                        Ahora que ya definiste la fecha y hora del EPA, continúa en la Meet con tu nuevo(a) Coordinador(a), quien te dará la invitación para unirte al grupo de WhatsApp del EPA, ahí se coordinará el proceso de preselección de tus Aspirantes y los detalles para realizar el EPA.
                    </Paragraph>
                    <Button onClick={() => { }} size="small" style="ghost">Ver video</Button>
                    <Paragraph size="s" align="center" className="background-p">
                        Podrás continuar cuando tu Nuevo(a) Director(a) confirme que se han agregado los dos Nuevos Anfitriones al grupo de Whatsapp.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role="host">Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “No existen secretos para alcanzar el éxito. Este es el resultado de la preparación, el trabajo arduo y de aprender de los fracasos”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">¡Compra tu Contenido ACDC!</span>
                    <img src={HostGift} alt="Host Gift" className="host-gift" />
                    <Paragraph size="s" align="center">
                        ¡Nuevo Anfitrión!
                        <br />
                        Es momento que accedas al contenido y a la plataforma de ACDC.
                    </Paragraph>
                    <Paragraph size="s" align="center" className="background-p">
                        Tu <b>Nuevo(a) Coordinador(a)</b> te obsequiará el acceso a los niveles de la  plataforma y tú solo deberás pagar el acceso al contenido audiovisual.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title"><span className="icon-shop-bag"></span> <b>Módulo de compra ACDC</b></span>
                    <div className="buy-item">
                        <img src={GraphIcon} className="buy-item__logo" />
                        <div className="buy-item__info">
                            <span className="buy-item__info__name"><span className="icon-gift"></span> Niveles Plataforma ACDC</span>
                            <span className="buy-item__info__description">(Obsequio)</span>
                            <span className="buy-item__info__price">$0.00 <span className="sale">($42.016)</span> COP</span>
                        </div>
                    </div>
                    <div className="buy-item">
                        <img src={MediaIcon} className="buy-item__logo" />
                        <div className="buy-item__info">
                            <span className="buy-item__info__name">{alegraProducts?.[0]?.name}</span>
                            <span className="buy-item__info__description"></span>
                            <span className="buy-item__info__price">{formatPrice(alegraProducts?.[0]?.price?.[0]?.price)} COP</span>
                        </div>
                    </div>
                    <table className="buy-table">
                        <tr>
                            <th></th>
                            <th align="left">($COP)</th>
                            <th align="left">Cant.</th>
                        </tr>
                        <tr>
                            <th align="right">Niveles Plataforma:</th>
                            <td>$ 0</td>
                            <td align="center">1</td>
                        </tr>
                        <tr>
                            <th align="right">Cont. Audiovisual:</th>
                            <td>{formatPrice(alegraProducts?.[1]?.price?.[0]?.price)}</td>
                            <td align="center">1</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th align="right">Subtotal:</th>
                            <td>{formatPrice(alegraProducts?.[1]?.price?.[0]?.price)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th align="right">IVA(19%):</th>
                            <td>{formatPrice((alegraProducts?.[1]?.price?.[0]?.price * 19 / 100))}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th align="right">Total a pagar:</th>
                            <td colSpan={2} className="border">{formatPrice(alegraProducts?.[1]?.price?.[0]?.price + (alegraProducts?.[1]?.price?.[0]?.price * 19 / 100))} <span>COP</span></td>
                        </tr>
                    </table>
                    <Button onClick={() => setStep(step + 1)}>Iniciar pago</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">Elige el método de pago</span>
                    <PaymentMethodSelector callback={(e) => setPaymentMethod(e)} />
                    <WaterMark />
                    <Paragraph size="xs" align="center">
                        Selecciona un método para poder continuar
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={!paymentMethod}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <span className="title">Por favor ingresa los datos de tu tarjeta</span>
                    <Form className="card-form" onSubmit={saveInfoCard}>
                        <TextField
                            name="first_name"
                            placeholder="Primer nombre"
                            onChange={(e) => {
                                setPaymentBody({
                                    ...paymentBody,
                                    firstName: e.target.value,
                                });
                            }}
                            required />
                        <TextField
                            name="last_name"
                            placeholder="Primer apellido"
                            onChange={(e) => {
                                setPaymentBody({
                                    ...paymentBody,
                                    lastName: e.target.value,
                                });
                            }}
                            required />
                        <div className="card-form__item">
                            <span className="card-form__item__number">Número de tarjeta</span>
                            <TextField
                                name="card_number"
                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        number: e.target.value,
                                    });
                                }}
                                maxLength={16}
                                required />
                        </div>
                        <div className="card-form__item">
                            <span className="card-form__item__exp_month">Mes de Expiración</span>
                            <TextField
                                name="exp_month"
                                placeholder="MM"
                                pattern="0[1-9]|1[0-2]"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        exp_month: e.target.value,
                                    });
                                }}
                                maxLength={2}
                                required />
                            <span className="card-form__item__exp_year">Año de Expiración</span>
                            <TextField
                                name="exp_year"
                                placeholder="AA"
                                pattern="[0-9]{2}"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        exp_year: e.target.value,
                                    });
                                }}
                                maxLength={2}
                                required />
                        </div>
                        <div className="card-form__item cvc">
                            <span className="card-form__item__cvc">CVC</span>
                            <TextField
                                name="cvc"
                                placeholder="XXX"
                                pattern="[0-9]{3}"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        cvc: e.target.value,
                                    });
                                }}
                                maxLength={3}
                                required />
                        </div>
                        <div className="card-form__item identification">
                            <span className="card-form__item__identification">Doc. de identidad</span>
                            <Select name="identification_type" onChange={(e) => {
                                setPaymentBody({
                                    ...paymentBody,
                                    documentType: e.target.value,
                                });
                            }}>
                                <option value="CC">CC</option>
                                <option value="NIT">NIT</option>
                            </Select>
                            <TextField
                                name="identification"
                                placeholder="0000000000"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        identificationNumber: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <TextField
                            name="email"
                            placeholder="Correo electrónico"
                            leftIcon={{ icon: 'icon-mail' }}
                            pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                            value={email}
                            onChange={handleChangeEmail}
                            required />
                        <div className="card-form__item">
                            <span className="card-form__item__phone">Teléfono</span>
                            <TextField
                                name="phone"
                                placeholder="0000000000"
                                onChange={(e) => {
                                    setPaymentBody({
                                        ...paymentBody,
                                        phone: e.target.value,
                                    });
                                }}
                                required />
                        </div>
                        <Paragraph size="xs" align="center">
                            Tus datos solo se utilizarán para procesar tu pedido y mejorar tu experiencia en esta plataforma.
                        </Paragraph>
                        <Checkbox
                            label="He leído y estoy de acuerdo con los términos y condiciones de la web"
                            checked={terms}
                            onChange={() => setTerms(!terms)}
                        />
                        {/* <Button onClick={() => {
                            setIsValid(false);
                            setStep(step + 1);
                        }} >Continuar</Button> */}
                        <Button size="small" disabled={!terms}>Continuar</Button>
                        {/* <Button  disabled={!terms || isButtonDisabled} >Continuar</Button> */}
                    </Form>
                </Step>
                <ModalAlertAutocompleEpaDay isValid={isValid} setIsValid={setIsValid} setStep={setStep} step={step} className="host-alignment-day-container epa-confirmation-modal fit-content" />
                <Loader isShow={loader} />
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    {/* <span className="title">Ahora ingresa los detalles de facturación y completa la compra</span>
                    <Checkbox
                        label="Autocompletar datos"
                        checked={autoComplete}
                        onChange={() => setAutoComplete(!autoComplete)}
                    />
                    <Form className="card-form" onSubmit={handlePayment}>
                        <div className="card-form__item identification">
                            <span className="card-form__item__identification">Doc. de identidad</span>
                            <Select name="identification_type" onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    documentType: e.target.value,
                                });
                            }}>
                                <option value="CC">CC</option>
                                <option value="NIT">NIT</option>
                            </Select>
                            <TextField
                                name="identification"
                                placeholder="0000000000"
                                onChange={(e) => {
                                    setBillBody({
                                        ...billBody,
                                        identificationNumber: e.target.value,
                                    });
                                }}
                                value={autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber}
                                required
                            />
                        </div>
                        <TextField
                            name="name"
                            placeholder="Nombres"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    firstName: e.target.value,
                                });
                            }}
                            value={autoComplete ? paymentBody?.firstName : billBody?.firstName}
                            required />
                        <TextField
                            name="last_name"
                            placeholder="Apellidos"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    lastName: e.target.value,
                                });
                            }}
                            value={autoComplete ? paymentBody?.lastName : billBody?.lastName}
                            required />
                        <TextField
                            name="company"
                            placeholder="Nombre de la empresa (opcional)"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    company: e.target.value,
                                });
                            }}
                        />
                        <Select name="country" onChange={(e) => {
                            setBillBody({
                                ...billBody,
                                country: e.target.value,
                            });
                            getStates(e.target.value).then((response) => {
                                setStates(response);
                            }).catch((error) => {
                                console.log(error);
                            });
                        }} required>
                            <option value="">País / Región</option>
                            {countries?.map((country) => <option value={country.isoCode}>{country.name}</option>)}
                        </Select>
                        <Select name="state" onChange={(e) => {
                            setBillBody({
                                ...billBody,
                                state: e.target.value,
                            });
                            getCities(billBody?.country, e.target.value).then((response) => {
                                setCities(response);
                            }).catch((error) => {
                                console.log(error);
                            });
                        }} required>
                            <option value="">Departamento</option>
                            {states?.map((state) => <option value={state.isoCode}>{state.name}</option>)}
                        </Select>
                        <Select name="state" onChange={(e) => { }} required>
                            <option value="">Ciudad</option>
                            {cities?.map((city) => <option value={city.isoCode}>{city.name}</option>)}
                        </Select>
                        <TextField
                            name="address"
                            placeholder="Dirección de residencia"
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    address: e.target.value,
                                });
                            }}
                            required />
                        <TextField
                            name="email"
                            placeholder="Correo electrónico"
                            leftIcon={{ icon: 'icon-mail' }}
                            pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                            value={autoComplete ? paymentBody?.email : billBody?.email}
                            onChange={(e) => {
                                setBillBody({
                                    ...billBody,
                                    email: e.target.value,
                                });
                            }}
                            required />
                        <div className="card-form__item">
                            <span className="card-form__item__phone">Teléfono</span>
                            <TextField
                                name="phone"
                                placeholder="0000000000"
                                pattern="[0-9]{10}"
                                onChange={(e) => {
                                    setBillBody({
                                        ...billBody,
                                        phone: e.target.value,
                                    });
                                }}
                                value={autoComplete ? paymentBody?.phone : billBody?.phone}
                                required />
                        </div>
                        <Button  >Continuar</Button>
                    </Form> */}
                    <PaymentEpaDay email={email} setLoader={setLoader} setTransactionInfo={setTransactionInfo} alegraProducts={alegraProducts} setAlegraProducts={setAlegraProducts} paymentBody={paymentBody} setPaymentBody={setPaymentBody} step={step} setStep={setStep} />

                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-end-day-container host">
                    {(transactionInfo?.status === 'APPROVED') ? <>
                        <img src={GreenCheck} alt="Green Check" className="green-check" />
                        <span className="title">Transacción aprobada</span>
                    </> : <>
                        <img src={WarningIcon} alt="Warning" className="warning" />
                        <span className="title">Transacción rechazada</span>
                    </>}

                    <Card type="tertiary" >
                        <span className="card-title">Información de la transacción</span>
                        <div className="card-item">
                            <span className="card-item__title">Transacción #:</span>
                            <span className="card-item__value">{transactionInfo?.id}</span>
                        </div>
                        <div className="card-item">
                            <span className="card-item__title">Referencia:</span>
                            <span className="card-item__value">{transactionInfo?.reference}</span>
                        </div>
                        <div className="card-item last">
                            <span className="card-item__title">Email:</span>
                            <span className="card-item__value">{email}</span>
                        </div>
                    </Card>
                    <Card type="tertiary" >
                        <span className="card-title">Información del pagador</span>
                        <div className="card-item">
                            <span className="card-item__title">Nombre:</span>
                            <span className="card-item__value">{paymentBody?.firstName} {paymentBody?.lastName}</span>
                        </div>
                        <div className="card-item last">
                            <span className="card-item__title">Teléfono:</span>
                            <span className="card-item__value">{paymentBody?.phone}</span>
                        </div>
                    </Card>
                    <Button onClick={() => {
                        html2canvas(document.getElementById('step-8')).then(canvas => {
                            const link = document.createElement('a');
                            link.download = `${transactionInfo?.id}.png`;
                            link.href = canvas.toDataURL();
                            link.click();
                        });
                    }} size="small" style="ghost">Imprimir</Button>

                    {(transactionInfo?.status === 'APPROVED') ? <>
                        <Button onClick={() => setStep(step + 1)}>Finalizar transacción</Button>
                    </> : <>
                        <Button onClick={() => setStep(step - 1)}>Volver al método de pago</Button>
                    </>}

                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Una vez te hayas unido envía un pantallazo al grupo de whatsapp que lo evidencie.
                    </Paragraph>
                    <GiftSwitch role={'host'} nameRole={'Anfitrión(a)'} name={user?.name} range={7} onClick={(e) => { }} items={[
                        {
                            image: GraphIcon,
                            title: 'Niveles de plataforma ACDC',
                            wait: newRole?.paymentLevelsAcdcPlatform !== 'APPROVED' && newRole?.paymentLevelsAcdcPlatform !== 'PENDING'
                        },
                        {
                            image: MediaIcon,
                            title: 'Contenido Audiovisual ACDC',
                            wait: newRole?.paymentForAudiovisualContentAcdc !== 'APPROVED' && newRole?.paymentForAudiovisualContentAcdc !== 'PENDING'
                        }
                    ]} />
                    <Paragraph size="s" align="center">
                        Este switch se activa
                        <br />
                        automáticamente
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        Podrás continuar cuando tu Nuevo Coordinador confirme que te uniste al canal y el Nuevo Director habilite el avance.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar EPA</Button>
                </Step>
                <Modal isOpen={step === 10} className="host-alignment-day-container epa-confirmation-modal fit-content">
                    <img src={GiftImage} alt="Gift Image" className="gift-image" />
                    <span className="title">¡Súper!</span>
                    <Paragraph size="s" align="center">
                        Has recibido el obsequio de parte de tu Nuevo(a) Coordinador(a), ahora puedes acceder a la plataforma y al contenido que requieres  para aplicar la estrategia.
                        <br />
                        <br />
                        Mañana iniciarás con el día de Inducción junto con tu equipo.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} size="small">¡Vale!</Button>
                </Modal>
                <Step order={11} visibility={step === 11 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío Humildad</b> del Final del EPA
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="background-p" >
                        Ya puedes iniciar el <b>Desafío Hambre</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Hambre' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Card type="secondary" outline className="epa-end-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “Tu meta será el producto de todo el esfuerzo y los pensamientos correctos que has tenido a lo largo del camino, es decir, a través de tu preparación. La preparación es donde radica el éxito”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmento tomado del Libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="epa-end-day-container-button">Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        ¡Momento de agradecer!
                    </Paragraph>
                    <img src={ToolsScreenCoordinator} alt="Tools Screen Coordinator" className="tools-screen-coordinator" />
                    <Paragraph size="s" align="center">
                        Comienza con el entrenamiento de la clave A (Agradecimiento) del ABC de la Convocatoria junto a tu Nuevo(a) Capitán(a) (Nuevo TWOBOT) y Nuevo(a) Coordinador(a). Utiliza el nuevo contacto de TWOBOT que te envió tu Director(a).
                        <br />
                        <br />
                        Una vez realices el Entrenamiento, será momento de poner en práctica lo aprendido.
                        <br />
                        <br />
                        Aplica la clave A (Agradecimiento) con tus validadores y marca la casilla de aquellos con los que ya lo hayas aplicado.
                    </Paragraph>
                    <Button onClick={() => { }} size="small" style="ghost">Ver video</Button>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        Las casillas de los validadores se habilitan una vez tu Nuevo Coordinador confirma que realizaste el entrenamiento.
                    </Paragraph>
                    <div className="training-completed">
                        <MenuUserInfo orientation="row" name={user?.name} role={user?.role?.split(' ')?.[1] || user?.role} nameRole={'Nuevo(a) Anfitrión(a)'} range={7} />
                        <div className="training-completed__table">
                            <span className="training-completed__table__column text">Entrenamiento completado</span>
                            <div className="training-completed__table__column">
                                <Checkbox
                                    checked={newRole?.completedTrainingOfKeyA}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                        <div className="training-completed__table">
                            <span className="training-completed__table__column text">Agradecimientos Realizados:</span>
                            <div className="training-completed__table__column">
                                {newRole?.personalReferences?.filter((personalReference) => {
                                    return personalReference.gratitudeDone
                                }).length || 0}
                            </div>
                        </div>
                    </div>
                    <div className="training-validators">
                        {newRole?.personalReferences?.map((personalreference, index) => <Checkbox
                            label={personalreference?.name}
                            checked={personalreference?.gratitudeDone}
                            disabled={(!newRole?.completedTrainingOfKeyA || personalreference?.gratitudeDone)}
                            onChange={() => {
                                axios.post(`${API.CHECK_GRATITUDE_DONE_VALIDATOR.url}/${personalreference.token}`).then((response) => {
                                    if (response.status === 201) {
                                        const newData = {
                                            id: 'all',
                                            message: 'refresh',
                                            action: 'GENERIC_EVENT'
                                        };
                                        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                                            if (response.status === 201) {
                                                setRefresh(!refresh);
                                            }
                                        }).catch((error) => {
                                            console.log(error);
                                        });
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }}
                            labelBackground
                        />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role="host" disabled={!newRole?.completedTrainingOfKeyA}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-end-day-container">
                    <Paragraph size="s" align="center">
                        <b>¡Felicitaciones!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        ¡Has completado los desafíos del final del EPA! Mañana es el día de Inducción del siguiente nivel de influencia, Desempeño!
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Button onClick={changeRole}>Finalizar</Button>
                </Step>
            </>}
        </>
    );
};

export default EpaEndDay;