import React from "react";
import Modal from "../../atoms/modal";

const CarouselModal = ({ children, isOpen, color, skipStarsBackground }) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [navLeft, setNavLeft] = React.useState(false);
    const [navRight, setNavRight] = React.useState(true);
    const countSlides = React.Children.count(children);

    const handleNavigation = (plus) => {
        const navigation = currentSlide + plus;
        if(navigation < countSlides && navigation >= 0) {
            setCurrentSlide(navigation);
        } 
    };

    return (
        <Modal className="carousel-modal" isOpen={isOpen} color={color} skipStarsBackground={skipStarsBackground}>
            <div className="carousel-modal-container">
                <div className="carousel-slides">
                    {React.Children.map(children, (child, index) => {
                        return index === currentSlide && child;
                    })}
                </div>
                <div className="carousel-controls">
                    <div className={`carousel-control carousel-control--prev ${currentSlide === 0 ? 'disabled' : ''}`} onClick={() => handleNavigation(-1)} onKeyDown={() => {}}>
                        <span className="icon icon-previous"></span>
                    </div>
                    <ul className="carousel-control carousel-control--pagination">
                        {React.Children.map(children, (child, index) => (
                            <li key={index} className={`item ${index === currentSlide ? 'active' : ''}`}></li>
                        ))}
                    </ul>
                    <div className={`carousel-control carousel-control--next ${currentSlide === countSlides - 1 ? 'disabled' : '' }`} onClick={() => handleNavigation(1)} onKeyDown={() => {}}>
                        <span className="icon icon-next"></span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CarouselModal;