const ProgressBar = ({role, range, size}) => {

    console.log('range', range);
    return (
        <div className={`learn-bar learn-bar--${size}`}>
            <div className={`learn-bar__progress learn-bar__progress--${role}`} style={{transform: `scaleX(${range/100})`}}></div>
        </div>
    );
};

export default ProgressBar;