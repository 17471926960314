import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Book from "../../../resources/images/book.png";
import SyncIcon from '../../../resources/images/sync-icon-large.png';
import ClapHand from "../../../resources/images/clap-candidate.png";
import TouchScreen from "../../../resources/images/screen-touch-hand.png"
import Key from '../../../resources/images/key.png';
import MyPerformance from "../../../resources/images/my-performance-host.png";
import ScreenBridge from "../../../resources/images/screen-bridge-host.png";
import PortalBridge from "../../../resources/images/portal-bridge-host.png";
import AspirantsPdl from "../../../resources/images/aspirants-pdl.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Button from "../../atoms/button";
import Checkbox from "../../atoms/checkbox";
import { useContext, useEffect, useState } from "react";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import CustomDatePicker from "../../molecules/date-picker";

const HostFilterDay = ({ finishChallenge, step, setStep, challenge }) => {
    const { challengeName, idChallenge } = useParams();
    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const [challengeState, setChallengeState] = useState(challenge);

    const [firstSchedule, setFirstSchedule] = useState(false);
    const [secondSchedule, setSecondSchedule] = useState(false);
    const [thirdSchedule, setThirdSchedule] = useState(false);
    const [fourthSchedule, setFourthSchedule] = useState(false);
    const [time, setTime] = useState([]);
    const [timeD, setTimeD] = useState(new Date());
    const [timeE, setTimeE] = useState(new Date());
    const [selectedAspirants, setSelectedAspirants] = useState([]);
    const [aspirants, setAspirants] = useState([]);
    const [timeRanges, setTimeRanges] = useState([]);
    const [requiredTime, setRequiredTime] = useState({});
    const [timeSelected, setTimeSelected] = useState(false);
    const [canFinish, setCanFinish] = useState(false);

    const [isTimePickerOpenD, setIsTimePickerOpenD] = useState(false);
    const [isTimePickerOpenE, setIsTimePickerOpenE] = useState(false);

    const [aspirantsWithTraining, setAspirantsWithTraining] = useState([]);

    const handleAspirantSelection = (isChecked, aspirant) => {
        console.log(isChecked, aspirant);
        const selected = selectedAspirants;
        if (isChecked) {
            selected.push(aspirant);
        } else {
            selected.splice(selected.indexOf(aspirant), 1);
        }

        if (selected.length >= 3) {
            finishChallenge(null);
        }

        setSelectedAspirants(selected);

    };

    const isCompletedChallenges = (aspirant) => {
        const personalReferences = aspirant?.personalReferences.find((personalReference) => personalReference?.status && personalReference?.role === 'host');
        return personalReferences;
    };

    const handleOpenTimePicker = (index) => {
        if (index === 'D') {
            setIsTimePickerOpenD(true);
        } else {
            setIsTimePickerOpenE(true);
        }

    };

    const saveScheduleTime = () => {
        const data = {
            questions: timeRanges.map((timeRange) => {
                return {
                    start: timeRange.start,
                    end: timeRange.end,
                    status: timeRange.status
                }
            }),
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        };


        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`, data).then((response) => {
            if (response.status === 201) {
                const newData = {
                    id: 'all',
                    message: JSON.stringify(data.questions),
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        console.log('Evento enviado');
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const getMin = (time) => {
        const newTime = new Date(time);
        newTime.setMinutes(newTime.getMinutes() + requiredTime?.totalTime);
        return newTime;
    };

    useEffect(() => {
        axios.get(`${API.LIST_CANDIDATES_KEYS.url}/${user?._id}?action=LIST_CANDIDATES_KEYS`).then((response) => {
            if (response.status === 200) {
                const data = response.data.data;
                setAspirants(data.data);
                data.timeRanges.push({
                    start: '00:00',
                    end: '00:00',
                    status: false,
                    onClick: handleOpenTimePicker,
                });
                setTimeRanges(data.timeRanges);
                const minimumTimeToSupportApplicants = data.minimumTimeToSupportApplicants;
                const totalTimeToCompleteChallengeB = data.totalTimeToCompleteChallengeB;
                const totalTime = minimumTimeToSupportApplicants + totalTimeToCompleteChallengeB;
                setRequiredTime({
                    minimumTimeToSupportApplicants,
                    totalTimeToCompleteChallengeB,
                    totalTime
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (isTimePickerOpenD || isTimePickerOpenE) {
            window.scrollTo(0, 0);
        }
    }, [isTimePickerOpenD, isTimePickerOpenE]);

    return (<>
        {challengeName === 'Desafío A' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Card type="secondary" outline className="host-filter-day-container-card">
                    <div className="card-read">
                        <Paragraph align="center" size="s">
                            <b>Las Estrategias de juego se dibujan sobre la Arena.</b>
                            <br />
                            <br />
                            Una vez que tiene clara la meta, el enfoque específico de sus esfuerzos; y se ha respondido la pregunta ¿Qué es lo más importante que debo hacer ahora?, un líder debe entender que:
                            “Ningún plan sobrevive del todo a su choque con la realidad, suele ser necesario que el líder conciba varias estrategias de juego con el tiempo, antes de alcanzar la meta. Las metas se graban en piedra, pero los planes se dibujan en la arena.”
                        </Paragraph>
                    </div>
                </Card>
                <div className="phrase-container">
                    <img src={Book} alt="Book" className="book" />
                    <Paragraph size="s" align="left">
                        Basado en el libro
                        <br />
                        <b>Lanzando una Revolución sobre el Liderazgo.</b>
                    </Paragraph>
                </div>
                <Button onClick={() => setStep(step + 1)} className="host-filter-day-container-button">Continuar</Button>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">Estrategia Sincronización.</span>
                <img src={SyncIcon} className="sync" width={150} height={150} />
                <Paragraph size="s" align="center">
                    Según el Cronograma, hoy tus 6 Aspirantes deberán haber terminado su Día 3...
                    <br />
                    <br />
                    Sin embargo puede que esto no suceda; tal vez hoy tengas 3 Aspirantes al día y otros 3 con días pendientes por completar.
                    <br />
                    <br />
                    Desde ahora, ninguno de tus Aspirantes puede tener días pendientes. Todos tienen que estar sincronizados hoy en el Día de Filtro.
                    <br />
                    <br />
                    Ya no será a la hora que ellos quieran sino a la hora que tú definas.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Paragraph size="s" align="center">
                    Antes de sincronizarte con tus Aspirantes para el entrenamiento ABC Filtro, necesitas saber:
                </Paragraph>
                <Paragraph size="s" align="center" className="like-card">
                    👉🏼 Quiénes ya completaron todos los Desafíos hasta el Día 3, están listos para recibir su entrenamiento y obtener las 6 validaciones de sus referencias personales.
                </Paragraph>
                <Paragraph size="s" align="center" className="like-card">
                    👉🏼 Quiénes aún no han completado
                    y cuánto tiempo les falta para llegar a este Día de Filtro.
                </Paragraph>
                <Paragraph size="s" align="center" className="like-card">
                    👉🏼 Cuánto tiempo requieres para llevar a todos tus Aspirantes hasta el Día de Filtro.
                </Paragraph>
                <Paragraph size="s" align="center">
                    <b>A continuación encontrarás una tabla con toda la información necesaria para identificar cada uno de los puntos anteriores.</b>
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">Resumen del Progreso de mis Aspirantes:</span>
                {step === 4 && <div className="referred-table">
                    <div className="referred-table__header">
                    </div>
                    {aspirants.map((aspirant, index) => <div className="referred-table__item">
                        <div className="referred-table__item__column">
                            <span className="index">{index + 1}</span>
                        </div>
                        <div className="referred-table__item__column medium">
                            <span className="name blue">{aspirant.name}</span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className={`h1 blue ${aspirant?.Keys?.[0]?.status ? 'active' : ''} small`}>
                                <img src={Key} alt="Key" className="key" />
                            </span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className={`h2 blue ${aspirant?.Keys?.[1]?.status ? 'active' : ''} small`}>
                                <img src={Key} alt="Key" className="key" />
                            </span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className={`h3 blue ${aspirant?.Keys?.[2]?.status ? 'active' : ''} small`}>
                                <img src={Key} alt="Key" className="key" />
                            </span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className="time blue"><span>{aspirant?.time}</span> min</span>
                        </div>
                    </div>)}
                </div>}
                <Paragraph size="s" align="center">
                    Ten en cuenta que cada día requiere 15 minutos para ser completados y poder recibir las llave.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">Tiempo mínimo requerido:</span>
                <div className="segment">
                    <div className="info-card">
                        <Paragraph size="s" align="left">
                            Tiempo mínimo requerido para apoyar a tus Aspirantes a completar
                            Día 1, 2 y 3.
                        </Paragraph>
                        <span className="icon-info"></span>
                    </div>
                    <span className="time-box">
                        <span>{requiredTime?.minimumTimeToSupportApplicants}</span>
                        min
                    </span>
                </div>
                <div className="segment">
                    <div className="info-card">
                        <Paragraph size="s" align="left">
                            Tiempo mínimo requerido para completar tu Desafío B.
                        </Paragraph>
                        <span className="icon-info"></span>
                    </div>
                    <span className="time-box">
                        <span>{requiredTime?.totalTimeToCompleteChallengeB}</span>
                        min
                    </span>
                </div>
                <div className="segment-total">
                    <span className="total">Total</span>
                    <span className="time-box">
                        <span>{requiredTime?.totalTime}</span>min
                    </span>
                </div>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">¡Es el momento de tomar decisiones! Recuerda que:</span>
                <Paragraph size="s" align="center">
                    Las 🎯 metas de tu equipo están claras y siguen siendo las mismas:
                </Paragraph>
                <Paragraph size="s" align="left" className="like-card">
                    ➡ Cada Aspirante debe obtener sus 6 validaciones de sus referencias personales.
                </Paragraph>
                <Paragraph size="s" align="left" className="like-card">
                    ➡ Y tú Anfitrión(a), debes preseleccionar los 3 Aspirantes con mejor desempeño.¡Sólo 3 podrán asistir al EPA!
                </Paragraph>
                <Paragraph size="s" align="center">
                    Aún estás 🖌️ dibujando tu estrategia sobre la arena… ¿Cómo las vas a dibujar HOY para que todos puedan cumplir las metas?
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">⏰ Sincronízate con tu equipo</span>
                <Paragraph size="s" align="center">
                    Abajo 👇🏼, define tus horarios para acompañar y entrenar a tus Aspirantes para el día de hoy.
                </Paragraph>
                <Card type="tertiary" outline>
                    <Paragraph size="s" align="center">
                        <span>Recuerda que requieres al menos</span>
                        <h3>{requiredTime?.totalTime} minutos</h3>
                        <span>para que tus Aspirantes queden al día. </span>
                    </Paragraph>
                </Card>
                <Paragraph size="xs" align="center">
                    Selecciona uno o más horarios (máximo 3) según tu disponibilidad.
                </Paragraph>
                <div className="checkbox-table">
                    <div className="checkbox-table__header">
                    </div>
                    {timeRanges.map((timeRange, index) => <div className={`checkbox-table__item ${typeof timeRange?.onClick === 'function' ? 'margin-to-button' : ''}`}>
                        <Checkbox
                            name={`time-range-${index}`}
                            checked={timeRange.status}
                            onChange={(e) => {
                                const newTimeRange = [...time];
                                newTimeRange.push(timeRange);
                                setTime(newTimeRange);
                                const newTimeRanges = [...timeRanges];
                                newTimeRanges[index].status = time.length < 3 && e.target.checked;
                                if(timeRange.start === '00:00' || timeRange.end === '00:00') {
                                    newTimeRanges[index].status = false;
                                }
                                setTimeRanges(newTimeRanges);
                                const timeSelected = newTimeRanges.filter((timeRange) => timeRange.status);
                                setTime(timeSelected);
                            }}
                        />
                        <span id="start-picker" tabindex="-1" className="checkbox-table__item__start-time" onClick={() => timeRange?.onClick?.('D')}>{timeRange?.start}</span>
                        <span id="end-picker" tabindex="-1" className="checkbox-table__item__end-time">{timeRange?.end}</span>
                        {typeof timeRange?.onClick === 'function' && <><CustomDatePicker
                            isOpen={isTimePickerOpenD}
                            onSelect={(newTime) => {
                                timeRange['start'] = newTime?.toLocaleTimeString('es',{ hour: '2-digit', minute: '2-digit' });
                                newTime.setMinutes(newTime.getMinutes() + 15);
                                timeRange['end'] = newTime?.toLocaleTimeString('es',{ hour: '2-digit', minute: '2-digit' });
                                setTimeD(newTime);
                                setIsTimePickerOpenD(false);
                                document.getElementById('start-picker').focus();
                                window.scrollTo(0, 200);
                            }}
                            time={timeD}
                            role={user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role}
                            onCancel={() => setIsTimePickerOpenD(false)}
                            isTimePicker
                        />
                            <CustomDatePicker
                                isOpen={isTimePickerOpenE}
                                onSelect={(newTime) => {
                                    timeRange['end'] = newTime?.toLocaleTimeString('es');
                                    if (timeD > newTime) {
                                        timeRange['end'] = '00:00';
                                        setTimeE(new Date());
                                        alert('La hora de finalización no puede ser menor a la hora de inicio');
                                    } else {
                                        setTimeE(newTime);
                                    }
                                    
                                    setIsTimePickerOpenE(false);
                                    document.getElementById('end-picker').focus();
                                    window.scrollTo(0, 200);
                                }}
                                time={timeE}
                                role={user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role}
                                onCancel={() => setIsTimePickerOpenE(false)}
                                min={getMin(timeD)}
                                isTimePicker
                            /></>}
                    </div>)}
                </div>
                <Button onClick={saveScheduleTime} disabled={!(timeRanges.filter((timeRange) => timeRange.status).length > 0 && timeRanges.filter((timeRange) => timeRange.status).length < 4)}>Continuar</Button>
            </Step>
            <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    <b>¡Vas super!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                    Te queda poco para completar el <b>Desafío B.</b>
                </Paragraph>
                <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                <Card type="secondary" outline>
                    <div className="epa-schedule-card" >
                        <Paragraph align="center" size="s">
                            Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                        </Paragraph>
                        <div className="epa-schedule-card__key">
                            Sync Filtro
                        </div>
                        <CopyField value="Sync Filtro" setStep={() => {
                            setCanFinish(true);
                        }} />
                        <Paragraph align="center" size="xs" padding={16}>
                            Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío A</b> en
                            <b>Mi Progreso</b>
                        </Paragraph>
                    </div>
                </Card>
                <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
            </Step>
        </>}
        {challengeName === 'Desafío B' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Paragraph size="s" align="center">
                    Marca la Casilla del Aspirante que ya haya realizado su entrenamiento contigo:
                </Paragraph>
                <div className="checkbox-table">
                    <div className="checkbox-table__header">
                    </div>
                    {aspirants.map((aspirant, index) => <div className="checkbox-table__item">
                        <Checkbox
                            name={`aspirant-${index}`}
                            checked={selectedAspirants?.find((selectedAspirant) => selectedAspirant === aspirant)}
                            onChange={(e) => handleAspirantSelection(e.target.checked, aspirant)}
                        />
                        <span className="checkbox-table__item__name" onClick={() => {
                            document.getElementsByName(`aspirant-${index}`)[0].click();
                        }}
                            onKeyDown={() => { }}
                        >{aspirant?.name}</span>
                    </div>)}
                </div>
                <Paragraph size="s" align="center" className="like-card like-card--secondary">
                    Desbloquearás el Desafío C una vez hayas realizado el Entrenamiento ABC Filtro con al menos 3 de tus Aspirantes.
                    <br />
                    <br />
                    Ten en cuenta que si realizas el entrenamiento con los 6 Aspirantes, más probabilidades tienes de lograr la Meta 2.
                </Paragraph>
            </Step>
        </>}
        {challengeName === 'Desafío C' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Paragraph size="s" align="center">
                    <b>Zona de Aprendizaje</b>
                    <br />
                    <br />
                    A continuación te vamos a explicar de forma breve cómo puedes visualizar el <b>Reporte de Tickets</b> desde el apartado Mi Desempeño.
                </Paragraph>
                <img src={TouchScreen} alt="Touch Screen" />
                <Paragraph size="xs" align="center">
                    Haz clic en <b>Continuar</b>
                    <br />
                    para seguir completando el <b>Desafío B</b>
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Paragraph size="s" align="center">
                    Primero dirígete a <b>Mi Desempeño</b> desde el menú superior haciendo clic en la pestaña.
                </Paragraph>
                <img src={MyPerformance} alt="Touch Screen" />
                <Paragraph size="s" align="center">
                    Luego selecciona la <b>Meta</b> que contiene <b>El Reporte de Tickets</b>, en este caso la <b>Meta 2.</b>
                </Paragraph>
                <img src={ScreenBridge} alt="Screen Bridge" className="screen-bridge" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Paragraph size="s" align="center">
                    Cada vez que selecciones una meta podrás ver más detalladamente su información, en la parte inferior.
                </Paragraph>
                <img src={PortalBridge} alt="Portal Bridge" />
                <Paragraph size="s" align="center">
                    Al dar clic en el botón <b>Ver Tickets</b> entonces encontrarás El Reporte de Tickets.
                    <br />
                    <br />
                    A continuación te lo mostramos.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <Paragraph size="s" align="center">
                    Cada vez que el Aspirante obtiene una validación se enciende una estrella de su ticket, cuando complete las 6 validaciones, se encienden todas las estrellas y el ticket completo.
                </Paragraph>
                <img src={AspirantsPdl} alt="AspirantsPDl Tutorial" className="aspirants-pdl" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                <Paragraph size="s" align="center">
                    <b>¡Vas super!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                    Estás iniciando el <b>Desafío C.</b>
                </Paragraph>
                <img src={GoodHand} alt="Clap Hand" className="good-hand" />
                <Card type="secondary" outline>
                    <div className="epa-schedule-card" >
                        <Paragraph align="center" size="s">
                            Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                        </Paragraph>
                        <div className="epa-schedule-card__key">
                            Cierre Filtro Anfitrión
                        </div>
                        <CopyField value="Cierre Filtro Anfitrión" setStep={() => {
                            finishChallenge(null);
                        }} />
                        <Paragraph align="center" size="xs" padding={16}>
                            Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                            <b>Mi Progreso</b>
                        </Paragraph>
                    </div>
                </Card>
            </Step>
        </>}
    </>);
};

export default HostFilterDay;