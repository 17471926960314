const IconItem = ({ icon, children }) => {
    return (
        <div className="icon-item">
            <div className="icon-item__icon">
                {icon}
            </div>
            <div className="icon-item__text">
                {children}
            </div>
        </div>
    );
}

export default IconItem;