import { useContext } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import ScaleItem from "../../atoms/scale-item";
import ProfileInfo from "../../molecules/profile-info";
import Scale from "../../molecules/scale";
import { ROLES } from "../../../middleware/utils/constants";

const ProfileLevel = ({ role }) => {

    const { user } = useContext(UserContext);

    return (
        <div className="profile-level">
            <div className="profile-level__info">
                <div className="profile-level__info__avatar">
                    <div className="image"></div>
                    <div className="circle"></div>
                </div>
                <ProfileInfo
                    name={user?.name}
                    role={role}
                    level={ROLES[role]?.level}
                    description={ROLES[role]?.description}
                    levelDescription={ROLES[role]?.levelDescription} />
            </div>
            <Scale>
                <ScaleItem role="candidate" roleName="Aspirante" level="Aprendizaje" active={role === 'candidate'}/>
                <ScaleItem role="host" roleName="Anfitrión" level="Desempeño" active={role === 'host'}/>
                <ScaleItem role="coordinator" roleName="Coordinador" level="Liderazgo" active={role === 'coordinator'}/>
                <ScaleItem role="director" roleName="Director" level="Formador de Líderes" active={role === 'director'}/>
                <ScaleItem role="captain" roleName="Capitán" level="Formación Formador de Líderes" active={role === 'captain'}/>
            </Scale>
        </div>
    );
};

export default ProfileLevel;