import React from 'react'
import Step from '../../molecules/step'
import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'
import NewDirector from "../../../resources/images/director-avatar.png";
import './index.scss';
export const ChallengeHonorFirst = ({setStep,step}) => {
   

  return (
    <>
                     <img src={NewDirector} alt="new-host" width={209} height={209} />
                    <Paragraph size="s" align="center">
                    ¿¡Listos para hacer historia?! Hoy vas a sellar el compromiso con tu equipo porque aquí, en ACDC, todos compartimos un Legado en común, estamos construyendo el ecosistema de innovación y emprendimiento 4.0 de nuestra Comunidad Épica.  </Paragraph>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
         
    
    </>
  )
}
