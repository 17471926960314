import classNames from "classnames";
import ProgressBar from "../../atoms/progress-bar";

const Challenge = ({ key, name, progress, icon = 'mic', role, active, onClick, disabled }) => {

    const classes = classNames('challenge-container', {
        'challenge-container--disabled': disabled
    });

    return (
        <div className={classes} key={`challenge-${key}`}>
            <div className={`challenge challenge--${active ? `active--${role}` : `${role}`}`}>
                <div className={`challenge--icon challenge--icon--${icon}`}></div>
                <div className="challenge--progress">
                    <span>{name}</span>
                    <div className="challenge--progress--bar">
                        <ProgressBar role={role} range={progress} size="large" />
                        {`${progress}%`}
                    </div>
                </div>
            </div>
            <div className="challenge--info">
                <span className="icon icon-info" onClick={onClick} onKeyDown={() => {}}></span>
            </div>
        </div>
    );
};

export default Challenge;