const TextArea = ({ name, id, placeholder, minlength, maxlength, required, onChange, disabled, value, ...props }) => {
    return (
        <textarea
            className="text-area"
            name={name} 
            id={id}
            minLength={minlength}
            maxLength={maxlength}
            placeholder={placeholder}
            value={value}
            required={required}
            onChange={onChange}
            disabled={disabled}
            {...props}
        ></textarea>
    );
};

export default TextArea;