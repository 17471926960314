import Card from "../../atoms/card";
import MenuUserInfo from "../menu-user-info";

export const UserCardInfo = ({ user }) => {
    const isActive = user?.trainingList?.filter((training) => training.active).length >= 3;
    const TrainingContent = ({ items }) => {
        return (
            <div className="training-content">
                <span className="training-content__title">Entrenamientos:</span>
                {items.map((item) => (
                    <span className={`training-content__item icon-grade ${item.active ? 'active' : 'inactive'}`}></span>
                ))}
            </div>
        )
    };
    return (
        <Card className={`user-card-info ${user?.role} ${isActive ? 'active' : 'inactive'}`} type="quinary">
            {isActive && <span className="icon-state-selected"></span>}
            <MenuUserInfo
                name={user?.name}
                role={user?.role}
                nameRole={user?.nameRole}
                range={user?.range}
                orientation="row"
                size="small"
            >
                <TrainingContent items={user?.trainingList} />
            </MenuUserInfo>
        </Card>
    )
};