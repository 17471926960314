import MenuAspiranteInfo from "./MenuAspitanteInfo";

import "./CoordinadorByAnfitrionByAspirantesList.scss";
import { useContext, useEffect, useState } from "react";
import { getCaptainAndHostWithAspirants } from "../../../middleware/services/epaCoordinatorServices";
import { UserContext } from "../../../middleware/providers/user-context";
import Paragraph from "../../atoms/paragraph";
import { ROLES } from "../../../middleware/utils/constants";
import Checkbox from "../../atoms/checkbox";

const CoordinadorByAnfitrionByAspirantesList = ({ idCoordinator }) => {
  const { eventEpa } = useContext(UserContext);
  const [captainAndHosts, setCaptainAndHosts] = useState([]);
  useEffect(() => {
    fetchData();
  }, [idCoordinator, eventEpa]);
  const fetchData = async () => {
    const list = await getCaptainAndHostWithAspirants(idCoordinator);
    setCaptainAndHosts(list);
    return;
  };

  return (
    <>
      {captainAndHosts?.map(
        (generation) =>
          generation.role === "host" && (
            <div className="generation-map">
              <Paragraph
                size="s"
                align="center"
                className="custom-border-color-room-a"
              >
                <div className={`info ${generation.role}-text`}>
                  <span className="name">
                    <b>{generation.name}</b>
                  </span>
                </div>
                <div className={`info ${generation.role}`}>
                  <span className="role">
                    {ROLES[generation.role]?.circle}{" "}
                    {ROLES[generation.role]?.roleName}
                  </span>
                </div>
              </Paragraph>
              {generation.children?.map(
                (candidate) =>
                  candidate.role === "candidate" && (
                    <>
                      <MenuAspiranteInfo
                        className="epa-requirements epa-list-candiate"
                        orientation="row"
                        name={candidate.name}
                        role={candidate.role || "candidate"}
                        nameRole={ROLES[candidate.role || "candidate"].roleName}
                        range={2}
                        icon={ROLES[candidate.role || "candidate"].circle}
                      >
                        <div
                          className="epa-requirements__status"
                          style={{ margin: "0" }}
                        >
                          {Object.keys(
                            candidate.epaParticipationRequirement || {
                              maintainPerformance: false,
                              confidentialityAgreement: false,
                              minutesByWhatsApp: false,
                              provisionTwoHours: false,
                              enhanceTheThreeHours: false,
                            }
                          ).map((index) => (
                            <Checkbox
                              name="hold"
                              checked={
                                candidate?.epaParticipationRequirements?.[index]
                              }
                              disabled
                            />
                          ))}
                        </div>
                      </MenuAspiranteInfo>
                    </>
                  )
              )}
            </div>
          )
      )}
      <Paragraph size="s" align="center" className="p-room-p-1">
        Podrás avanzar una vez todos los <br />
        Aspirantes hayan aceptado los 5 requisitos
        <br />
        <br />
      </Paragraph>
    </>
  );
};
export default CoordinadorByAnfitrionByAspirantesList;
