import Paragraph from "../../atoms/paragraph";

import Book from "../../../resources/images/book.png";

export const Quote = ({ children }) => {
    return (
        <div className="quote">
            <img src={Book} alt="Book" className="quote-book" />
            <div className="quote-text" >
                {children}
            </div>
        </div >
    );
};