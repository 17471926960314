import classNames from 'classnames';
import React from 'react';

const Button = ({ role, size = 'large', children, style = 'default', icon, onClick, id, disabled, className, styles}) => {
    const classes = classNames('button', {
        [role]: role,
        [`button--${size}`]: size,
        [`button--${style}`]: style,
        'button--icon': icon,
        [className]: className,
    });

    const iconClasses = classNames('button--icon__span', {
        [`button--icon__span--${icon?.position}`]: icon?.position,
        [icon?.icon]: icon?.icon,
    });

    return (
        <button id={id} className={classes} onClick={onClick} disabled={disabled} style={styles}>
            {icon && <span className={iconClasses}></span>}
            {children}
        </button>
    );
};

Button.displayName = 'Button';

export default Button;