import Background from '../../templates/background';
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../middleware/providers/user-context';
import Menu from '../../organisms/menu';
import FilterScheduleStepper from '../../organisms/filter-schedule-stepper';

const FilterSchedule = () => {
    const { user } = useContext(UserContext);
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(20);
    const [menuShow, setMenuShow] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setMenuShow(false);
            }
        });
    }, []);

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };

    return (
        <Background className="filter-schedule">
            <header>
                <span className="icon-chevron-back icon left" onClick={() => setStep(step - 1)} onKeyDown={() => {}}></span>
                <div className={`logo logo--${user?.role || 'candidate'}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => {}}></span>
            </header>
            <h4 className="title">Sincronízate con tu Anfitrión(a)</h4>
            <FilterScheduleStepper />
            <Menu role={user?.role} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
        </Background>
    );
};

export default FilterSchedule;