import axios from "axios";
import { API } from "../utils/constants";
import { useMutation, useQueryClient} from "@tanstack/react-query";



const putEpaDateSaveDateAvailable = async (idUser,data) => {

    const resp= await axios.put(`${API.EPA_DATE_SAVE_DATE_AVAILABLE.url}/${idUser}?action=save-date-available`,data);
    return  resp;

  }
 
 export const QueriesEpaDateSaveDateAvailable=(idUser) => {
    const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data) => putEpaDateSaveDateAvailable(idUser,data),
    onSuccess: (response) => {
      console.log(response);
    },
  });
return mutation
 }