const CryptoJS = require("crypto-js");

const { REACT_APP_ENCRYPTION_KEY } = process.env;

function encryptData(data) {
    const encryptedData = CryptoJS.AES.encrypt(data, REACT_APP_ENCRYPTION_KEY).toString();
    return encryptedData;
}

function decryptData(encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

module.exports = {
    encryptData,
    decryptData
};
