import { useContext, useEffect, useState } from "react";

import Button from "../../atoms/button";
import axios from "axios";

import Checkbox from "../../atoms/checkbox";

import Form from "../../molecules/form"
import Select from "../../atoms/select";
import forge from 'node-forge';
import TextField from "../../atoms/text-field";
import { UserContext } from "../../../middleware/providers/user-context";
import { API } from "../../../middleware/utils/constants";
import './payment.scss';

const publicDecrypt = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0BY6F1qMy+bbcPzRrxY/
cqo2sPUO8MkBJTRcf+YSeopD6kpo+Wefsbpnf5pLz4nfmgerPS7ma3dVaqnSqfEd
bR0a3MlB2AVUy/0KK09btpMBi0fkUVHzg8jNXS8l4M8uRTnF3Nw1Em82MFAj4g9w
YX/2wNuFzfHX7+I0n4eok2z8YVEnQrCgwDSNN9iZ6odBYaEWJMx68CNY4E79QAms
IPkzi9L+qHk7qbH97Da5zQeYb19aa6dTKYRaxbzPEu+NsvwHsBzOAoOHELCSwVmu
/9ftvBbbLKc67PPWruof7ehDGe/IHtHMtkrQdI5RdDQ1qcfcHT1fiGm9YK+PAm3h
WwIDAQAB
-----END PUBLIC KEY-----`

export const PaymentEpaDay = ({ email, setLoader, setTransactionInfo, alegraProducts, setAlegraProducts, paymentBody, setPaymentBody, step, setStep }) => {
    const { user, eventEpa, transationParam, setTransationParam } = useContext(UserContext);
    

    const [billBody, setBillBody] = useState({});

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [isValid, setIsValid] = useState(false);

    const [autoComplete, setAutoComplete] = useState(true);




    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const handlePayment = async (e) => {
        setTransationParam('')
        e.preventDefault();

        try {  
            const getAcceptanceKey = await axios.get(`${API.GET_ACCEPTANCE_KEY.url}?action=GET_ACCEPTANCE_KEY`);

            setLoader(true);          
            if (getAcceptanceKey.status === 200 && paymentBody && e.target.checkValidity()) {
                let price =0
                
                if(user.role==="new host"){
                    const priceSplit = (alegraProducts?.[0]?.price?.[0]?.price + (alegraProducts?.[1]?.price?.[0]?.price * 19 / 100)).toString().split('.');
                     price = parseInt(priceSplit[0] + '00');
                  }  else{
                    const priceSplit = ((alegraProducts?.[1]?.price?.[0]?.price * 2) + ((alegraProducts?.[1]?.price?.[0]?.price * 2) * 19 / 100)).toString().split('.');
                     price = parseInt(priceSplit[0] + '00');
                  }
    
                const publicKey = forge.pki.publicKeyFromPem(publicDecrypt);
    
                const dataCard = {
                    number: paymentBody.number,
                    cvc: paymentBody.cvc,
                    exp_month: paymentBody.exp_month,
                    exp_year: paymentBody.exp_year,
                }
                const encrypted = publicKey.encrypt(`${JSON.stringify(dataCard)}`, 'RSA-OAEP');
                const encryptedBase64 = forge.util.encode64(encrypted);
                const body = {
                    id: user?._id,
                    action: 'ADD_CARD',
                    token: "",
                    acceptance_token: getAcceptanceKey.data.data.acceptance_token,
                    encryptedCardData: encryptedBase64,
                    firstName: autoComplete ? paymentBody?.firstName : billBody?.firstName,
                    lastName: autoComplete ? paymentBody?.lastName : billBody?.lastName,
                    email: autoComplete ? email : billBody?.email,
                    documentType: autoComplete ? paymentBody?.documentType : billBody?.documentType === undefined ? paymentBody?.documentType : billBody?.documentType,
                    documentTypeBill: autoComplete ? paymentBody?.documentType : billBody?.documentType === undefined ? paymentBody?.documentType : billBody?.documentType,
    
                    identificationNumber: autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber,
                    phone: autoComplete ? paymentBody?.phone : billBody?.phone,
                    price: price,
                    idProduct: user.role==="new host"?alegraProducts?.[0]?.id.toString():alegraProducts?.[1]?.id.toString(),
                    firstNameBill: autoComplete ? paymentBody?.firstName : billBody?.firstName,
                    lastNameBill: autoComplete ? paymentBody?.lastName : billBody?.lastName,
                    identificationNumberBill: autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber,
                    emailBill: autoComplete ? email : billBody?.email,
                    phoneBill: autoComplete ? paymentBody?.phone : billBody?.phone,
                }


                axios.post(`${API.ADD_CARD.url}`, body).then((response) => {

                    if (response.status === 201) {
                        setStep(step + 1);
                        setLoader(false);

                        setTransactionInfo(response.data.data);

                        setTransationParam(response.data.data?.status)

                        const newData = {
                            id: 'all',
                            message: 'refresh',
                            action: 'GENERIC_EVENT'
                        };

                        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                            if (response.status === 201) {
                                //setRefresh(!refresh);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                    setLoader(false);
                });
            } else {
                setLoader(false);
            }
            
        } catch (error) {
            console.log(error);
            setLoader(false);
        }

    };


    useEffect(() => {
        getCountries().then((response) => {
            setCountries(response);
        }).catch((error) => {
            console.log(error)
        });
    }, []);
    useEffect(() => {
        const allFieldsFilled = Object.values(paymentBody).every(field => field.trim() !== '');
        setIsButtonDisabled(!allFieldsFilled);
    }, [paymentBody]);

    useEffect(() => {
        const allFieldsFilled = Object.values(billBody).every(field => field.trim() !== '' || field?.length > 0);
        setIsButtonDisabled(!allFieldsFilled);
        //setIsButtonDisabled(!autoComplete); 

    });






    const getCountries = () => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_COUNTRIES.url}?action=GET_COUNTRIES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getStates = (country) => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_STATES.url}/${country}?action=GET_STATES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getCities = (country, state) => {
        return new Promise((resolve, reject) => {
            axios.get(`${API.GET_CITIES.url}/${country}/${state}?action=GET_CITIES`).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    };
    const handleChangeComplete = () => {
        setAutoComplete(!autoComplete);
        console.log(!autoComplete);

        if (!autoComplete === false) {

            setBillBody({
                ...billBody,
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                identificationNumber: "",
                documentType: "",
                identificationNumberBill: "",
                documentTypeBill: "",
                firstNameBill: "",
            });

            return
        }
        setIsButtonDisabled(false);
    }
    return (
        <>
            <span className="title-payment">

                Ahora ingresa los detalles de facturación y completa la compra
            </span>
            <Checkbox
                label="Autocompletar datos"
                checked={autoComplete}
                onChange={handleChangeComplete}
            />
            <Form className="card-form" onSubmit={handlePayment}>
                <div className="card-form__item identification">
                    <span className="card-form__item__identification">Doc. de identidad</span>
                    <Select name="identification_type" onChange={(e) => {
                        setBillBody({
                            ...billBody,
                            documentType: e.target.value === "" ? "CC" : e.target.value,
                        });
                    }}>
                        <option value="CC">CC</option>
                        <option value="NIT">NIT</option>
                    </Select>
                    <TextField
                        name="identification"
                        placeholder="0000000000"
                        onChange={(e) => {
                            setBillBody({
                                ...billBody,
                                identificationNumber: e.target.value,
                            });
                        }}
                        value={autoComplete ? paymentBody?.identificationNumber : billBody?.identificationNumber}
                        required
                    />
                </div>
                <TextField
                    name="name"
                    placeholder="Nombres"
                    onChange={(e) => {
                        setBillBody({
                            ...billBody,
                            firstName: e.target.value,
                        });
                    }}
                    value={autoComplete ? paymentBody?.firstName : billBody?.firstName}
                    required />
                <TextField
                    name="last_name"
                    placeholder="Apellidos"
                    onChange={(e) => {
                        setBillBody({
                            ...billBody,
                            lastName: e.target.value,
                        });
                    }}
                    value={autoComplete ? paymentBody?.lastName : billBody?.lastName}
                    required />
                <TextField
                    name="company"
                    placeholder="Nombre de la empresa (opcional)"
                    onChange={(e) => {
                        setBillBody({
                            ...billBody,
                            company: e.target.value,
                        });
                    }}
                />
                <Select name="country" onChange={(e) => {
                    setBillBody({
                        ...billBody,
                        country: e.target.value,
                    });
                    getStates(e.target.value).then((response) => {
                        setStates(response);
                    }).catch((error) => {
                        console.log(error);
                    });
                }} required>
                    <option value="">País / Región</option>
                    {countries?.map((country) => <option value={country.isoCode}>{country.name}</option>)}
                </Select>
                <Select name="state" onChange={(e) => {
                    setBillBody({
                        ...billBody,
                        state: e.target.value,
                    });
                    getCities(billBody?.country, e.target.value).then((response) => {
                        setCities(response);
                    }).catch((error) => {
                        console.log(error);
                    });
                }} required>
                    <option value="">Departamento</option>
                    {states?.map((state) => <option value={state.isoCode}>{state.name}</option>)}
                </Select>
                <Select name="state" onChange={(e) => { }} required>
                    <option value="">Ciudad</option>
                    {cities?.map((city) => <option value={city.isoCode}>{city.name}</option>)}
                </Select>
                <TextField
                    name="address"
                    placeholder="Dirección de residencia"
                    onChange={(e) => {
                        setBillBody({
                            ...billBody,
                            address: e.target.value,
                        });
                    }}
                    required />
                <TextField
                    name="email"
                    placeholder="Correo electrónico"
                    leftIcon={{ icon: 'icon-mail' }}
                    pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                    value={autoComplete ? paymentBody?.email : billBody?.email}
                    onChange={(e) => {
                        setBillBody({
                            ...billBody,
                            email: e.target.value,
                        });
                    }}
                    required />
                <div className="card-form__item">
                    <span className="card-form__item__phone">Teléfono</span>
                    <TextField
                        name="phone"
                        placeholder="0000000000"
                        pattern="[0-9]{10}"
                        onChange={(e) => {
                            setBillBody({
                                ...billBody,
                                phone: e.target.value,
                            });
                        }}
                        value={autoComplete ? paymentBody?.phone : billBody?.phone}
                        required />
                </div>
                {/* <Button disabled={isButtonDisabled}>Continuar</Button> */}
                <Button >Continuar</Button>
            </Form>


        </>
    )
}
