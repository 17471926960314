import Background from '../../templates/background';
import Paragraph from "../../atoms/paragraph";
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import { ReactComponent as CalendarIcon } from '../../../resources/images/calendar-icon.svg';
import { ReactComponent as SyncIcon } from '../../../resources/images/sync-icon.svg';
import CheckHand from '../../../resources/images/check-hand.png';
import ScreenTouchHand from '../../../resources/images/screen-touch-hand.png';
import ScreenShotWhatsApp from '../../../resources/images/screenshot-whatsapp.png';

import IconItem from '../../atoms/icon-item';
import EpaScheduleForm from '../../organisms/epa-schedule-form';
import Modal from '../../atoms/modal';
import Button from '../../atoms/button';
import { useEffect, useState } from 'react';
import CopyField from '../../atoms/copy-field';
import Card from '../../atoms/card';
import CarouselModal from '../../molecules/carousel-modal';
import CarouselSlide from '../../atoms/carousel-slide';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API, MESSAGES } from '../../../middleware/utils/constants';
import { getInformation, getSchedule } from '../../../middleware/services/common';
import { formatDateLines } from '../../../middleware/utils/formatDate';

const EpaSchedulePage = ({ role }) => {

    const navigate = useNavigate();

    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationOptions, setConfirmationOptions] = useState(false);
    const [error, setError] = useState({ isOpen: false, message: '' });
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [epaSchedule, setEpaSchedule] = useState({});
    const [host, setHost] = useState({});

    const { idHost } = useParams();

    const handleSubmit = async (e, name, email) => {
        setName(name);
        if (!confirmationOptions) {
            setConfirmationModal(true);
            return;
        }

        if (e.target.checkValidity()) {
            const body = {
                email,
                name: name,
                id: idHost,
                action: 'CREATE_CANDIDATE'
            };
            try {
                const createCandidate = await axios.post(API.CREATE_CANDIDATE.url, body);

                if (createCandidate.status === 201) {
                    setStep(2);
                }
            } catch (error) {
                if (error.response.status === 400) {
                    let message = MESSAGES.ERROR[error.response.data?.message] || 'El correo ingresado ya está registrado';
                    if (message.includes('[Nombre Anfitrión]')) {
                        message = message.replace('[Nombre Anfitrión]', host?.name);
                    }
                    setError({ isOpen: true, message: message });
                }
                console.log(error);
            }
        }
    };

    const fetchData = async () => {
        const schedule = await getSchedule(idHost);
        const infoHost = await getInformation(idHost);
        let epaSchedule = schedule?.pop();
        epaSchedule = {
            ...epaSchedule,
            date: formatDateLines(epaSchedule?.date),
            hour: epaSchedule?.hour.toUpperCase(),
        };
        setEpaSchedule(epaSchedule);
        setHost(infoHost);
    };

    useEffect(() => {
        fetchData();
    },[]);

    return (
        <Background className="epa-schedule-page">
            <header>
                <div className={`logo logo--${role}`}>
                    <MainLogo />
                </div>
                <h4 className="title">Agendamiento EPA</h4>
                <span className="subtitle">Sé parte de este gran proyecto</span>
            </header>
            {step === 1 && <div className="epa-schedule-content">
                <div className="epa-schedule-info">
                    <IconItem icon={<CalendarIcon />}>
                        <span className="title">Fecha del EPA</span>
                        <span className="text">{epaSchedule?.date}</span>
                    </IconItem>
                    <IconItem icon={<SyncIcon />}>
                        <span className="title">Hora del EPA</span>
                        <span className="text">{epaSchedule?.hour}</span>
                    </IconItem>
                </div>
                <Paragraph align="center" size="s">
                    El mundo está en constante cambio, prepárate para vivir la aventura de una experiencia de innovación 4.0
                </Paragraph>
                <EpaScheduleForm callback={handleSubmit} />
            </div>}
            {step === 4 && <div className="epa-confirmation-screen">
                <span className="title">¡Excelente!</span>
                <Paragraph align="center" size="s">
                    Te agendaste al EPA
                </Paragraph>
                <div className="image">
                    <img src={CheckHand} alt="epa-schedule-hand" />
                </div>
                <Card type="secondary" outline>
                    <div className="epa-schedule-card" >
                        <Paragraph align="center" size="s">
                            Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                        </Paragraph>
                        <div className="epa-schedule-card__key">
                            Inducción Preselección ACDC
                        </div>
                        <CopyField value="Inducción Preselección ACDC" setStep={() => { }} />
                        <Paragraph align="center" size="xs" padding={16}>
                            Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en <b>Mi Progreso</b>
                        </Paragraph>
                    </div>
                </Card>
            </div>}
            <Modal className="confirmation-modal" isOpen={confirmationModal}>
                <Paragraph align="center" size="s">
                    Asegúrate de que tus nombres y apellidos estén completos y que el correo esté bien escrito antes de confirmar el agendamiento.
                </Paragraph>
                <Button size="small" onClick={() => {
                    setConfirmationModal(false);
                    setConfirmationOptions(true);
                }}>¡Vale!</Button>
            </Modal>
            <Modal className="confirmation-modal error" isOpen={error?.isOpen}>
                <Paragraph align="center" size="s">
                    {error?.message}
                </Paragraph>
                <Button size="small" onClick={() => {
                    setError({ ...error, isOpen: false });
                }}>¡Vale!</Button>
            </Modal>
            <Modal className="confirmation-modal step" isOpen={step === 2}>
                <div className="image">
                    <img src={CheckHand} alt="epa-schedule-hand" width={156} height={156} />
                </div>
                <span className="title">¡Excelente!</span>
                <Paragraph align="center" size="s">
                    Completaste satisfactoriamente tu agendamiento al EPA.
                </Paragraph>
                <Button size="small" onClick={() => setStep(3)}>¡Vale!</Button>
            </Modal>
            <CarouselModal isOpen={step === 3} color="secondary" skipStarsBackground>
                <CarouselSlide >
                    <div className="info-section">
                        <img src={ScreenTouchHand} alt="epa-schedule-hand" width={156} height={156} />
                        <span className="title">Zona de Aprendizaje</span>
                        <span className="subtitle">Claves TWOBOT</span>
                        <Paragraph align="center" size="s">
                            A continuación te explicamos brevemente cómo funcionan las <b>Claves del TWOBOT</b> para que puedas terminar la actividad.
                        </Paragraph>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section">
                        <Paragraph align="center" size="s">
                            Cada vez que te encuentres con una clave, lo primero que debes hacer es copiarla como lo indica la plataforma
                        </Paragraph>
                        <div className="container-card">
                            <Card type="secondary" outline>
                                <div className="epa-schedule-card" >
                                    <Paragraph align="center" size="s">
                                        Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                                    </Paragraph>
                                    <div className="epa-schedule-card__key">
                                        Inducción Preselección ACDC
                                    </div>
                                    <CopyField value="Inducción Preselección ACDC" setStep={() => setStep(3)} />
                                    <Paragraph align="center" size="xs" padding={16}>
                                        Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en <b>Mi Progreso</b>
                                    </Paragraph>
                                </div>
                            </Card>
                        </div>
                    </div>
                </CarouselSlide>
                <CarouselSlide >
                    <div className="info-section">
                        <Paragraph align="center" size="s">
                            Luego sal de la plataforma y pégala en el chat de WhatsApp que tienes con TWOBOT
                        </Paragraph>
                        <img className="image" src={ScreenShotWhatsApp} alt="epa-schedule-hand" width={223} />
                        <Button size="small" onClick={() => setStep(4)}>¡Entendido!</Button>
                    </div>
                </CarouselSlide>
            </CarouselModal>
        </Background >
    );
};

export default EpaSchedulePage;