const ImageButton = ({ src, alt, onClick, style = 'default', label, opacity }) => (
    <a
        className={`image-button ${style}`}
        onClick={onClick}
        onKeyDown={() => {}}
        style={{
            opacity,
            border: opacity < 1 ? 'none' : '2px solid #FFF'
        }}>
        {style !== 'default' && <div className={`circle`}></div>}
        <img src={src} alt={alt} />
        {style !== 'default' && <label>{label}</label>}
    </a>
);

ImageButton.displayName = 'ImageButton';

export default ImageButton;