import { useEffect, useState } from "react";

const Switch = ({ value, onClick }) => {
    const [checked, setChecked] = useState(false);
    const [active, setActive] = useState('');

    useEffect(() => {
        if (value === 'Si') {
            setChecked(true);
            return;
        } else if (value === 'No') {
            setChecked(false);
            setActive('active');
            return;
        }

        if (!checked) {
            setActive('');
        }
    }, [value]);

    return (
        <div className="switch">
            <input type="checkbox" id="switch" checked={checked} />
            <label htmlFor="switch" className={`label-switch success ${checked ? 'active' : ''}`} onClick={() => {
                setChecked(true);
                onClick('Si');
            }}
            onKeyDown={() => {}}
            >Sí</label>
            <label htmlFor="switch" className={`label-switch error ${!checked ? active : ''}`} onClick={() => {
                setChecked(false);
                setActive('active');
                onClick('No');
            }}
            onKeyDown={() => {}}
            >No</label>
        </div>
    );
};


const Toggle = ({ value, onClick }) => {
 
    return (
        <label className="toggle">
            <input type="checkbox" onClick={onClick}/>
            <span className="slider round"></span>
        </label>
    );
};

export { Switch, Toggle};