import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";
import TouchScreenCoordinator from "../../resources/images/touch-screen-coordinator.png";
import TutorialMyHosts from "../../resources/images/tutorial-my-hosts.png";
import TutorialItsCandidates from "../../resources/images/tutorial-its-candidates.png";
import TutorialProgressTable from "../../resources/images/tutorial-progress-table.png";
import TutorialViewSchedule from "../../resources/images/tutorial-view-schedule.png";

export const getBricksChallengeA = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `<b>Zona de aprendizaje.</b> 
<br />
<br />
¡Hola Coordinador(a)!
<br />
Has desbloqueado una nueva funcionalidad en la pestaña <strong>Mis Anfitriones.</strong>
 Desde allí, podrás visualizar el progreso en los días del cronograma y los espacios de sincronización con día y hora de tus Anfitriones y sus Aspirantes. 
A continuación, te explicaremos de forma breve como hacer uso de esta funcionalidad.`,
            },
            {
                type: 'image',
                props: {
                    src: TouchScreenCoordinator,
                    alt: 'touch image',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Dirígete a la pestaña <strong>Mis Anfitriones</strong> en el menú que encuentras en la parte superior.`
            },
            {
                type: 'image',
                props: {
                    src: TutorialMyHosts,
                    alt: 'tutorial my hosts',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '72px'
                    }
                },
                children: `Luego, haz clic en el botón <strong>Ver Aspirantes</strong> o <strong>Ver Agendamientos</strong>, según tu interés.`
            },
            {
                type: 'image',
                props: {
                    src: TutorialItsCandidates,
                    alt: 'tutorial its candidates',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Si haces clic e ingresas en Ver Aspirantes:
                <br />
Encontrarás el botón <strong style="color: #0063DD">Ver Tabla de Progreso</strong>, con el cual podrás visualizar cómo van los Aspirantes del Anfitrión seleccionado. Según sus llaves y el entrenamiento. `
            },
            {
                type: 'image',
                props: {
                    src: TutorialProgressTable,
                    alt: 'tutorial progress table',
                    style: {
                        marginTop: '52px'
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Al elegir la opción <strong>Ver Agendamientos</strong>, visualizarás los horarios que tu Anfitrión(a) dispuso de su tiempo, para guiar a sus Aspirantes. También quiénes se agendaron en dichos horarios.`
            },
            {
                type: 'image',
                props: {
                    src: TutorialViewSchedule,
                    alt: 'tutorial view schedule',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                action: 'next',
                children: 'Continuar',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 5,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '129px'
                    }
                },
                children: `Tu objetivo en este Desafío, es llevar a tus  dos Anfitriones al Día de Filtro. 
<br /><strong style="color: #00FF19">Confirmarás con ellos los espacios  para la sincronización que deberán enviar a sus Aspirantes para quedar al día.</strong> 
<br />Todos deben lograr sincronizarse en el <strong>Día de Filtro</strong> y estar listos para el entrenamiento del <strong>ABC Filtro</strong>.
Si tu Anfitrión(a) no ha realizado el <strong>Desafío A</strong>, no podrás ver la información completa.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 6,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío A.'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Sync Filtro Coordinador',
                    nameChallenge: 'Desafío A',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];