import Background from "../../templates/background";
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import ValidatorForm from "../../organisms/validator-form";

const ValidatorPage = ({ role }) => {
    return (
        <Background className="validator-container">
            <header>
                <div className={`logo logo--${role}`}>
                    <MainLogo />
                </div>
            </header>
            <span className="background__content__title">UNIVERSO</span>
            <span className="background__content__subtitle">ACDC</span>
            <ValidatorForm />
        </Background>
    );
};

export default ValidatorPage;