const InfoBox = ({ role, roleName, level, title, paragraph, day }) => {
    return (
        <div className="info-box">
            <div className="info-box-icon"></div>
            <div className="info-box-content">
                <h4 className="title">{title}</h4>
                <div className="info-section">
                    <div className={`info-section--level`}>
                        <span className="icon icon-user">⚫</span>
                        <span className="info-section--role--text">Tu rol: {roleName}</span>
                    </div>
                    <div className={`info-section--level`}>
                        <span className="icon icon-scale">📶</span>
                        <span className="info-section--role--text">Tu nivel: {level}</span>
                    </div>
                    <div className={`info-section--level`}>
                        <span className="icon icon-controllers">{day}</span>
                    </div>
                    <p className="info-section--paragraph">{paragraph}</p>
                </div>
            </div>
        </div>
    );
};

export default InfoBox;