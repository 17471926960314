import TextField from "../../atoms/text-field";

export const TextFieldPoints = ({ points, setPoints, copValue, setCopValue }) => {
    return (
        <div className="text-field-points">
            <div className="text-field-points-col">
                <label className="text-field-points-col-label">Puntos</label>
                <TextField
                    className="text-field-points-col-points"
                    label="Puntos"
                    placeholder="000"
                    value={points}
                    onChange={(e) => setPoints?.(e.target.value)}
                />
            </div>
            <div className="text-field-points-col">
                <label className="text-field-points-col-label">Valor a pagar</label>
                <TextField
                    className="text-field-points-col-cop"
                    label="Puntos"
                    placeholder="$ 00000000"
                    value={points}
                    onChange={(e) => setPoints?.(e.target.value)}
                />
                <label className="text-field-points-col-label">COP</label>
            </div>
        </div>
    );
};