import React from "react";

export const TextFieldList = ({ children }) => {
    return (
        <div className="text-field-list">
            {React.Children.map(children, (child, index) => {
                return <div className="text-field-list__item" >
                    <span className="text-field-list__item__label">{index + 1}.</span>
                {
                    React.cloneElement(child, {
                        ...child.props,
                        value: child.props.value,
                    })
                }
                </div>
            })}
        </div >
    );
}