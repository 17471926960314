import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../../atoms/button'
//import './index.scss';
import Checkbox from '../../../../atoms/checkbox';
import { UserContext } from '../../../../../middleware/providers/user-context';
import { QueriesListReidualDatesById } from '../../../../../middleware/services/useQueryListResidualDatesServices';
import { QueriesEpaDateSaveDateAvailable } from '../../../../../middleware/services/useQueryEpaSaveDateAvailableServices';
export const PossibleAvailableDates = ({ setStep, step }) => {
  const { user, scheduleParam } = useContext(UserContext)
  const [acceptDate, setAcceptDate] = useState({
    data: [],
  })
  const [activeBtn, setActiveBtn] = useState(false);
  const [checked, setChecked] = useState(false);

  const { data } = QueriesListReidualDatesById(user?._id)

  const [selectedDates, setSelectedDates] = useState([]);
  const mutationEpaDateSaveDateAvailable = QueriesEpaDateSaveDateAvailable(user._id)
  const handleDateSelection = (isChecked, row) => {
    setSelectedDates((prevState) => ({

      data: [{
        status: isChecked,
        date: row.date,
        hour: row.hour
      }]

    }));
  };

  const handleCreateDate = (isChecked, row) => {

    data.forEach(item => {
      item.residualDates.forEach(residualDate => {
        if (residualDate.date === row.date) {
          residualDate.status = true;
          setChecked(true)
        }
      });
    });
    if (isChecked) {

      setSelectedDates((prevState) => ({

        data: [{
          status: isChecked,
          date: row.date,
          hour: row.hour
        }]
      }))
    } else {
      console.log("Fecha deseleccionada:", row);
    }
  };
  const handleSend = () => {

    mutationEpaDateSaveDateAvailable.mutate(selectedDates)
    setStep(step - 3)
  }
  return (
    <>

      <div className="event-container">
        <span className="title">Elige una de las posibles fechas disponibles</span>

        {data && data?.map((item) => (
          <div key={item._id}>


            {item.residualDates?.map((residualDate, index) => (
              <div className="event-possible">
                <Checkbox className="event-possible-checkbock" name="hold"
                  checked={residualDate.status}
                  onChange={(e) => {

                    handleCreateDate(e.target.checked, residualDate)
                    handleDateSelection(e.target.checked, residualDate)

                  }} // Crea o actualiza el objeto cuando se selecciona

                  value={residualDate.date}

                />
                <span className='event-possible-title'>{residualDate.date}</span>
                <span className='event-possible-hour'>{residualDate.hour}</span>
              </div>
            ))}
            <span className='event-possible-hour'>No elegida por {item.name}</span>
          </div>
        ))}
        <div className="event-possible">
          <Checkbox name="hold"
          className="event-possible-checkbock"
            checked={activeBtn}
            onChange={(e) => setActiveBtn(e.target.checked)}


          />
          <input className='event-possible-title' type="date" placeholder='Crear fecha' onChange={(e) => setSelectedDates({ ...selectedDates, date: e.target.value })} />
          <input className='event-possible-hour' type="time" placeholder='0:00__' onChange={(e) => setSelectedDates({ ...selectedDates, hour: e.target.value })} />
        </div>
        <span className='event-possible-span'> Elige una fecha del calendario </span>
      </div>
      {
        checked ? <Button onClick={handleSend} disabled={!checked}>Aceptar Fecha</Button> : <Button onClick={handleSend} disabled={!activeBtn}>Aceptar Fecha</Button>
      }


    </>
  )
}
