import { useNavigate, useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import { ReactComponent as PdlBadge } from '../../../resources/images/pdl-badge.svg';
import WarningIcon from '../../../resources/images/warning.png';
import SyncIcon from '../../../resources/images/sync-icon-large.png';
import ClapHand from "../../../resources/images/clap-candidate.png";
import Button from "../../atoms/button";
import CopyField from "../../atoms/copy-field";
import Checkbox from "../../atoms/checkbox";
import { useState } from "react";
import Modal from "../../atoms/modal";

const AlignmentDay = ({ finishChallenge, step, setStep, ...props }) => {
    const { challengeName, idChallenge } = useParams();

    const navigate = useNavigate();

    const [availale, setAvailable] = useState(false);
    const [devices, setDevices] = useState(false);
    const [confidentiality, setConfidentiality] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [sessionsqty, setSessionsqty] = useState(false);
    const [whatsapptimebox, setWhatsapptimebox] = useState(false);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        ¡Aspirante!
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Para cumplir tu <b>Meta 2</b>
                        <br />
                        necesitas contar con <span className="candidate">{props?.validationsCount} validaciones *</span>
                    </Paragraph>
                    <PdlBadge />
                    <Paragraph size="s" align="center">
                        Hasta el momento cuentas con:
                    </Paragraph>
                    <div className="validations">
                        Validaciones obtenidas: <span className="candidate">{props?.validationsCount}</span>
                    </div>
                    <div className="validations  disabled">
                        Correos de Referencias disponibles: {props?.availableReferences}
                    </div>
                    <Paragraph size="s" align="center">
                        ¡Excelente!
                        <br />
                        No requieres más acciones.
                    </Paragraph>
                    <Paragraph size="s" align="center" className="spacing-paragraph">
                        Presiona en el botón <b>Finalizar</b> para completar tu desafío.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center" className="color-paragraph">
                        Para poder completar al 100% este desafío, debes haber cumplido tu <span>Meta 2: Conseguir 6 validaciones.</span>
                    </Paragraph>
                    <Paragraph size="s" align="center" className="color-paragraph">
                        Sin embargo, una vez completes 4 validaciones, podrás acceder al <b>Desafío C</b> para que le eches un vistazo y puedas estar listo(a).
                    </Paragraph>
                    <div className="warning-card">
                        <img src={WarningIcon} alt="warning" className="warning" width={98} height={93} />
                        <Paragraph size="s" align="center" className="color-paragraph">
                            Te recomendamos estar pendiente de tus referencias personales y las referencias adicionales que tienes disponibles.
                        </Paragraph>
                    </div>
                    <Button
                        icon={{
                            icon: 'icon-exit-side-btn',
                            position: 'left'
                        }}
                        onClick={() => finishChallenge(null)}
                    >
                        Ir a mis Validadores
                    </Button>
                </Step>
            </>}
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center" >
                        A continuación, encontrarás la hora y el link de la meet a la que deberás conectarte para realizar una sincronización de 10 minutos con el resto del equipo.
                    </Paragraph>
                    <div className="sync-meet-card">
                        <img src={SyncIcon} alt="sync" className="sync" />
                        <span className="title">Hora: 9:30 pm</span>
                        <Paragraph size="s" align="center" >
                            Link de la meet:
                        </Paragraph>
                        <div className="meet-link">
                            <span>meet//:a923nr42098nf3</span>
                        </div>
                        <CopyField value="meet//:a923nr42098nf3" text="Copiar Link" />
                    </div>
                    <Paragraph size="xs" align="center" className="bottom-advice" >
                        Este botón se habilitará en medio de la reunión para que puedas realizar el resto del desafío.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <span className="title">Requisitos de participación en un EPA:</span>
                    <Paragraph size="xs" align="center">
                        Marca cada una de las casillas indicando que aceptas y cumples con los requisitos.
                    </Paragraph>
                    <div className="termsandconditions">
                        <Checkbox
                            name="available"
                            label="Disposición de 2 horas para participar enfocado(a) en el EPA y sin interrupciones."
                            checked={availale}
                            onChange={(e) => {
                                setAvailable(e.target.checked);
                            }} />
                        <Checkbox
                            name="device"
                            label="Tener computador y celular cargados, audífonos y buena conexión a internet."
                            checked={devices}
                            onChange={(e) => {
                                setDevices(e.target.checked);
                            }} />
                        <Checkbox
                            name="confidentiality"
                            label="Acuerdo de Confidencialidad."
                            checked={confidentiality}
                            onChange={(e) => {
                                setConfidentiality(e.target.checked);
                            }} />
                        <Button id="read-button" size="small" onClick={() => setIsOpen(true)}>Leer Acuerdo</Button>
                    </div>
                    <Paragraph size="xs" align="center" className="bottom-advice" >
                        Podrás avanzar cuando tu Coordinador(a)
                        habilite el botón.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={!availale || !devices || !confidentiality} role="candidate">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                    <span className="title">Requisitos de participación en un EPA:</span>
                    <Paragraph size="xs" align="center">
                        Marca cada una de las casillas indicando que aceptas y cumples con los requisitos.
                    </Paragraph>
                    <div className="termsandconditions">
                        <Checkbox
                            name="sessionsqty"
                            label="4 sesiones virtuales de trabajo en equipo de 2 horas cada una."
                            checked={sessionsqty}
                            onChange={(e) => {
                                setSessionsqty(e.target.checked);
                            }} />
                        <Checkbox
                            name="whatsapptimebox"
                            label="15 minutos diarios por WhatsApp."
                            checked={whatsapptimebox}
                            onChange={(e) => {
                                setWhatsapptimebox(e.target.checked);
                            }} />
                    </div>
                    <Paragraph size="xs" align="center" className="bottom-advice" >
                        Podrás avanzar cuando tu Coordinador(a)
                        habilite el botón.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(step + 1)} disabled={!sessionsqty || !whatsapptimebox} role="candidate">Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Has completado exitosamente todos los desafíos del <b>Día de Alineamiento.</b>
                        <br />
                        <br />
                        Nos vemos el <b>Día del EPA</b> para continuar con tu proceso y realizar los nuevos desafíos.
                    </Paragraph>
                    <Button onClick={() => navigate('/missions')}>Finalizar</Button>
                </Step>
                <Modal className="challenge-modal" isOpen={isOpen}>
                    <span className="close icon-close" onClick={onClose} onKeyDown={() => {}}></span>
                    <span className="title" >Acuerdo de confidencialidad</span>
                    <Paragraph size="s" align="center">
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </Paragraph>
                </Modal>
            </>}
        </>
    );
};

export default AlignmentDay;