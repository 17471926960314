import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atoms/button";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Step from "../../molecules/step";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import TouchScreen from "../../../resources/images/screen-touch-hand.png";
import TutorialMyValidators from "../../../resources/images/tutorial-my-validators.png";
import TutorialSendEmail from "../../../resources/images/tutorial-send-email.png";
import TutorialSendEmailForm from "../../../resources/images/tutorial-send-email-form.png";
import TutorialWhatSayAboutMe from "../../../resources/images/tutorial-what-say-about-me.png";
import SyncIcon from '../../../resources/images/sync-icon-large.png';
import Recognition from "../../../resources/images/recognition-medal.svg";
import GoodHand from "../../../resources/images/good-hand.png";
import SendMail from "../../../resources/images/send-mail.png";
import CheckHand from '../../../resources/images/check-hand.png';
import TutorialAddReference from "../../../resources/images/tutorial-add-reference.png";
import Modal from "../../atoms/modal";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import Checkbox from "../../atoms/checkbox";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { TabsContext } from "../../../middleware/providers/tabs-context";

const FilterDay = ({ finishChallenge, step, setStep }) => {
    const { user, eventEpa, setEventEpa } = useContext(UserContext);
    const { activeTab, setActiveTab } = useContext(TabsContext);
    const { challengeName, idChallenge } = useParams();
    const [morning, setMorning] = useState(false);
    const [afternoon, setAfternoon] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const navigate = useNavigate();

    const saveScheduleTime = () => {
        const data = {
            questions: schedules,
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        };


        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`, data).then((response) => {
            if (response.status === 201) {
                finishChallenge(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        axios.get(`${API.LIST_OF_TIMES_FOR_SYNCHRONIZATION_WITH_THE_HOST.url}/${user?.createByHost}?action=LIST_OF_TIMES_FOR_SYNCHRONIZATION_WITH_THE_HOST`).then((response) => {
            if (response.status === 200) {
                const schedulesNew = response.data.data?.filter((schedule) => schedule.status).map((schedule) => {
                    return {
                        ...schedule,
                        status: false
                    }
                });
                setSchedules(schedulesNew);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [eventEpa]);

    return <>
        {challengeName === 'Desafío A' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                <div className="day-rules-info">
                    <span className="greet">¡Hey!</span>
                    <img src={WarningIcon} alt="warning-icon" />
                    <Card className="card-warning" type="secondary">
                        <Paragraph className="card-warning__text" align="center" size="s">
                            <b>Este desafío lo completarás al agendarte con tu Anfitrión para el Entrenamiento ABC Filtro. </b>
                            <br />
                            <br />
                            Cuando tu Anfitrión(a) te envíe el link de sincronización y te agendes, podrás continuar con tus desafíos diarios.
                        </Paragraph>
                    </Card>
                    <Button onClick={() => setStep(step + 1)} disabled={schedules?.length < 1}>¡Vale!</Button>
                </div>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                <img src={SyncIcon} alt="Sync Icon" />
                <Paragraph size="s" align="center">
                    A continuación encontrarás los horarios que tu Anfitrión(a) dispuso para sincronizarse contigo para el Entrenamiento ABC Filtro.
                </Paragraph>
                <div className="filter-schedule--content">
                    <div className="time-box-info">
                        <span className="time-box-info--text">Requieres de:</span>
                        <span className="time-box-info--badge">20 Minutos</span>
                        <Paragraph size="s" align="center">
                            Recuerda que requieres aproximadamente
                            <b className="featured"> 20 minutos</b> para realizar tu Entrenamiento ABC Filtro.
                        </Paragraph>
                    </div>
                </div>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                <div className="filter-schedule--content">
                    <Paragraph size="s" align="center" >
                        Selecciona el horario en el que vas a sincronizarte con tu Anfitrión(a).
                    </Paragraph>
                    <div className="checkbox-group">
                        {schedules?.map((schedule, index) => <div className="checkbox-group-item"
                            onKeyDown={() => { }}
                        >
                            <Checkbox checked={schedule.status} onChange={(e) => {
                                const checkedSchedule = schedules?.map((schedule, i) => {
                                    return {
                                        ...schedule,
                                        status: i === index ? e.target.checked : false
                                    }
                                });
                                setSchedules([...checkedSchedule]);
                            }} />
                            <span className="time">{schedule.start}</span>
                            <span className="time">{schedule.end}</span>
                        </div>)}
                    </div>
                    <img src={SyncIcon} className="sync" />
                </div>
                <Button disabled={schedules?.filter((schedule) => schedule.status).length !== 1} onClick={saveScheduleTime}>Continuar</Button>
            </Step>
            <Modal isOpen={step === 4} className="confirmation-register-modal filter-day-container">
                <div className="confirmation-register-container">
                    <div className="light">
                        <img src={ClapHand} alt="Clap Candidate" />
                    </div>
                    <Paragraph align="center" size="s">
                        ¡Genial!
                        <br />
                        Has completado el <b>Desafío A.</b>
                        <br />
                        <br />
                        Ya puedes continuar con el <b>Desafío B</b> del Día 1.
                        <br />
                        <br />
                        <b>👋🏻  Ya nos vemos.</b>
                    </Paragraph>
                    <Button size="small" onClick={() => finishChallenge(null)}>¡Vale!</Button>
                </div>
            </Modal>
        </>}
        {challengeName === 'Desafío B' && <>
            <Modal isOpen={step === 1} className="confirmation-register-modal">
                <div className="confirmation-register-container">
                    <div className="light">
                        <img src={WarningIcon} alt="Warning" />
                    </div>
                    <Paragraph align="center" size="s">
                        ¡Hey!
                        <br />
                        Recuerda que este desafío lo debes realizar en el horario que agendaste para la sincronización con tu <b>Anfitrión(a).</b>
                    </Paragraph>
                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </div>
            </Modal>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="center">
                    <b>Zona de Aprendizaje</b>
                    <br />
                    <br />
                    A continuación, te vamos a explicar de forma breve cómo funciona la pestaña de <b>Mis Validadores.</b> Desde allí, podrás enviar el correo prueba de validación a tu Anfitrión(a), durante tu entrenamiento.
                </Paragraph>
                <img src={TouchScreen} alt="Touch Screen" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="justify">
                    1. Dirígete a la pestaña Mis validadores en el menú que encuentras en la parte superior.
                </Paragraph>
                <img src={TutorialMyValidators} alt="Tutorial My Validators" />
                <Paragraph size="s" align="justify">
                    2. Presiona sobre el(la) Validador(a) a quién vas a enviar el correo.
                </Paragraph>
                <img src={TutorialSendEmail} alt="Tutorial Send Email" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="center">
                    3. Una vez te encuentres dentro del Validador, escribe su correo electrónico y presiona Enviar. Desde aquí también podrás revisar siempre, el estado de la validación.
                </Paragraph>
                <img src={TutorialSendEmailForm} alt="Tutorial Send Email Form" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="center">
                    Una vez hayas sido validado podrás visualizar, en la parte inferior, lo que dijo tu Validador sobre ti.
                </Paragraph>
                <img src={TutorialWhatSayAboutMe} alt="What Say About Me" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="center">
                    <b>¡Vas super!</b>
                </Paragraph>
                <Paragraph size="s" align="center">
                    Te queda poco para completar el <b>Desafío B.</b>
                </Paragraph>
                <img src={GoodHand} alt="Good Hand" className="good-hand" />
                <Paragraph size="s" align="center" className="filter-day-first-paragraph">
                    Es momento de practicar:
                    Ahora vas a enviar un correo de validación a tu Validador 0 - que será tu Anfitrión(a) - para realizar tu entrentamiento...
                </Paragraph>
                <Paragraph size="s" align="center" className="color-paragraph">
                    Para completar tu desafío al 100% debes:
                    <br />
                    <br />
                    <span>1. Haber enviado el correo de validación
                        <br />
                        2. Enviar la clave de C del ABC...</span>
                </Paragraph>
                <Paragraph size="xs" align="center">
                    Haz clic en el siguiente botón para ir al módulo ‘’Mis Validadores’’ y completar tu desafío.
                </Paragraph>
                <Button onClick={() => { finishChallenge(null); }}>Ir a Mis Validadores</Button>
            </Step>
        </>}
        {challengeName === 'Desafío C' && <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="filter-day-container">
                <img src={SendMail} alt="Send Mail" />
                <Paragraph size="s" align="center">
                    <b>¡Aspirante!</b>
                    <br />
                    <br />
                    Ahora deberás aplicar lo que aprendiste y enviar los correos de validación a las referencias personales que ingresaste en la Plataforma  ACDC en los Día 1, 2 y 3.
                </Paragraph>
                <img src={Recognition} alt="Time Management" />
                <Paragraph size="s" align="center" className="spacing-paragraph">
                    Recuerda que por cada una de estas validaciones ganarás una Medalla de Reconocimiento.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="center">
                    Para completar este Desafío debes enviar 6 correos de validación, si después de aplicar el ABC Filtro con las referencias personales que ingresaste en la Plataforma en los Día 1, 2 y 3, aún no has logrado este Desafío, ¡te tenemos una buena noticia! Podrás agregar nuevas <b>Referencias Personales.</b>
                </Paragraph>
                <img src={SendMail} alt="Send Mail" />
                <Paragraph size="xs" align="center" className="spacing-paragraph">
                    A continuación, conoce brevemente como puedes agregar nuevas referencias.
                </Paragraph>
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="filter-day-container">
                <Paragraph size="s" align="center">
                    Presiona el botón <b>Agregar Referencia</b> que se encuentra en Mis Validadores. Ingresa los nombres y apellidos de tu nueva referencia y finaliza presionando el botón <b>Agregar.</b>
                </Paragraph>
                <img src={TutorialAddReference} alt="Tutorial Add Reference" />
                <Button onClick={() => setStep(step + 1)}>Continuar</Button>
            </Step>
            <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="filter-day-container">
                <span className="title">
                    ¡Excelente!
                </span>
                <img src={CheckHand} alt="epa-schedule-hand" />
                <Paragraph size="s" align="center">
                    Ahora que ya sabes como agregar nuevas referencias, es momento de ponerte manos a la obra.
                </Paragraph>
                <Button onClick={() => { setStep(step + 1); }}>Continuar</Button>
            </Step>
            <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="filter-day-container">
                {user?.personalReferences?.filter((personalReference) => personalReference.sent).length < 6 ? <><Paragraph size="s" align="center">
                    <b>¡Vas super!</b>
                </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="filter-day-first-paragraph">
                        Recuerda que para completar al 100% tu Desafío C debes enviar al menos 6 correos de validación desde Mis Validadores.
                        <br />
                        <br />
                        Si tienes alguna duda o requieres apoyo con algo, cuentas siempre con tu Anfitrión(a).
                    </Paragraph></> : <>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Good Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="filter-day-first-paragraph">
                        ¡Conseguiste enviar 6 correos!
                        <br />
                        <br />
                        Esto te da el paso al Día de Alineamiento.
                        <br />
                        <br />
                        👋🏻 Nos vemos.
                    </Paragraph>
                </>}
                <Button onClick={() => {
                    if ((user?.personalReferences?.filter((personalReference) => personalReference.sent).length >= 6)) {
                        finishChallenge(null);
                    } else {
                        setActiveTab(1);
                        navigate('/missions');
                    }
                }}>{user?.personalReferences?.filter((personalReference) => personalReference.sent).length === 6 ? 'Finalizar' : 'Ir a Mis Validadores'}</Button>
            </Step>
        </>}

    </>
};

export default FilterDay;