import React, { useEffect, useState } from 'react';
import { ReactComponent as PortalBridgeIcon } from '../../../resources/images/portal-bridge.svg';
import { ReactComponent as PortalSelectorIcon } from '../../../resources/images/portal-selector.svg';

const PortalBridge = ({ children, role, selector, setSelector }) => {
    const stops = {
        'first': 137,
        'second': 10,
        'third': -115,
        'fourth': -239,
        'fifth': -365,
        'sixth': -491,
        'seventh': -613,
        'eighth': -903,
    };

    const stopsDates = {
        'first': [111, 0, -100, -100, -100, -100, -100, -100],
        'second': [235, 111, 0, -100, -100, -100, -100, -100],
        'third': [400, 220, 111, 0, -100, -100, -100, -100],
        'fourth': [400, 400, 220, 111, 0, -100, -100, -100],
        'fifth': [400, 400, 400, 220, 111, 0, -100, -100],
        'sixth': [400, 400, 400, 400, 220, 111, 0, -100],
        'seventh': [400, 400, 400, 400, 400, 220, 111, 0],
        'eighth': [400, 400, 400, 400, 400, 400, 220, 111],
    };


    
    const [index, setIndex] = useState(0);
    const [startX, setStartX] = useState(0);

    useEffect(() => {
        const initialIndex = Object.keys(stops).indexOf(selector);
        setIndex(initialIndex);
    }, [selector]);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
        const movement = startX - e.changedTouches[0].clientX;
        const threshold = 50;

        if (Math.abs(movement) > threshold) {
            const direction = movement > 0 ? 'left' : 'right';
            if (direction === 'left' && index < Object.keys(stops).length - 1) {
                setIndex(index + 1);
                setSelector?.(Object.keys(stops)[index + 1]);
            } else if (direction === 'right' && index > 0) {
                setIndex(index - 1);
                setSelector?.(Object.keys(stops)[index - 1]);
            }

        }
    };

    return (
        <div
            className={`portal-bridge portal-bridge--${role}`}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <PortalBridgeIcon
                id="portal-bridge-icon"
                className="portal-bridge-icon"
                style={{ left: `${stops[Object.keys(stops)[index]]}px`}}
            />
            <PortalSelectorIcon className={`position-${selector}`} />
            <div className="portal-bridge-dates">
                {React.Children.map(children, (child, i) => {
                    return React.cloneElement(child, {
                        style: {
                            left: `${stopsDates[child.props.className][index]}px`
                        }
                    });
                })}
            </div>
        </div>
    );
};

export default PortalBridge;
