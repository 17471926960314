import React from "react";
import { useQueryEpaGetTimeManagementHostWinners } from "../../../middleware/services/useQueryEpaGetTimeManagementHostWinners";
import { SwitchDisableTrue } from "../../atoms/switch-disabled-true";

const TimeManagementHostWinners = ({ idCoordinator, role, className }) => {
  const { data: TimeManagementHostWinners } =
    useQueryEpaGetTimeManagementHostWinners(idCoordinator, role);
  
  return (
    <div className={`${className}`}>
      {TimeManagementHostWinners?.map((candidate) =>
        candidate?.challenges?.map(
          (challenge) =>
            challenge.nameChallenge !== "Cambio de Rol" && (
              <>
                <div
                  className={`medal-switch disabled`}
                >
                  <div className="medal-switch__info">
                    <span className="medal-switch__info__name">
                      {challenge.nameChallenge}
                    </span>
                  </div>
                  <SwitchDisableTrue
                    value={challenge?.justInTime >= 1 ? "Si" : "No"}
                  />
                </div>
              </>
            )
        )
      )}

      <br />
    </div>
  );
};

export default TimeManagementHostWinners;
