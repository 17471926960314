import { ReactComponent as MyProccessBridge } from '../../../resources/images/my-proccess-bridge.svg';
import { ReactComponent as H1 } from '../../../resources/images/h1.svg';
import { ReactComponent as H2 } from '../../../resources/images/h2.svg';
import { ReactComponent as H3 } from '../../../resources/images/h3.svg';
import { ReactComponent as AvatarIcon } from '../../../resources/images/avatar-icon.svg';
import GoalImage from '../../../resources/images/goal-image.png';
import StarBadge from '../../../resources/images/star-badge.png';
import ProgressBar from '../../atoms/progress-bar';
import { UserContext } from '../../../middleware/providers/user-context';
import { useContext, useEffect, useState } from 'react';
import Medals from '../../molecules/medals';
import { TabsContext } from '../../../middleware/providers/tabs-context';
import { ROLES } from '../../../middleware/utils/constants';

const MyPerformance = () => {

    const { user } = useContext(UserContext);
    const { activeTab } = useContext(TabsContext);

    const [goals, setGoals] = useState([]);
    const [activeGoal, setActiveGoal] = useState(null);

    const [selector, setSelector] = useState('first');

    const validateRules = (goal) => {
        if (typeof goal?.filter === 'object' && goal?.infoUserKey) {
            const count = user?.[goal?.infoUserKey]?.filter((personalReference) => goal?.filter?.find((item) => item === personalReference?.processStatus))?.length;
            return {
                ...goal,
                scheduledCount: count,
                percentage: Math.round(count / goal?.target * 100),
            }
        } else if (typeof goal?.filter === 'string') {
            const scheduledCount = goal?.status ? goal?.extra : 0;
            return {
                ...goal,
                scheduledCount,
                target: goal?.extra,
                percentage: Math.round(scheduledCount / goal?.extra * 100),
            }
        }


        return goal;
    };

    const listGoals = () => {
        const list = user?.goals?.filter((goal) => user?.role.indexOf(goal.role) > -1);
        const active = list?.find((challenge, index) => {
            if (list?.[index - 1]?.status && !challenge?.status) {
                return challenge;
            }
        }) || list?.[0];
        const infoRole = ROLES[user?.role]?.goals?.find((goal) => {
            return goal?.id === active?.goal
        }) || ROLES[user?.role]?.goals?.[0];

        setGoals(list);
        const mergedGoal = {
            ...active,
            ...infoRole,
        };
        const validatedGoal = validateRules(mergedGoal);
        setActiveGoal(validatedGoal);

        setSelector(infoRole?.position);
    };

    const selectGoal = (index, position) => {
        const list = user?.goals?.filter((goal) => user?.role.indexOf(goal.role) > -1);
        const active = list?.[index];
        const infoRole = ROLES[user?.role]?.goals?.find((goal) => {
            return goal?.id === active?.goal
        }) || ROLES[user?.role]?.goals?.[0];

        const mergedGoal = {
            ...active,
            ...infoRole,
        };
        const validatedGoal = validateRules(mergedGoal);
        setActiveGoal(validatedGoal);
        setSelector(position);
    };

    useEffect(() => {
        listGoals();
    }, [user]);

    return (
        <>
            <h2>Metas</h2>
            <div className="goals-bridge" >
                <AvatarIcon className={`selector ${user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} ${selector}`} />
                <div className={`selector-border ${user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} ${selector}`}></div>
                <MyProccessBridge />
                <div className="goals-bridge__names">
                    <div className={`selector-border ${user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} first`} 
                    style={{opacity: 0.5, top: '-63px'}}
                    onClick={() => selectGoal(0,'first')}
                    ></div>
                    <span className="goal1">Meta 1</span>
                    <div className={`selector-border ${user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} second`} 
                    style={{opacity: 0.5, top: '-63px'}}
                    onClick={() => selectGoal(1,'second')}
                    ></div>
                    <span className="goal2">Meta 2</span>
                    <div className={`selector-border ${user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} third`} 
                    style={{opacity: 0.5, top: '-63px'}}
                    onClick={() => selectGoal(2,'third')}
                    ></div>
                    <span className="goal3">Meta 3</span>
                </div>
            </div>
            <div className="goal-info">
                <img src={StarBadge} alt="star-badge" />
                <div className="progress">
                    <span className="progress-bar-container">
                        <span className="progress-bar-container-title">{activeGoal?.name}</span>
                        <ProgressBar range={activeGoal?.percentage} role={user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} />
                        <span className="progress-bar-container-score">{activeGoal?.scheduledCount}/{activeGoal?.target}</span>
                    </span>
                    <span>
                        {activeGoal?.description}
                    </span>
                    <span>
                        <span className="icon-calendar"></span> Plazo Día de Reglas
                    </span>
                </div>
            </div>
            <img src={GoalImage} alt="goal" />
            <div className="level-keys">
                <h2>Llaves por Nivel</h2>
                <div className="keys inactive">
                    <H1 className="h1" />
                    <H2 className="h2" />
                    <H3 className="h3" />
                </div>
            </div>
            <h2 className="medals-title">Medallas por nivel</h2>
            <Medals role={user?.role} />
        </>
    );
};

export default MyPerformance;