import { useContext, useEffect, useState } from "react";
import { getSchedule } from "../../../middleware/services/common";
import Button from "../../atoms/button";
import CarouselSlide from "../../atoms/carousel-slide";
import PortalBridge from "../../atoms/portal-bridge";
import CarouselModal from "../../molecules/carousel-modal";
import InfoBox from "../../molecules/info-box";
import { UserContext } from "../../../middleware/providers/user-context";
import { formatDateLong } from "../../../middleware/utils/formatDate";
import { indexes } from "../../../middleware/utils/constants";

const WelcomeModal = ({ role, roleName, level, isOpen, onClose }) => {

    const { user } = useContext(UserContext);
    const [schedule, setSchedule] = useState([]);
   
    useEffect(() => {
            if(user?._id){
                fetchData(user?._id)
            }
    }, [user])
    const fetchData = async (userId) => {
        const schedule = await getSchedule(userId);
        setSchedule(schedule);
    };



    return (
        <CarouselModal isOpen={isOpen}>
            <CarouselSlide >
                <InfoBox
                    role={role}
                    roleName={roleName}
                    level={level}
                    day={<>Día de Reglas 🎮</>}
                    title="¡Bienvenido(a) a la Plataforma ACDC!"
                    paragraph="Hoy, deberás asegurarte de convertir el Aprendizaje en tu dieta fundamental. Si quieres desarrollarte como líder, en estos tiempos de cambios tecnológicos acelerados. Recuerda Aspirante, ¡puedes aprender de cualquier situación! La educación, la información, la sabiduría y las ideas provienen de cualquier fuente. ¡Necesitas dar lo mejor de ti y lograr ser preseleccionado(a) por tu buen desempeño!"
                ></InfoBox>
            </CarouselSlide>
            <CarouselSlide >
                <div className="info-section-bridge">
                    <h3>Día de Reglas</h3>
                    <PortalBridge id="day-rules" role={role} >
                        {schedule?.map((day, index) => <span className={indexes[index]}>{formatDateLong(day.date)}</span>)}
                    </PortalBridge>
                    <p>Deslizando el selector hacia Día de Reglas en el Cronograma que verás en la pantalla, encontrarás, paso a paso, las actividades que debes realizar.</p>
                    <Button size="small" onClick={onClose}>¡Vale!</Button>
                </div>
            </CarouselSlide>
        </CarouselModal>
    );
};

export default WelcomeModal;