import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import Book from "../../../resources/images/book.png";
import WarningIcon from '../../../resources/images/warning.png';
import PauseIcon from '../../../resources/images/pause.png';
import ClapHand from "../../../resources/images/clap-candidate.png";
import ListBox from "../../../resources/images/list-box-136-148.svg";
import { ReactComponent as TimeManagementMedal } from "../../../resources/images/time-management-medal.svg";
import { ReactComponent as RecognitionMedal } from "../../../resources/images/recognition-medal.svg";
import { ReactComponent as JustInTimeMedal } from "../../../resources/images/just-in-time-medal.svg";
import JustInTime from "../../../resources/images/just-in-time-medal.svg";
import HostToCoordinator from "../../../resources/images/host-to-coordinator.png";
import NewCoordinator from "../../../resources/images/new-coordinator.png";
import Mic from "../../../resources/images/mic-image-136-148.png";
import GoodHand from '../../../resources/images/good-hand.png';
import Button from "../../atoms/button";
import Modal from "../../atoms/modal";
import MedalSwitch from "../../molecules/medal-switch";
import Card from "../../atoms/card";
import RadioQuestion from "../radio-question";
import MenuUserInfo from "../../molecules/menu-user-info";
import Checkbox from "../../atoms/checkbox";
import axios from "axios";
import { API, ROLES } from "../../../middleware/utils/constants";
import Select from "../../atoms/select";

const HostEpaDay = ({ finishChallenge, step, setStep, ...props }) => {
    const { user, eventEpa, setEventEpa, changeRoleContext } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();

    const [medals, setMedals] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [currentTime, setCurrentTime] = useState(null);
    const [accordeonOpen, setAccordeonOpen] = useState(false);
    const [winnerCandidates, setWinnerCandidates] = useState(null);
    const [hostWinners, setHostWinners] = useState(null);
    const [networkMarketing, setNetworkMarketing] = useState(null);
    const [listCandidates, setListCandidates] = useState(null);
    const [shortlisted, setShortlisted] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [performanceModal, setPerformanceModal] = useState(false);

    const [hold, setHold] = useState(false);
    const [dedicate, setDedicate] = useState(false);
    const [participate, setParticipate] = useState(false);
    const [potentiate, setPotentiate] = useState(false);
    const [confidentiality, setConfidentiality] = useState(false);

    const [selectedCandidate, setSelectedCandidate] = useState([]);

    const fetchData = async () => {
        try {
            const [candidateWinnersResponse, hostWinnersResponse, listCandidatesResponse, networkMarketingResponse, shortlistedResponse] = await Promise.all([
                axios.get(`${API.GET_TIME_MANAGEMENT_CANDIDATE_WINNERS.url}/${user?._id}?action=GET_TIME_MANAGEMENT_CANDIDATE_WINNERS`),
                axios.get(`${API.GET_TIME_MANAGEMENT_HOST_WINNERS.url}/${user?._id}/${user?.role}?action=GET_TIME_MANAGEMENT_HOST_WINNERS`),
                axios.get(`${API.LIST_CANDIDATES_WITH_TRAINING.url}/${user?._id}?action=GET_ASPIRANTS_WITH_TRAINING`),
                axios.get(`${API.NETWORK_MARKETING.url}/${user?._id}?action=NETWORK_MARKETING`),
                axios.get(`${API.SHORTLISTED_APPLICANTS.url}/${user?._id}?action=SHORTLISTED_APPLICANTS`),
            ]);

            if (candidateWinnersResponse.status === 200) {
                const winnerCandidates = candidateWinnersResponse.data.data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                });
                setWinnerCandidates(winnerCandidates);
                candidateWinnersResponse.data.data.map((candidate, index) => !candidate.timeManagement && setMedals([...medals, { name: candidate?.name }]));
            }

            if (hostWinnersResponse.status === 200) {
                setHostWinners(hostWinnersResponse.data.data);
                hostWinnersResponse.data.data.map((host, index) => !host.timeManagement && setRooms([...rooms, { name: host?.name }]));
            }

            if (listCandidatesResponse.status === 200) {
                setListCandidates(listCandidatesResponse.data.data);
            }

            if (networkMarketingResponse.status === 200) {
                setNetworkMarketing(networkMarketingResponse.data.data);
            }

            if (shortlistedResponse.status === 200) {
                setShortlisted(shortlistedResponse.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const assignMedal = (medalName, value, idUser, idChallenges) => {

        const data = {
            typeMedals: medalName,
            medal: value === 'Si'
        };

        if (data.medal) {
            axios.put(`${API.REGISTER_MEDAL.url}/${idUser}/${idChallenges}`, data).then((response) => {
                if (response.status === 200) {
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        const newData = {
            id: 'all',
            message: 'refresh',
            action: 'GENERIC_EVENT'
        };
        axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
            if (response.status === 201) {
                setRefresh(!refresh);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveResponseChallengeA = () => {
        const data = {
            maintainPerformance: hold,
            confidentialityAgreement: confidentiality,
            minutesByWhatsApp: dedicate,
            provisionTwoHours: participate,
            enhanceTheThreeHours: potentiate,
        };


        axios.put(`${API.EPA_PARTICIPATION_REQUIREMENTS.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                const newData = {
                    id: 'all',
                    message: 'refresh',
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleSelect = (idCandidate) => {
        if (selectedCandidate.length < 2) { }
        if (selectedCandidate.includes(idCandidate)) {
            setSelectedCandidate(selectedCandidate.filter((candidate) => candidate !== idCandidate));
        } else {
            setSelectedCandidate([...selectedCandidate, idCandidate]);
        }
    };

    const sendSelection = () => {
        const data = {
            selected: shortlisted.map((candidate, index) => ({ id: candidate.idUser, status: selectedCandidate.includes(candidate.idUser) })),
            action: 'SELECT_FINAL_EPA_CANDIDATES',
        };

        axios.post(`${API.SELECT_FINAL_EPA_CANDIDATES.url}/${user?._id}`, data).then((response) => {
            if (response.status === 201) {
                const finishTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Bogota', hour12: false });
                setCurrentTime({
                    hours: finishTime.split(':')[0],
                    minutes: finishTime.split(':')[1],
                    seconds: finishTime.split(':')[2]
                });
                const newData = {
                    id: 'all',
                    message: 'refresh',
                    action: 'GENERIC_EVENT'
                };
                axios.post(`${API.GENERIC_EVENT.url}`, newData).then((response) => {
                    if (response.status === 201) {
                        setRefresh(!refresh);
                    }
                }).catch((error) => {
                    console.log(error);
                });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const changeRole = (role) => {
        const data = {
            role: `new coordinator`
        };

        axios.put(`${API.CHANGE_OF_ROLE.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
    }, [eventEpa, refresh]);

    useEffect(() => {
        if (currentTime) {
            assignMedal('justInTime', currentTime?.hours === '09' && currentTime?.minutes === '00', user?._id, idChallenge);
        }
    }, [currentTime]);

    return (
        <>
            {challengeName === 'Desafío Sala E' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center" className="color-paragraph">
                        <b>Actividad PDL</b>
                    </Paragraph>
                    <Paragraph size="s" align="center" className="color-paragraph">
                        ¡Anfitrión(a)! Te encuentras en el Desafío de la Sala Épica, aquí compartirás a tu equipo ACDC tu PDL (Propósito, Destino y Legado), sigue las instrucciones dadas por el(la) Director(a) del EPA para realizar esta actividad.
                    </Paragraph>
                    <div className="warning-card">
                        <img src={WarningIcon} alt="warning" className="warning" width={98} height={93} />
                        <Paragraph size="s" align="center" className="color-paragraph">
                            Recuerda que para ganar la medalla, la Nota de voz debe ser de 0:50 a 1:10.
                        </Paragraph>
                    </div>
                    <Button
                        onClick={() => setStep(step + 1)}
                    >
                        Continuar
                    </Button>
                </Step>
                <Modal className="confirmation-modal step epa-day-modal" isOpen={step === 2 || step === 5}>
                    <img src={PauseIcon} alt="pause-icon" width={106} height={106} />
                    <span className="title">¡Aspirante!</span>
                    <Paragraph align="center" size="s">
                        Te encuentras en una pantalla de Pausa Global, ningún miembro del EPA podrá avanzar hasta que el(la) Director(a) lo permita.
                        <br />
                        <br />
                        Procura estar atento(a) a las indicaciones para completar las actividades de forma sincronizada.
                    </Paragraph>
                    <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                </Modal>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad PDLs.</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Manejo del Tiempo 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <TimeManagementMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Manejo del Tiempo.
                            </Paragraph>
                        </div>
                        {winnerCandidates?.map((candidate) => <MedalSwitch roleName={ROLES[candidate.role]?.roleName} name={candidate.name} onClick={(value) => {
                            setMedals([...medals, { value, name: candidate.name }]);
                            assignMedal('timeManagement', value, candidate._id, candidate.challenges[0]?.idChallenges);
                        }} />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">¡Felicitaciones!<br />Tu participación fue perfecta.</span>
                    <img src={GoodHand} alt="good-hand" />
                    <Paragraph size="s" align="center">
                        ¡Has ganado una medalla de <b>Manejo del  Tiempo!</b>
                    </Paragraph>
                    <TimeManagementMedal />
                    <Button onClick={() => setStep(step + 1)} role={user?.role}>Ver vídeo</Button>
                    <Button onClick={() => setStep(step + 1)} style="ghost" size="small">Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Visualiza si tu equipo ganó la medalla en equipo de Justo a Tiempo de la Sala Épica</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Manejo del Tiempo 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <TimeManagementMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                Para ganar esta medalla de equipo, tu Director(a) debió haber completado la pausa #4 de la Sala Épica máximo 30 minutos después de la hora de inicio del EPA.
                            </Paragraph>
                        </div>
                        {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled onClick={(value) => {
                            setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        }} />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={rooms.length === 0}>Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Card type="secondary" outline className="epa-day-container-card">
                        <div className="card-read">
                            <Paragraph align="left" size="s">
                                “Necesitamos líderes con un idealismo inspirador, líderes que cuenten con grandes visiones, que sueñen en grande y que luchen por esos sueños para que se vuelvan realidad, personas que logren encender a los demás con la chispa de sus propias almas apasionadas”
                                <br />
                                <br />
                                <br />
                                <br />
                                -Theodore Roosevelt.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="epa-day-container-button">Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el Desafío de la <b>Sala Épica.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ahora ya sabes por qué somos una Comunidad <b>Épica.</b>
                        <br />
                        <br />
                        Ya puedes iniciar el desafío de la <b>Sala Plataforma.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Sala P' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad compartir Juego de Pulgares</b>
                        <br />
                        <br />
                        ¡Aspirante!, en esta actividad deberás esperar a que todo el equipo complete la actividad para ganar la medalla de Reconocimiento 🥇.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <RecognitionMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Reconocimiento.
                            </Paragraph>
                        </div>
                        {winnerCandidates?.map((candidate) => <MedalSwitch roleName={ROLES[candidate.role]?.roleName} name={candidate.name} onClick={(value) => {
                            setMedals([...medals, { value, name: 'Karime Castañeda' }]);
                            assignMedal('recognition', value, candidate._id, candidate.challenges[0]?.idChallenges);
                        }} />)}
                        <Paragraph size="s" align="center">
                            Podrás continuar una vez tu Director(a) confirme que se dieron o no, todas las medallas.
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        Anfitrión(a), en este desafío vas a compartir cómo ha sido tu experiencia en este rol.
                        Tu Director(a) te informará en qué momento debes participar.
                    </Paragraph>
                    <img src={Mic} alt="Mic" className="mic" />
                    <Paragraph size="s" align="center">
                        Ten en cuenta que el día de Alineamiento, realizaste un entrenamiento para esta participación.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Actividad compartir experiencia del rol</b>
                        <br />
                        <br />
                        Recuerda que para ganar la medalla, la Nota de voz debe ser de 0:50 a 1:10.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <TimeManagementMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                En la siguiente tabla podrás visualizar los Aspirantes que ganaron medalla de
                                Manejo del Tiempo.
                            </Paragraph>
                        </div>
                        {winnerCandidates?.map((candidate) => <MedalSwitch roleName={ROLES[candidate.role]?.roleName} name={candidate.name} onClick={(value) => {
                            setMedals([...medals, { value, name: 'Karime Castañeda' }]);
                            assignMedal('timeManagement', value, candidate._id, candidate.challenges[0]?.idChallenges);
                        }} />)}
                    </div>
                    <Paragraph size="s" align="center">
                        Podrás continuar una vez tu Director(a) confirme que se dieron o no, todas las medallas.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={medals.length === 0}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    {networkMarketing?.filter((candidate) => candidate.role === 'candidate')?.map((candidate) => {
                        return <>
                            <div className="accordeon" onClick={() => {
                                setAccordeonOpen(!accordeonOpen);
                            }} onKeyDown={() => { }}>
                                <MenuUserInfo orientation="row" name={candidate.name} role={candidate.role} nameRole={ROLES[candidate.role].roleName} range={7} />
                                <span className="icon-chevron-back icon" style={{ transform: accordeonOpen ? 'rotate(270deg)' : 'rotate(180deg)' }}></span>
                            </div>
                            <div className="accordeon-body" style={{ display: accordeonOpen ? 'flex' : 'none' }}>
                                <RadioQuestion
                                    name="Prospectar"
                                    questionText="Prospectar"
                                    auxText="Puntúa:"
                                    value={candidate?.challenges[0]?.questions?.[0]?.options[0]?.number}
                                    disabled
                                    onChange={() => { }}
                                />
                                <RadioQuestion
                                    name="Asociar"
                                    questionText="Asociar"
                                    auxText="Puntúa:"
                                    value={candidate?.challenges[0]?.questions?.[0]?.options[1]?.number}
                                    disabled
                                    onChange={() => { }}
                                />
                                <RadioQuestion
                                    name="Capacitar"
                                    questionText="Capacitar"
                                    auxText="Puntúa:"
                                    value={candidate?.challenges[0]?.questions?.[0]?.options[2]?.number}
                                    disabled
                                    onChange={() => { }}
                                />
                                <RadioQuestion
                                    name="Duplicar"
                                    questionText="Duplicar"
                                    auxText="Puntúa:"
                                    value={candidate?.challenges[0]?.questions?.[0]?.options[3]?.number}
                                    disabled
                                    onChange
                                    ={() => { }}
                                />
                            </div>
                        </>
                    })}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el Desafío de la <b>Sala Plataforma.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ahora ya conoces que la <b>Plataforma</b> ACDC nos asegura con 2 conectados.
                        <br />
                        <br />
                        Ya puedes iniciar el desafío de la <b>Sala Admisiones.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío Sala A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad confirmación de requisitos</span>
                    <Paragraph size="s" align="center">
                        Anfitrión(a), tus Aspirantes en el PREPA confirmaron los primeros 4 requisitos.
                        <br />
                        <br />
                        A continuación te los recordamos:
                    </Paragraph>
                    <Checkbox
                        name="hold"
                        label="1. Sostener el Desempeño que tuviste como Aspirante y mejorarlo hasta convertirte en Capitán."
                        checked={hold}
                        onChange={() => setHold(!hold)}
                    />
                    <Checkbox
                        name="dedicate"
                        label="2. Dedicar 15 minutos diarios en promedio de trabajo individual a través de WhatsApp y la Plataforma ACDC."
                        checked={dedicate}
                        onChange={() => setDedicate(!dedicate)}
                    />
                    <Checkbox
                        name="participate"
                        label="3. Participar 4 noches de trabajo en equipo virtual en los Espacios Privados Agendados (EPAs) en sesiones de 2 horas en Google Meet."
                        checked={participate}
                        onChange={() => setParticipate(!participate)}
                    />
                    <Checkbox
                        name="potentiate"
                        label="4. Potenciar las 3 Hs: Hambre para tomar acción, Humildad para aprender y Honorabilidad para lograr lo que te propones y ser tu palabra."
                        checked={potentiate}
                        onChange={() => setPotentiate(!potentiate)}
                    />
                    <Button onClick={() => setStep(step + 1)} disabled={!hold || !dedicate || !participate || !potentiate}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center" className="custom-border-color">
                        Sin embargo, para seguir avanzando necesitas confirmar el <b>quinto requisito</b> que es esencial para ser parte de nuestra comunidad épica:
                    </Paragraph>
                    <Checkbox
                        name="confidentiality"
                        label="5. Confidencialidad absoluta de lo que has vivido durante el proceso incluido el EPA hasta que seas Capitán."
                        checked={confidentiality}
                        onChange={() => setConfidentiality(!confidentiality)}
                    />
                    <img src={ListBox} alt="List" />
                    <Paragraph size="s" align="center">
                        Marca el la casilla del Quinto Requisito para poder continuar
                    </Paragraph>
                    <Button onClick={saveResponseChallengeA} disabled={!confidentiality}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        A continuación podrás visualizar el estado de los 5 requisitos de tus 3  Aspirantes.
                        <br />
                        <br />
                        Podrás avanzar una vez  todos los Aspirantes hayan aceptado los 5 requisitos
                    </Paragraph>
                    {listCandidates?.map((candidate) => <MenuUserInfo
                        className="epa-requirements"
                        orientation="row"
                        name={candidate.name}
                        role={candidate.role || 'candidate'}
                        nameRole={ROLES[candidate.role || 'candidate'].roleName}
                        range={7}
                    >
                        <div className="epa-requirements__status">
                            {Object.keys(candidate.epaParticipationRequirement || {
                                maintainPerformance: false,
                                confidentialityAgreement: false,
                                minutesByWhatsApp: false,
                                provisionTwoHours: false,
                                enhanceTheThreeHours: false,
                            }).map((index) => <Checkbox name="hold"
                                checked={candidate?.epaParticipationRequirements?.[index]}
                                disabled
                            />)}
                        </div>
                    </MenuUserInfo>)}
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="epa-day-container">
                    <span className="title">Actividad Selección</span>
                    <Paragraph size="s" align="center">
                        Anfitrión(a) ¡Felicitaciones, tus 3 Aspirantes han cumplido con los requisitos!. Para que nos ayudes a realizar la selección más acertada, por favor da respuesta a las siguientes dos preguntas:
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        1. Si la decisión de quiénes son tus dos dependiera solo de ti, ¿a quiénes seleccionarías?
                    </Paragraph>
                    <Select onChange={(e) => handleSelect(e.target.value)}>
                        <option value="">Selecciona tu Aspirante</option>
                        {shortlisted?.map((candidate) => <option value={candidate.idUser}>{candidate.name}</option>)}
                    </Select>
                    <Select onChange={(e) => handleSelect(e.target.value)}>
                        <option value="">Selecciona tu Aspirante</option>
                        {shortlisted?.map((candidate) => <option value={candidate.idUser}>{candidate.name}</option>)}
                    </Select>
                    <Button onClick={sendSelection}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        2. De los PDLs de tus Aspirantes, ¿cuál está más conectado con tu PDL?
                    </Paragraph>
                    {shortlisted?.map((candidate, index) => <Checkbox
                        name="hold"
                        label={<>
                            {candidate.name}
                            <br />
                            {ROLES[candidate.role || 'candidate'].roleName} # {index + 1}
                        </>}
                        checked={selectedCandidate.includes(candidate.idUser)}
                        onChange={() => handleSelect(candidate.idUser)}
                    />)}
                    <Button onClick={() => setPerformanceModal(true)} size="small">Ver Desempeño</Button>
                    <Paragraph size="s" align="center">
                        Este botón te muestra el desempeño de tus aspirantes
                    </Paragraph>
                    <Paragraph size="xs" align="center" >
                        *Ten en cuenta que las respuestas a estas preguntas son informativas para tu Director(a) y tu Coordinador(a). No garantizan que estas personas sean las seleccionadas.
                    </Paragraph>
                    <Button onClick={sendSelection}>Continuar</Button>
                </Step>
                <Modal isOpen={performanceModal} className="epa-table-modal">
                    <span className="close icon-close" onClick={() => setPerformanceModal(false)}></span>
                    <span className="title">Acumulado por nivel (No incluye el EPA) </span>
                    <table className="epa-table">
                        <tr>
                            <th align="left">⚪ Anfitrión(a)</th>
                            <th><TimeManagementMedal width="31" height="28" /></th>
                            <th><RecognitionMedal width="31" height="28" /></th>
                            <th><JustInTimeMedal width="31" height="28" /></th>
                        </tr>
                        {shortlisted?.map((candidate) => <tr>
                            <td>{candidate.name}</td>
                            <td align="center" className="medal">{candidate.timeManagement}</td>
                            <td align="center" className="medal">{candidate.recognition}</td>
                            <td align="center" className="medal">{candidate.justInTime}</td>
                        </tr>)}
                    </table>
                    <span className="title second">Acumulado durante el EPA</span>
                    <table className="epa-table">
                        <tr>
                            <th align="left">⚪ Anfitrión(a)</th>
                            <th><TimeManagementMedal width="31" height="28" /></th>
                            <th><RecognitionMedal width="31" height="28" /></th>
                            <th><JustInTimeMedal width="31" height="28" /></th>
                        </tr>
                        {shortlisted?.map((candidate) => <tr>
                            <td>{candidate.name}</td>
                            <td align="center" className="medal">{candidate.timeManagementEpa}</td>
                            <td align="center" className="medal">{candidate.recognitionEpa}</td>
                            <td align="center" className="medal">{candidate.justInTimeEpa}</td>
                        </tr>)}
                    </table>
                </Modal>
                <Modal isOpen={step === 6} className="confirmation-register-modal">
                    <div className="confirmation-register-container">
                        {currentTime?.hours === '09' && currentTime?.minutes === '00' && <Paragraph align="center" size="s">
                            ¡Felicitaciones equipo ACDC!
                            <br />
                            Lograron completar las actividades de la Sala de Admisiones <b>Justo a Tiempo.</b>
                        </Paragraph>}
                        <img src={JustInTime} alt="Just in Time" style={{ opacity: currentTime?.hours === '09' && currentTime?.minutes === '00' ? '1' : '0.5' }} />
                        {currentTime?.hours === '09' && currentTime?.minutes === '00' ? <><Paragraph align="center" size="s">
                            ¡Has ganado una Medalla en equipo de <b>Justo a Tiempo!</b>
                        </Paragraph>
                        </> :
                            <Paragraph align="center" size="s">
                                <b>¡Equipo ACDC!</b>
                                <br />
                                <br />
                                ¡Ups! Lamentablemente, no cumplieron con el tiempo establecido.
                                En esta ocasión, no ganaron medalla en equipo de Justo a Tiempo ¡Podrán mejorar su desempeño durante el EPA!
                            </Paragraph>
                        }
                        <Button size="small" onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Modal>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-alignment-day-container">
                    <Paragraph size="s" align="center">
                        <b>Visualiza si tu equipo ganó la medalla en equipo de Justo a Tiempo de la Sala de Admisiones</b>
                        <br />
                        <br />
                        Para ganar esta medalla de equipo, los dos Anfitriones deben completar el entrenamiento máximo 1 hora con 45 minutos después de la hora de inicio del EPA.
                    </Paragraph>
                    <div className="tutorial-time-management">
                        <div className="tutorial-time-management__info">
                            <JustInTimeMedal className={user?.role} />
                            <Paragraph size="s" align="center">
                                Para ganar esta medalla de equipo, los dos Anfitriones deben completar el entrenamiento máximo 1 hora con 45 minutos después de la hora de inicio del EPA.
                            </Paragraph>
                        </div>
                        {hostWinners?.[0]?.challenges.map((host, index) => index < 3 && <MedalSwitch name={host.nameChallenge} disabled onClick={(value) => {
                            setRooms([...medals, { value, name: 'Karime Castañeda' }]);
                        }} />)}
                    </div>
                    <Button onClick={() => setStep(step + 1)} role={user?.role} disabled={rooms.length === 0}>Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el Desafío de la <b>Sala de Admisiones.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Cambio de Rol' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="epa-day-container">
                    <img src={HostToCoordinator} alt="Host To Corrdinator" />
                    <Paragraph size="s" align="center">
                        Presiona el botón “Convertirme en Nuevo(a) Coordinador(a)” si lo ves habilitado, de lo contrario espera las indicaciones del Director.
                    </Paragraph>{/*!changeRoleContext?.status*/}
                    <Button role="coordinator"
                        onClick={() => {
                            changeRole();
                        }}
                        disabled={false}
                    >
                        Convertirme en Nuevo(a) Coordinador(a)
                    </Button>
                    <Button id="wait-list" onClick={() => { }} disabled={changeRoleContext?.status}>
                        Lista de espera
                    </Button>
                    <Button id="game-over" onClick={() => { }} style="abort" size="small">Game Over</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="epa-day-container">
                    <Paragraph size="s" align="center">
                        ¡Genial!
                    </Paragraph>o el cambio de rol.
                    <img src={NewCoordinator} alt="New Coordinator" width={194} height={194} />
                    <Paragraph size="s" align="center">
                        Ahora que eres el nuevo(a) Anfitrión(a),
                        continúa con el EPA en tu nuevo rol.
                    </Paragraph>
                    <Button onClick={() => { }}>Ver video</Button>
                    <Button id="finish" onClick={() => finishChallenge(null)} style="ghost" size="small">Continuar</Button>
                </Step>
            </>}
        </>
    );
};

export default HostEpaDay;