import ForgotForm from '../../organisms/forgot-form';
import BackgroundLogo from '../../templates/background-logo';

const ForgotPage = (props) => {

    return (
        <BackgroundLogo >
            <ForgotForm />
        </BackgroundLogo>
    );
};

export default ForgotPage;