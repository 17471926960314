import { useContext, useState } from "react";
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import { UserContext } from "../../../middleware/providers/user-context";
import Menu from "../../organisms/menu";

const Header = ({id, title, background = 'transparent'}) => {

    const {user} = useContext(UserContext);
    const [menuShow, setMenuShow] = useState(false);

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };

    return (
        <>
            <Menu role={user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
            <header id={id} className={background}>
                <div className={`logo logo--${user?.role.indexOf('new') > -1 ? user?.role.split(' ')[1] : user?.role}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => { }}></span>
            </header>
            <h4 className="title">{title}</h4>
        </>
    )
};

export default Header;