import { number } from "prop-types";
import { decryptData, encryptData } from "./encrypt";

const LOGIN = (config) => {
    const data = config.data;

    const sessionValues = JSON.parse(localStorage.getItem('session-values'));

    if (data.remember && !sessionValues) {
        localStorage.setItem('session-values', JSON.stringify({
            email: data.email,
            password: encryptData(data.password),
        }));
    }

    config.data = {
        username: config.data.email,
        password: sessionValues ? decryptData(sessionValues.password) : data.password,
        always: sessionValues ? sessionValues.always : data.always,
        remember: sessionValues ? sessionValues.remember : data.remember,
    };

    /*config.data = {
                //encryptedData: encryptData(JSON.stringify(config.data))
                username: config.data.email,
                password: config.data.password
    };*/
};

const RECOVERY = (config) => {
    config.data = {
        email: config.data.email,
    };
};


const UPDATE_PASSWORD = (config) => {
    config.data = {
        password: config.data.password,
    };
};

const SAVE_VALIDATION = (config) => {
    config.data = config.data.form;
};

const REGISTER_CANDIDATE = (config) => {
    config.data = {
        username: config.data.email,
        password: config.data.password,
    };
};

const CREATE_CANDIDATE = (config) => {
    config.data = {
        username: config.data.email,
        name: config.data.name,
        id: config.data.id,
    };
};

const REGISTRATION_CONFIRMATION = (config) => {
    config.data = {
        username: config.data.email,
        code: config.data.code,
    };
};

const SAVE_RULES_DAY_CHALLENGE_B = (config) => {
    config.data = {
        upliftTeamMembers: config.data.upliftTeamMembers,
        promoteTrainingSystemAndLearningEnvironment: config.data.promoteTrainingSystemAndLearningEnvironment,
        showcasePerformanceWithProvenMethods: config.data.showcasePerformanceWithProvenMethods,
        leverageStrengthsAvoidWeaknesses: config.data.leverageStrengthsAvoidWeaknesses,
        motivateMyself: config.data.motivateMyself,
        motivateMyselfText: config.data.motivateMyselfText,
        showActiveConcernForOthers: config.data.showActiveConcernForOthers,
        speakAndActWithConviction: config.data.speakAndActWithConviction,
        giveBestInAllSituations: config.data.giveBestInAllSituations,
        prioritizeAndGetResults: config.data.prioritizeAndGetResults,
        prioritizeAndGetText: config.data.prioritizeAndGetText,
        maintainHumility: config.data.maintainHumility,
    };
};

const SAVE_SITUATIONS_TO_CHANGE = (config) => {
    config.data = {
        questions: config.data.questions,
    };
};

const EVENT_MEDAL_ALLOCATION = (config) => {
    config.data = {
        typeMedals: config.data.typeMedals,
        medal: config.data.medal,
    };
};

const GENERIC_EVENT = (config) => {
    config.data = {
        id: config.data.id,
        message: config.data.message,
    };
};

const SELECT_FINAL_EPA_CANDIDATES = (config) => {
    delete config.data.action;
};

const VALIDATE = (config) => {
    config.data = {
        number: config.data.number,
    };
};

const ADD_CARD = (config) => {
    delete config.data.action;
};

const AWARDING_MEDALS_TO_THE_ROOMS = (config) => {
    delete config.data.action;
};



export { 
    LOGIN, 
    RECOVERY, 
    UPDATE_PASSWORD, 
    SAVE_VALIDATION, 
    REGISTER_CANDIDATE, 
    CREATE_CANDIDATE, 
    REGISTRATION_CONFIRMATION, 
    SAVE_RULES_DAY_CHALLENGE_B,
    SAVE_SITUATIONS_TO_CHANGE, 
    EVENT_MEDAL_ALLOCATION,
    GENERIC_EVENT,
    SELECT_FINAL_EPA_CANDIDATES,
    VALIDATE,
    ADD_CARD,
    AWARDING_MEDALS_TO_THE_ROOMS,
};