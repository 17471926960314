import Checkbox from "../../atoms/checkbox";



const RequirementsConfirmationActivity=({className,hold,setHold,dedicate,setDedicate,participate,setParticipate,potentiate,setPotentiate})=>{
    return (
        <>
           <Checkbox
                    name="hold"
                    label="1. Sostener el Desempeño que tuviste como Aspirante y mejorarlo hasta convertirte en Capitán."
                    checked={hold}
                    onChange={(e) => setHold(e.target.checked)}
                    className={`checkbox-room-p-1 ${className}`}
                />
                <Checkbox
                    name="dedicate"
                    label="2. Dedicar 15 minutos diarios en promedio de trabajo individual a través de WhatsApp y la Plataforma ACDC."
                    checked={dedicate}
                    onChange={(e) => setDedicate(e.target.checked)}
                    className={`checkbox-room-p-1 ${className}`}
                />
                <Checkbox
                    name="participate"
                    label="3. Participar 4 noches de trabajo en equipo virtual en los Espacios Privados Agendados (EPAs) en sesiones de 2 horas en Google Meet."
                    checked={participate}
                    onChange={(e) => setParticipate(e.target.checked)}
                    className={`checkbox-room-p-1 ${className}`}
                />
                <Checkbox
                    name="potentiate"
                    label="4. Potenciar las 3 Hs: Hambre para tomar acción, Humildad para aprender y Honorabilidad para lograr lo que te propones y ser tu palabra."
                    checked={potentiate}
                    onChange={(e) => setPotentiate(e.target.checked)}
                    className={`checkbox-room-p-1 ${className}`}
                />
              
        </>
       );
   };
   
   export default RequirementsConfirmationActivity;
