import Background from "../../templates/background";
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import React, { useContext, useEffect, useState } from "react";
import Menu from "../../organisms/menu";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API, ROLES } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import ProgressBar from "../../atoms/progress-bar";
import { saveStepOfAChallenge } from "../../../middleware/services/common";

const ChallengePage = ({transactionExist}) => {

    const navigate = useNavigate();
    const { challengeName, idChallenge } = useParams();
    const { user, currentChallenge,transationParam } = useContext(UserContext);

    const [step, setStep] = useState(currentChallenge?.passed || 1);    
    const [progress, setProgress] = useState(20);
    const [menuShow, setMenuShow] = useState(false);
    const [challenge, setChallenge] = useState({});

    const [changeRole, setChangeRole] = useState(user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role);
    const [changeDay, setChangeDay] = useState(challenge?.day);


    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setMenuShow(false);
            }
        });
    }, []);

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };

    const finishChallenge = (step) => {
        axios.put(`${API.CHALLENGE_COMPLETED.url}/${user?._id}/${idChallenge}`).then((response) => {
            if (response.status === 200) {
                if (step) {
                    setStep(step);
                } else {
                    navigate('/missions');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (step <= 0) {
            navigate('/missions');
        }

        const steps = document.querySelectorAll('.step').length;
        const percentage = step * 100 / steps;
        console.log('percentage', percentage);
        const newPercentage = percentage > 100 ? percentage - 100 : percentage;
        setProgress(newPercentage);
    }, [step]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                saveStepOfAChallenge(user?._id, idChallenge, step);
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    }, []);


    useEffect(() => {

        axios.get(`${API.GET_CHALLENGE.url}/${idChallenge}?action=get`).then((response) => {
            if (response.status === 200) {
                setChallenge(response.data.data);
                setChangeDay(response.data.data?.nameDay);
                const newStep = currentChallenge?.passed || step;
                setStep(newStep);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [currentChallenge]);


    const renderChallenge = () => {
        const Component = ROLES[user?.role]?.days?.[challenge?.day];
        return Component && <Component
            finishChallenge={finishChallenge}
            step={step}
            setStep={setStep}
            validationsCount={6}
            availableReferences={4}
            setChangeRole={setChangeRole}
            setChangeDay={setChangeDay}
            challenge={challenge}        />;
    };

    return (
        <Background className="challenge-page">
            <header className="primary" id="header">
<>
{
    transationParam!=='APPROVED'?
    <span className="icon-chevron-back icon left" onClick={() => setStep(step - 1)} onKeyDown={() => {}} ></span>
    :
    null
 
}
</>
                <div className={`logo logo--${(changeRole ?? (user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role)) || 'candidate'}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => {}}></span>
            </header>
            <h4 className="title">{challengeName}</h4>
            <span className="subtitle">{changeDay}</span>
            <div style={{ maxWidth: '412px', width: '100%' }}>
                <ProgressBar range={progress} role={(changeRole ?? (user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role)) || 'candidate'} />
            </div>
            <Menu role={changeRole ?? (user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role)} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
            {renderChallenge()}
        </Background>
    );
};

export default ChallengePage;