export const GenerationTable = ({ headings, items }) => {
    return <table className="generation-table">
        <thead className="generation-table-head">
           {headings?.map((heading) => <th>{heading}</th>)}
        </thead>
        <tbody className="generation-table-body">
            {items?.map((item) => {
                const itemArray = Object.values(item);
                return <tr>
                {itemArray?.map((subItem) => <td>
                    {subItem}
                </td>)}
            </tr>})}
        </tbody>
    </table>
};