import React from 'react'
import Checkbox from '../../atoms/checkbox'

const ConfidentialityEpa = ({confidentiality,setConfidentiality}) => {
  return (
    <>
      <Checkbox
                    name="confidentiality"
                    label="5. Confidencialidad absoluta de lo que has vivido durante el proceso incluido el EPA hasta que seas Capitán."
                    checked={confidentiality}
                    onChange={(e) => setConfidentiality(e.target.checked)}
                    className="coordinator-room-a-checkbox checkbox-room-p-1"
                />
    </>
  )
}

export default ConfidentialityEpa