import React from 'react'
import ClapHand from "../../../resources/images/clap-hand-director.png";
import Paragraph from '../../atoms/paragraph';
import Button from '../../atoms/button';
export const FinishHonor = ({finishChallenge}) => {
    return (
        <>
            <Paragraph size="s" align="center">
                <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
                Has completado el <b>Desafío Honor.</b> del Final del EPA
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />
            <Paragraph size="s" align="center" className="background-p">
                Ya puedes iniciar el  <b>Desafío Humildad.</b>
                <br />
                <br />
                👋🏻 Ya nos vemos.
            </Paragraph>
            <Button >Ver video</Button>

            <Button size="small" style="ghost" onClick={()=>finishChallenge(null)}>Finalizar</Button>

        </>
    )
}
