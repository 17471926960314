import React, { useContext, useState } from 'react'
import Paragraph from '../../atoms/paragraph';
import Button from '../../atoms/button';
import Modal from '../../atoms/modal';
import { UserContext } from '../../../middleware/providers/user-context';
import { QueriesEpaDateCoordinatingSchedulesById } from '../../../middleware/services/useQueryEpaDateCoordinatingSchedules';
import { formatDate } from '../../../middleware/utils/formatDate';

import pen from "../../../resources/images/pen.png";

const circle = {
    host: '⚪',
    'new host': '⚪',
    coordinator: '🔴',
    'new coordinator': '🔴',
    director: '🟡',
    captain: '🔵',
};
export const NewCoordinatorsSchedule = ({ setModalSchedule,setAcceptData,setStep, step }) => {
    const {user}=useContext(UserContext)
    const {data}=QueriesEpaDateCoordinatingSchedulesById(user?._id)
    const handleDateSelection=(coordinator)=>{
        setAcceptData(coordinator?.schedule)
    }
    return (
        <>
            <span className="title"><b>¡Nuevo(a) Director(a)!</b> </span>
            <Paragraph size="s" align="center" className="host-box-paragraph">
                Estos son los cronogramas de tus Nuevos(as) Coordinadores(as). El día de Inducción será mañana y el Día de Alineamiento será un día antes del EPA.
            </Paragraph>
            <div className="event-container">
            {data?.map((coordinator) => (
                
                <div key={coordinator._id}>
                    <div className="card-host-user host-box">
                    <span className="coordinator-name">{circle.coordinator} {coordinator.nameCoordinator}</span>
                </div>
                    <>
                        {coordinator.schedule.map((event, index) => (
                           
                           <>
                           <div className="event">
                           <span className='event-title'>{event.day}</span>
                           {
                            event.day==="Día de Reglas"?(<>
                              <span className='event-date' onClick={()=>{
                            handleDateSelection(coordinator)
                            setModalSchedule(true)
                            }}>{formatDate(event.date) }    <img src={pen} alt='pen' /></span>
                            </>):(<>
                                <span className='event-date'>{formatDate(event.date)}</span>
                            </>)
                           }
                         
                       </div>
                </>     
                        ))}
                    </>
                 </div>
            ))}
            </div>
            <Button className="director" onClick={() => setStep(step + 1)} >Continuar EPA</Button>
        </>
    )
}
