import axios from "axios";
import { API } from "../utils/constants";
import { useMutation, useQuery } from "@tanstack/react-query";


const keys = {
    ListOfCoordinatorsWithTherNewHosts: ["ListOfCoordinatorsWithTherNewHosts"],
  };
  
  
  
  
  const getListOfCoordinatorsWithTherNewHosts = async (idUser) => {
  
    const { data } = await axios.get(`${API.LIST_OF_NEW_COORDINATORS_WITH_THEIR_NEW_HOSTS.url}/${idUser}?action=LIST_OF_NEW_COORDINATORS_WITH_THEIR_NEW_HOSTS`);
    return data.data;
  
  }
  
  export const QueriesListOfCoordinatorsWithTherNewHosts = (idUser) => {
  
    return useQuery({
      queryKey: keys.ListOfCoordinatorsWithTherNewHosts,
      queryFn: async () => await getListOfCoordinatorsWithTherNewHosts(idUser),
    })
  }

  
const putMarkGiftGiving = async (data) => {
    let dataUpdate = {
      "status": data.status,
    
    }
  
    const resp = await axios.put(`${API.MARK_GIFT_GIVING.url}/${data.id}?action=mark-gift-giving`, dataUpdate);
    return resp;
  
  }
  
  export const QueriesputMarkGiftGiving = () => {
  
    const mutation = useMutation({
      mutationFn: (data) => putMarkGiftGiving(data),
      onSuccess: (response) => {
        console.log(response);
      },
      onError: (error) => {
        console.log(error)
      }
    });
    return mutation
  }
  