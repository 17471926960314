const Select = ({ children, onChange, disabled, name }) => {
    return (
        <select className="select" onChange={onChange} disabled={disabled} name={name}>
            {children}
        </select>
    )
};


Select.displayName = 'Select';

export default Select;