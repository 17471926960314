const Tab = ({ children, active, onClick }) => {
    const className = `tab ${active ? 'tab--active' : ''}`;

    return (
        <div className={className} onClick={onClick} onKeyDown={() => {}}>
            <span>{children}</span>
        </div>
    )
};

export default Tab;