import Background from "../../templates/background";
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import { UserContext } from "../../../middleware/providers/user-context";
import { useContext, useEffect, useState } from "react";
import Menu from "../../organisms/menu";
import ProgressBar from "../../atoms/progress-bar";
import InductionDayChallengeB from "../../organisms/induction-day-challenge-b";
import { useNavigate, useParams } from "react-router-dom";
import InductionDayChallengeC from "../../organisms/induction-day-challenge-c";
import { API } from "../../../middleware/utils/constants";
import axios from "axios";

const InductionDay = () => {

    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { challenge, idChallenge } = useParams();
    const [menuShow, setMenuShow] = useState(false);
    const [progress, setProgress] = useState(20);
    const [step, setStep] = useState(1);

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };

    const finishChallenge = (step) => {
        axios.put(`${API.CHALLENGE_COMPLETED.url}/${user?._id}/${idChallenge}`).then((response) => {
            if (response.status === 200) {
                if(step) {
                    setStep(step);
                } else {
                    setStep(1);
                    navigate('/missions');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (step <= 0) {
            navigate('/missions');
        }

        switch (step) {
            case 1:
                setProgress(20);
                break;
            case 2:
                setProgress(40);
                break;
            case 3:
                setProgress(60);
                break;
            case 4:
                setProgress(80);
                break;
            case 5:
                setProgress(100);
                break;
            default:
                setProgress(0);
                break;
        }
    }, [step]);


    return (
        <Background className="induction-day-page" full>
            <header>
                <span className="icon-chevron-back icon left" onClick={() => setStep(step - 1)} onKeyDown={() => {}}></span>
                <div className={`logo logo--${user?.role || 'candidate'}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => {}}></span>
            </header>
            <h4 className="title">Desafío {challenge?.toUpperCase()}</h4>
            <span className="subtitle">Día de Inducción</span>
            <div style={{ maxWidth: '412px', width: '100%' }}>
                <ProgressBar range={progress} role={user?.role || 'candidate'} />
            </div>
            {challenge === 'b' && <InductionDayChallengeB role={user?.role || 'candidate'} step={step} setStep={setStep} finishChallenge={finishChallenge}/>}
            {challenge === 'c' && <InductionDayChallengeC role={user?.role || 'candidate'} step={step} setStep={setStep} finishChallenge={finishChallenge}/>}
            <Menu role={user?.role} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
        </Background>
    );
};

export default InductionDay;