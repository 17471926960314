import KeyHandCoordinator from "../../resources/images/key-hand-coordinator-large.png";
import CoordinatorCheckList from "../../resources/images/coordinator-check-list.png";
import CoordinatorH3Key from '../../resources/images/CoordinatorH3Key.png';
export const getBricksChallengeB = () => [
  {
    type: "step",
    props: {
      order: 1,
    },
    bricks: [
      {
        type: "readingSection",
        props: {},
        bricks: [
          {
            type: "paragraph",
            props: {
              size: "s",
              align: "center",
            },
            children: `Honor`,
          },
          {
            type: "paragraph",
            props: {
              size: "s",
              align: "left",
            },
            children: `“La palabra honor es raramente usada en nuestros tiempos, parece un poco anticuada. Sin embargo, vivir una vida de integridad y carácter no tiene épocas y para un líder es absolutamente necesario. Se trata de alternativas, las elecciones que toma una persona en su vida lo acompañan a la tumba.”
“Las palabras de un líder y los pasos que da deben coincidir. Como dice el dicho, - tu palabra es tu valor y tu valor está en tu palabra -. El carácter se desarrolla en los valles y en las cimas de la vida, permitiéndole a la persona superar ”`,
          },
        ],
      },
      {
        type: "quote",
        bricks: [
          {
            type: "paragraph",
            props: {
              size: "s",
              align: "left",
              style: {
                margin: "0",
              },
            },
            children: "Tomado del libro:",
          },
          {
            type: "paragraph",
            props: {
              size: "s",
              align: "left",
              style: {
                margin: "0",
                fontWeight: "700",
              },
            },
            children: "Lanzando una Revolución sobre el Liderazgo.",
          },
        ],
      },
      {
        type: "button",
        props: {
          styles: {
            position: "relative",
            marginTop: "96px",
          },
        },
        children: "Continuar",
        action: "next",
      },
    ],
  },
  {
    type: "step",
    props: {
      order: 2,
    },
    bricks: [
      {
        type: "paragraph",
        props: {
          size: "s",
          align: "center",
          style: {
            maxWidth: '253px',
          }
        },
        children: `Actividad selección del paquete 
y uso de la calculadora.`,
      },
      {
        type: "paragraph",
        props: {
          size: "s",
          align: "center",
        },
        children: `Si bien en toda la estrategia se ha mostrado los resultados simulando que todos van a comprar el paquete de inicio más pequeño ESP 1 de 100 puntos que cuesta $200 dólares, les recomendaremos desde la llave de Humildad, que si tienen los recursos, es mejor iniciar con el ESP 2 ó ESP 3,  paquetes de inicio que cuestan respectivamente $500 ó 1000 dólares  los cuales dan 250 o 500 Puntos de Volumen Comisionalble  (CV) dependiendo el que ustad compre mas otros beneficios adicionales que podrás ver en la siguiente pantalla.`,
      },
      {
        type: "button",
        props: {
          styles: {
            position: "relative",
            marginTop: "196px",
          },
        },
        children: "Continuar",
        action: "next",
      },
    ],
  },
  {
    type: "step",
    props: {
      order: 3,
    },
    bricks: [
      {
        type: "paragraph",
        props: {
          size: "s",
          align: "center",
          style: {
            maxWidth: '323px',
          }
        },
        children: `¿Cuáles de los paquetes disponibles del proveedor seleccionado te interesa y cuentas con la capacidad para hacer la compra?`,
      },
      {
        type: "button",
        props: {
          style: 'ghost',
          size: 'small',
          styles: {
            position: "relative",
            marginTop: 0,
            bottom: 0,
          },
        },
        children: "Ver paquetes",
      },
      {
        type: "paragraph",
        props: {
          size: "xs",
          align: "center",
          style: {
            marginBottom: "0",
          }
        },
        children: `Selecciona solo 1 paquete.`,
      },
      {
        type: "checkbox",
        props: {
          label: ["Paquete ESP 1", "100pts", "<span style='text-align: right;'>798.000 <span style='font-size: 14px; opacity: 0.7;'>COP</span></span>"],
          name: "binary-bonus",
          labelBackground: true,
          dynamic: true,
          style: {
            marginTop: "12px",
          },
        },
      },
      {
        type: "checkbox",
        props: {
          label: ["Paquete ESP 2", "250pts", "<span style='text-align: right;'>1´995.000 <span style='font-size: 14px; opacity: 0.7;'>COP</span></span>"],
          name: "binary-bonus",
          labelBackground: true,
          dynamic: true,
          style: {
            marginTop: "12px",
          },
        },
      },
      {
        type: "checkbox",
        props: {
          label: ["Paquete ESP 3", "500pts", "<span style='text-align: right;'>3´990.000 <span style='font-size: 14px; opacity: 0.7;'>COP</span></span>"],
          name: "binary-bonus",
          labelBackground: true,
          dynamic: true,
          style: {
            marginTop: "12px",
          },
        },
      },
      {
        type: "checkbox",
        props: {
          label: ["Paquete Inteligente", "80pts aprox.", "<span style='text-align: right;'>275.000 <span style='font-size: 14px; opacity: 0.7;'>COP aprox.</span></span>"],
          name: "binary-bonus",
          labelBackground: true,
          dynamic: true,
          style: {
            marginTop: "12px",
          },
        },
      },
      {
        type: "button",
        id: "candidateList",
        props: {
          styles: {
            position: "relative",
            marginTop: "96px",
          },
        },
        children: "Continuar",
        action: "next",
      },
    ],
  },
  {
    type: "step",
    props: {
      order: 4,
    },
    bricks: [
      {
        type: "paragraph",
        props: {
          size: "s",
          align: "center",
          style: {
            marginTop: "10px",
          },
        },
        children: "Has elegido <b>Crear un paquete.</b>",
      },
      {
        type: "paragraph",
        props: {
          size: "s",
          align: "center",
          style: {
            maxWidth: '327px',
          }
        },
        children: `Primero da clic en el botón del carrito y calcula los puntos y el valor exacto a pagar.`,
      },
      {
        type: "paragraph",
        props: {
          size: "s",
          align: "center",
          style: {
            maxWidth: '338px',
          }
        },
        children: `Luego ingresa el número de puntos y el valor que vas a pagar según lo calculado en le carrito.`,
      },
      {
        type: "button",
        props: {
          styles: {
            position: "relative",
            marginTop: "96px",
          },
          size: "small",
        },
        children: "ir al  carrito",
        action: "next", 

      },
      {
        type:'textFieldPoints',
      },
      {
        type: "button",
        props: {
          styles: {
            position: "relative",
            marginTop: "246px",
          },
        },
        children: "Aceptar",
        action: "next",
      },
    ],
  },
  {
    type: 'step',
    props: {
        order: 5,
    },
    bricks: [
        {
            type: 'paragraph',
            props: {
                size: 's',
                align: 'center',
            },
            children: '¡Genial!<br/><br/>Has completado el Desafío B.'
        },
        {
            type: 'image',
            props: {
                src: CoordinatorH3Key,
                alt: 'H3 Key',
                style: {
                    marginTop: '10px'
                }
            }
        },
        {
            type: 'cardCopyKey',
            props: {
                phrase: 'Desafío B Día 3 Coordinador',
                nameChallenge: 'Desafío B',
            }
        },
        {
            type: 'button',
            props: {
                styles: {
                    position: 'relative',
                    marginTop: '96px',
                },
            },
            children: 'Finalizar',
            action: 'finish',
        }
    ]
},
];
