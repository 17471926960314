import LoginForm from '../../organisms/login-form';
import BackgroundLogo from '../../templates/background-logo';

const LoginPage = () => {
    return (
        <BackgroundLogo>
            <LoginForm />
        </BackgroundLogo>
    );
};

export default LoginPage;