const formatDate = (date) => {
    const splitDate = {
        day: date?.split('/')[0],
        month: date?.split('/')[1],
        year: date?.split('/')[2]
    };
    const options = { weekday: 'long', day: 'numeric', month: 'short' };
    const dateObj = new Date(splitDate.year, splitDate.month - 1, splitDate.day);
    let formattedDate = dateObj.toLocaleDateString('es-ES', options);
    const [dayName, day, month] = formattedDate.split(' ');
    const capitalizedDayName = dayName?.charAt(0).toUpperCase() + dayName?.slice(1);
    const capitalizedMonth = month?.charAt(0).toUpperCase() + month?.slice(1).replace('.', '') + '.';
    return `${capitalizedDayName.replace(',', '')} ${day} de ${capitalizedMonth}`;
};

const formatDateLong = (date) => {
    const splitDate = {
        day: date?.split('/')[0],
        month: date?.split('/')[1],
        year: date?.split('/')[2]
    };
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    const dateObj = new Date(splitDate.year, parseInt(splitDate.month) - 1, splitDate.day);
    let formattedDate = dateObj.toLocaleDateString('es-ES', options);
    const [dayName, day, aux, month] = formattedDate.split(' ');
    const capitalizedDayName = dayName?.charAt(0).toUpperCase() + dayName?.slice(1);
    const capitalizedMonth = month?.charAt(0).toUpperCase() + month?.slice(1).replace('.', '');
    return `${capitalizedDayName.replace(',', '')} ${day} ${aux} ${capitalizedMonth}`;
};


const formatDateLines = (date) => {
    const splitDate = {
        day: date?.split('/')[0],
        month: date?.split('/')[1],
        year: date?.split('/')[2]
    };
    const newDate = new Date(splitDate.year, splitDate.month-1, splitDate.day);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    
    const formattedDate = newDate.toLocaleDateString('es-ES', options)
        .replace('.', '')
        .replace(' ', ' de ')
        .replace('ene', 'Ene -')
        .replace('feb', 'Feb -')
        .replace('mar', 'Mar -')
        .replace('abr', 'Abr -')
        .replace('may', 'May -')
        .replace('jun', 'Jun -')
        .replace('jul', 'Jul -')
        .replace('ago', 'Ago -')
        .replace('sep', 'Sep -')
        .replace('oct', 'Oct -')
        .replace('nov', 'Nov -')
        .replace('dic', 'Dic -');
        const mesesCortos = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const fecha = new Date(splitDate.year, splitDate.month-1, splitDate.day);
        const dia = fecha.getDate();
        const mes = mesesCortos[fecha.getMonth()]; 
        const anio = fecha.getFullYear();
        const fechaCorta = `${dia} de ${mes} - ${anio}`;

    return fechaCorta;
};


export { formatDate, formatDateLong, formatDateLines };