import Modal from "../../atoms/modal";

const ChallengeModal = ({icon, name, children, isOpen, onClose}) => {

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    };

    return (
        <Modal className="challenge-modal" isOpen={isOpen}>
            <span className="close icon-close" onClick={onClose} onKeyDown={handleKeyDown}></span>
            <div className={`challenge-modal--icon challenge-modal--icon--${icon}`}>
                <div className="light"></div>
            </div>
            <h2>{name}</h2>
            {children}
        </Modal>
    );
};

export default ChallengeModal;