import axios from "axios";
import React, { createContext, useState } from "react";

const TabsContext = createContext();

const TabsProvider = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <TabsContext.Provider value={{ activeTab, setActiveTab }}>
            {children}
        </TabsContext.Provider>
    );
};

export { TabsProvider, TabsContext };