import React, { useContext, useEffect, useState } from 'react'
import TableNewDirector from "../../../resources/images/table_new_director.png";
import Paragraph from '../../atoms/paragraph'
import Button from '../../atoms/button'
import Book from "../../../resources/images/book.png";
import Card from '../../atoms/card';
import './index.scss';


export const ChallengeHonorSeven = ({ setStep, step }) => {


  return (
    <>
      <br />
      <div>
        <img src={TableNewDirector}  alt='table-new-director'/>
        <Paragraph align="left" className="honor-text-table" size="s">
          “El honor está conformado por las virtudes de la integridad, la honestidad, el sacrificio, la lealtad y la humildad, pero de un corazón justo y piadoso para aquellos menos poderosos”.
        </Paragraph>
      </div>
      <div className="phrase-container phrase-container-height">
        <img src={Book} alt="Book" className="book book-top" />
        <Paragraph className="gift-text-phrase" size="s" align="left">
          Fragmento tomado del Libro
          <br />
          <b>Lanzando una Revolución sobre el Liderazgo.</b>
        </Paragraph>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className='container honor-group'>
        <Button   >Ver video</Button>
        <Button onClick={() => setStep(step + 1)} size="small" style="ghost">Continuar</Button>
      </div>
      <br />
    </>
  )
}
