const LevelInfo = ({ role, roleName, level }) => {
    return (
        <div className="level-info">
            <div className="level-info__level">
                <span className={`level-info__level__circle level-info__level__circle--${role}`}></span>                    
                {level}
            </div>
            <div className="level-info__role">{roleName}</div>
        </div>
    );
};

export default LevelInfo;