import React from "react";

const Form = ({children, onSubmit, buttonFixed}) => {
    return (
        <form className={`form ${buttonFixed ? 'fixed' : ''}`} onSubmit={onSubmit} noValidate>
            {React.Children.map(children, child => {
                if (child?.type.displayName === 'TextField' 
                || child?.type.displayName === 'Button'
                || child?.type.displayName === 'Checkbox'
                || child?.type.displayName === 'LoginFederation'
                || child?.type.displayName === 'Paragraph'
                || child?.type.displayName === 'Step'
                || child?.type.displayName === 'Select'
                || child?.type === 'span'
                || child?.type === 'a'
                || child?.type === 'p'
                || child?.type === 'div') {
                    return child;
                }
            })}
        </form>
    );
};

export default Form;