const io = require('socket.io-client');

const {
    REACT_APP_SOCKET_URL
} = process.env;

const connectToSocket = (set, idChannel, setChangeRole) => {
    // Obtener el idUser de las cookies
    const idUser = idChannel || document.cookie?.split(';').find(cookie => cookie.includes('session-id'))?.split('=')[1].split('|')[1];


    if (!idUser) {
        console.log('No idUser found in cookies');
        return;
    }


    // Conectar al servidor con la cabecera idUser
    const socket = io(REACT_APP_SOCKET_URL, {
        extraHeaders: {
            iduser: idUser
        },
    });

    // Escuchar el evento "connect"
    socket.on('connect', () => {
        console.log('Connected to server', idChannel, idUser);
    });

    // Escuchar el evento "medal-allocation"
    socket.on('medal-allocation', (data) => {
        console.log('Received medal allocation:', data);
        set(data);
    });

    socket.on('role-change', (data) => {
        console.log('Received role change:', data);
        setChangeRole?.(data);
    });
    
    // Escuchar el evento "epa-generic-event"
    socket.on('epa-generic-event', (data) => {
        console.log('Received epa event:', data);
        set(data);
    });

    // Desconectarse del servidor cuando haya terminado
    socket.on('disconnect', () => {
        console.log('Disconnected from server');
    });
}

module.exports = connectToSocket;