import { useContext, useState } from "react";
import Step from "../../molecules/step";
import Button from "../../atoms/button";
import Paragraph from "../../atoms/paragraph";
import Checkbox from "../../atoms/checkbox";
import SyncIcon from '../../../resources/images/sync-icon-large.png';
import CheckHand from '../../../resources/images/check-hand.png';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import { UserContext } from "../../../middleware/providers/user-context";

const FilterScheduleStepper = () => {

    const { user } = useContext(UserContext);
    const { challengeName, idChallenge } = useParams();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [morning, setMorning] = useState(false);
    const [afternoon, setAfternoon] = useState(false);

    const finishChallenge = (step) => {
        axios.put(`${API.CHALLENGE_COMPLETED.url}/${user?._id}/${idChallenge}`).then((response) => {
            if (response.status === 200) {
                if (step) {
                    setStep(step);
                } else {
                    navigate('/missions');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                <div className="filter-schedule--content">
                    <Paragraph size="s" align="center">
                        A continuación encontrarás los horarios que tu Anfitrión(a) dispuso para sincronizarse contigo para el Entrenamiento ABC Filtro.
                    </Paragraph>
                    <div className="time-box-info">
                        <span className="time-box-info--text">Requieres de:</span>
                        <span className="time-box-info--badge">20 Minutos</span>
                        <Paragraph size="s" align="center">
                            Recuerda que requieres aproximadamente
                            <b className="featured"> 20 minutos</b> para realizar tu Entrenamiento ABC Filtro.
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </div>
            </Step>
            <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                <div className="filter-schedule--content">
                    <Paragraph size="s" align="center" >
                        Selecciona el horario en el que vas a sincronizarte con tu Anfitrión(a).
                    </Paragraph>
                    <div className="checkbox-group">
                        <div className="checkbox-group-item" onClick={() => {
                            setMorning(true);
                            setAfternoon(false);
                        }}
                        onKeyDown={() => {}}
                        >
                            <Checkbox checked={morning} onChange={() => {
                                setMorning(true);
                                setAfternoon(false);
                            }} />
                            <span className="time">8:00 AM</span>
                            <span className="time">9:10 AM</span>
                        </div>
                        <div className="checkbox-group-item" onClick={() => {
                            setMorning(false);
                            setAfternoon(true);
                        }}
                        onKeyDown={() => {}}
                        >
                            <Checkbox checked={afternoon} onChange={() => {
                                setMorning(false);
                                setAfternoon(true);
                            }} />
                            <span className="time">6:00 PM</span>
                            <span className="time">7:10 PM</span>
                        </div>
                    </div>
                    <img src={SyncIcon} className="sync" />
                    <Button disabled={!morning && !afternoon} onClick={() => finishChallenge(step + 1)}>Continuar</Button>
                </div>
            </Step>
            <Modal className="confirmation-modal step" isOpen={step === 3}>
                <div className="image">
                    <img src={CheckHand} alt="epa-schedule-hand" width={156} height={156} />
                </div>
                <span className="title">¡Excelente!</span>
                <Paragraph align="center" size="s">
                    Te has agendado satisfactoriamente para la sincronización con tu Anfitrión(a).
                </Paragraph>
                <Button size="small" onClick={() => navigate('/missions')}>¡Vale!</Button>
            </Modal>
        </>
    );
};

export default FilterScheduleStepper;