import Checkbox from "../../atoms/checkbox";
import MenuUserInfo from "../menu-user-info";

const AvatarChallengesMarked = ({ name, role, nameRole, range, onClick, checks, outline }) => {
    return (
        <div className={`avatar-challenges-marked ${outline ? 'outline' : ''} ${role}`}>
            <MenuUserInfo name={name} role={role} nameRole={nameRole} range={range} />
            {checks?.length > 0 && <div className="avatar-challenges-marked__checks">
                {checks?.map((check, index) => <>
                    <span>{index + 1}.</span> 
                    <Checkbox
                        checked={check}
                        onChange={() => { }}
                    />
                </>)}
            </div>}
        </div>
    );
};

export default AvatarChallengesMarked;