import React, { useContext, useEffect, useState } from 'react';
import Background from '../../templates/background';
import ProfileLevel from '../../organisms/profile-level';
import Medals from '../../molecules/medals';
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import Menu from '../../organisms/menu';
import Pdl from '../../organisms/pdl';
import { UserContext } from '../../../middleware/providers/user-context';

const ProfilePage = (props) => {

    const { user, login } = useContext(UserContext);
    const [menuShow, setMenuShow] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setMenuShow(false);
            }
        });
        if(!user) {
            login();
        }


        const sortedReferences = user?.personalReferences?.sort((a, b) => {
            if (a.status && !b.status) {
                return -1;
            }
            if (!a.status && b.status) {
                return 1;
            }
            return 0;
        });

        document.getElementById('pdl-badge').querySelectorAll('path').forEach((path) => {
            path?.setAttribute('opacity', '0.5');
        });


        let counter = 0;
        sortedReferences?.map((reference, index) => {
            if (reference.status) {
                document.getElementById(`star${index + 1}`)?.setAttribute('opacity', '1');
            } else {
                document.getElementById(`star${index + 1}`)?.setAttribute('opacity', '0.5');
            }


            if(counter === 6) {
                document.getElementById('pdl-badge').querySelectorAll('path').forEach((path) => {
                    path?.setAttribute('opacity', '1');
                });
            }

            counter++;
        });
    }, []);

    const handleMouseMove = (e) => {
        e.stopPropagation();
        const mouseY = e.clientY;
        const windowHeight = window.innerHeight;

        if (mouseY < windowHeight / 2) {
            setMenuShow(false);
        }
    };
    
    const role = user?.role?.indexOf('new') > -1 ? user?.role?.split(' ')?.[1] : user?.role;
    return (
        <Background full>
            <header>
                <div className={`logo logo--${role}`}>
                    <MainLogo />
                </div>
                <span className="icon-btn-menu icon right" onClick={() => setMenuShow(!menuShow)} onKeyDown={() => {}}></span>
            </header>
            <h4 className="title">Mi Perfil</h4>
            <ProfileLevel role={role} />
            <h2 className="subtitle">Medallería por rol</h2>
            <Medals role={role} />
            <Menu role={role} isShow={menuShow} onTouchMove={handleMouseMove} onClick={() => setMenuShow(false)} />
            <Pdl role={role} purpose={user?.purpose} destination={user?.destination} legacy={user?.legacy}/>
        </Background >
    );
};

export default ProfilePage;