const LoginFederation = ({ children }) => {
    return (
        <div className="login-federation">
            <div className="description">o continuar con</div>
            <div className="apps">
                {children}
            </div>
        </div>
    );
};

LoginFederation.displayName = "LoginFederation";

export default LoginFederation;