import { Switch } from "../../atoms/switch";

const MedalSwitch = ({ roleName, name, disabled, value, onClick, circle }) => {
    return (
        <div className={`medal-switch ${disabled ? 'disabled' : ''}`}>
            <div className="medal-switch__info">
                <span className="medal-switch__info__name">{name}</span>
                {roleName && <span className="medal-role__info__role">{circle} {roleName}</span>}
            </div>
            <Switch value={value} onClick={onClick} />
        </div>
    );
};

export default MedalSwitch;