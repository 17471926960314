const Paragraph = ({ children, align, size, padding, color, className, withBackground, style, type = 'primary' }) => {
    const customClassName = `paragraph paragraph--${align} paragraph--${size} paragraph--${padding} paragraph--${color} ${withBackground ? 'paragraph--with-background' : ''} ${type} ${className}`;

    if (typeof children === 'string') {
        return <p className={customClassName} style={style} dangerouslySetInnerHTML={{ __html: children }} />;
    }
    return <p className={customClassName} style={style}>{children}</p>;
};

Paragraph.displayName = 'Paragraph';

export default Paragraph;