import { useNavigate, useParams } from "react-router-dom";
import WarningIcon from "../../../resources/images/warning.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import LoyalImage from "../../../resources/images/loyal-image.png";
import ExtrovertImage from "../../../resources/images/extrovert-image.png";
import CreativeImage from "../../../resources/images/creative-image.png";
import IntelectualImage from "../../../resources/images/intelectual-image.png";
import TothepointImage from "../../../resources/images/tothepoint-image.png";
import HappyImage from "../../../resources/images/happy-image.png";
import Book from "../../../resources/images/book.png";
import CheckHand from '../../../resources/images/check-hand.png';
import KeyH3 from "../../../resources/images/key-h3-host.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import Checkbox from "../../atoms/checkbox";
import { useContext, useState } from "react";
import DragSelect from "../../molecules/drag-select";
import DragItem from "../../atoms/drag-item";
import TextArea from "../../atoms/text-area";
import CopyField from "../../atoms/copy-field";
import { UserContext } from "../../../middleware/providers/user-context";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";

const HostThirdDay = ({ finishChallenge, step, setStep, challenge }) => {
    const { challengeName, idChallenge } = useParams();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [challengeState, setChallengeState] = useState(challenge);

    const [preferences, setPreferences] = useState([]);

    const [buttonLeaderShip, setButtonLeaderShip] = useState(false);

    const getImage = () => {
        const optionSelected = challengeState?.questions?.[0]?.options?.find((option) => option.status);
        if (optionSelected?.option === 'Leal, responsable y acertado') {
            return {
                image: LoyalImage,
                label: 'Leal, responsable y acertado'
            };
        }
        if (optionSelected?.option === 'Extrovertida y que logras lo que te propones.') {
            return {
                image: ExtrovertImage,
                label: 'Extrovertida y que logras lo que te propones'
            };
        }
        if (optionSelected?.option === 'Creativa, intuitiva y original para todo.') {
            return {
                image: CreativeImage,
                label: 'Creativa, intuitiva y original para todo.'
            };
        }
        if (optionSelected?.option === 'Intelectual, inteligente y observadora.') {
            return {
                image: IntelectualImage,
                label: 'Intelectual, inteligente y observadora'
            };
        }
        if (optionSelected?.option === 'Que va al grano y prefiere las acciones prácticas y las cosas concretas.') {
            return {
                image: TothepointImage,
                label: 'Que va al grano y prefiere las acciones prácticas y las cosas concretas'
            };
        }
        if (optionSelected?.option === 'Alegre, conciliadora y generosa.') {
            return {
                image: HappyImage,
                label: 'Alegre, conciliadora y generosa'
            };
        }

        return {
            image: LoyalImage,
            label: 'Leal, responsable y acertado'
        }

    };

    const getPreferences = () => {
        const newPreferences = challengeState?.questions?.[1]?.options;
        preferences.forEach((preference) => {
            newPreferences[preference.id - 1] = {
                option: preference.content.props.children.props.children[1].props.children[0].props.children,
            };
        });
        return newPreferences;
    };

    const saveResponse = () => {
        const newChallengeState = { ...challengeState };
        newChallengeState.questions[1].options = getPreferences();
        const data = {
            questions: newChallengeState.questions,
            action: 'SAVE_SITUATIONS_TO_CHANGE'
        };
        
        axios.post(`${API.SAVE_SITUATIONS_TO_CHANGE.url}/${user?._id}/${idChallenge}/`, data).then((response) => {
            if (response.status === 201) {
                finishChallenge(null);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hey!</span>
                        <img src={WarningIcon} alt="warning-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío inicia por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />
                                Cuando tu Coordinador(a) reciba y confirme que le enviaste la nota de voz correspondiente al <b>Desafío A</b> podrás continuar con tus desafíos diarios.
                            </Paragraph>
                        </Card>
                        <Button onClick={() => finishChallenge(step + 1)}>¡Vale!</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío A.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ya puedes continuar con el <b>Desafío B</b> del <b>Día 3.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => navigate('/missions')}>¡Vale!</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <Paragraph size="s" align="center">
                        Cómo <b>LÍDER</b>, te gustaría <b>MÁS</b> ser reconocido(a) como una persona:
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        (Selecciona 1 de las opciones, la que más aplique).
                    </Paragraph>
                    {challengeState?.questions?.[0]?.options?.map((option, index) => {
                        return (
                            <Checkbox
                                name={`leadership-${index}`}
                                label={option.option}
                                checked={option.status}
                                onChange={(e) => {
                                    const newChallengeState = { ...challengeState };
                                    newChallengeState.questions[0].options.forEach((o) => {
                                        o.status = false;
                                    });
                                    option.status = e.target.checked;
                                    setChallengeState(newChallengeState);
                                    if(e.target.checked)
                                        setButtonLeaderShip(true);
                                    else
                                        setButtonLeaderShip(false);
                                }}
                            />
                        );
                    })}
                    <Button onClick={() => setStep(step + 1)} disabled={!buttonLeaderShip}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <img src={getImage().image} alt="Loyal Image" className="skills-image" width={343} height={394} />
                    <Paragraph size="s" align="center" className="button-sibling">
                        <h2>{getImage().label}</h2>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <Card type="secondary" outline className="host-third-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                "Para convertirte en un líder que valga la pena seguir, debes dedicarle tiempo y atención al ser interno para dejar un legado que vaya más allá del logro, un líder debe abocarse a las cuestiones del corazón”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-third-day-container-button">Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        Ordena de 1 a 5, las siguientes formas en las que sientes que mejor puedes apoyar como líder a tu equipo. Siendo 1 la MEJOR y 5 en la que MENOS.
                    </Paragraph>
                    <Paragraph size="xs" align="center">
                        (Mantén presionada la opción que deseas mover).
                    </Paragraph>
                    <DragSelect callback={(items) => setPreferences(items)}>
                        {challengeState?.questions?.[1]?.options?.map((option, index) => {
                            return (
                                <DragItem>
                                    <div className="drag-item__content">
                                        <h1 className="drag-item__title">{index + 1}.</h1>
                                        <div className="drag-item__info">
                                            <Paragraph >{option?.option}</Paragraph>
                                            <div className="icon-container">
                                                <span className="icon icon-chevron-back up"></span>
                                                <span className="icon icon-chevron-back down"></span>
                                            </div>
                                        </div>
                                    </div>
                                </DragItem>
                            );
                        })}
                    </DragSelect>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <span className="title">
                        ¡Excelente Anfitrión(a)!
                    </span>
                    <img src={CheckHand} alt="epa-schedule-hand" />
                    <Paragraph size="s" align="center" className="button-sibling">
                        Siempre que permanezcas <b>Manteniendo la Visión y perseverando; y Demostrando excelencia con tu propio desempeño</b>, serás un gran apoyo para tus Aspirantes.
                    </Paragraph>
                    <Button onClick={() => { setStep(step + 1); }}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <Card type="secondary" outline className="host-third-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                “Cuando practicamos un deporte o en la vida, cada tanto tenemos la oportunidad de hacer algo grandioso. Cuando llega ese momento, solo importan dos cosas: estar preparado para poder aprovechar el momento y tener la valentía de hacer tu mejor esfuerzo.”
                                <br />
                                <br />
                                “El éxito se da al identificar las fortalezas y construir sobre ellas.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Fragmentos del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-third-day-container-button">Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <Card type="secondary" outline className="host-third-day-container-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                <h3>Honor</h3>
                                “La integridad puede ser considerada como la condición de “no hacer lo que está mal”. El carácter puede ser definido como hacer lo correcto por el simple hecho de hacer lo correcto, incluso si es difícil de hacerse o no es popular. El honor nace de la fusión de ambas. El autor Jeff O´leary escribe, “el honor está formado por las virtudes de integridad, honestidad, sacrificio, lealtad y humildad para servir a aquellos con mayor autoridad así como un corazón justo y piadoso para aquellos inferiores.” De hecho el honor, es la fuerza que mantiene en su lugar al hambre. Sin honor el hambre se desviará del camino correcto para finalmente servir solamente a los intereses egoístas. El honor es el componente que permite al hambre ser productiva para el hombre dentro del líder. Con esta combinación los líderes encontrarán alegría el cumplimiento de su deber hacía otros.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="host-third-day-container-button">Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="host-third-day-container">
                    <Paragraph size="s" align="center">
                        ¿Cuál sería el tipo de negocio que transmitiría el Mensaje de tu Vida con el que quisieras ser recordado? y ¿Por qué?
                    </Paragraph>
                    <TextArea
                        placeholder="Escribe aquí."
                        maxLength={280}
                        onChange={(e) => {
                            const newChallengeState = { ...challengeState };
                            newChallengeState.questions[2].text = e.target.value;
                            setChallengeState(newChallengeState);
                        }}
                    />
                    <Paragraph size="xs" align="center" className="button-sibling-paragraph">
                        Escribe entre 180 y 280 caracteres.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={challengeState?.questions[2].text.length < 180 || challengeState?.questions[2].text.length > 280}>Continuar</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'}>
                    <Paragraph align="center" size="s">
                        <b>¡Genial!</b>
                        <br />
                        Has completado el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={KeyH3} alt="Key H3" />
                    <Card type="secondary" >
                        <Paragraph align="center" size="s">
                            <b>¡Conseguiste la Llave de hoy!</b>
                            <h3>Llave H3 (Honor)</h3>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Esta te da el paso al <b>Día de Filtro.</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            Ahora solo falta que completes el Desafío C, ¡ya puedes iniciarlo!
                        </Paragraph>
                    </Card>
                    <Button onClick={saveResponse}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Cierre Día 3 Anfitrión
                            </div>
                            <CopyField value="Cierre Día 3 Anfitrión" setStep={() => {
                                console.log('setStep');
                                finishChallenge(null);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                </Step>
            </>}
        </>
    );
};


export default HostThirdDay;