const Modal = ({ className, children, isOpen, onClose, color='primary', skipStarsBackground, style }) => {

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    };

    return (
        <div className={`modal-container ${isOpen ? 'is-active' : ''}`}>
            <div className={`modal ${className}`} style={style}>
                <div className={`circle ${color}`}></div>
                {!skipStarsBackground && <div className="modal-background" onClick={onClose} onKeyDown={handleKeyDown}></div>}
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;