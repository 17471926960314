import axios from "axios";
import { API } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
const keys = {
    TimeManagementHostWinners: ["TimeManagementHostWinners"],
};




const EpaGetTimeManagementHostWinnersByIdCoordinatorWithRole =async  (idUser,role) => {

    const {data}=await  axios.get(`${API.GET_TIME_MANAGEMENT_HOST_WINNERS.url}/${idUser}/${role}?action=GET_TIME_MANAGEMENT_HOST_WINNERS`);
    return  data.data;

  }
 
 export const useQueryEpaGetTimeManagementHostWinners=(idUser,role) => {
  
    return useQuery({
        queryKey: keys.TimeManagementHostWinners,
        queryFn: async () => await EpaGetTimeManagementHostWinnersByIdCoordinatorWithRole(idUser,role),
    })
 }
