import Button from "../../atoms/button";
import Paragraph from "../../atoms/paragraph";
import Step from "../step";

import Key from '../../../resources/images/key.png';
import CalendarIcon from '../../../resources/images/calendar-icon.png';
import ClockIcon from '../../../resources/images/clock-icon.png';

import { UserContext } from "../../../middleware/providers/user-context";
import { useContext, useEffect, useState } from "react";
import { getListCandidateKeys } from "../../../middleware/services/common";
import Card from "../../atoms/card";

const CandidateProgress = () => {

    const { user } = useContext(UserContext);

    const [aspirants, setAspirants] = useState([]);
    const [screen, setScreen] = useState(1);

    const fetchData = async () => {
        const aspirantsList = await getListCandidateKeys(user?._id);
        setAspirants(aspirantsList);
    };

    useEffect(() => {
        if (user) {
            fetchData();
        }
    }, [user]);

    return (
        <>
            <Step order={1} visibility={screen === 1 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">Resumen del Progreso de mis Aspirantes:</span>
                {screen === 1 && <div className="referred-table">
                    <div className="referred-table__item header">
                        <div className="referred-table__item__column" style={{ width: '10px' }}>
                        </div>
                        <div className="referred-table__item__column medium" style={{ width: '95px' }}></div>
                        <div className="referred-table__item__column" style={{ width: '41px', textAlign: 'center' }}>
                            <span className="h1">H1</span>
                        </div>
                        <div className="referred-table__item__column" style={{ width: '41px', textAlign: 'center' }}>
                            <span className="h2">H2</span>
                        </div>
                        <div className="referred-table__item__column" style={{ width: '41px', textAlign: 'center' }}>
                            <span className="h3">H3</span>
                        </div>
                        <div className="referred-table__item__column" style={{ width: '76px', textAlign: 'center' }}>
                            <span className="time">¿Cuánto tiempo
                                necesita?</span>
                        </div>
                    </div>
                    {aspirants?.map((aspirant, index) => <div className="referred-table__item">
                        <div className="referred-table__item__column">
                            <span className="index">{index + 1}</span>
                        </div>
                        <div className="referred-table__item__column medium">
                            <span className="name blue">{aspirant.name}</span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className={`h1 blue ${aspirant?.Keys?.[0]?.status ? 'active' : ''} small`}>
                                <img src={Key} alt="Key" className="key" />
                            </span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className={`h2 blue ${aspirant?.Keys?.[1]?.status ? 'active' : ''} small`}>
                                <img src={Key} alt="Key" className="key" />
                            </span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className={`h3 blue ${aspirant?.Keys?.[2]?.status ? 'active' : ''} small`}>
                                <img src={Key} alt="Key" className="key" />
                            </span>
                        </div>
                        <div className="referred-table__item__column">
                            <span className="time blue"><span>{aspirant?.time}</span> min</span>
                        </div>
                    </div>)}
                </div>}
                <Paragraph size="s" align="center">
                    Ten en cuenta que cada día requiere 15 minutos para ser completados y poder recibir las llave.
                </Paragraph>
                <Button onClick={() => setScreen(2)}>Ver agendamientos</Button>
            </Step>
            <Step order={2} visibility={screen === 2 ? 'visible' : 'hidden'} className="host-filter-day-container">
                <span className="title">Agendamientos</span>
                <Card type="quaternary">
                    <div className="item date">
                        <img src={CalendarIcon} alt="Calendar"/>
                        <span className="text">16/11/2023</span>
                    </div>
                    <div className="item time">
                        <img src={ClockIcon} alt="Clock"/>
                        <span className="text">08:00 AM - 9:10 AM</span>
                    </div>
                    <Paragraph size="s" align="justify" className="candidates">
                        <b>Aspirantes agendados:</b>
                        <br />
                        <ul>
                            <li>Juan Perez</li>
                            <li>Juan Perez</li>
                            <li>Juan Perez</li>
                        </ul>
                    </Paragraph>
                </Card>
            </Step>
        </>
    )
};

export default CandidateProgress;