import axios from "axios";
import { API } from "../utils/constants";

const changeProcessStatus = async (data, token) => {
    try {
        const response = await axios.put(`${API.CHANGE_PROCESS_STATUS.url}/${token}`, data);
        if (response.status === 200) {
            return response.status;
        }
    } catch (error) {
        console.log(error);
    }
};

export { changeProcessStatus };