import RadioButton from "../../atoms/radio-button";
import GroupRadio from "../../molecules/group-radio";

const RadioQuestion = ({ name, questionText, role = 'candidate', auxText, required, onChange, content, disabled, value }) => {
    ;

    return (
        <div className={`radio-question`}>
            <div className={`radio-question--question radio-question--question--${role}`}>{questionText}</div>
            <div className={`radio-question--content`}>{content}</div>
            <div className={`radio-question--options`}>
                <span className="radio-question--options--text">{auxText}</span>
                <GroupRadio name={name} value={value} disabled={disabled}>
                    <RadioButton value="1" onClick={onChange} />
                    <RadioButton value="2" onClick={onChange} />
                    <RadioButton value="3" onClick={onChange} />
                    <RadioButton value="4" onClick={onChange} />
                    <RadioButton value="5" onClick={onChange} />
                </GroupRadio>
            </div>
        </div>
    );
};

export default RadioQuestion;