import React from 'react'
import ClapHand from "../../../resources/images/clap-hand-director.png";
import Paragraph from '../../atoms/paragraph';
import Button from '../../atoms/button';
export const FinishHumildad = ({finishChallenge}) => {
    return (
        <>
            <Paragraph size="s" align="center">
                <b>¡Genial!</b>
            </Paragraph>
            <Paragraph size="s" align="center">
                Has completado el <b>Desafío Humildad.</b> del Final del EPA
            </Paragraph>
            <img src={ClapHand} alt="Clap Hand" className="good-hand" />
            <Paragraph size="s" align="center" className="background-p">
                Ya puedes iniciar el  <b>Desafío Hambre.</b>
                <br />
                <br />
                <b>👋🏻 Ya nos vemos</b>.
            </Paragraph>
            <Button onClick={()=>finishChallenge(null)}>Finalizar</Button>

        </>
    )
}
