import CoordinatorTools from '../../resources/images/coordinator-tools.png';
import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";

export const getBricksChallengeC = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'image',
                props: {
                    src: CoordinatorTools,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '40px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        maxWidth: '320px'
                    }
                },
                children: `En el momento en el que tus Anfitriones inicien el entrenamiento en el <b>ABC Preselección</b>, te contactarán. Tú harás las veces de un Aspirante, a punto de iniciar su <b>Día 1</b>. 
<br />
<br />
Cuando estés en las Actividad de la lectura y escritura de Propósito, ingresarás a tu Plataforma de Coordinador(a) y en este Desafío C, darás clic en alguno de estos dos botones de abajo según estés haciendo el entrenamiento con tu Anfitrión #1 o tu Anfitrión #2.`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '66px'
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    withBackground: true,
                    style: {
                        marginTop: '24px',
                    }
                },
                children: 'A continuación debes activar al Anfitrión que ya  haya completado el entrenamiento ABC Preselección.'
            },
            {
                type: 'gridGiftSwitch',
                bricks: [
                    {
                        type: 'giftSwitch',
                        props: {
                            role: 'host',
                            nameRole: 'Anfitrión(a)',
                            name: 'Karime Castañeda',
                            range: 7,
                        }
                    },
                    {
                        type: 'giftSwitch',
                        props: {
                            role: 'host',
                            nameRole: 'Anfitrión(a)',
                            name: 'Karime Castañeda',
                            range: 7,
                        }
                    },
                ]
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        marginTop: '60px',
                    }
                },
                children: 'Podrás continuar, cuando hayas iniciado el Entrenamiento con ambos Anfitriones.',
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '66px'
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío C.'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                    style: {
                        marginTop: '49px',
                    }
                },
                children: 'Avísale a tu Director(a) 🟡 que has terminado el Desafío C ¡Estará feliz por TI!<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];