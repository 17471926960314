import Background from '../../templates/background';
import { ReactComponent as MainLogo } from '../../../resources/images/main-logo.svg';
import RegisterFormCandidate from '../../organisms/register-form-candidate';
import Paragraph from '../../atoms/paragraph';

const RegisterCandidate = () => {

    const role = 'candidate';

    return (
        <Background className="register-candidate-container">
            <header>
                <div className={`logo logo--${role}`}>
                    <MainLogo />
                </div>
                <span className="background__content__title">UNIVERSO</span>
                <span className="background__content__subtitle">ACDC</span>
            </header>
            <RegisterFormCandidate />
        </Background>
    );
};

export default RegisterCandidate;