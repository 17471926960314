import Button from "../../atoms/button";
import Checkbox from "../../atoms/checkbox";
import Paragraph from "../../atoms/paragraph";
import MenuUserInfo from "../menu-user-info";

const CompletedProcess = ({ name, role, nameRole, range, checked, onChange, greetings = 0, showModal }) => {
    return (
        <div className="completed-process">
            <MenuUserInfo name={name} role={role} nameRole={nameRole} range={range} orientation="row" />
            <div className="completed-process__checkbox">
                <span className="completed-process__checkbox__span large">Entrenamiento completado</span>
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                />
            </div>
            <div className="completed-process__checkbox">
                <span className="completed-process__checkbox__span large">Agradecimientos Realizados:</span>
                <span className="completed-process__checkbox__span">{greetings}</span>
            </div>
            <Button size="small" onClick={() => showModal(true)}>Ver validadores</Button>
        </div>
    );
};

export default CompletedProcess;