import { Children, useContext, useEffect, useState } from "react";
import TouchScreen from "../../../resources/images/screen-touch-hand.png";
import MyPerformance from "../../../resources/images/my-performance.png";
import ScreenBridge from "../../../resources/images/screen-bridge.png";
import CursorHand from "../../../resources/images/cursor-hand.png";
import ScreenSelectedMeta from "../../../resources/images/screen-selected-meta.png";
import ClapHand from "../../../resources/images/clap-candidate.png";
import WarningIcon from "../../../resources/images/warning.png";
import Book from "../../../resources/images/book.png";
import { useParams } from "react-router-dom";
import Step from "../../molecules/step";
import Card from "../../atoms/card";
import Paragraph from "../../atoms/paragraph";
import Button from "../../atoms/button";
import DragSelect from "../../molecules/drag-select";
import DragItem from "../../atoms/drag-item";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import { UserContext } from "../../../middleware/providers/user-context";
import GroupRadio from "../../molecules/group-radio";
import RadioButton from "../../atoms/radio-button";
import CopyField from "../../atoms/copy-field";
import Modal from "../../atoms/modal";
import Recognition from "../../../resources/images/recognition-medal.svg";
import TimeManagement from "../../../resources/images/time-management-medal.svg";
import JustInTime from "../../../resources/images/just-in-time-medal.svg";
import Comodin from "../../../resources/images/comodin-medal.svg";

const RulesDay = ({ step, setStep, finishChallenge }) => {

    const { challengeName, idChallenge } = useParams();
    const { user, medalModal, setMedalModal } = useContext(UserContext);
    const [preferences, setPreferences] = useState([]);
    const [readingSkill, setReadingSkill] = useState(0);
    const [thinkingSkill, setThinkingSkill] = useState(0);
    const [understandingSkill, setUnderstandingSkill] = useState(0);
    const [learningSkill, setLearningSkill] = useState(0);
    const [media, setMedia] = useState(0);

    const [canFinish, setCanFinish] = useState(false);

    const [auxMedal, setAuxMedal] = useState(false);

    const MEDALS = {
        recognition: {
            img: Recognition,
            text: 'Reconocimiento'
        },
        timeManagement: {
            img: TimeManagement,
            text: 'Manejo del tiempo'
        },
        justInTime: {
            img: JustInTime,
            text: 'Justo a tiempo'
        },
        wildcard: {
            img: Comodin,
            text: 'Comodin'
        }
    };

    const texts = {
        readingSkill: [
            'Considera practicar la lectura activa y pausada de cada pantalla para mejorar tu comprensión. Leer lentamente y preguntar a tu Anfitrión puede ayudarte a entender mejor las instrucciones.',
            'Intenta leer pausadamente hasta comprender cada lectura para poder seguir las instrucciones. También podrías pedir aclaraciones si algo no está claro a tu Anfitrión que puedey verificar tu entendimiento de cada desafío',
            'Sigue desarrollando tu habilidad para identificar las ideas principales y detalles importantes del paso a paso y de las instrucciones . La práctica constante y la lectura de los textos te ayudarán a mejorar tu rapidez en la comprensión.',
            'Aprovecha tu buena capacidad de comprensión para aprender y enseñar el paso a paso y las instrucciones. La lectura crítica y el análisis de textos pueden ayudarte a seguir perfeccionando tus habilidades.',
            'Considera compartir tus habilidades enseñando a otros a leer detalladamente las instrucciones. Mantén el hábito de leer y analalizar bien todos los textos con una excelente comprensión.',
        ],
        thinkingSkill: [
            'Puede tomar de 1 a 2 días familiarizarte con las acciones de los desafiós de cada día. Lee cada instrucción para practicar y completar cada tarea, esto te ayudará a mejorar tu rendimiento.',
            'Enfócate en aprender y practicar cada acción básica siguiendo el paso a paso de cada desafío. Considera que aprenderás gradualmente al seguir las instrucciones. Es sencillo!',
            'Aprovecha tus habilidades de aprendizaje para perfeccionar cada acción básica. Considera el día de hoy como una simulación de los desafíos A, B y C para afianzar tu aprendizaje y asegurarte de que puedas cumplir con alto desempeño los desafiós de las misiones diarias.',
            'Utiliza tu capacidad para aprender de manera efectiva y aborda las acciones básicas de cada desafío con confianza. Así podrás optimizar tu proceso y asegurar un alto desempeño en cada Misión Diaria.',
            'Con tu alta capacidad de aprendizaje, considera asumir el reto de lograr un alto desempeño en los desafiós de cada misión diaria así podrás mantener un alto nivel de competitividad en el proceso de selección.',
        ],
        understandingSkill: [
            'Revisa el cronograma en detalle en la sección Misiones Diarias/Mi Progreso y ten presentes las fechas cada día.',
            'Revisa el cronograma en detalle en la sección Misiones Diarias/Mi Progreso y ten presentes las fechas cada día.',
            'Refuerza tu comprensión del cronograma revisando y ajustando tu planificación diaria. Asegúrate de asignar tiempos específicos para cada desafío y verifica tu progreso regularmente para mantenerte en el camino.',
            'Con tu buena comprensión del cronograma, busca optimizar la distribución de tu tiempo para asegurarte de cumplir con los plazos de cada misión diaria y sus desafíos.',
            'Aprovecha tu dominio del cronograma para mantenerte al día de manera consistente y así garantizar que cumplas con todas las misiones diarias justo a tiempo.',
        ],
        learningSkill: [
            'Revisa nuevamente el video que TWOBOT te compartió para iniciar el día de Reglas del Juego y luego escribe la clave que te dan al final, allí encontrarás cómo ganar cada medalla en el Resumen de las reglas del Juego..',
            'Revisa nuevamente el video que TWOBOT te compartió para iniciar el día de Reglas del Juego y luego escribe la clave que te dan al final, allí encontrarás cómo ganar cada medalla en el Resumen de las reglas del Juego.',
            'Revisa nuevamente en TWOBOT la clave: Confirmación reglas del juego y escribe la opción 1 Resumen Reglas del Juego.',
            'Aprovecha tu comprensión de cómo ganar los 3 tipos de medallas para asegurarte de ganar todas con consistencia en cada Misión Diaria. Es sencillo!',
            'Aprovecha tu comprensión de cómo ganar los 3 tipos de medallas para asegurarte de ganar todas con consistencia en cada Misión Diaria. Es sencillo!',
        ]

    };

    const sendPreferences = () => {
        const preferencesNew = [];

        preferences.forEach((preference, index) => {
            const dragSelect = document.querySelector('.drag-select');
            preferencesNew.push(dragSelect.children[index].querySelector('.paragraph').textContent);
        });

        axios.put(`${API.CHALLENGE_B_RULES_DAY.url}/${user?._id}/${idChallenge}`, { challenges: preferencesNew }).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const saveResponse = () => {


        const data = {
            responses: [
                {
                    question: 0,
                    response: readingSkill
                },
                {
                    question: 1,
                    response: thinkingSkill
                },
                {
                    question: 2,
                    response: understandingSkill
                },
                {
                    question: 3,
                    response: learningSkill
                }
            ]
        }


        axios.put(`${API.SAVE_RESPONSE.url}/${user?._id}/${idChallenge}`, data).then((response) => {
            if (response.status === 200) {
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (medalModal !== '' && !auxMedal) {
            setAuxMedal(true);
        }
    }, [medalModal]);

    useEffect(() => {
        const data = {
            params: {
                day: 'rules day',
            }
        };

        axios.get(`${API.CHALLENGES_WITH_MEDALS_BE_ASSIGNED.url}/${user?._id}?action=CHALLENGES_WITH_MEDALS_BE_ASSIGNED`, data).then((response) => {
            if (response.status === 200) {
                const challenge = response.data.data;
                if (challenge[0].wildcard) {
                    setMedalModal('continue');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hey!</span>
                        <img src={WarningIcon} alt="warning-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>Este desafío inicia por fuera de la Plataforma ACDC.</b>
                                <br />
                                <br />
                                Cuando tu Anfitrión(a) reciba y confirme que le enviaste la nota de voz correspondiente a la <b>Zona de Entrenamiento de TWOBOT</b>, podrás continuar con tu <b>Desafío A.</b>
                            </Paragraph>
                        </Card>
                        <Button onClick={() => setStep(step + 1)} disabled={medalModal === ''}>¡Vale!</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Paragraph size="s" align="center">
                        <b>Zona de Aprendizaje</b>
                        <br />
                        <br />
                        A continuación te vamos a explicar de forma breve cómo funciona la pestaña de Mi Desempeño. Es donde podrás revisar el estado por nivel de tus metas, medallas y llaves.
                    </Paragraph>
                    <img src={TouchScreen} alt="Touch Screen" />
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        Dirígete a la pestaña <b>Mi Desempeño</b> en el menú que encuentras en la parte superior.
                    </Paragraph>
                    <div className="screen-my-performance">
                        <img src={MyPerformance} alt="Touch Screen" />
                        <img src={CursorHand} alt="Cursor Hand" className="cursor-hand" />
                    </div>
                    <Paragraph size="s" align="center">
                        Una vez dentro, podrás seleccionar la Meta que deseas ver, haciendo clic sobre alguno de los selectores que se encuentran en el puente portal.
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        El <span className="role-color">Casco de Astronauta</span> indica qué meta te encuentras realizando actualmente.
                    </Paragraph>
                    <img src={ScreenBridge} alt="Screen Bridge" className="screen-bridge" />
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        Cada vez que selecciones una meta podrás ver más detalladamente su información,  en la parte inferior.
                    </Paragraph>
                    <img src={ScreenSelectedMeta} alt="Screen Selected Meta" className="screen-selected-meta" />
                    <Paragraph size="s" align="center">
                        Deslizando la pantalla hacia abajo encontrarás también tus <b>llaves</b> y <b>medallas</b> por nivel.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        ¡Genial!
                        <br />
                        <br />
                        Has completado el Desafío A.
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ya puedes continuar con el Desafío B del Día de Reglas.
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => {
                        setMedalModal('');
                        finishChallenge(null);
                    }}>Finalizar</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Card type="secondary" outline className="rules-day-container-card">
                        <span className="title-card-read">ARTE APRENDIZAJE</span>
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                                “Los líderes en desarrollo deben entender los procesos de pensamiento fundamentales que les abren las puertas al APRENDIZAJE. Sin una base de pensamiento correcto, será difícil aprender y se resistirá a las actividades adecuadas del aprendizaje, se las perderá o no las utilizará del todo. Sin embargo, cuando alguien entiende el concepto del APRENDIZAJE y la mentalidad en la que se basa, la educación puede prosperar. Aprender es una Prioridad: La educación inicial y continua es crucial. No es necesario que esta educación sea formal o académica. La clave es que el aprendizaje se convierta en la dieta fundamental de un líder en desarrollo.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="rules-day-container-button">Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        Ordena de 1 a 5, siendo 5 la que más te gusta y 1 la que menos te gusta
                    </Paragraph>
                    <DragSelect callback={(items) => setPreferences(items)}>
                        <DragItem>
                            <div className="drag-item__content">
                                <h1 className="drag-item__title" id="index0">1.</h1>
                                <div className="drag-item__info">
                                    <Paragraph >Guía de TWOBOT cuando le envías las palabras claves.</Paragraph>
                                    <div className="icon-container">
                                        <span className="icon icon-chevron-back up"></span>
                                        <span className="icon icon-chevron-back down"></span>
                                    </div>
                                </div>
                            </div>
                        </DragItem>
                        <DragItem>
                            <div className="drag-item__content">
                                <h1 className="drag-item__title" id="index1">2.</h1>
                                <div className="drag-item__info">
                                    <Paragraph >Videos explicativos para las misiones diarias.</Paragraph>
                                    <div className="icon-container">
                                        <span className="icon icon-chevron-back up"></span>
                                        <span className="icon icon-chevron-back down"></span>
                                    </div>
                                </div>
                            </div>
                        </DragItem>
                        <DragItem>
                            <div className="drag-item__content">
                                <h1 className="drag-item__title" id="index2">3.</h1>
                                <div className="drag-item__info">
                                    <Paragraph >Tutoriales del uso de la Plataforma.</Paragraph>
                                    <div className="icon-container">
                                        <span className="icon icon-chevron-back up"></span>
                                        <span className="icon icon-chevron-back down"></span>
                                    </div>
                                </div>
                            </div>
                        </DragItem>
                        <DragItem>
                            <div className="drag-item__content">
                                <h1 className="drag-item__title" id="index3">4.</h1>
                                <div className="drag-item__info">
                                    <Paragraph >Comunicación con tu Anfitrión(a).</Paragraph>
                                    <div className="icon-container">
                                        <span className="icon icon-chevron-back up"></span>
                                        <span className="icon icon-chevron-back down"></span>
                                    </div>
                                </div>
                            </div>
                        </DragItem>
                        <DragItem>
                            <div className="drag-item__content">
                                <h1 className="drag-item__title" id="index4">5.</h1>
                                <div className="drag-item__info">
                                    <Paragraph >Lecturas en la Plataforma.</Paragraph>
                                    <div className="icon-container">
                                        <span className="icon icon-chevron-back up"></span>
                                        <span className="icon icon-chevron-back down"></span>
                                    </div>
                                </div>
                            </div>
                        </DragItem>
                    </DragSelect>
                    <Button onClick={sendPreferences}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Card type="secondary" outline className="rules-day-container-card">
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                                “Los Líderes pueden Aprender de cualquiera: Esta conciencia ofrece oportunidades de aprender en toda situación . La educación, la información, las ideas, y la sabiduría pueden provenir de cualquier fuente. Los líderes pueden Aprender mejor de aquellos que tienen resultados: Aunque se puede aprender de cualquier persona, también es real que la mejor educación proviene de aquellos que obtienen resultados en la vida, en especial aquellos que tienen éxito en el área de interés del líder en desarrollo.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="rules-day-container-button">Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado <b>Desafío B.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center">
                        Ya puedes continuar con el <b>Desafío C.</b> del <b>Día de Reglas.</b>
                        <br />
                        <br />
                        👋🏻 Ya nos vemos.
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Finalizar</Button>
                </Step>
            </>
            }
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="rules-day-c">
                    <Card type="secondary" >
                        <Paragraph size="s" align="center">
                            <b>Ciencia</b>
                            <br />
                            <br />
                            ¡Aspirante! Si te mantienes firme en los pensamientos correctos para abrir las puertas del aprendizaje, puedes enfocarte en las acciones -o lado científico del aprendizaje.- A estas acciones las podemos llamar habilidades del aprendizaje.
                        </Paragraph>

                    </Card>
                    <Paragraph size="s" align="justify">
                        De 1 a 5:
                        <br />
                        ¿Qué tan bueno te consideras para la comprensión de lectura? Siendo 5 que entiendes todas las instrucciones y detalles, y 1 que requieres leer más de una vez, despacio para entender y poder seguir instrucciones.
                    </Paragraph>
                    <GroupRadio name="readingSkill">
                        <RadioButton value="1" onClick={(e) => setReadingSkill(e.target.value)} />
                        <RadioButton value="2" onClick={(e) => setReadingSkill(e.target.value)} />
                        <RadioButton value="3" onClick={(e) => setReadingSkill(e.target.value)} />
                        <RadioButton value="4" onClick={(e) => setReadingSkill(e.target.value)} />
                        <RadioButton value="5" onClick={(e) => setReadingSkill(e.target.value)} />
                    </GroupRadio>
                    <Button disabled={readingSkill === 0} onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="rules-day-c rules-day-container">
                    <Card type="secondary" outline className="rules-day-container-card">
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                                “Los líderes aprenden los temas básicos: Aprender lo básico no es opcional, todo el conocimiento que un líder tiene lo debe obtener desde abajo. Conforme una persona comienza a desempeñarse y a tener errores (medallas no ganadas), se cae, se levanta y lo vuelve a intentar y aprende. Los Líderes Aprenden sobre Metas y Objetivos: Los líderes deben Aprender todo sobre las Metas y objetivos requeridos y esperados por su organización.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="rules-day-container-button">Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="rules-day-c">
                    <Paragraph size="s" align="justify">
                        De 1 a 5:
                        <br />
                        ¿Cuánto crees que será tu capacidad de Aprendizaje de las acciones básicas del proceso de preselección y selección? Estas son:
                        <br />
                        <ul>
                            <li>Manejo del Tiempo grabando notas de voz de aproximadamente 1:00.</li>
                            <li>Escribir textos de auto-reconocimiento del Propósito, Destino y Legado en la Plataforma, de máximo 250 caracteres.</li>
                            <li>Terminar las actividades a tiempo, antes de las 9:30 de la noche.</li>
                        </ul>
                        Siendo 5 que crees que puedes aprender en un 100% las acciones básicas al finalizar el proceso de preselección. Y siendo 1 que no estás seguro(a) de aprender estas acciones básicas antes del EPA.
                    </Paragraph>
                    <GroupRadio name="thinkingSkill">
                        <RadioButton value="1" onClick={(e) => setThinkingSkill(e.target.value)} />
                        <RadioButton value="2" onClick={(e) => setThinkingSkill(e.target.value)} />
                        <RadioButton value="3" onClick={(e) => setThinkingSkill(e.target.value)} />
                        <RadioButton value="4" onClick={(e) => setThinkingSkill(e.target.value)} />
                        <RadioButton value="5" onClick={(e) => setThinkingSkill(e.target.value)} />
                    </GroupRadio>
                    <Button disabled={setThinkingSkill === 0} onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Card type="secondary" outline className="rules-day-container-card">
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                                “Los Líderes aprenden sobre los procesos: Comprender los procesos correspondientes a su organización es imperativo. Aprender todo acerca de los procesos le dará credibilidad a la hora de tratar las personas.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="rules-day-container-button">Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="rules-day-c">
                    <Paragraph size="s" align="justify">
                        De 1 a 5:
                        <br />
                        ¿Qué tan claro tienes el Cronograma del proceso de preselección?
                        <br />
                        Siendo 5 que:
                        <br />
                        <ul>
                            <li>Tienes claro los días y sus respectivas misiones diarias.</li>
                            <li>Sabes como vas a distribuir los 15 minutos para realizar los 3 desafíos de cada misión diaria.</li>
                        </ul>
                        Y siendo 1 que:
                        <br />
                        <ul>
                            <li>Aún no tienes claro los días y las misiones diarias del cronograma.</li>
                            <li>No sabes cómo distribuirás los 15 minutos diarios para terminar los 3 desafíos antes de las 9:30 de la noche.</li>
                        </ul>
                    </Paragraph>
                    <GroupRadio name="understandingSkill">
                        <RadioButton value="1" onClick={(e) => setUnderstandingSkill(e.target.value)} />
                        <RadioButton value="2" onClick={(e) => setUnderstandingSkill(e.target.value)} />
                        <RadioButton value="3" onClick={(e) => setUnderstandingSkill(e.target.value)} />
                        <RadioButton value="4" onClick={(e) => setUnderstandingSkill(e.target.value)} />
                        <RadioButton value="5" onClick={(e) => setUnderstandingSkill(e.target.value)} />
                    </GroupRadio>
                    <Button disabled={understandingSkill === 0} onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Card type="secondary" outline className="rules-day-container-card">
                        <div className="card-read">
                            <Paragraph align="justify" size="s">
                            “Los Líderes aprenden a medir el desempeño: Los líderes deben aprender los sistemas utilizados para medir el desempeño en su organización.”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="rules-day-container-button">Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Paragraph size="s" align="center">
                        De 1 a 5, siendo 5 que conoces con claridad como ganar los 3 tipos de medallas que miden el Desempeño: Manejo del Tiempo, Justo a Tiempo, Reconocimiento; y siendo 1 que aún no tienes claro como ganar los 3 tipos de medallas del Desempeño.
                    </Paragraph>
                    <GroupRadio name="learningSkill">
                        <RadioButton value="1" onClick={(e) => setLearningSkill(e.target.value)} />
                        <RadioButton value="2" onClick={(e) => setLearningSkill(e.target.value)} />
                        <RadioButton value="3" onClick={(e) => setLearningSkill(e.target.value)} />
                        <RadioButton value="4" onClick={(e) => setLearningSkill(e.target.value)} />
                        <RadioButton value="5" onClick={(e) => setLearningSkill(e.target.value)} />
                    </GroupRadio>
                    <Button disabled={learningSkill === 0} onClick={() => {
                        setStep(step + 1);
                        const calc = Math.round((parseInt(readingSkill) + parseInt(understandingSkill) + parseInt(learningSkill)) / 3);
                        setMedia(calc);
                    }}>Continuar</Button>
                </Step>
                <Step order={8} visibility={step === 8 ? 'visible' : 'hidden'} className="rules-day-container">
                    <Paragraph size="s" align="center">
                        <b>Recomendaciones según tus respuestas:</b>
                    </Paragraph>
                    <Paragraph size="s" align="left" className="title-quest">
                        Pregunta 1:
                    </Paragraph>
                    <GroupRadio name="readingSkillResults">
                        <RadioButton value="1" checked={readingSkill === '1'} />
                        <RadioButton value="2" checked={readingSkill === '2'} />
                        <RadioButton value="3" checked={readingSkill === '3'} />
                        <RadioButton value="4" checked={readingSkill === '4'} />
                        <RadioButton value="5" checked={readingSkill === '5'} />
                    </GroupRadio>
                    <Paragraph size="s" align="center">
                        {texts.readingSkill[readingSkill - 1]}
                    </Paragraph>
                    <Paragraph size="s" align="left" className="title-quest">
                        Pregunta 2:
                    </Paragraph>
                    <GroupRadio name="thinkingSkillResults">
                        <RadioButton value="1" checked={thinkingSkill === '1'} />
                        <RadioButton value="2" checked={thinkingSkill === '2'} />
                        <RadioButton value="3" checked={thinkingSkill === '3'} />
                        <RadioButton value="4" checked={thinkingSkill === '4'} />
                        <RadioButton value="5" checked={thinkingSkill === '5'} />
                    </GroupRadio>
                    <Paragraph size="s" align="center">
                        {texts.thinkingSkill[thinkingSkill - 1]}
                    </Paragraph>
                    <Paragraph size="s" align="left" className="title-quest">
                        Pregunta 3:
                    </Paragraph>
                    <GroupRadio name="understandingSkillResults">
                        <RadioButton value="1" checked={understandingSkill === '1'} />
                        <RadioButton value="2" checked={understandingSkill === '2'} />
                        <RadioButton value="3" checked={understandingSkill === '3'} />
                        <RadioButton value="4" checked={understandingSkill === '4'} />
                        <RadioButton value="5" checked={understandingSkill === '5'} />
                    </GroupRadio>
                    <Paragraph size="s" align="center">
                        {texts.understandingSkill[understandingSkill - 1]}
                    </Paragraph>
                    <Paragraph size="s" align="left" className="title-quest">
                        Pregunta 4:
                    </Paragraph>
                    <GroupRadio name="learningSkillResults">
                        <RadioButton value="1" checked={learningSkill === '1'} />
                        <RadioButton value="2" checked={learningSkill === '2'} />
                        <RadioButton value="3" checked={learningSkill === '3'} />
                        <RadioButton value="4" checked={learningSkill === '4'} />
                        <RadioButton value="5" checked={learningSkill === '5'} />
                    </GroupRadio>
                    <Paragraph size="s" align="center">
                        {texts.learningSkill[learningSkill - 1]}
                    </Paragraph>
                    <Button onClick={saveResponse}>Continuar</Button>
                </Step>
                <Step order={9} visibility={step === 9 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado <b>Desafío C.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Cierre Reglas del Juego
                            </div>
                            <CopyField value="Cierre Reglas del Juego" setStep={() => setCanFinish(true)} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                    <Button onClick={() => finishChallenge(null)} disabled={!canFinish}>Finalizar</Button>
                </Step>
            </>}
            <Modal isOpen={medalModal !== '' && medalModal !== 'continue'} className="confirmation-register-modal medal-modal">
                <div className="confirmation-register-container">
                    <img src={MEDALS[medalModal?.typeMedals]?.img} alt="Time Management" style={{ opacity: medalModal.medal ? '1' : '0.5' }} width={90} height={81} />
                    {medalModal.medal ? <>
                        <Paragraph align="center" size="s">
                            ¡Has ganado una Medalla <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                        <Paragraph align="center" size="s">
                            El Día Alineamiento, podrás cambiarla por alguna de las otras medallas para mejorar tu desempeño
                        </Paragraph></> :
                        <Paragraph align="center" size="s">
                            ¡No has ganado una Medalla de <b>{MEDALS[medalModal?.typeMedals]?.text}!</b>
                        </Paragraph>
                    }
                    <Button size="small" onClick={() => {
                        setAuxMedal(true);
                        setMedalModal('continue');
                    }}>¡Vale!</Button>
                </div>
            </Modal>
        </>
    );
};


export default RulesDay;