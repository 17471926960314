import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Button from "../../atoms/button";
import Checkbox from "../../atoms/checkbox";
import TextField from "../../atoms/text-field";
import Form from "../../molecules/form";
import ImageButton from "../../atoms/image-button";
import GoogleIcon from "../../../resources/images/google-logo.png";
import FacebookLogo from "../../../resources/images/facebook-logo.png";
import IgLogo from "../../../resources/images/ig-logo.png";
import XLogo from "../../../resources/images/x-logo.png";
import MicrosoftLogo from "../../../resources/images/microsoft-logo.png";
import LoginFederation from "../../molecules/login-federation";
import { API, MESSAGES } from "../../../middleware/utils/constants";
import { decryptData, encryptData } from "../../../middleware/utils/encrypt";
import { UserContext } from "../../../middleware/providers/user-context";

const LoginForm = () => {

    const { login } = useContext(UserContext);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [always, setAlways] = useState(false);
    const [remember, setRemember] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const sessionValues = localStorage.getItem('session-values');
        if (sessionValues) {
            const { email, password, remember, always } = JSON.parse(sessionValues);
            setEmail(email);
            setPassword(password);
            setRemember(remember);
            setAlways(always);
        }
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();

        const tagName = e.target.tagName;
        const checkValidity = tagName === 'FORM' ? e.target.checkValidity() : true;
        const form = tagName === 'FORM' ? {
            email: e.target.email.value,
            password: e.target.password.value,
            always: e.target.always.checked,
            remember: e.target.remember.checked,
        } : {};

        if (checkValidity) {
            const body = {
                ...form,
                type: tagName === 'FORM' ? 'LOGIN' : e.target.children[0].alt,
                action: 'LOGIN'
            };

            axios.post(API[body.type].url, body).then((response) => {
                if (response?.status === 201) {
                    login(response.data.data);
                    window.location.href = '';
                } 

            }).catch((error) => {
                error.response?.status === 401 && alert(MESSAGES.ERROR[error.response.data.message]);
            });
        };
    }

    const handleChangeEmail = (e) => {
        localStorage.removeItem('session-values');
        setEmail(e.target.value);
    };

    const handleChangePassword = (e) => {
        localStorage.removeItem('session-values');
        setPassword(e.target.value);
    };

    const handleEyeClick = () => {
        if (localStorage.getItem('session-values')) {
            if (!showPassword) {
                setPassword(decryptData(password));
            } else {
                setPassword(encryptData(password));
            }
        }
        setShowPassword(!showPassword);
    };
    const federatedLogin = (index) => {
        window.location.href = API[index].url;
    };

    return (
        <div className="login-form">
            <span className="login-form--title">Iniciar sesión</span>
            <Form onSubmit={handleSubmit}>
                <TextField
                    name="email"
                    placeholder="Correo electrónico"
                    leftIcon={{ icon: 'icon-mail' }}
                    pattern="[a-zA-Z0-9_%+-.]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}"
                    value={email}
                    onChange={handleChangeEmail}
                    required />
                <TextField
                    name="password"
                    type={`${!showPassword ? `password` : `text`}`}
                    placeholder="Contraseña"
                    rightIcon={{ icon: !showPassword ? 'icon-eye-off' : 'icon-eye', size: showPassword ? 11 : 16, onClick: handleEyeClick }}
                    leftIcon={{ icon: 'icon-lock' }}
                    max={10}
                    value={password}
                    onChange={handleChangePassword}
                    required />
                <a className="login-form--forgot-password" onClick={() => navigate('/forgot')} onKeyDown={() => {}}>¿Olvidaste tu contraseña?</a>
                <LoginFederation >
                    <ImageButton src={GoogleIcon} alt="GOOGLE" onClick={() => federatedLogin('GOOGLE')} />
                    <ImageButton src={FacebookLogo} alt="FACEBOOK" onClick={() => federatedLogin('FACEBOOK')} />
                    <ImageButton src={IgLogo} alt="INSTAGRAM" onClick={() => federatedLogin('INSTAGRAM')} />
                    <ImageButton src={XLogo} alt="TWITTER" onClick={() => federatedLogin('TWITTER')} />
                    <ImageButton src={MicrosoftLogo} alt="MICROSOFT" onClick={() => federatedLogin('MICROSOFT')} />
                </LoginFederation>
                <Checkbox name="remember" label="Recuérdame" checked={remember} onChange={(e) => setRemember(e.target.checked)} />
                <Checkbox name="always" label="Mantener sesión iniciada" checked={always} onChange={(e) => setAlways(e.target.checked)} />
                <Button >Iniciar sesión</Button>
            </Form>
        </div>
    );
};

export default LoginForm;
