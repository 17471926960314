const BackgroundLogo = ({ children, full = false }) => {
    return (
        <div className="background-logo">
            <div className={`background-logo__overlay ${full ? `isFull` : ''}`}></div>
            <div className="background-logo__content">
                <div className="background-logo__content__image"></div>
                <span className="background-logo__content__title">UNIVERSO</span>
                <span className="background-logo__content__subtitle">ACDC</span>
            </div>
            {children}
        </div>
    );
};

export default BackgroundLogo;