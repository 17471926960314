import { useContext } from "react";
import { UserContext } from "../../../middleware/providers/user-context";
import LevelInfluence from "../../atoms/level-influence";
import LevelInfo from "../../atoms/level-info";
import ProgressBar from "../../atoms/progress-bar";

const ProfileInfo = ({ name, role = 'candidate', level, description, roleName, levelDescription }) => {

    const { user } = useContext(UserContext);

    const medalPorcentage = () => {
        const recognition = user?.recognition;
        const justInTime = user?.justInTime;
        const timeManagement = user?.timeManagement;

        const porcentage = (recognition?.has + justInTime?.has + timeManagement?.has / (recognition?.of + justInTime?.of + timeManagement?.of)) * 100;

        return porcentage;
    };

    return (
        <div className="profile-info">
            <span className="profile-info__name">{name}</span>
            <LevelInfluence level={level} description={description} />
            <ProgressBar role={role} range={25} />
            <LevelInfo role={role} roleName={roleName} level={levelDescription} />
        </div>
    );
}

export default ProfileInfo;