import WarningIcon from '../../resources/images/warning.png';
import ClapHandCoordinator from "../../resources/images/clap-hand-coordinator.png";

export const getBricksChallengeA = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '<b>¡Hey!</b>',
            },
            {
                type: 'image',
                props: {
                    src: WarningIcon,
                    alt: 'Warning',
                    style: {
                        marginTop: '28px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    withBackground: true,
                    style: {
                        backgroundColor: '#1A2233',
                        marginTop: '28px',
                        padding: '25px 27px 31px',
                        maxWidth: '289px',
                    }
                },
                children: `<b>Este desafío se realiza por fuera de la Plataforma ACDC.</b>
<br />
<br />
Cuando tu Director(a) reciba y confirme que le enviaste la nota de voz correspondiente al <b>Desafío A</b> podrás continuar con tus desafíos diarios.
`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '120px',
                    },
                },
                children: '¡Vale!',
                action: 'next',
            }
        ],
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el <b>Desafío A.</b>'
            },
            {
                type: 'image',
                props: {
                    src: ClapHandCoordinator,
                    alt: 'clap-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    type: 'secondary',
                    withBackground: true,
                    style: {
                        marginTop: '40px',
                        padding: '35px 27px 21px',
                        maxWidth: '289px',
                    }
                },
                children: 'Ya puedes continuar con el <b>Desafío B</b> del <b>Día 1</b>.<br/><br/>👋🏻 Ya nos vemos.'
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: '¡Vale!',
                action: 'finish',
            }
        ]
    }
];