import axios from "axios";
import { API } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
const keys = {
  listNewRoles: ["listNewRoles"],
};




const getListNewRoles =async  (idUser) => {

    const {data}=await  axios.get(`${API.LIST_NEW_ROLES.url}/${idUser}?action=LIST_NEW_ROLES`);
    return  data.data;

  }
 
 export const QueriesListNewRoles=(idUser) => {

    return useQuery({
        queryKey: keys.listNewRoles,
        queryFn: async () => await getListNewRoles(idUser),
    })
 }
