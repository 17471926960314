import GoodHandCoordinator from "../../resources/images/good-hand-coordinator.png";
import Union from "../../resources/images/union.png";

export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'readingSection',
                props: {
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                        },
                        children: `Ciencia`,
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `“La ciencia de las personas en este nivel de influencia es moldear el camino. Inspirar a las personas a tener un buen desempeño, capacitarlos y prepararlos. Un líder sirve a su gente, organiza actividades, mide resultados y resuelve problemas enfocándose principalmente en las soluciones. Si nos llega una situación a resolver, nos enfocamos en cómo podemos resolverla, no en por qué pasó o como pudo haber sido; sino en cómo soluciono ahora lo que está ocurriendo. También, tienen una excelente comunicación con su equipo, tanto de mentores como de personas a su cargo.”`,
                    }
                ]
            },
            {
                type: 'quote',
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0'
                            }
                        },
                        children: 'Tomado del libro:'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                fontWeight: '700'
                            }
                        },
                        children: 'Lanzando una Revolución sobre el Liderazgo.'
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                    style: {
                        fontWeight: '700'
                    }
                },
                children: 'Actividad Lista de Espera Convocatoria'
            },
            {
                type: 'image',
                props: {
                    src: Union,
                    alt: 'union',
                }
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: `Verifica en la pestaña de Mi Desempeño si ambos ya lograron la Meta 1. 

Después de haber aplicado el Plan B, para quién no lo haya logrado y tenga al menos 4 agendados: Se activará la lista de espera convocatoria.`
            },
            {
                type: 'paragraph',
                props: {
                    size: 'xs',
                    align: 'center',
                    style: {
                        maxWidth: '320px',
                    }
                },
                children: `Podrá usar tu lista de espera solo si tu lista no tiene personas en espera. Si es el caso contrario, podrás acudir a las de tu Director(a) y/o la de tu Capitán(a).`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '66px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 3,
        },
        bricks: [
            {
                type: 'card',
                props: {
                    type: 'quinary',
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '343px',
                        paddingLeft: '13px',
                        marginBottom: '16px'
                    }
                },
                bricks: [
                    {
                        type: 'menuUserInfo',
                        props: {
                            name: 'Juan Perez',
                            role: 'host',
                            nameRole: 'Anfitrión',
                            range: 7,
                            orientation: 'row',
                            size: 'small',
                        }
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                        },
                        children: `Requiere de <strong style="color: #4DFFDD">2</strong> personas de Lista de Espera.`
                    }
                ]
            },
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'justify',
                    style: {
                        maxWidth: '320px',
                    }
                },
                children: `A continuación, elige las personas que usarás de tu Lista de Espera para ayudar a tu Anfitrión(a):`
            },
            {
                type: 'textFieldList',
                bricks: [
                    {
                        type: 'textField',
                        id: 'textFieldCandidate',
                        props: {
                            disabled: true,
                            placeholder: 'Selecciona un Aspirante',
                            rightIcon: {
                                icon: 'icon-add',
                                size: '24',
                                color: '#0063DD',
                            }
                        },
                        action: {
                            action: 'openModalSelect',
                            id: 'candidateList'
                        },
                    },
                    {
                        type: 'textField',
                        id: 'textFieldCandidate2',
                        props: {
                            disabled: true,
                            placeholder: 'Selecciona un Aspirante',
                            rightIcon: {
                                icon: 'icon-add',
                                size: '24',
                                color: '#0063DD',
                            }
                        },
                        action: {
                            action: 'openModalSelect',
                            id: 'candidateList'
                        },
                    },
                ]
            },
            {
                type: 'paragraph',
                props: {
                    size: 'xs',
                    align: 'center',
                    style: {
                        maxWidth: '320px',
                    }
                },
                children: `Si no cuentas con personas disponibles en tu Lista de Espera, no dudes en acudir a la lista de tu Director(a) ¡La  ayuda está a la mano!`
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '66px'
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'listModal',
        id: 'candidateList',
        props: {
            isOpen: false,
            title: ['Lista de Espera Coordinador(a)  🔴 (Tú)', 'Lista de Espera  Director(a) 🟡'],
            items: [
                {
                    id: 1,
                    name: 'Martin Olivas Samper.',
                    action: {
                        action: 'selectCandidate',
                        id: 'textFieldCandidate',
                        index: 0,
                    }
                },
                {
                    id: 2,
                    name: 'Julián Verde Bustos.',
                    action: {
                        action: 'selectCandidate',
                        id: 'textFieldCandidate',
                        index: 1,
                    }
                },
                {
                    id: 3,
                    name: 'Alejandro Contreras Sandoval.',
                    action: {
                        action: 'selectCandidate',
                        id: 'textFieldCandidate',
                        index: 2,
                    }
                }
            ],
        },
        children: 'Prueba'
    },
    {
        type: 'step',
        props: {
            order: 4,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío B.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Preselección Coordinador',
                    nameChallenge: 'Desafío B',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];