import GoodHandCoordinator from "../../resources/images/good-hand-coordinator.png";

export const getBricksChallengeA = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'readingSection',
                props: {
                },
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'center',
                        },
                        children: `Arte`,
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                        },
                        children: `“El arte del tercer nivel de influencia (LIDERAZGO) es entender que los resultados provienen de un esfuerzo en equipo. Entender que las personas deben estar de acuerdo con su liderazgo y aceptarlo antes que cualquier cosa.  Las personas en este nivel de influencia, entienden la importancia de encontrar y desarrollar buenas personas; también que el liderazgo es la demarcación base, pues todo comienza con el liderazgo y también termina con el liderazgo. Saben también el impacto que tienen sus acciones sobre la organización, entienden que hay precios a pagar y aprenden a encontrar derrotas en cada victoria para mejorar y victorias en cada derrota para reconocer.”`,
                    }
                ]
            },
            {
                type: 'quote',
                bricks: [
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0'
                            }
                        },
                        children: 'Tomado del libro:'
                    },
                    {
                        type: 'paragraph',
                        props: {
                            size: 's',
                            align: 'left',
                            style: {
                                margin: '0',
                                fontWeight: '700'
                            }
                        },
                        children: 'Lanzando una Revolución sobre el Liderazgo.'
                    },
                ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío A.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Pensamientos Coordinador',
                    nameChallenge: 'Desafío A',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];