import classNames from "classnames";

const PaymentMethod = ({ name, label, logo, onClick, selected, disabled }) => {

    const className = classNames("payment-method", {
        "payment-method--selected": !disabled && selected,
        "payment-method--disabled": disabled,
    });

    return (
        <div className={className} onClick={onClick} name={name}>
            <img src={logo} alt={name} />
            <span>{label}</span>
        </div>
    );
};

export default PaymentMethod;