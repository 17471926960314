import React, { useContext } from "react";
import { TabsContext } from "../../../middleware/providers/tabs-context";

const Tabs = ({ children, active }) => {
    const { activeTab, setActiveTab } = useContext(TabsContext);

    return (
        <div className="tabs">
            {React.Children.map(children, (child, index) => {
                return child && React.cloneElement(child, {
                    active: child.props.active ?? index === activeTab,
                    onClick: () => {
                        setActiveTab(index)
                        child.props.onClick?.();
                    }
                });
            })}
        </div>
    );
};

export default Tabs;