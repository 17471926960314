import classNames from "classnames";

const Card = ({children, type = 'default', outline, className, style}) => {
    const classes = classNames('card', {
        [`card--${type}`]: type,
        [`card--${type}--outline`]: outline,
        [className]: className,
    });

    return (
        <div className={classes} style={style}>
            {children}
        </div>
    );
};

export default Card;