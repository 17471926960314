import GoodHandCoordinator from "../../resources/images/good-hand-coordinator.png";

export const getBricksChallengeB = () => [
    {
        type: 'step',
        props: {
            order: 1,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: 'Este Desafío se completa una vez te agendes como “Aspirante” de tus dos Anfitriones en la Plataforma. En este momento habrás terminado el entrenamiento ABC Convocatoria de tus Anfitriones y ya estarán listos para empezar a convocar a sus Validadores.',
            },
            {
                type: 'gridGiftSwitch',
                bricks: 'getGridGiftSwitchCalendar',
                // bricks:[
                //     {
                //         type: 'giftSwitch',
                //         props: {
                //             role: 'host',
                //             nameRole: 'Nuevo(a) Anfitrión(a)',
                //             name: 'Karime Castañeda',
                //             range: 7,
                //             items: [
                //                 {
                //                     image: CalendarIcon,
                //                     wait: true,
                //                     width: '24px',
                //                 },
                //             ]
                //         }
                //     },
                //     {
                //         type: 'giftSwitch',
                //         props: {
                //             role: 'host',
                //             nameRole: 'Nuevo(a) Anfitrión(a)',
                //             name: 'Karime Castañeda',
                //             range: 7,
                //             items: [
                //                 {
                //                     image: CalendarIcon,
                //                     title:'Prueba',
                //                     wait: true,
                //                     width: '24px',
                //                 },
                //             ]
                //         }
                //     },
                //     {
                //         type: 'paragraph',
                //         props: {
                //             size: 's',
                //             align: 'center',
                //             style: {
                //                 width: '129px',
                //                 top: '-10px',
                //                 opacity: '0.7',
                //             }                        
                //         },
                //         children: 'Ya te agendaste al Epa.'
                //     },
                //     {
                //         type: 'paragraph',
                //         props: {
                //             size: 's',
                //             align: 'center',
                //             style: {
                //                 width: '129px',
                //                 top: '-10px',
                //                 opacity: '0.7',
                //             }                        
                //         },
                //         children: 'Ya te agendaste al Epa.'
                //     }
                // ]
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Continuar',
                action: 'next',
            }
        ]
    },
    {
        type: 'step',
        props: {
            order: 2,
        },
        bricks: [
            {
                type: 'paragraph',
                props: {
                    size: 's',
                    align: 'center',
                },
                children: '¡Genial!<br/><br/>Has completado el Desafío B.'
            },
            {
                type: 'image',
                props: {
                    src: GoodHandCoordinator,
                    alt: 'good-hand-coordinator',
                    style: {
                        marginTop: '10px'
                    }
                }
            },
            {
                type: 'cardCopyKey',
                props: {
                    phrase: 'Desafío B Coordinador',
                    nameChallenge: 'Desafío B',
                }
            },
            {
                type: 'button',
                props: {
                    styles: {
                        position: 'relative',
                        marginTop: '96px',
                    },
                },
                children: 'Finalizar',
                action: 'finish',
            }
        ]
    }
];