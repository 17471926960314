import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../middleware/providers/user-context";
import Step from "../../molecules/step";
import Paragraph from "../../atoms/paragraph";
import TextField from "../../atoms/text-field";
import Button from "../../atoms/button";
import MoneyHand from "../../../resources/images/money-hand.png";
import CurrencyImage from "../../../resources/images/currency-image.png";
import WarningIcon from "../../../resources/images/warning.png";
import ACDCInductionLogo from "../../../resources/images/acdc-induction-logo.png";
import ScreenTouchHand from "../../../resources/images/screen-touch-hand.png";
import Book from "../../../resources/images/book.png";
import ClapHand from "../../../resources/images/clap-hand-host.png";
import Warning from "../../../resources/images/warning.png";
import GoodHand from "../../../resources/images/good-hand.png";
import Card from "../../atoms/card";
import CopyField from "../../atoms/copy-field";
import axios from "axios";
import { API } from "../../../middleware/utils/constants";
import Modal from "../../atoms/modal";
import { ReferenceBox } from "../../molecules/reference-box";

const HostInductionDay = ({ finishChallenge, step, setStep }) => {
    const { challengeName, idChallenge } = useParams();
    const { user } = useContext(UserContext);
    const [income, setIncome] = useState('');
    const [dailyHours, setDailyHours] = useState('');
    const [daysOfWeek, setDaysOfWeek] = useState('');
    const [weeksOfMonth, setWeeksOfMonth] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const [projection, setProjection] = useState({});
    const [scheduleInformation, setScheduleInformation] = useState({});
    const [calculateHourIncomes, setCalculateHourIncomes] = useState(false);
    const [calculateMonthlyIncomes, setCalculateMonthlyIncomes] = useState(false);
    const [acdcProjection, setAcdcProjection] = useState({});
    const [calculateModal, setCalculateModal] = useState(false);
    const [calculateModalMonthly, setCalculateModalMonthly] = useState(false);

    const handleButtonDisabled = () => {
        if (income.length > 0 && dailyHours.length > 0 && daysOfWeek.length > 0 && weeksOfMonth.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    };

    const formatNumber = (value) => {
        const newValue = typeof value === 'number' ? Math.trunc(value) : value;
        const n = newValue?.toString().replace(/\D/g, ""); // Elimina todo lo que no sea un dígito
        return n
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");  // Inserta comilla simple para los millones
    };

    const saveProjections = () => {
        const data = {
            monthlyIncome: parseInt(income.split('.').join('')),
            hoursWorkedPerDay: parseInt(dailyHours),
            daysWorkedPerWeek: parseInt(daysOfWeek),
            weeksWorkedPerMonth: parseInt(weeksOfMonth)
        };

        axios.put(`${API.SAVE_INCOMES_INFO.url}/${user?._id}`, data).then((response) => {
            if (response.status === 200) {
                setProjection({ ...projection, ...response.data.data });
                setStep(step + 1);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        handleButtonDisabled();
    }, [income, dailyHours, daysOfWeek, weeksOfMonth]);

    useEffect(() => {
        axios.get(`${API.GET_PROJECTION.url}?action=GET_PROJECTION`).then((response) => {
            if (response.status === 200) {
                setAcdcProjection(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });

        axios.get(`${API.GET_SCHEDULE_INFORMATION.url}/${user?._id}?action=GET_SCHEDULE_INFORMATION`).then((response) => {
            if (response.status === 200) {
                setScheduleInformation(response.data.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <>
            {challengeName === 'Desafío A' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'}>
                    <div className="day-rules-info">
                        <span className="greet">¡Hey!</span>
                        <img src={WarningIcon} alt="warning-icon" />
                        <Card className="card-warning" type="secondary">
                            <Paragraph className="card-warning__text" align="center" size="s">
                                <b>¡ATENCIÓN!
                                    <br />
                                    <br />
                                    La información que introduzcas en este Desafío A del Día de Inducción
                                    es sensible y personal..</b>
                                <br />
                                <br />
                                Tu Coordinador(a) NO tendrá acceso a las respuestas que ingreses, ya que se trata de información confidencial que no estará disponible para ningún miembro del equipo ACDC..

                            </Paragraph>
                        </Card>
                        <Button onClick={() => setStep(step + 1)}>¡Vale!</Button>
                    </div>
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="container-host-induction-day">
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph">
                        Te propongo el siguiente ejercicio, solo para que puedas reflexionar sobre el nivel de los ingresos mensuales que estás generando
                        <b> HOY 💰​:</b>
                    </Paragraph>
                    <span className="aux-title">En promedio...</span>
                    <div className="amount-values">
                        <div className="question">
                            <span>¿Cuál es el valor de tus ingresos mensuales?</span>
                            <TextField
                                name="income"
                                placeholder="0"
                                value={income}
                                inputMode="numeric"
                                minLength="1"
                                maxLength="20"
                                onChange={(e) => {
                                    setIncome(formatNumber(e.target.value));
                                }}
                                required />
                            <span className="currency">COP</span>
                        </div>
                        <div className="question">
                            <span>¿Cuántas horas trabajas en el día?</span>
                            <TextField
                                name="dailyHours"
                                placeholder="0"
                                inputMode="numeric"
                                minLength="1"
                                maxLength="2"
                                onChange={(e) => {
                                    setDailyHours(e.target.value);
                                }}
                                required />
                        </div>
                        <div className="question">
                            <span>¿Cuántos días trabajas a la semana?</span>
                            <TextField
                                name="daysOfWeek"
                                placeholder="0"
                                inputMode="numeric"
                                pattern="[0-9]"
                                minLength="1"
                                maxLength="1"
                                onChange={(e) => {
                                    setDaysOfWeek(e.target.value);
                                }}
                                required />
                        </div>
                        <div className="question">
                            <span>¿Cuántos semanas trabajas al mes?</span>
                            <TextField
                                name="weeksOfMonth"
                                placeholder="0"
                                inputMode="numeric"
                                pattern="[0-9]"
                                minLength="1"
                                maxLength="1"
                                onChange={(e) => {
                                    setWeeksOfMonth(e.target.value);
                                }}
                                required />
                        </div>
                    </div>
                    <Button onClick={saveProjections} disabled={buttonDisabled}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'} className="container-host-induction-day">
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph">
                        Si entras al juego y sigues los pasos para atravesar los diferentes niveles y desempeñar los 5 roles de liderazgo que comprende nuestra estrategia gamificada ACDC:
                    </Paragraph>
                    <span className="aux-title">En promedio...</span>
                    <div className="amount-values step-2">
                        <div className="question title">
                            <span className="label empty"></span>
                            <span className="label title">Hoy</span>
                            <span className="label title">
                                <img src={ACDCInductionLogo} alt="ACDC Logo" className="currency-image" />
                                Con ACDC
                            </span>
                        </div>
                        <div className="question">
                            <span className="label">Horas que trabajas al mes</span>
                            <TextField
                                name="hoursOfMonth"
                                placeholder="0"
                                value={formatNumber(projection?.hoursWorkedPerMonth)}
                                minLength="1"
                                maxLength="20"
                                disabled />
                            <TextField
                                name="hoursOfMonthACDC"
                                placeholder="0"
                                value={formatNumber(acdcProjection?.hoursWorked)}
                                minLength="1"
                                maxLength="20"
                                required
                                outline
                                disabled />

                        </div>
                        <div className="question">
                            <span className="label">Ingresos por hora</span>
                            <TextField
                                name="dailyHours"
                                placeholder="0"
                                value={formatNumber(projection?.hourlyIncome)}
                                minLength="1"
                                maxLength="20"
                                disabled />
                            <span className="currency first">COP</span>
                            {!calculateHourIncomes ? <Button className="calculate" style="ghost" size="small" onClick={() => {
                                setCalculateHourIncomes(true);
                                setCalculateModal(true);
                            }}>Calcular</Button>
                                : <TextField
                                    name="hoursOfMonthACDC"
                                    placeholder="0"
                                    value={formatNumber(acdcProjection?.hourlyIncomeWithAcdc)}
                                    minLength="1"
                                    maxLength="20"
                                    required
                                    outline
                                    disabled />}
                            <span className="currency">COP</span>
                        </div>
                        <div className="question">
                            <span className="label">Ingresos mensuales</span>
                            <TextField
                                name="daysOfWeek"
                                placeholder="0"
                                value={formatNumber(projection?.monthlyIncome)}
                                minLength="1"
                                maxLength="20"
                                disabled />
                            <span className="currency first">COP</span>
                            {!calculateMonthlyIncomes ? <Button className="calculate" style="ghost" size="small" onClick={() => {
                                setCalculateMonthlyIncomes(true);
                                setCalculateModalMonthly(true);
                            }}>Calcular</Button>
                                : <TextField
                                    name="hoursOfMonthACDC"
                                    placeholder="0"
                                    value={formatNumber(acdcProjection?.monthlyIncomeWithAcdc)}
                                    minLength="1"
                                    maxLength="20"
                                    required
                                    outline
                                    disabled />}
                            <span className="currency">COP</span>
                        </div>
                    </div>
                    <Paragraph size="xs" align="center" className="host-induction-day-paragraph bottom mt">
                        Conversión de pesos a dólar con una tasa equivalente a {formatNumber(acdcProjection?.dollarPrice)} COL$/USD.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={buttonDisabled}>Continuar</Button>
                </Step>
                <Modal isOpen={calculateModal} className="host-induction-day-modal">
                    <span className="close icon-close" onClick={() => setCalculateModal(false)} onKeyDown={() => { }}></span>
                    <img src={CurrencyImage} alt="Currency Image" className="currency-image" width={102} />
                    <Paragraph size="s" align="center" >
                        Con ACDC...
                        <br />
                        <h3>$ {formatNumber(acdcProjection?.hourlyIncomeWithAcdc)}</h3>
                        🫢 El valor de tu hora de trabajo se multiplicará por {Math.trunc(projection?.timesTheValueOfTheHourIsMultiplied)}.
                    </Paragraph>
                </Modal>
                <Modal isOpen={calculateModalMonthly} className="host-induction-day-modal">
                    <span className="close icon-close" onClick={() => setCalculateModalMonthly(false)} onKeyDown={() => { }}></span>
                    <img src={CurrencyImage} alt="Currency Image" className="currency-image" width={102} />
                    <Paragraph size="s" align="center" >
                        Con ACDC...
                        <br />
                        <h3>$ {formatNumber(acdcProjection?.monthlyIncomeWithAcdc)}</h3>
                        😀 {Math.trunc(projection?.incomeMultiplicationWithAcdc)} Veces más que los ingresos que generas hoy.
                    </Paragraph>
                </Modal>
                <Step order={4} visibility={step === 4 ? 'visible' : 'hidden'} className="container-host-induction-day center">
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph">
                        En la <b>Vida Real</b>, podrás generar {Math.trunc(projection?.incomeMultiplicationWithAcdc)} veces más los ingresos mensuales que hoy generas y el valor de una hora de tu trabajo, se multiplicará {Math.trunc(projection?.timesTheValueOfTheHourIsMultiplied)} veces.
                    </Paragraph>
                    <img src={MoneyHand} alt="Money Hand" className="money-hand" />
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph">
                        Y… 👉🏼 Trabajando solo el {parseFloat(projection?.percentageOfHoursWorkedAtACDC).toFixed(1)}% de las horas que trabajas hoy ¡{parseFloat(projection?.percentageReductionInTimeWorked).toFixed(1)}% menos del tiempo que trabajas actualmente!
                    </Paragraph>
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph bottom">
                        ¿Lo ves?
                        <br />
                        Acabas de calcular tu
                        <br />
                        <b>Costo de Oportunidad.</b>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} disabled={buttonDisabled}>Continuar</Button>
                </Step>
                <Step order={5} visibility={step === 5 ? 'visible' : 'hidden'} className="container-host-induction-day">
                    <Card type="secondary" outline className="container-host-induction-day-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                Tal como dice Ed Koch,
                                <br />
                                autor de <b>El Principio del 80/20:</b>
                                <br />
                                <br />
                                “El Principio del 80/20 establece que una minoría de causas, contribuciones o esfuerzos por lo general lleva a una mayoría de resultados, productos o recompensas”.
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="container-host-induction-day-button">Continuar</Button>
                </Step>
                <Step order={6} visibility={step === 6 ? 'visible' : 'hidden'} className="container-host-induction-day">
                    <Card type="secondary" outline className="container-host-induction-day-card">
                        <div className="card-read">
                            <Paragraph align="center" size="s">
                                <b>La PRIORIZACIÓN dentro <br /> de las Estrategias del Juego.</b>
                                <br />
                                <br />
                                “Una de las grandes ventajas de la estrategia del juego, es que permite fijar prioridades. El líder debe pensar y entender la siguiente pregunta: ¿Qué es lo más importante que debo hacer ahora?”
                                “El líder las asigna en el momento en que planifica su estrategia.
                                Sin esto, el líder pasará tiempo haciendo cosas “buenas” o incluso “grandiosas”, pero no las “mejores”. ”
                            </Paragraph>
                        </div>
                    </Card>
                    <div className="phrase-container">
                        <img src={Book} alt="Book" className="book" />
                        <Paragraph size="s" align="left">
                            Tomado del libro
                            <br />
                            <b>Lanzando una Revolución sobre el Liderazgo.</b>
                        </Paragraph>
                    </div>
                    <Button onClick={() => setStep(step + 1)} className="container-host-induction-day-button">Continuar</Button>
                </Step>
                <Step order={7} visibility={step === 7 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Genial!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Has completado el <b>Desafío A.</b>
                    </Paragraph>
                    <img src={ClapHand} alt="Clap Hand" className="good-hand" />
                    <Paragraph size="s" align="center" className="background-card">
                        Ahora que ya realizaste las actividades, continúa con el siguiente ▶️ VIDEO, para seguir con tu <b>Inducción.</b>
                        <br />
                        <br />
                        <b>👋🏻 Ya nos vemos.</b>
                    </Paragraph>
                    <Button onClick={() => finishChallenge(null)}>Ver video</Button>
                </Step>
            </>}
            {challengeName === 'Desafío B' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="container-host-induction-day center">
                    <Paragraph size="s" align="center" >
                        <b>En este momento estás realizando tu Desafío B.</b>
                        <br />
                        <br />
                        Para poder completarlo, debes dirigirte a
                        <b>Mis Validadores</b> y actualizar los estados de cada uno de ellos, de acuerdo con el resultado del ABC Convocatoria.
                        <br />
                        <br />
                        ¿Cuántos se han agendado?
                        <br />
                        ¿Cuántos no?
                    </Paragraph>
                    <div className="sync-meet-card">
                        <img src={Warning} alt="warning" className="sync" />
                        <Paragraph size="s" align="center" >
                            Procura terminar el <b>Desafío B</b> lo más pronto posible, así tendrás tiempo para realizar tu <b>Desafío C.</b> Recuerda que el día termina a las 9:30 pm.
                        </Paragraph>
                    </div>
                    <Paragraph size="xs" align="center" className="bottom-advice" >
                        Desde aquí también puedes copiar el link de invitación al EPA para tus Validadores.
                    </Paragraph>
                    <CopyField value={`${window.location.origin}/schedule/${user?._id}`} text="Copiar Link" setStep={() => setStep(step + 1)} />
                </Step>
                <Step order={2} visibility={step === 2 ? 'visible' : 'hidden'} className="container-host-induction-day center">
                    <span className="title">Zona de Entrenamiento ABC Convocatoria.</span>
                    <img src={ScreenTouchHand} alt="ACDC Logo" className="acdc-logo" />
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph">
                        Ahora debes ingresar a Whatsapp  al Grupo del EPA y Pegar el Link que acabas de Copiar. Así tu Coordinador(a) sabrá que ya iniciaste tu Desafío B y que estás listo(a) para terminar el Entrenamiento del  ABC Convocatorias.
                        Presta atención para que puedas prepararte de manera óptima para Agendar al EPA a tus Validadores.
                    </Paragraph>
                    <Paragraph size="s" align="center" className="host-induction-day-paragraph">
                        <b>¡Tu Coordinador(a) simulará ser un Validador para este Entrenamiento!</b>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={3} visibility={step === 3 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío B.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key">
                                Desafío B Anfitrión
                            </div>
                            <CopyField value=" Desafío B Anfitrión" setStep={() => {
                                finishChallenge(null);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío B</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                </Step>
            </>}
            {challengeName === 'Desafío C' && <>
                <Step order={1} visibility={step === 1 ? 'visible' : 'hidden'} className="container-host-induction-day center">
                    <Paragraph size="s" align="center">
                        ¡Ya casi has logrado la Meta!
                    </Paragraph>
                    <div className="counter-references-box">
                        <span>Tienes:</span>
                        <span className="featured">{scheduleInformation?.scheduled}</span>
                        <Paragraph size="s" align="center">
                            ...Aspirantes Agendados
                            Meta 1:  6 Aspirantes para el EPA.
                        </Paragraph>
                    </div>
                    <div className="counter-references-box">
                        <span>Hoy puedes agregar: </span>
                        <span className="featured">{scheduleInformation?.appointmentsToSchedule}</span>
                        <Paragraph size="s" align="center">
                            nuevas Referencias Personales
                            a la plataforma ACDC.
                        </Paragraph>
                    </div>
                    <Paragraph size="s" align="center">
                        ¡Ya estás habilitado(a) para hacerlo!
                        <br />
                        <br />
                        Usa el ABC Filtro Plan B, siguiendo los pasos que te dará TWOBOT cuando copies y le pegues la clave que encontrarás al final.
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)}>Continuar</Button>
                </Step>
                <Step order={2} visibility={step === 2 && scheduleInformation?.scheduled < 6 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center" style={{
                        marginTop: '23px',
                    }}>
                        🖐🏼 Antes de iniciar el ABC Filtro Plan B, considera incluir estas 3 personas recomendadas por tu Coordinador(a):
                    </Paragraph>
                    {user?.personalReferences.filter((reference) => reference.suggested).map((reference, index) => <ReferenceBox index={index + 1} name={reference.name} />)}
                    <Paragraph size="s" align="center" style={{
                        marginTop: '25px',
                    }}>
                        Tienes hasta el Día de Reglas del Juego para convocar a estos <b>3 Aspirantes</b> que te hacen falta para cumplir la meta.
                    </Paragraph>
                    <Paragraph size="s" align="center" style={{
                        marginTop: '25px',
                    }}>
                        <b>¡Adelante, estás a punto de conseguirlo!</b>
                    </Paragraph>
                    <Button onClick={() => setStep(step + 1)} styles={{
                        position: 'relative',
                        marginTop: '75px',
                    }}>Continuar</Button>
                </Step>
                <Step order={3} visibility={(step === 2 && scheduleInformation?.scheduled >= 6) || step === 3 ? 'visible' : 'hidden'}>
                    <Paragraph size="s" align="center">
                        <b>¡Vas super!</b>
                    </Paragraph>
                    <Paragraph size="s" align="center">
                        Te queda poco para completar el <b>Desafío C.</b>
                    </Paragraph>
                    <img src={GoodHand} alt="Good Hand" className="good-hand" />
                    <Card type="secondary" outline>
                        <div className="epa-schedule-card" >
                            <Paragraph align="center" size="s">
                                Copia la clave que deberás pegar y enviar a TWOBOT para continuar:
                            </Paragraph>
                            <div className="epa-schedule-card__key" style={{'width': '244px'}}>
                                {scheduleInformation?.scheduled < 6 ? 'Estrategia Desafío C Anfitrión' : 'Cierre Inducción Anfitrión'}
                            </div>
                            <CopyField value={scheduleInformation?.scheduled < 6 ? 'Estrategia Desafío C Anfitrión' : 'Cierre Inducción Anfitrión'} setStep={() => {
                                finishChallenge(null);
                            }} />
                            <Paragraph align="center" size="xs" padding={16}>
                                Podrás copiar la clave en cualquier momento desde la <span className="icon-info"></span> información del <b>Desafío C</b> en
                                <b>Mi Progreso</b>
                            </Paragraph>
                        </div>
                    </Card>
                </Step>
            </>}
        </>
    )
};

export default HostInductionDay;